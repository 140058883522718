import { useIonAlert } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import CreateApplicationJSX from "./CreateApplicationJSX"
import Loading from "../../../components/Loading"

const CreateApplication: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const appsReducer:ApplicationsState = useSelector((state: RootState) => state.apps)
    const [infoAlert] = useIonAlert()

    const [application, setApplication] = useState({
        name: "",
        description: "",
        primary_color: "",
        platforms: [],
        logo: null,
        cover: null,
        price: null
    })
    const [submitted, setSubmitted] = useState<boolean>(false)

    useEffect(() => {
        dispatch(ApplicationActions.readPlatforms({ arguments: { "hashed": true } }))
    }, [dispatch])

    useEffect(() => {
        if (submitted === false) return
        if (appsReducer.application.createStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: 'Application has been created.',
        })
        if (appsReducer.application.createStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: appsReducer.application.createMessage,
        })
    }, [appsReducer.application.createStatus, appsReducer.application.createMessage, infoAlert, submitted, dispatch])

    const handleSubmit = () => {
        if (application.name.length < 4) {
            infoAlert({
                header: 'Invalid name.', buttons: ['OK'],
                message: 'Application name must be longer than 4 characters.',
            })
        } else if (application.description.length < 64) {
            infoAlert({
                header: 'Invalid description.', buttons: ['OK'],
                message: 'Application description must be longer than 64 characters.',
            })
        } else if (/^#[0-9A-F]{6}$/i.test(application.primary_color) === false) {
            infoAlert({
                header: 'Invalid primary color.', buttons: ['OK'],
                message: 'Application primary color must be hexadecimal color code.',
            })
        } else if (application.cover === null) {
            infoAlert({
                header: 'Missing cover photo.', buttons: ['OK'],
                message: 'Application must have a cover photo.',
            })
        } else if (application.logo === null) {
            infoAlert({
                header: 'Missing logo.', buttons: ['OK'],
                message: 'Application must have a logo.',
            })
        } else if (application.price !== null && isNaN(parseInt(application.price)) === true) {
            infoAlert({
                header: 'Price issue.', buttons: ['OK'],
                message: 'Price must be a numerical value.',
            })
        }
        setSubmitted(true)
        const body = new FormData()
        body.append("name", application.name)
        body.append("description", application.description)
        body.append("primary_color", application.primary_color)
        body.append("platforms", JSON.stringify(application.platforms))
        body.append("logo", application.logo)
        body.append("cover", application.cover)
        body.append("price", application.price)

        dispatch(ApplicationActions.createApplication({ application: body}))
    }

    if (appsReducer.platforms.readStatus !== "fulfilled") return <Loading isOpen={true} message={appsReducer.platforms.readMessage} />
    if (appsReducer.application.createStatus === "pending") return <Loading isOpen={true} message={appsReducer.application.createMessage} />
    
    return <CreateApplicationJSX
        application={application}
        setApplication={setApplication}
        platforms={appsReducer.platforms.platforms.filter(p => p !== null && p !== undefined)}
        handleSubmit={handleSubmit}
    />
}

export default CreateApplication