import { IonItem } from "@ionic/react";
import SetNewSetting from "../../Settings/SetNewSetting";
import UpdateSetting from "../../Settings/UpdateSetting";
import Translate from "../../../components/Translate";

const ApplicationSettingsJSX = ({
    filled,
    unfilled,
    organization_id
}) => (
    <div className="flex flex-col gap-4 mb-4">
        <span className="block">
            <h3 className="mb-4 text-lg font-bold text-slate-700 w-[950px] mx-auto"><Translate label="Organization specific settings " /> : </h3>

            <div className="w-full overflow-x-auto">
                <IonItem className="border-b border-solid border-slate-800 w-[950px] mx-auto rounded-tl rounded-tr">
                    <span className="w-full flex p-2 items-center justify-between">
                        <h1 className="w-3/12 text-slate-700 font-semibold"><Translate label="Application" /> </h1>
                        <h1 className="w-5/12 text-slate-700 font-semibold"><Translate label="Parameter" /> </h1>
                        <h1 className="text-center w-2/12 text-slate-700 font-semibold"><Translate label="Value" /></h1>
                        <h1 className="text-center w-1/12 text-slate-700 font-semibold"><Translate label="Action" /></h1>
                    </span>
                </IonItem>
                {filled.map((f, idx) => <UpdateSetting
                    key={f.id}
                    setting={f}
                    current={f.settings.find(s => s.organization_id === organization_id)}
                    set_rounded={idx === filled.length - 1}
                />)}
                {unfilled.map((f, idx) => <SetNewSetting
                    key={f.id}
                    setting={f}
                    organization_id={organization_id}
                    set_rounded={idx === unfilled.length - 1}
                />)}
            </div>

        </span>
    </div>
)

export default ApplicationSettingsJSX