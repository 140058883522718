import { IonButton } from "@ionic/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { STATIC_URL } from "../../../Common/constants";
import { Applications, DLC, Purchase } from "../../../Common/Interfaces/Entities";
import { PurchaseActions } from "../../../Redux/Purchases/Actions";
import { AppDispatch } from "../../../Redux/Store";
import Translate from "../../../components/Translate";

const GeneralInformation: React.FC<{ branding: DLC, purchase: Purchase, application: Applications }> = ({
    branding,
    purchase,
    application
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [identifier, setIdentifier] = useState<string>(purchase?.custom_identifier === null ? "" : purchase?.custom_identifier)

    const handleChange = e => {
        const data = new FormData()
        data.append("id", purchase?.id?.toString())
        data.append("custom_identifier", identifier)

        dispatch(PurchaseActions.updatePurchase({ purchase: data }))
    }

    return (
        <div className="p-8 bg-white rounded-lg shadow mb-4">
            <span className="flex justify-between mb-3">
                <h3 className="text-slate-700 font-medium text-lg"><Translate label="General Information" /></h3>
                <span className="flex items-center">
                    <input
                        value={identifier} placeholder="Branding custom identifier"
                        onChange={e => setIdentifier(e.target.value)}
                        className="block px-3 text-base font-normal
                                   text-gray-700 bg-white bg-clip-padding
                                   border border-solid border-gray-300
                                   rounded transition ease-in-out h-10
                                   focus:text-gray-700 focus:bg-white 
                                   focus:border-cythero-primary focus:outline-none"/>
                    <IonButton onClick={e => handleChange(e)}>Update</IonButton>
                </span>
            </span>
            <span className="flex items-start">
                <img className="w-24 mr-6" src={STATIC_URL + branding?.cover_path} alt="branding cover" />
                <span>
                    <h3 className="text-slate-700 text-lg font-bold">{branding?.name}</h3>
                    <h3 className="text-slate-800"><b>Developed for:</b> {application?.name}</h3>
                    <br />
                    <h3 className="text-slate-800">{branding?.description}</h3>
                </span>
            </span>
        </div>
    )
}

export default GeneralInformation