import { IonInput, IonItem, IonLabel, IonList, IonTextarea } from "@ionic/react"


const Input = ({
    placeholder,
    name,
    handleChange,
    type,
    accept,
    value,
    disabled = false
}) => {
    return (
        <IonList className='select-list' >
            <IonItem className='select-list'>
                <IonLabel position="stacked">{placeholder}</IonLabel>
                {type === "textarea" ? <IonTextarea value={value} placeholder={placeholder} name={name} onIonChange={e => handleChange(e)} /> : type === "file" ?
                    <input className="mt-5" accept={accept} type={type} placeholder={placeholder} name={name} onChange={e => handleChange(e)} /> :
                    <IonInput disabled={disabled} type={type} value={value} placeholder={placeholder} name={name}  onIonChange={e => handleChange(e)} />
                }
            </IonItem>
        </IonList>
    )
}

export default Input