import { FiEdit } from "react-icons/fi"
import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../Common/constants"
import { Purchase } from "../../../Common/Interfaces/Entities"
import Translate from "../../../components/Translate"


const ProductCard: React.FC<{ purchase: Purchase, application_id: number | null, organization_id: number }> = ({ purchase, application_id, organization_id }) => {

    if (purchase.product.type === "application") {
        if (application_id !== null) return (
            <div className="flex my-2">
                <img className="w-12 h-12 mr-2" src={STATIC_URL + purchase.product.logo_path} alt="Application Logo" />
                <span>
                    <p className="text-slate-600 text-lg">{purchase.product.name}</p>
                    <p className="text-slate-600 text-sm"><Translate label="Purchased on" /> {purchase.purchase_date.replace(" GMT", "")}</p>
                </span>
            </div>)
        else return (
            <NavLink to={"/application_dashboard/" + organization_id + "/" + purchase.application_id} className="flex my-2">
                <img className="w-12 h-12 mr-2" src={STATIC_URL + purchase.product.logo_path} alt="Application Logo" />
                <span>
                    <p className="text-slate-600 text-lg">{purchase.product.name}</p>
                    <p className="text-slate-600 text-sm"><Translate label="Purchased on" /> {purchase.purchase_date.replace(" GMT", "")}</p>
                </span>
            </NavLink>
        )
    }
    else if (purchase.product.type.includes("branding")) {
        return (
            <div className="flex my-2">
                <img className="w-12 h-12 mr-2" src={STATIC_URL + purchase.product.cover_path} alt="Application Logo" />
                <span className="flex w-full justify-between items-center">
                    <span>
                        <p className="text-slate-600 text-base">
                            {purchase.product.name} ({purchase.custom_identifier === null ? "Not Defined" : purchase.custom_identifier})
                        </p>
                        <p className="text-slate-600 text-sm"><Translate label="Purchased on" /> {purchase.purchase_date.replace(" GMT", "")}</p>
                    </span>
                    <NavLink className="flex items-center justify-center w-8 h-8 bg-cythero-primary text-white rounded shadow" to={"/manage_branding/" + purchase.id}><FiEdit /></NavLink>
                </span>
            </div>
        )
    }
    else if (purchase.product.type.includes("dlc")) {
        return (
            <div className="flex my-2">
                <img className="w-12 h-12 mr-2" src={STATIC_URL + purchase.product.cover_path} alt="Application Logo" />
                <span>
                    <p className="text-slate-600 text-base">{purchase.product.name} {purchase.product.dlc_type_id === 5 ? (purchase.product.subscription_duration - Math.round((new Date().getTime() - new Date(purchase.purchase_date).getTime()) / (1000 * 3600 * 24))) + " Days Left" : null}</p>
                    <p className="text-slate-600 text-sm"><Translate label="Purchased on" /> {purchase.purchase_date.replace(" GMT", "")}</p>
                </span>
            </div>
        )
    }
    else if (purchase.product.type.includes("package")) {
        return (
            <div className="flex my-2">
                <img className="w-12 h-12 mr-2" src={STATIC_URL + purchase.product.cover_path} alt="Application Logo" />
                <span>
                    <p className="text-slate-600 text-base">{purchase.product.name}</p>
                    <p className="text-slate-600 text-sm"><Translate label="Purchased on" /> {purchase.purchase_date.replace(" GMT", "")}</p>
                </span>
            </div>
        )
    }

    return (
        <div className="flex my-2">
            <img className="w-12 h-12 mr-2" src={STATIC_URL + purchase.product.cover_path} alt="Application Logo" />
            <span>
                <p className="text-slate-600 text-base">Player License</p>
                <p className="text-slate-600 text-sm"><Translate label="Purchased on" /> {purchase.purchase_date.replace(" GMT", "")}</p>
            </span>
        </div>
    )
}

export default ProductCard