import Input from "../../../components/Form/Input";
import { IonButton } from "@ionic/react";
import Loading from "../../../components/Loading";
import Select from "../../../components/Form/Select";


const CreateOrganizationFormJSX = ({
    organizationReducer,
    org,
    setOrg,
    handleSubmit,
    applications,
    dlcs,
    packages
}) => (
    <div className="p-8 bg-white rounded-lg shadow mb-4">
        <Loading isOpen={organizationReducer.organization.createStatus === "pending"} message="Creating your organization... Please wait." />
        <h3 className="text-slate-700 font-medium text-lg">Register organization</h3>
        <Input
            type="text"
            name="name"
            value={org.name}
            placeholder="Enter organization name"
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            accept=""
        />
        <Input
            type="email"
            name="email"
            value={org.email}
            placeholder="Enter organization email"
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            accept=""
        />
        <Input
            type="text"
            name="ocolus_id"
            value={org.ocolus_id}
            placeholder="Enter Product Key"
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            accept=""
        />
        <Select 
            multiple={true}
            displayField="name"
            valueField="id"
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            options={applications.filter(a => a !== null && a !== undefined)}
            name="applications"
            value={org.applications}
            placeholder={"Select Applications"}
        />
        <Select 
            multiple={true}
            displayField="name"
            valueField="id"
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            options={dlcs.filter(a => a !== null && a !== undefined).map(dlc =>({...dlc, name: applications[dlc.application_id].name + ": " + dlc.name}))}
            name="dlcs"
            value={org.dlcs}
            placeholder={"Select DLC's"}
        />
        <Select 
            multiple={true}
            displayField="name"
            valueField="id"
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.value })}
            options={packages.filter(a => a !== null && a !== undefined).map(pkg =>({...pkg, name: applications[pkg.application_id].name + ": " + pkg.name}))}
            name="packages"
            value={org.packages}
            placeholder={"Select Packages"}
        />
        <Input
            accept="image/*"
            name="profile_picture"
            value={org.profile_picture}
            handleChange={e => setOrg({ ...org, [e.target.name]: e.target.files[0] })}
            type="file"
            placeholder="Select organization logo"
        />
        <IonButton onClick={handleSubmit} className="mt-2 ml-3">Register</IonButton>
        <span className="text-xs text-slate-600 block mt-8 ml-3">
            <p>Registering organization allows you to: </p>
            <ul>
                <li>- Work with a <b>group</b> of users of your choosing.</li>
                <li>- Purchase licenses to use with <b>multiple</b> devices.</li>
                <li>- Manage devices, see usage <b>logs and statistics</b>.</li>
                <li>- <b>Prioritized</b> customer support by our Cythero specialists.</li>
            </ul>
        </span>
    </div>
)

export default CreateOrganizationFormJSX