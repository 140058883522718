import { NavLink } from "react-router-dom"


const UserSearch = ({
    filter, setFilter, users
}) => (
    <div className="w-11/12 mx-auto my-10">
        <div className="inline-flex flex-col justify-center relative text-gray-500 w-full relative">
            <div className="relative">
                <input onChange={e => setFilter(e.target.value)} value={filter} type="text" className="w-full p-2 pl-8 rounded border border-gray-200 bg-gray-200 focus:bg-cythero-ultra-light focus:text-gray-600 focus:outline-none focus:ring-2 focus:ring-cythero-primary focus:border-transparent" placeholder="Search..." />
                <svg className="w-4 h-4 absolute left-2.5 top-3.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </div>
            <ul className="bg-white border border-gray-100 w-full mt-2 absolute top-10">
                {
                    filter === "" ? null : users.map(a => (
                        <li key={"user_" + a.id} className="pl-8 pr-2 py-2 border-b-2 border-gray-100 relative cursor-pointer hover:bg-cythero-ultra-light hover:text-gray-900">
                            <svg className="absolute w-4 h-4 left-2 top-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                            <NavLink to={"/users/" + a.id}>{a.username}</NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    </div>
)

export default UserSearch