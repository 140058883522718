import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ImageType } from "../../../Common/Interfaces/Entities"
import Input from "../../../components/Form/Input"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import Loading from "../../../components/Loading"

const checkCharBetweenNumbers = (str, char) => {
    const split = str.split(char)
    if (split.length !== 2) return false
    else if (isNaN(split[0]) || isNaN(split[1])) return false
    return true
}

const DlcImageType: React.FC<{
    id: number
    dlc_id: number,
    name: string
    prefered_aspect_ratio: string
    prefered_size: string
    offset_left: number
    offset_top: number
    scale: number
}> = ({
    id,
    dlc_id,
    name,
    prefered_aspect_ratio,
    prefered_size,
    offset_left,
    offset_top,
    scale,
}) => {
        const dispatch = useDispatch<AppDispatch>()
        const [modal, setModal] = useState<boolean>(false)
        const [infoAlert] = useIonAlert()
        const [submitted, setSubmitted] = useState<boolean>(false)
        const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

        const [type, setType] = useState<ImageType>({
            id: id,
            dlc_id: dlc_id,
            name: name,
            prefered_aspect_ratio: prefered_aspect_ratio,
            prefered_size: prefered_size,
            offset_left: offset_left,
            offset_top: offset_top,
            scale: scale,
        })

        useEffect(() => {
            if (submitted !== true) return
            if (["fulfilled", "rejected"].includes(ApplicationsReducer.image_types.updateStatus)) infoAlert({
                header: ApplicationsReducer.image_types.updateStatus === "fulfilled" ? 'Success' : "Error",
                buttons: ['OK'], message: ApplicationsReducer.image_types.updateMessage
            })

        }, [ApplicationsReducer.image_types.updateStatus, ApplicationsReducer.image_types.updateMessage])

        useEffect(() => {
            if (submitted !== true) return
            if (["fulfilled", "rejected"].includes(ApplicationsReducer.image_types.deleteStatus)) infoAlert({
                header: ApplicationsReducer.image_types.deleteStatus === "fulfilled" ? 'Success' : "Error",
                buttons: ['OK'], message: ApplicationsReducer.image_types.deleteMessage
            })
            if (ApplicationsReducer.image_types.deleteStatus === "fulfilled") setTimeout(() => setModal(false), 100)
        }, [ApplicationsReducer.image_types.deleteStatus, ApplicationsReducer.image_types.deleteMessage])

        const handleUpdate = e => {
            if (type.name.length < 5) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Please enter more specific image name (label).' })
            else if (!checkCharBetweenNumbers(type.prefered_aspect_ratio, ":")) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Aspect ratio must be formated in A:B format where A and B are whole positive numbers.' })
            else if (!checkCharBetweenNumbers(type.prefered_size, "x")) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Size must be formated in AxB format where A and B are whole positive numbers.' })
            else if (isNaN(type.offset_left)) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Offset left must be a numerical value' })
            else if (isNaN(type.offset_top)) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Offset top must be a numerical value' })
            else if (type.scale <= 0 || isNaN(type.scale)) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Scale must be a positiv decimal number.' })

            const data = new FormData()
            data.append("id", type.id.toString())
            data.append("dlc_id", type.dlc_id.toString())
            data.append("name", type.name)
            data.append("prefered_aspect_ratio", type.prefered_aspect_ratio)
            data.append("prefered_size", type.prefered_size)
            data.append("offset_left", type.offset_left.toString())
            data.append("offset_top", type.offset_top.toString())
            data.append("scale", type.scale.toString())
            dispatch(ApplicationActions.updateImageType({ type: data }))
            setSubmitted(true)
        }

        const handleDelete = e => {
            dispatch(ApplicationActions.deleteImageType({ arguments: { id: id } }))
            setSubmitted(true)
        }

        useEffect(() => {
            if (modal === false) {
                setSubmitted(false)
                setType({
                    id: id,
                    dlc_id: dlc_id,
                    name: name,
                    prefered_aspect_ratio: prefered_aspect_ratio,
                    prefered_size: prefered_size,
                    offset_left: offset_left,
                    offset_top: offset_top,
                    scale: scale,
                })
            }
        }, [modal, dlc_id, id, name, offset_left, offset_top, prefered_size, scale])

        return (
            <div className="bg-white w-48 shadow rounded p-4 mx-auto mb-5">
                <Loading isOpen={submitted === true && ApplicationsReducer.image_types.deleteStatus === "pending"} message={ApplicationsReducer.image_types.deleteMessage} />
                <Loading isOpen={submitted === true && ApplicationsReducer.image_types.updateStatus === "pending"} message={ApplicationsReducer.image_types.updateMessage} />
                <IonModal id='image_type-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
                    <IonContent>
                        <IonToolbar>

                            <IonTitle>Edit Image Type</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={e => setModal(false)}>CLOSE</IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <Input
                            placeholder="Enter image type name"
                            name="name"
                            handleChange={e => setType({ ...type, name: e.target.value })}
                            type="text"
                            accept="*"
                            value={type.name}
                        />
                        <Input
                            placeholder="Enter prefered image aspect ration (e.g 16:9)"
                            name="prefered_aspect_ratio"
                            handleChange={e => setType({ ...type, prefered_aspect_ratio: e.target.value })}
                            type="text"
                            accept="*"
                            value={type.prefered_aspect_ratio}
                        />
                        <Input
                            placeholder="Enter prefered image size (e.g 1920x1080)"
                            name="prefered_size"
                            handleChange={e => setType({ ...type, prefered_size: e.target.value })}
                            type="text"
                            accept="*"
                            value={type.prefered_size}
                        />
                        <Input
                            placeholder="Enter offset left in pixels"
                            name="offset_left"
                            handleChange={e => setType({ ...type, offset_left: e.target.value })}
                            type="text"
                            accept="*"
                            value={type.offset_left}
                        />
                        <Input
                            placeholder="Enter offset top in pixels"
                            name="offset_top"
                            handleChange={e => setType({ ...type, offset_top: e.target.value })}
                            type="text"
                            accept="*"
                            value={type.offset_top}
                        />
                        <Input
                            placeholder="Enter scale in decimal format (0.5 = 1/2, 2=2/1, 1.5=3/2 etc.)"
                            name="scale"
                            handleChange={e => setType({ ...type, scale: e.target.value })}
                            type="text"
                            accept="*"
                            value={type.scale}
                        />
                        <span className="flex justify-around">
                            <IonButton className="w-2/5" onClick={e => handleUpdate(e)}>UPDATE</IonButton>
                            <IonButton color={"danger"} className="w-2/5" onClick={e => handleDelete(e)}>DELETE</IonButton>
                        </span>
                    </IonContent>
                </IonModal>
                <div className="bg-slate-200 h-32 flex flex-col items-center justify-center">
                    <p className="font-bold text-slate-700 text-2xl">{prefered_size}</p>
                    <p className="font-bold text-slate-700">({prefered_aspect_ratio})</p>
                </div>
                <p className="mt-2 mb-3">{name}</p>
                <IonButton className="block" onClick={e => setModal(!modal)}>Edit</IonButton>
            </div>
        )
    }

export default DlcImageType