
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { getTranslation, userOwnsProduct } from "../../../../Common/Functions"
import { dateToDateStr } from "../../../../components/Form/DateSelect"
import UserOwnsAnalytics from "../../../../components/UserOwnsAnalytics/UserOwnsAnalytics"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { PurchasesState } from "../../../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { UserActions } from "../../../../Redux/User/Actions"
import { UserState } from "../../../../Redux/User/Interface"
import { prepareActivityLine, prepareSessionDataLine } from "../pret"
import UserSessions from "./UserSessions"
import WeldVRJSX from "./WeldVRJSX"
import Loading from "../../../../components/Loading"

const sum_in_reduce = (sum, b, key = null) => {
    if (key !== null) {
        b = b[key]
    }
    if (isNaN(parseInt(b)) !== true && b !== undefined) sum += parseInt(b)
    return sum
}

const WeldVR: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const id = parseInt(useParams<{ id: string }>().id)

    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const analytics: Analytics = useSelector((state: RootState) => state.analytics)
    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)

    const [range, setRange] = useState<"OVERALL" | "WEEK" | "MONTH" | "YTD">("OVERALL")
    const [selectedUser, setSelecterUser] = useState(null)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        if (analytics.weldingReport.status === "pending") return
        const data = new FormData()
        data.append("org_id", UserReducer.current.organization_id.toString())
        dispatch(AnalyticsActions.WeldVRReport({ filters: data }))
    }, [])

    if (UserReducer.readUserStatus !== "fulfilled") return <Loading isOpen={true} message="Loading report pre-requisits... Please wait." />
    if (analytics.weldingReport.status !== "fulfilled") return <Loading isOpen={true} message="Loading report data... Please wait." />


    const application = AppsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === id)

    let table_data = []
    try {
        table_data = Object.keys(analytics.weldingReport.data.user)
            .sort((a, b) => analytics.weldingReport.data.user[a]["time_spent"] > analytics.weldingReport.data.user[b]["time_spent"] ? 1 : -1).map(key => ({
                user: UserReducer.users.find(u => u !== null && u !== undefined && u.id === parseInt(key)),
                time_spent: Math.floor(analytics.weldingReport.data.user[key].time_spent / 60) + " hrs " + (analytics.weldingReport.data.user[key].time_spent % 60).toFixed(0) + " mins",
                most_used_method: Object.keys(analytics.weldingReport.data.user[key].methods_distribution).reduce((a, b) => analytics.weldingReport.data.user[key].methods_distribution[a] > analytics.weldingReport.data.user[key].methods_distribution[b] ? a : b),
                most_welded_part: Object.keys(analytics.weldingReport.data.user[key].parts_distribution).reduce((a, b) => analytics.weldingReport.data.user[key].parts_distribution[a] > analytics.weldingReport.data.user[key].parts_distribution[b] ? a : b),
                number_sessions: analytics.weldingReport.data.user[key].num_sessions,
                user_name: UserReducer.users.find(u => u !== null && u !== undefined && u.id === parseInt(key)).username,
                id: parseInt(key)
            }))
    } catch (e) {
        console.log(analytics.weldingReport.data.user)
        console.log(e)
    }

    const general_info = {
        number_of_sessions: analytics.weldingReport.data.timeline.length,
        time_spent_welding: analytics.weldingReport.data.timeline.reduce((sum, b) => sum_in_reduce(sum, b, "time_spent"), 0),
        avg_work_angle: (analytics.weldingReport.data.timeline.reduce((sum, b) => sum_in_reduce(sum, b, "avg_work_angle"), 0) / analytics.weldingReport.data.timeline.length).toFixed(2),
        avg_travel_angle: (analytics.weldingReport.data.timeline.reduce((sum, b) => sum_in_reduce(sum, b, "avg_travel_angle"), 0) / analytics.weldingReport.data.timeline.length).toFixed(2),
        avg_speed: (analytics.weldingReport.data.timeline.reduce((sum, b) => sum_in_reduce(sum, b, "avg_speed"), 0) / analytics.weldingReport.data.timeline.length).toFixed(2),
        avg_distance: (analytics.weldingReport.data.timeline.filter(i => i.method !== "STICK").reduce((sum, b) => sum_in_reduce(sum, b, "avg_distance"), 0) / analytics.weldingReport.data.timeline.filter(i => i.method !== "STICK").length).toFixed(2)
    }

    const handleRangeChange = _range => {
        setRange(_range)

        const data = new FormData()
        data.append("org_id", UserReducer.current.organization_id.toString())
        if (_range !== "OVERALL") {
            const today = new Date;
            let from, to

            if (_range === "WEEK") {
                from = new Date(today.setDate(today.getDate() - today.getDay()))
                to = new Date(today.setDate(today.getDate() - today.getDay() + 6))
            } else if (_range === "MONTH") {
                from = new Date(today.getFullYear(), today.getMonth(), 1)
                to = new Date(today.getFullYear(), today.getMonth() + 1, 0)
            } else if (_range === "YTD") {
                from = new Date(today.getFullYear(), 0, 1)
                to = new Date()
            }

            from = {
                day: from.getDate() < 10 ? "0" + from.getDate() : from.getDate(),
                month: (from.getMonth() + 1) < 10 ? "0" + (from.getMonth() + 1) : (from.getMonth() + 1),
                year: from.getFullYear()
            }
            to = {
                day: to.getDate() < 10 ? "0" + to.getDate() : to.getDate(),
                month: (to.getMonth() + 1) < 10 ? "0" + (to.getMonth() + 1) : (to.getMonth() + 1),
                year: to.getFullYear()
            }

            data.append("from", dateToDateStr(from, "00:00:00"))
            data.append("to", dateToDateStr(to, "23:59:59"))
        }
        dispatch(AnalyticsActions.WeldVRReport({ filters: data }))
    }
    const colors = ["#f87171", "#10b981", "#3b82f6", "#6e71f1", "#c34cf1"]


    return (
        <UserOwnsAnalytics
            application={application}
            purchases={ShopReducer.purchases}
            user={UserReducer.current}
        >
            <WeldVRJSX
                settings_statistics={analytics.weldingReport.data.settings_statistics}
                methods_pie={["MIG", "TIG", "STICK"].map((method, index) => ({
                    id: getTranslation(method), label: getTranslation(method), color: colors[index],
                    value: analytics.weldingReport.data.timeline.filter(item => item.method === method).length
                }))}
                parts_pie={["Butt Joint", "Lap Joint", "Tee Joint", "Corner Joint", "Edge Joint"].map((part, index) => ({
                    id: getTranslation(part), label: getTranslation(part), color: colors[index],
                    value: analytics.weldingReport.data.timeline.filter(item => item.part === part).length
                }))}
                general_info={general_info}
                range={range}
                setRange={handleRangeChange}
                application={application}
                table_data={table_data}
                setSelecterUser={setSelecterUser}
            >
                <UserSessions
                    sessions={analytics.weldingReport.data.timeline.filter(tl => tl.user_id === selectedUser)}
                    user={selectedUser === null ? null : UserReducer.users.find(u => u !== null && u !== undefined && u.id === selectedUser)}
                    setSelectrUser={setSelecterUser}
                />
            </WeldVRJSX>
        </UserOwnsAnalytics>
    )
}

export default WeldVR