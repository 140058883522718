import { IonButton } from "@ionic/react"
import { TailSpin } from "react-loader-spinner"
import DeviceCard from "./DeviceCard"
import Loading from "../../../components/Loading"
import Translate from "../../../components/Translate"

const DevicesListJSX = ({
    application_id,
    loading,
    deleting,
    devices,
    count,
    showMore,
    setShowMore,
    available
}) => (
    <div className="p-8 bg-white rounded-lg shadow mb-4">
        <h3 className="text-slate-700 font-medium text-lg flex justify-between items-center"><Translate label="Application Devices" /><p>{devices.length} / {available}</p></h3>
        <Loading isOpen={deleting} message="Deleting device from list... Please wait." />
        <span className="flex justify-center items-center mb-5">
            <TailSpin
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
            />
        </span>
        {devices.length > 0 ? null : <p className="text-slate-600">There are no devices</p>}
        {devices.map(device => <DeviceCard key={device.id} device={device} />)}
        {count > 5 ? <IonButton className="mt-5" onClick={e => setShowMore(!showMore)}><Translate label={showMore ? "Show Less" : "Show More"} /></IonButton> : null}
    </div>
)

export default DevicesListJSX