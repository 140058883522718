import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { useEffect, useState } from "react"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { IonButton, IonContent, IonHeader, IonModal, useIonAlert } from "@ionic/react"
import Loading from "../../components/Loading"
import { FaWindowClose } from "react-icons/fa"
import { setUserState } from "../../Redux/User/Slice"
import { DataGrid } from '@mui/x-data-grid'
import { darken, lighten, styled } from '@mui/material/styles';

const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Unverified': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Banned': {
    backgroundColor: getBackgroundColor(
      theme.palette.grey[500],
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Exceeded': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const RegisteredEmailsContainer: React.FC<{}> = ({ }) => {
  const dispatch = useDispatch<AppDispatch>()
  const UserReducer: UserState = useSelector((state: RootState) => state.user)
  const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
  const [delUserId, setDelUserId] = useState<number | null>(null)
  const [time, setTime] = useState<number | null>(null)
  const [confirmationModal] = useIonAlert()
  const [banned, setBanned] = useState(null)

  useEffect(() => {
    dispatch(UserActions.readDemoUsers({ arguments: {} }))
    dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
  }, [])

  useEffect(() => {
    if (UserReducer.demoUsers.deleteStatus === "fulfilled" && delUserId !== null) {
      setTimeout(() => {
        setDelUserId(null)
        dispatch(setUserState({
          ...UserReducer,
          demoUsers: {
            ...UserReducer.demoUsers,
            deleteStatus: "idle"
          }
        }))
      }, 1500)
    } else if (UserReducer.demoUsers.deleteStatus === "rejected" && delUserId !== null) {
      setTimeout(() => {
        dispatch(setUserState({
          ...UserReducer,
          demoUsers: {
            ...UserReducer.demoUsers,
            deleteStatus: "idle"
          }
        }))
      }, 1500)
    }
  }, [UserReducer.demoUsers.deleteStatus])

  useEffect(() => {
    if (UserReducer.demoUsers.updateStatus === "fulfilled" && delUserId !== null) {
      setTimeout(() => {
        setDelUserId(null)
        setBanned(null)
        dispatch(setUserState({
          ...UserReducer,
          demoUsers: {
            ...UserReducer.demoUsers,
            updateStatus: "idle"
          }
        }))
        setTime(null)
      }, 1500)
    } else if (UserReducer.demoUsers.updateStatus === "rejected" && delUserId !== null) {
      setTimeout(() => {
        dispatch(setUserState({
          ...UserReducer,
          demoUsers: {
            ...UserReducer.demoUsers,
            updateStatus: "idle"
          }
        }))
        setTime(null)
        setBanned(null)
      }, 1500)
    }
  }, [UserReducer.demoUsers.updateStatus])

  function copyToClipboard(text) {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);

    tempInput.value = text;
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  const downloadTxtFile = (e) => {
    e.preventDefault()
    const element = document.createElement('a');
    let csvContent = "Email,Organization,Platform,Date Registered,Last Session,Location,Time Played(min),Allowed Time(min),Status\n"
    for (const user of UserReducer.demoUsers.users) {
      const email = user.serial_number === null ? user.email.replaceAll(",", " ") : user.serial_number.replaceAll(",", " ")
      const platform = user.serial_number === null ? "Meta" : "Pico"
      const organization = user.name.replaceAll(",", " ")
      const date_registered = user.date_registered.replaceAll(",", " ")
      const last_session = user.last_session.replaceAll(",", " ")
      const location = (user.location.length === 0 ? "Not Defined" : user.location).replaceAll(",", " ")
      const timePlayedHours = (user.time_played / 60).toFixed(1)
      const allowedTimeHours = (user.allowed_time / 60).toFixed(1)
      const status = user.banned === 1 ? "Banned" : user.verified === 1 ? "Verified" : "Unverified"
      
      csvContent += `${email},${organization},${platform},${date_registered},${last_session},${location},${timePlayedHours},${allowedTimeHours},${status}\n`;
    }
    element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
    element.setAttribute('download', "Emails.csv");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  if (ApplicationsReducer.application.readStatus === "pending" || UserReducer.demoUsers.readStatus === "pending") return <Loading isOpen={true} message="Loading data... Please wait." />

  const columns = [
    { field: 'email', headerName: 'Email OR Serial Number', width: 310, hide: true },
    { field: 'organization', headerName: 'Organization', width: 110 },
    { field: 'platform', headerName: 'Platform', width: 110 },
    { field: 'application', headerName: 'Application', width: 200 },
    { field: 'date_registered', headerName: 'Register Date', type: 'date', width: 100 },
    { field: 'last_session', headerName: 'Last Session', type: "date", width: 100 },
    { field: 'location', headerName: 'Location', width: 230 },
    { field: 'time_played', headerName: 'Played (min)', width: 90, type: "number" },
    { field: 'allowed_time', headerName: 'Allowed (min)', width: 100, type: "number" },
    { field: 'status', headerName: 'Status', width: 100 },
  ];

  const rows = UserReducer.demoUsers.users.map(user => ({
    id: user.id,
    email: user.serial_number === null ? user.email : user.serial_number,
    organization: user.name,
    platform: user.serial_number === null ? "Meta" : "Pico",
    application: ApplicationsReducer.application.applications.find(app => app !== null && app !== undefined && app.id === user.application_id).name ,
    date_registered: new Date(user.date_registered),
    last_session: new Date(user.last_session),
    location: user.location.length === 0 ? "Not Defined" : user.location,
    time_played: (user.time_played / 60).toFixed(1),
    allowed_time: (user.allowed_time / 60).toFixed(1),
    status: user.banned === 1 ? "Banned" : user.verified === 1 ? "Verified" : "Unverified"
  }))


  return (
    <div className="w-full mx-auto">
      <div className="w-[1350px] max-w-[95%] mx-auto bg-white mb-12 mt-12">
        <span className="flex justify-between items-center px-3 py-1 bg-slate-700">
          <p className="text-white font-bold uppercase text-xl">List of collected user emails</p>
          <IonButton color={"light"} onClick={e => downloadTxtFile(e)}>Download Emails</IonButton>
        </span>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick={true}
          isRowSelectable={() => true}
          onRowDoubleClick={e => setDelUserId(Number(e.id))}
          onCellClick={e => copyToClipboard(e.formattedValue)}
          onFilterModelChange={a => console.log(a)}
          getRowClassName={(params) => {
            return `super-app-theme--${params.row.status === "Banned" ? "Banned" : params.row.status === "Unverified" ? "Unverified" : parseFloat(params.row.time_played) >= parseFloat(params.row.allowed_time) ? "Exceeded" : ""}`
          }}
        />
      </div>
      <IonModal key={"delete_User_key"} isOpen={delUserId !== null} onWillDismiss={e => {
        setDelUserId(null)
        setTime(null)
        setBanned(null)
      }}>
        <IonHeader>
          <div className="w-full bg-cythero-primary px-2 py-3 flex justify-between items-center">
            <h1 className="font-bold text-white">Maage User Email</h1>
            <FaWindowClose className="text-white text-lg" onClick={e => {
              setDelUserId(null)
              setTime(null)
              setBanned(null)
            }} />
          </div>
        </IonHeader>
        <IonContent>
          <div className="w-full h-full bg-slate-100 p-4 relative">
            <div className="mb-4">
              <h1 className="text-slate-800 font-bold mb-2">Delete user permanently</h1>
              <IonButton disabled={UserReducer.demoUsers.deleteStatus === "pending"} color={"danger"} onClick={e => {
                confirmationModal({
                  "message": "This will PERMANENTLY delete this user.", "header": "Alert!", buttons: [
                    {
                      text: 'No', role: 'cancel',
                      handler: () => { }
                    },
                    {
                      text: 'Yes', role: 'confirm',
                      handler: e => {
                        const data = new FormData()
                        data.append("id", delUserId.toString())
                        dispatch(UserActions.deleteDemoUsers({ "user": data }))
                      }
                    }
                  ]
                })
              }}>DELETE USER EMAIL</IonButton>
              {UserReducer.demoUsers.deleteStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.deleteMessage}</h1> : null}
              {UserReducer.demoUsers.deleteStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.deleteMessage}</h1> : null}
              {UserReducer.demoUsers.deleteStatus === "fulfilled" ? <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.deleteMessage}</h1> : null}
            </div>
            <div className="mb-4">
              <h1 className="text-slate-800 font-bold mb-2">Manage user status:</h1>
              <IonButton className="mb-2" color={"danger"} disabled={UserReducer.demoUsers.updateStatus === "pending"} onClick={e => {
                setBanned(UserReducer.demoUsers.users?.find(u => u !== null && u !== undefined && u.id === delUserId)?.banned === 1 ? false : true)
                const data = new FormData()
                data.append("id", delUserId.toString())
                data.append("banned", (UserReducer.demoUsers.users?.find(u => u !== null && u !== undefined && u.id === delUserId)?.banned === 1 ? 0 : 1).toString())
                dispatch(UserActions.updateDemoUsers({ "user": data }))
              }}>{
                  UserReducer.demoUsers.users?.find(u => u !== null && u !== undefined && u.id === delUserId)?.banned === 1 ? "Unban" : "Ban"
                }</IonButton>

              {banned !== null && UserReducer.demoUsers.updateStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.updateMessage}</h1> : null}
              {banned !== null && UserReducer.demoUsers.updateStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.updateMessage}</h1> : null}
              {banned !== null && UserReducer.demoUsers.updateStatus === "fulfilled" ? <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{banned === true ? "The ban hammer has spoken." : "User has been unbanned."}</h1> : null}
            </div>
            <div className="mb-4">
              <h1 className="text-slate-800 font-bold mb-2">Update user allowed time</h1>
              <span className="flex justify-between items-center">
                <input className="w-full px-3 py-0 h-9 border border-slate-500 rounded" type="number" value={
                  time === null ? UserReducer.demoUsers.users?.find(u => u !== null && u !== undefined && u.id === delUserId)?.allowed_time : time
                } onChange={e => setTime(parseInt(e.target.value))} />
                <IonButton disabled={UserReducer.demoUsers.updateStatus === "pending"} onClick={e => {
                  const data = new FormData()
                  data.append("id", delUserId.toString())
                  data.append("allowed_time", time.toString())
                  dispatch(UserActions.updateDemoUsers({ "user": data }))
                }}>UPDATE TIME</IonButton>
              </span>
              {banned === null && UserReducer.demoUsers.updateStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.updateMessage}</h1> : null}
              {banned === null && UserReducer.demoUsers.updateStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.updateMessage}</h1> : null}
              {banned === null && UserReducer.demoUsers.updateStatus === "fulfilled" ? <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.demoUsers.updateMessage}</h1> : null}
            </div>
          </div>
        </IonContent>
      </IonModal>
    </div>
  )
}

export default RegisteredEmailsContainer