const OrganizationSearch:React.FC<{ filter: string, setFilter: Function }> = ({ filter, setFilter }) => {

    return (
        <div className="w-11/12 mx-auto my-10">
        <div className="inline-flex flex-col justify-center relative text-gray-500 w-full relative">
            <div className="relative">
                <input onChange={e => setFilter(e.target.value)} value={filter} type="text" className="w-full p-2 pl-8 rounded border border-gray-200 bg-gray-200 focus:bg-cythero-ultra-light focus:text-gray-600 focus:outline-none focus:ring-2 focus:ring-cythero-primary focus:border-transparent" placeholder="Search..." />
                <svg className="w-4 h-4 absolute left-2.5 top-3.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
            </div>
        </div>
    </div>
    )
}

export default OrganizationSearch