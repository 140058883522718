import { useIonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../Common/Functions";
import { OrganizationActions } from "../../../Redux/Organizations/Actions";
import { OrganizationsState } from "../../../Redux/Organizations/Interface";
import { AppDispatch, RootState } from "../../../Redux/Store";
import { UserState } from "../../../Redux/User/Interface";
import CreateOrganizationFormJSX from "./CreateOrganizationFormJSX";
import { ApplicationActions } from "../../../Redux/Applications/Actions";
import { ApplicationsState } from "../../../Redux/Applications/Interface";

const CreateOrganizationForm: React.FC<{ organizationReducer: OrganizationsState }> = ({ organizationReducer }) => {
    const dispatch = useDispatch<AppDispatch>()

    const userReducer: UserState = useSelector((state: RootState) => state.user)
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    const [org, setOrg] = useState<{ name: string, email: string, profile_picture: any, ocolus_id: string, applications, dlcs, packages }>({
        name: "",
        email: "",
        ocolus_id: "",
        profile_picture: null,
        applications: [],
        dlcs: [],
        packages: [],
    })
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [infoAlert] = useIonAlert()

    useEffect(() => {
        if (submitted === false) return
        if (organizationReducer.organization.createStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'], message: organizationReducer.organization.createMessage })
        if (organizationReducer.organization.createStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'], message: organizationReducer.organization.createMessage })
    }, [organizationReducer.organization.createStatus, organizationReducer.organization.createMessage, submitted, infoAlert])

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: { } }))
        dispatch(ApplicationActions.readPackages({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
    }, [])

    console.log(org.applications, org.dlcs, org.packages)

    const handleSubmit = () => {
        if (org.name.length < 4) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Organization name must be at least 4 characters long.' })
        if (validateEmail(org.email) === null) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Invalid email.' })
        if (org.profile_picture === null) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'You must select an organization logo / picture.' })

        setSubmitted(true)
        const form = new FormData()
        form.append("name", org.name)
        form.append("email", org.email)
        form.append("ocolus_id", org.ocolus_id)
        form.append("profile_picture", org.profile_picture)
        form.append("creator_id", userReducer.current.id.toString())
        dispatch(OrganizationActions.createOrganization({ "organization": form }))
    }

    return <CreateOrganizationFormJSX
        organizationReducer={organizationReducer}
        org={org}
        setOrg={setOrg}
        handleSubmit={handleSubmit}
        applications={appsReducer.application.applications}
        dlcs={appsReducer.dlc.dlcs}
        packages={appsReducer.packages.packages}
    />
}

export default CreateOrganizationForm