import { useIonAlert } from "@ionic/react"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { User } from "../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import CartContainerJSX from "./CartContainerJSX"
import Loading from "../../components/Loading"
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_live_51NqXxRKsbQ6giahqCaV3kj53KpG097HG21QCO7B3GZSro9ekMC8ty3yWyEe3AgiwXvxnq7et8K7ePunJH04h9Pyy00wjLNSCzZ');

const CartContainer: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()

    const PurchasesReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const current: User = useSelector((state: RootState) => state.user.current)
    const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    const [submitted, setSubmitted] = useState<boolean>(false)
    const [ionAlert] = useIonAlert()

    useEffect(() => {
        dispatch(PurchaseActions.readCart({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readPackages({ arguments: {} }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
    }, [])

    useEffect(() => {
        if (submitted === false) return

        if (PurchasesReducer.createStatus === "fulfilled") {
            ionAlert({
                header: "Siccess", buttons: [{
                    text: 'Close',
                    handler: () => {
                        // const tryPayment = async () => {
                        //     const stripe = await stripePromise;
                        //     const result = await stripe.redirectToCheckout({ sessionId: PurchasesReducer.checkout_id });

                        //     if (result.error) {
                        //         console.error('Error during payment:', result.error);
                        //     }
                        // }
                        // tryPayment()
                    },
                }], message: "Purchase request created successfully. Waiting for approval."
            })
        }
        else if (PurchasesReducer.createStatus === "rejected") ionAlert({
            header: "Error", buttons: ["OK"], message: PurchasesReducer.createMessage
        })

    }, [submitted, PurchasesReducer.createStatus, PurchasesReducer.createMessage])

    const handleSubmit = e => {
        let currentUserItems = PurchasesReducer.cart.carts.filter(item => item !== null && item !== undefined && item.user_id === current.id)

        const data = new FormData()
        data.append("items", JSON.stringify(currentUserItems.map(item => ({
            ...item, type: item.dlc_id !== null ? "dlc" : item.application_id !== null ? "application" : "package"
        }))))
        data.append("user_id", current.id.toString())
        setSubmitted(true)
        dispatch(PurchaseActions.createPurchase({ purchase: data }))
    }

    if (
        PurchasesReducer.cart.readStatus === "pending" ||
        ApplicationsReducer.application.readStatus === "pending" ||
        ApplicationsReducer.dlc.readStatus === "pending"
    ) return <Loading isOpen={true} message="Loading cart info... Please wait." />
    const currentUserItems = PurchasesReducer.cart.carts.filter(item => item !== null && item !== undefined && item.user_id === current.id)
    const getTotal = () => {
        let total = 0
        for (const item of currentUserItems) {
            if (item.dlc_id !== null)
                total += item.quantity * ApplicationsReducer.dlc.dlcs.find(d => d !== null && d !== undefined && d.id === item.dlc_id)?.price
            else if (item.package_id !== null)
                total += item.quantity * ApplicationsReducer.packages.packages.find(d => d !== null && d !== undefined && d.id === item.package_id)?.price
            else
                total += item.quantity * ApplicationsReducer.application.applications[item.application_id]?.price
        }
        return total.toFixed(2)
    }
    return (
        <Fragment>
            {PurchasesReducer.cart.deleteStatus === "pending" ? <Loading isOpen={true} message="Loading cart info... Please wait." /> : null}
            {PurchasesReducer.createStatus === "pending" ? <Loading isOpen={true} message="Processing purchase request... Please wait." /> : null}
            <CartContainerJSX
                cartItems={currentUserItems}
                ApplicationsReducer={ApplicationsReducer}
                getTotal={getTotal}
                handleSubmit={handleSubmit}
            />
        </Fragment>
    )
}

export default CartContainer