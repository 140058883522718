import { STATIC_URL } from "../../Common/constants";
import { Applications } from "../../Common/Interfaces/Entities";
import Translate from "../../components/Translate";


const ApplicationInfo: React.FC<{ application: Applications }> = ({
    application
}) => {

    return (
        <div className="p-2 bg-white rounded-lg shadow mb-4 flex">
            <span className="flex justify-center mr-5">
                <img className="w-24 col-span-1 mx-auto" src={STATIC_URL + application.logo_path} alt="application logo" />
            </span>
            <span className="col-span-2 flex flex-col justify-center">
                <h1 className="text-lg font-bold text-slate-600">{application.name}</h1>
                <h1 className="text-md text-slate-600"><Translate label="Supported On" />: {application.supported_platforms.join(", ")}</h1>
            </span>
        </div>
    )
}

export default ApplicationInfo