import { IonButton } from "@ionic/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../Common/constants"
import { ApplicationPackages, PackageItems, User } from "../../../Common/Interfaces/Entities"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { PurchaseActions } from "../../../Redux/Purchases/Actions"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { getTranslation } from "../../../Common/Functions"


const PackageItem:React.FC<{
    item: PackageItems
    reducer: ApplicationsState
}> = ({
    item,
    reducer
}) => {
    let product
    if (item.application_id !== null) product = reducer.application.applications.find(a => a !== null && a !== undefined && a.id === item.application_id)
    else product = reducer.dlc.dlcs.find(d => d !== null && d !== undefined && d.id === item.dlc_id)
    return (
        <div className="flex items-center mb-3 justify-between">
            <h1 className="text-slate-800">{item.quantity}x {product.name}</h1>
            <img alt="product logo photo" className="w-8 mr-2" src={STATIC_URL + (product.logo_path || product.cover_path)} />
        </div>
    )
}

const PackageCard: React.FC<{
    _package: ApplicationPackages
    application_id: number
    current: User
}> = ({
    _package,
    application_id,
    current,
}) => {
        const dispatch = useDispatch<AppDispatch>()
        const PurchasesReducer: PurchasesState = useSelector((state: RootState) => state.shop)
        const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
        const item_in_cart = PurchasesReducer.cart.carts.find(item => item !== null && item !== undefined && item.package_id === _package.id && item.user_id === current.id)

        const handleCart = e => {
            if (item_in_cart === undefined) {
                const data = new FormData()
                data.append("user_id", current.id.toString())
                data.append("package_id", _package.id.toString())
                dispatch(PurchaseActions.createCart({ "cart": data }))
            } else dispatch(PurchaseActions.deleteCart({ arguments: { id: item_in_cart.id } }))
        }
        // let user_owns_dlc = userOwnsProduct(PurchasesReducer.purchases, current, "dlc", dlc.id)

        return (
            <div key={_package.id} className="w-[95%] md:w-[300px] p-5 shadow bg-white rounded flex flex-col items-between justify-between">
                <span className="w-full flex items-center mb-5">
                    <img alt="package cover photo" className="w-16 mr-3" src={STATIC_URL + _package.cover_path} />
                    <div>
                        <NavLink className="font-semibold" to={"/applications/packages/" + application_id}>{_package.name} </NavLink>
                        <p>€{_package.price} EUR</p>
                    </div>
                </span>
                {_package.items.map(item => <PackageItem item={item} reducer={appsReducer} />)}
                <IonButton onClick={e => handleCart(e)} className="block mt-4">{item_in_cart === undefined ? getTranslation("ADD TO CART") : getTranslation("REMOVE FROM CART")}</IonButton>
            </div>
        )
    }

export default PackageCard