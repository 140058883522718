import { BsChevronCompactRight } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import Select from "../../../components/Form/Select"
import Input from "../../../components/Form/Input"
import { IonButton, IonCheckbox, IonItem, IonLabel } from "@ionic/react"

const CreateDlcJSX = ({
    appliation,
    dlc_types,
    dlc,
    setDlc,
    handleSubmit
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700 mb-6">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/dlcs/" + appliation.id}>DLC</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/dlc/edit/" + dlc.id}>Create DLC</NavLink>
        </span>
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">Create new DLC</h1>
            <span className="w-full block pb-10 px-4">
                <Select
                    value={dlc.dlc_type_id}
                    multiple={false}
                    options={dlc_types.filter(r => r !== null)}
                    displayField="name"
                    valueField="id"
                    name="dlc_type_id"
                    placeholder="Select DLC type"
                    handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.value })}
                />
                <Input
                    name="name"
                    value={dlc.name}
                    type="text"
                    placeholder="DLC Name"
                    handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.value })}
                />
                <Input
                    name="description"
                    value={dlc.description}
                    type="textarea"
                    placeholder="Enter description about the DLC"
                    handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.value })}
                />
                <Input
                    name="price"
                    value={dlc.price}
                    type="number"
                    placeholder="Enter DLC price in EUR"
                    handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.value })}
                />
                {dlc.dlc_type_id === 5 ? <Input
                    name="subscription_duration"
                    value={dlc.subscription_duration}
                    type="number"
                    placeholder="Enter Subscription Duration in days"
                    handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.value })}
                /> : null}
                <Input accept="image/*" name="dlc_cover" handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select DLC cover photo" />
                {dlc.dlc_type_id === 3 ? <Input accept="image/*" name="branding_scene" handleChange={e => setDlc({ ...dlc, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select branding scene photo" /> : null}
                <IonItem>
                    <IonCheckbox slot="start" checked={dlc.multiple_purchase === 1 ? true : false} onIonChange={e => setDlc({ ...dlc, multiple_purchase: Number(e.detail.checked) })}></IonCheckbox>
                    <IonLabel>Multiple Purchase Allowed</IonLabel>
                </IonItem>
                <IonButton className="mt-5" onClick={handleSubmit}>Create DLC</IonButton>
            </span>
        </div>
    </div>
)

export default CreateDlcJSX