import { IonButton } from "@ionic/react"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../Redux/Store"
import ChartContainer from "../../Charts/ChartContainer"
import LineChart from "../../Charts/LineChart"
import PieChart from "../../Charts/PieChart"
import { UserState } from "../../../../Redux/User/Interface"
import Loading from "../../../../components/Loading"
import Translate from "../../../../components/Translate"
import { getTranslation } from "../../../../Common/Functions"


const WeldingPartReport = props => {

    const userReducer: UserState = useSelector((state: RootState) => state.user)

    const weldingPartReport = useSelector((state: RootState) => state.analytics.weldingPartReport)

    if (weldingPartReport.status === "pending") return <Loading isOpen={true} message="Report is being generated. Please wait." />
    if (weldingPartReport.status === "idle") return <Fragment></Fragment>

    const ret = []
    const colors = ["#f87171", "#10b981", "#3b82f6"]
    for (const user_id in weldingPartReport.data) {
        let num_sessions = 0
        let time_spent = 0
        let methods_arrays = {}
        let average_angles = {}
        let average_distance = {}
        let average_speed = {}
        let user_methods_pie = []

        let _ret = weldingPartReport.filters?.methods?.map((m, idx) => {
            methods_arrays[m] = []
            user_methods_pie.push({
                id: getTranslation(m), label: getTranslation(m), color: colors[idx],
                value: weldingPartReport.filters?.parts.reduce((a, b) => {
                    try {
                        if (weldingPartReport.filters?.parts.includes(a) && weldingPartReport.filters?.parts.includes(b)) return weldingPartReport.data[user_id][m][a]["Number Of Sessions"] + weldingPartReport.data[user_id][m][b]["Number Of Sessions"]
                        if (weldingPartReport.filters?.parts.includes(a)) return weldingPartReport.data[user_id][m][a]["Number Of Sessions"]
                        if (weldingPartReport.filters?.parts.includes(b)) return weldingPartReport.data[user_id][m][b]["Number Of Sessions"]
                    } catch(e) {
                        return 0
                    }
                    return 0
                }, 0),
            })
        })
        _ret = weldingPartReport.filters?.parts?.map(part => {
            average_angles[part] ={ "work": { ...methods_arrays }, "travel": { ...methods_arrays } }
            average_distance[part] = { ...methods_arrays }
            average_speed[part] = { ...methods_arrays }
        })
        const user = userReducer.users[user_id]

        for (const key in weldingPartReport.data[user_id]) {
            if (key === "user_id" || weldingPartReport.filters.methods.includes(key) === false) continue
            const method_data = weldingPartReport.data[user_id][key]
            for (const part of weldingPartReport.filters?.parts) {
                try{
                    average_angles[part]["work"][key] = [...average_angles[part]["work"][key], ...method_data[part]["Average Work Angle"]?.map((value, index) => ({ x: index + average_angles[part]["work"][key].length, y: value }))]
                    average_angles[part]["travel"][key] = [...average_angles[part]["travel"][key], ...method_data[part]["Average Travel Angle"]?.map((value, index) => ({ x: index + average_angles[part]["travel"][key].length, y: value }))]
                    average_distance[part][key] = [...average_distance[part][key], ...method_data[part]["Average Distance"]?.map((value, index) => ({ x: index + average_distance[part][key].length, y: value }))]
                    average_speed[part][key] = [...average_speed[part][key], ...method_data[part]["Average Speed"]?.map((value, index) => ({ x: index + average_speed[part][key].length, y: value }))]
                    num_sessions += method_data[part]["Number Of Sessions"]
                    time_spent += method_data[part]["Time Spent"]
                } catch(e) {
                    continue
                }
            }
        }

        ret.push(
            <div className="w-full print:pt-6" style={{ "pageBreakAfter": "always" }}>
                <div className="flex gap-4 mb-4">
                    <div className="w-5/12">
                        <ChartContainer
                            data={user_methods_pie}
                            maxHeight={160}
                            maxWidth={undefined}
                            title={getTranslation("Training Methods Distribution")}
                            Chart={PieChart}
                        />
                    </div>
                    <div className="bg-white px-3 py-3 pt-6 w-7/12 rounded shadow ">
                        <h1 className="text-slate-700 font-bold text-lg mb-3">Trainee Performance Report</h1>
                        <p className="text-slate-700"><Translate label="User" />: <b>{user.username}</b></p>
                        <p className="text-slate-700"><Translate label="Date" />: <b>{weldingPartReport.filters.date_from} - {weldingPartReport.filters.date_to}</b></p>
                        <p className="text-slate-700"><Translate label="Method" />: <b>{weldingPartReport.filters?.methods?.join(", ")}</b></p>
                        <p className="text-slate-700"><Translate label="Number of sessions" />: <b>{num_sessions}</b></p>
                        <p className="text-slate-700"><Translate label="Session Duration" />: <b>{(time_spent / 60).toFixed(2)} min</b></p>
                        <IonButton className="mt-5"><Translate label="EXPORT" /></IonButton>
                    </div>
                </div>
                {weldingPartReport.filters?.parts?.map(part => weldingPartReport.filters?.methods.map(method => average_distance[part][method].length < 3 ? null : (
                    <div className="mb-4 break-after-all print:pt-2" style={{ "pageBreakAfter": "always" }}>
                        <h1 className="font-bold text-center text-lg text-slate-800 mb-3 print:py-2 print:bg-slate-800 print:text-white rounded-md"><Translate label={part} /> (<Translate label={method} />)</h1>
                        <h1 className="text-center text-md text-slate-800 mb-3"><Translate label="Welded"/>: {average_distance[part][method].length}</h1>
                        <span>
                            <div className="flex justify-between items-center w-full gap-4 mt-4">
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[
                                        {
                                            id: getTranslation("Work Angle"),
                                            color: "#f87171",
                                            data: average_angles[part]["work"][method]
                                        }
                                    ]}

                                    maxHeight={200}
                                    maxWidth={450}
                                    title={getTranslation("Average Work Angle")}
                                    properties={{
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(20);
                                                const yEnd = yScale(40);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(248, 113, 113, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[
                                        {
                                            id: getTranslation("Travel Angle"),
                                            color: "#10b981",
                                            data: average_angles[part]["travel"][method]
                                        }
                                    ]}

                                    maxHeight={200}
                                    maxWidth={450}
                                    title={getTranslation("Average Travel Angle")}
                                    properties={{
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(16, 185, 129, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                            <div className="flex justify-between items-center w-full gap-4 mt-4">
                                {method === "STICK" ? null : <ChartContainer
                                    Chart={LineChart}
                                    data={[{
                                        id: getTranslation("Distance"),
                                        color: "#3b82f6",
                                        data: average_distance[part][method]
                                    }]}
                                    maxHeight={200}
                                    maxWidth={450}
                                    title={getTranslation("Average Distance")}
                                    properties={{
                                        axisLeft: { legend: 'Distance (mm)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />}
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[{
                                        id: getTranslation("Speed"),
                                        color: "#3b82f6",
                                        data: average_speed[part][method]
                                    }]}
                                    maxHeight={200}
                                    maxWidth={method === "STICK" ? 860 : 450}
                                    title={getTranslation("Average Speed")}
                                    properties={{
                                        axisLeft: { legend: 'Speed (mm / s)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(8);
                                                const yEnd = yScale(15);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                        </span>
                    </div>
                )))}
            </div>
        )
    }

    if (ret.length === 0) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4">Not enough data to display this report. Try to select a wider range of dates.</h1>
    return (
        <div id="part-report-container" className="w-[800px] mx-auto">
            {ret}
        </div>
    )
}

export default WeldingPartReport