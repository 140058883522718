import { useIonAlert } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { ApplicationActions } from "../../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import EditApplicationPackageJSX from "./EditApplicationPackageJSX"
import PackageItem from "./PackageItem"
import PackageItemCreate from "./PackageItemCreate"
import Loading from "../../../../components/Loading"


const EditApplicationPackage: React.FC = () => {
    const package_id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    const [ionAlert] = useIonAlert()
    const [submitted, setSubmitted] = useState(false)
    const [_package, setPackage] = useState<{
        id: number
        name: string
        description: string
        price: number
        application_id: number
        items: Array<{
            id: number
            type: "application_id" | "dlc_id" | null
            dlc_id: number | null
            application_id: number | null
            quantity: number
        }>
        cover: any
    } | null>(null)


    useEffect(() => {
        if (submitted === false || appsReducer.packages.updateStatus === "pending") return

        ionAlert({
            header: appsReducer.packages.updateStatus === "fulfilled" ? "Success" : appsReducer.packages.updateStatus === "rejected" ? "Error" : "",
            message: appsReducer.packages.updateMessage,
            buttons: ["OK"]
        })

    }, [dispatch, submitted, appsReducer.packages.updateStatus, appsReducer.packages.updateMessage, ionAlert])


    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: {} }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readPackages({ arguments: { id: package_id } }))
    }, [dispatch, package_id])

    const handleUpdate = e => {
        if (_package.name.length < 5) return ionAlert({ message: "Please enter more descriptive name.", buttons: ["OK"], header: "Warning" })
        if (_package.description.length < 120) return ionAlert({ message: "Please enter a better description (120+ chaacters).", buttons: ["OK"], header: "Warning" })
        if (_package.description.length > 1000) return ionAlert({ message: "Description cannot be longer than 1000 characters.", buttons: ["OK"], header: "Warning" })
        if (_package.price < 1) return ionAlert({ message: "Price must be greater than $1 US Dollar", buttons: ["OK"], header: "Warning" })

        setSubmitted(true)
        const data = new FormData()
        data.append("id", _package.id.toString())
        data.append("name", _package.name)
        data.append("description", _package.description)
        data.append("price", _package.price.toString())
        if (_package.cover !== null) data.append("cover", _package.cover)
        dispatch(ApplicationActions.updatePackage({ package: data }))
    }

    if (
        appsReducer.application.readStatus !== "fulfilled" ||
        appsReducer.dlc.readStatus !== "fulfilled" ||
        appsReducer.packages.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading package data... Please wait." />
    const pkg = appsReducer.packages.packages.find(p => p !== null && p !== undefined && p.id === package_id)
    const application = appsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === pkg.application_id)
    const dlcs = appsReducer.dlc.dlcs.filter(d => d !== null && d !== undefined && d.application_id === pkg.application_id)
    if (_package === null) {
        setPackage({
            id: pkg.id,
            name: pkg.name,
            description: pkg.description,
            application_id: pkg.application_id,
            price: pkg.price,
            cover: null,
            items: pkg.items.map(item => ({
                id: item.id,
                type: item.application_id === null ? "dlc_id" : "application_id",
                dlc_id: item.dlc_id,
                application_id: item.application_id,
                quantity: item.quantity
            }))
        })
        return <Loading isOpen={true} message="Setting up edit page... Please wait." />
    }
    return (
        <div>
            <Loading isOpen={submitted === true && appsReducer.packages.updateStatus === "pending"} message={appsReducer.packages.updateMessage} />

            <EditApplicationPackageJSX
                _package={_package}
                setPackage={setPackage}
                dlcs={dlcs}
                application={application}
                handleUpdate={handleUpdate}
            />

            <div className="flex flex-wrap gap-2 py-2 mx-auto mb-24 w-11/12 max-w-[1300px] justify-center lg:justify-start">
                {_package.items.map(item => <PackageItem
                    key={item.id}
                    id={item.id}
                    type={item.type}
                    dlc_id={item.dlc_id}
                    application_id={item.application_id}
                    quantity={item.quantity}
                    application={application}
                    dlcs={dlcs}
                    appsReducer={appsReducer}
                />)}
                <PackageItemCreate
                    appsReducer={appsReducer}
                    package_id={pkg.id}
                    application={application}
                    dlcs={dlcs}
                />
            </div>

        </div>
    )
}

export default EditApplicationPackage