import { useIonAlert } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { validateEmail } from "../../../Common/Functions"
import { OrganizationActions } from "../../../Redux/Organizations/Actions"
import { PurchaseActions } from "../../../Redux/Purchases/Actions"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import CreateUserJSX from "./CreateUserJSX"
import Loading from "../../../components/Loading"
import { LanguagesState } from "../../../Redux/Languages/Interface"

const CreateUser: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()

    const userReducer = useSelector((state: RootState) => state.user)
    const organizationsReduecr = useSelector((state: RootState) => state.organizations.organization)
    const purchasesReducer:PurchasesState = useSelector((state: RootState) => state.shop)
    const LanguagesReducer: LanguagesState = useSelector((state: RootState) => state.language)

    const [infoAlert] = useIonAlert()
    const [user, setUser] = useState({
        organization_id: userReducer.current.type_id >= 3 ? userReducer.current.organization_id : null,
        type_id: null,
        language_id: 2,
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirm: "",
        pin: "",
        applications_access: [],
    })

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(UserActions.readUserTypes({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ argumens: { hashed: true, organization_id : userReducer.current.organization_id } }))
    }, [dispatch])

    useEffect(() => {
        if (user.type_id === null) return
        if (userReducer.createUserStatus === "fulfilled") {
            infoAlert({
                header: 'Success.', buttons: ['OK'],
                message: userReducer.createUserMessage,
            })
            setUser({
                organization_id: null,
                type_id: null,
                language_id: 2,
                firstName: "",
                lastName: "",
                email: "",
                username: "",
                password: "",
                confirm: "",
                pin: "",
                applications_access: []
            })
        }
        if (userReducer.createUserStatus === "rejected") {
            infoAlert({
                header: 'Error.', buttons: ['OK'],
                message: userReducer.createUserMessage,
            })
        }
    }, [userReducer.createUserStatus, infoAlert])

    const handleSubmit = () => {
        if (user.type_id === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select user type.'
        })
        // if (user.firstName.length < 2) return infoAlert({
        //     header: 'Error.', buttons: ['OK'], message: 'First name must contain more letters.'
        // })
        // if (user.lastName.length < 2) return infoAlert({
        //     header: 'Error.', buttons: ['OK'], message: 'Last name must contain more letters.'
        // })
        if (validateEmail(user.email) === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Invalid email.'
        })
        if (user.username.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Username must be at least 5 letters'
        })
        if (user.type_id !== 1 && user.password.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Enter more secure password'
        })
        if (user.type_id !== 1 && user.password !== user.confirm) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Passwords do not match'
        })
        if (user.pin.length !== 4) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Pin must be 4 numbers'
        })
        if (/^\d+$/.test(user.pin) !== true) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Pin must be numerical'
        })

        const pw = Math.random().toString(16).substr(2, 16);
        const form = new FormData()
        form.append("organization_id", userReducer.current.type_id === 3 && userReducer.current.organization_id !== null ? userReducer.current.organization_id : user.organization_id)
        form.append("type_id", user.type_id)
        form.append("language_id", user.language_id.toString())
        form.append("firstName", user.firstName)
        form.append("lastName", user.lastName)
        form.append("email", user.email)
        form.append("username", user.username)
        form.append("password", user.type_id === 1 ? pw : user.password)
        form.append("applications_access", JSON.stringify(user.applications_access))
        form.append("pin", user.pin.length !== 4 ? "" : user.pin)
        dispatch(UserActions.createUser({ user: form }))
    }
    const owned_applications = purchasesReducer.purchases.filter(p => p !== null && p !== undefined && p.confirmed === 1 && p.organization_id === user.organization_id && p.application_id !== null).map(prod => prod.product)
    if (
        userReducer.readUserStatus !== "fulfilled" || 
        userReducer.userTypes.readStatus !== "fulfilled" || 
        organizationsReduecr.readStatus !== "fulfilled" ||
        purchasesReducer.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading user information... Plese wait." />
    return <CreateUserJSX
        handleSubmit={handleSubmit}
        setUser={setUser}
        user={user}
        organizations={organizationsReduecr.organizations.filter(o => o !== null && o !== undefined)}
        userTypes={userReducer.userTypes.types.filter(t => t! += null && t !== undefined)}
        type_id={userReducer.current.type_id}
        userReducer={userReducer}
        owned_applications={owned_applications}
        languages={LanguagesReducer.languages.languages}
    />
}

export default CreateUser