
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserState } from "../../../Redux/User/Interface"
import DlcsJsx from "./DlcsJsx"
import Loading from "../../../components/Loading"

const Dlcs: React.FC = () => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()

    const apps: ApplicationsState = useSelector((state: RootState) => state.apps)
    const user: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(ApplicationActions.readDlcs({ arguments: { application_id: id } }))
        dispatch(ApplicationActions.readDlcTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { id: id } }))
    }, [dispatch, id])

    if (
        apps.dlc.readStatus !== "fulfilled" ||
        apps.dlc_types.readStatus !== "fulfilled" ||
        apps.application.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading releases data... Please wait." />
    const dlcs = apps.dlc.dlcs.filter(dlc => dlc !== null && dlc !== undefined &&  dlc.application_id === id)
    return (
        <DlcsJsx
            current={user.current}
            appliation={apps.application.applications[id]}
            dlcs={dlcs}
            dlc_types={apps.dlc_types.types}
        />
    )
}

export default Dlcs