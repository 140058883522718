import { Redirect } from "react-router"
import TableContainer from "../../components/Table/TableContainer"
import { getTranslation } from "../../Common/Functions"
import Translate from "../../components/Translate"

const PurchasesContainerJSX = ({
    orders,
    redirect,
    setRedirect,
    location
}) => (
    <div>
        {redirect !== "" ? <Redirect to={{
            pathname: redirect,
            state: { prevLocation: location }
        }} /> : null}
        <div className="w-full px-6 py-3 bg-cythero-primary flex items-center">
            <h1 className="text-white text-3xl"><Translate label="Purchase History" /></h1>
        </div>
        <div className="w-full flex items-center justify-center mt-5 mb-5">
            <div className="w-[1100px] max-w-[95%]">
                <TableContainer
                    total={null}
                    table={orders}
                    hide={[]}
                    row_click_handler={(row, idx) => {
                        setRedirect("/purchase/" + row[getTranslation("Order Number")])
                    }}
                />
            </div>
        </div>
    </div>
)

export default PurchasesContainerJSX