import { useIonAlert } from "@ionic/react"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { validateEmail } from "../../../Common/Functions"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import EditUserFormJSX from "./EditUserFormJSX"
import Loading from "../../../components/Loading"

const EditUserForm = ({

}) => {
    const dispatch = useDispatch<AppDispatch>()
    const userReducer: UserState = useSelector((state: RootState) => state.user)

    const [user, setUser] = useState({ ...userReducer.current, password: undefined, confirm: undefined, pin: undefined })
    const [submitted, setSubmitted] = useState<Boolean>(false)
    const [infoAlert] = useIonAlert()

    useEffect(() => {
        if (submitted === false) return
        if (userReducer.updateUserStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: userReducer.updateUserMessage,
        })
        if (userReducer.updateUserStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: userReducer.updateUserMessage,
        })
    }, [userReducer.updateUserStatus])

    const checkForm = () => {
        if (user.type_id === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select user type.'
        })
        // if (user.firstName.length < 2) return infoAlert({
        //     header: 'Error.', buttons: ['OK'], message: 'First name must contain more letters.'
        // })
        // if (user.lastName.length < 2) return infoAlert({
        //     header: 'Error.', buttons: ['OK'], message: 'Last name must contain more letters.'
        // })
        if (validateEmail(user.email) === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Invalid email.'
        })
        if (user.username.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Username must be at least 5 letters'
        })
        if (user?.password !== undefined) {
            if (user.password.length < 5) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Enter more secure password'
            })
            if (user.password !== user.confirm) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Passwords do not match'
            })
        }
        if (user.pin !== undefined) {
            if (user.pin.length !== 4) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Pin must be 4 numbers'
            })
            if (/^\d+$/.test(user.pin) !== true) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Pin must be numerical'
            })
        }
        setSubmitted(true)

        const form = new FormData()
        form.append("id", user.id.toString())
        form.append("firstName", user.firstName)
        form.append("lastName", user.lastName)
        form.append("email", user.email)
        form.append("username", user.username)
        if (user?.password !== undefined) form.append("password", user.password)
        if (user?.pin !== undefined) form.append("pin", user.pin)
        dispatch(UserActions.updateUser({ user: form }))
    }

    return <Fragment>
        <Loading isOpen={userReducer.updateUserStatus === "pending"} message="Updating user... Please wait." />
        <EditUserFormJSX
            user={user}
            setUser={setUser}
            checkForm={checkForm}
        />
    </Fragment>
}

export default EditUserForm