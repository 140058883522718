import { IonButton, IonButtons, IonContent, IonFab, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { BiAddToQueue } from "react-icons/bi"
import { useDispatch } from "react-redux"
import { Applications, OrganizationSettingsTypes, User } from "../../Common/Interfaces/Entities"
import Input from "../../components/Form/Input"
import Select from "../../components/Form/Select"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { AppDispatch } from "../../Redux/Store"
import Loading from "../../components/Loading"

const CreateSetting: React.FC<{ current: User, applications: Array<Applications>, organizationsReducer: OrganizationsState }> = ({
    current,
    applications,
    organizationsReducer
}) => {
    const dispatch = useDispatch<AppDispatch>()

    const [ionAlert] = useIonAlert()
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)
    const [setting, setSetting] = useState<OrganizationSettingsTypes>({
        id: null,
        name: "",
        _type: "",
        application_id: null,
        _default: "",
        settings: []
    })

    const handleCreate = e => {
        e.preventDefault()
        if (setting.application_id === null) return ionAlert({
            message: "Please select an application.",
            header: "Warning",
            buttons: ["OK"]
        })
        else if (["string", "number", "boolean"].includes(setting._type) === false) return ionAlert({
            message: "Please select type of setting.",
            header: "Warning",
            buttons: ["OK"]
        })
        else if (setting.name.length < 5) return ionAlert({
            message: "Please specify more descriptive name.",
            header: "Warning",
            buttons: ["OK"]
        })
        else if (setting._default.length === 0) return ionAlert({
            message: "Please specify default value.",
            header: "Warning",
            buttons: ["OK"]
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("application_id", setting.application_id.toString())
        data.append("_type", setting._type)
        data.append("name", setting.name)
        data.append("_default", setting._default)
        dispatch(OrganizationActions.createSettingTypes({ setting: data }))
    }

    useEffect(() => {
        if (submitted === false) return
        if (organizationsReducer.settings.createStatus === "fulfilled") {
            ionAlert({
                message: organizationsReducer.settings.createMessage,
                header: "Success",
                buttons: ["OK"],
                onDidDismiss: () => {
                    setSetting({
                        id: null,
                        name: "",
                        _type: "",
                        application_id: null,
                        _default: "",
                        settings: []
                    })
                    setModal(false)
                    setSubmitted(false)
                }
            })
        } else if (organizationsReducer.settings.createStatus === "rejected") {
            ionAlert({
                message: organizationsReducer.settings.createMessage,
                header: "Error!",
                buttons: ["OK"],
                onDidDismiss: () => {
                    setSubmitted(false)
                }
            })
        }
    }, [submitted, organizationsReducer.settings.createStatus, organizationsReducer.settings.createMessage])

    return (
        <div>
            <IonModal id='settings-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
                <Loading isOpen={organizationsReducer.settings.createStatus === "pending" && submitted === true} message="Creating new setting type... Please wait." />
                <IonContent>
                    <IonToolbar>
                        <IonTitle>Create Setting Type</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={e => setModal(false)}>CLOSE</IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <Select
                        displayField="name"
                        valueField="id"
                        name="application_id"
                        multiple={false}
                        placeholder="Select application"
                        value={setting.application_id}
                        options={applications.filter(a => a !== null)}
                        handleChange={e => setSetting({ ...setting, application_id: e.target.value })}
                    />
                    <Select
                        displayField="type"
                        valueField="type"
                        name="_type"
                        multiple={false}
                        placeholder="Select type"
                        value={setting._type}
                        options={["string", "number", "boolean"].map(t => ({ type: t }))}
                        handleChange={e => setSetting({ ...setting, _type: e.target.value })}
                    />
                    <Input
                        placeholder="Enter name of setting"
                        name="name"
                        handleChange={e => setSetting({ ...setting, name: e.target.value })}
                        type="text"
                        accept="*"
                        value={setting.name}
                    />
                    <Input
                        placeholder="Specify default value"
                        name="_default"
                        handleChange={e => setSetting({ ...setting, _default: e.target.value })}
                        type="text"
                        accept="*"
                        value={setting._default}
                    />
                    <IonButton className="block mx-5" onClick={e => handleCreate(e)}>Create</IonButton>
                </IonContent>
            </IonModal>
            {current.type_id === 4 ? <IonFab vertical="bottom" horizontal="end" >
                <IonButton>
                    <BiAddToQueue onClick={e => setModal(true)} className="text-3xl" />
                </IonButton>
            </IonFab> : null}
        </div>
    )
}

export default CreateSetting