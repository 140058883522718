
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Purchase } from "../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import PurchasePageJSX from "./PurchasePageJSX"
import Loading from "../../components/Loading"
// import { loadStripe } from '@stripe/stripe-js';

// const stripePromise = loadStripe('pk_live_51NqXxRKsbQ6giahqCaV3kj53KpG097HG21QCO7B3GZSro9ekMC8ty3yWyEe3AgiwXvxnq7et8K7ePunJH04h9Pyy00wjLNSCzZ');

const regroup = (orders: Array<Purchase>, AppsReducer: ApplicationsState) => {
    const packages = {}
    const independent = []
    let total_price = 0
    for (const order of orders) {
        if (order.package_id !== null) {
            if (packages[order.order_number] === undefined) {
                packages[order.order_number] = {
                    items: [],
                    package: AppsReducer.packages.packages.find(p => p !== null && p !== undefined && p.id === order.package_id)
                }
                total_price += packages[order.order_number].package.price
            }
            packages[order.order_number].items.push(order)
        }
        else {
            independent.push(order)
            total_price += order.product.price
        }
    }
    return [total_price, packages, independent]
}

const PurchasePage: React.FC<{ location: { state: { prevLocation: string } } }> = ({ location }) => {
    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const order_number: string = useParams<{ order_number: string }>().order_number

    const OrganizationReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const ApplicationReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const PurchaseReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ arguments: { hashed: false } }))
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readPackages({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
    }, [])

    useEffect(() => {
        if (location.state === null) return
        window.addEventListener('popstate', () => history.push(location.state.prevLocation))
        return () => window.addEventListener('popstate', () => history.push(location.state.prevLocation))
    }, [history])

    if (
        OrganizationReducer.organization.readStatus !== "fulfilled" ||
        ApplicationReducer.application.readStatus !== "fulfilled" ||
        ApplicationReducer.packages.readStatus !== "fulfilled" ||
        ApplicationReducer.dlc.readStatus !== "fulfilled" ||
        PurchaseReducer.readStatus !== "fulfilled" ||
        UserReducer.readUserStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading purchases data... Please wait." />


    const confirmPackage = (order_number) => {
        const data = new FormData()
        data.append("order_number", order_number)
        data.append("confirmed", "1")

        dispatch(PurchaseActions.updatePurchase({ purchase: data }))
    }
    const confirmItem = (purchase_id) => {
        const data = new FormData()
        data.append("id", purchase_id)
        data.append("confirmed", "1")

        dispatch(PurchaseActions.updatePurchase({ purchase: data }))
    }

    const unconfirmPackage = (order_number) => {
        const data = new FormData()
        data.append("order_number", order_number)
        data.append("confirmed", "0")

        dispatch(PurchaseActions.updatePurchase({ purchase: data }))
    }
    const unconfirmItem = (purchase_id) => {
        const data = new FormData()
        data.append("id", purchase_id)
        data.append("confirmed", "0")

        dispatch(PurchaseActions.updatePurchase({ purchase: data }))
    }

    const confirmPurchase = e => {
        const tryPayment = async () => {
            const sess_id = PurchaseReducer.purchases.find(p => p !== null && p !== undefined && p.order_number.startsWith(order_number)).checkout_link
            console.log(sess_id)
            // const stripe = await stripePromise;
            // const result = await stripe.redirectToCheckout({ sessionId: sess_id });

            // if (result.error) {
            //     console.error('Error during payment:', result.error);
            // }
        }
        tryPayment()
    }

    let current_purchases = PurchaseReducer.purchases.filter(p => p !== null && p !== undefined && p.order_number.startsWith(order_number))
    let [total_price, packages, independent] = regroup(current_purchases, ApplicationReducer)
    const is_confirmed = current_purchases[0].confirmed
    
    return (
        <PurchasePageJSX
            confirm_purchase={confirmPurchase}
            is_confirmed={is_confirmed}
            packages={packages}
            independent={independent}
            total_price={total_price}
            purchase_date={current_purchases[0].purchase_date}
            confirmPackage={confirmPackage}
            confirmItem={confirmItem}
            unconfirmPackage={unconfirmPackage}
            unconfirmItem={unconfirmItem}
            PurchaseReducer={PurchaseReducer}
            UserReducer={UserReducer}
            order_number={current_purchases[0].order_number.split("/")[0]}
        />
    )
}

export default PurchasePage
