import { IonButton } from "@ionic/react"
import Input from "../../../components/Form/Input"
import Select from "../../../components/Form/Select"
import { Fragment } from "react"
import Translate from "../../../components/Translate"
import { getTranslation } from "../../../Common/Functions"

const UpdateUserJsx = ({
    checkForm,
    type_id,
    organizations,
    userTypes,
    setUser,
    user,
    languages
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        {/* <span className="flex items-center w-full mx-auto text-slate-700">
            <NavLink className="hover:underline" to="/users">Users</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to="/users/create">Edit User</NavLink>
        </span> */}
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl"><Translate label="Edit User Profile" /></h1>
            <span className="w-full block pb-10 px-4">
                {type_id === 4 ? <Select
                    value={user.organization_id}
                    multiple={false}
                    options={organizations}
                    displayField="name"
                    valueField="id"
                    name="organization_id"
                    placeholder="Select user organization"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                /> : null}
                <Select
                    value={user.type_id}
                    multiple={false}
                    options={userTypes.filter(t => t.id <= type_id)}
                    displayField="name"
                    valueField="id"
                    name="type_id"
                    placeholder={getTranslation("Select user type")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="firstName"
                    value={user.firstName}
                    type="text"
                    placeholder={getTranslation("Enter first name (Optional)")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="lastName"
                    value={user.lastName}
                    type="text"
                    placeholder={getTranslation("Enter last name (Optional)")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="email"
                    value={user.email}
                    type="email"
                    placeholder={getTranslation("Enter email")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="username"
                    value={user.username}
                    type="text"
                    placeholder={getTranslation("Enter username")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                {
                    user.type_id === 1 || user.type_id === null ? null : <Fragment>
                        <Input
                            name="password"
                            value={user.password}
                            type="password"
                            placeholder={getTranslation("Enter password")}
                            handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                        />
                        <Input
                            name="confirm"
                            value={user.confirm}
                            type="password"
                            placeholder={getTranslation("Confirm password")}
                            handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                        />
                    </Fragment>
                }
                <Select
                    value={user.language_id}
                    multiple={false}
                    options={languages.filter(l => l !== null && l !== undefined)}
                    displayField="name"
                    valueField="id"
                    name="language_id"
                    placeholder={"Language"}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="pin"
                    value={user.pin}
                    type="text"
                    placeholder={getTranslation("Enter 4 digit pin (optional)")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <IonButton className="mt-5" onClick={checkForm}><Translate label="UPDATE USER" /></IonButton>
            </span>
        </div>

    </div>
)

export default UpdateUserJsx




// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Afghanistan";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Albania";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Algeria";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Andorra";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Angola";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Anguilla";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Argentina";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Armenia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Aruba";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Australia";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Austria";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Azerbaijan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Bahamas";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Bahrain";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Bangladesh";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Barbados";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Belarus";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Belgium";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Belize";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Benin";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Bermuda";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Bhutan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Bonaire";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Bosnia and Herzegovina";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Botswana";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Brazil";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Bulgaria";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Burkina Faso";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Burundi";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Cambodia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Cameroon";
// UPDATE Countries SET region =  4, shipping_cost = 240 WHERE name = "Canada";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Canary Islands, The";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Cape Verde";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Cayman Islands";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Central African Republic";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Chad";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Chile";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Colombia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Comoros";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Congo, Democratic Rep. of";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Cook Islands";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Costa Rica";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Croatia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Cuba";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Curacao";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Cyprus";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Denmark";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Djibouti";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Dominica";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Dominican Republic";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Ecuador";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Egypt";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "El Salvador";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Eritrea";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Estonia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Eswatini";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Ethiopia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Faroe Islands";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Fiji";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Finland";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "France";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "French Guiana";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Gabon";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Gambia";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Germany";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Ghana";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Gibraltar";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Greece";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Greenland";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Grenada";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Guadeloupe";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Guam";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Guatemala";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Guernsey";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Haiti";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Honduras";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Hungary";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Iceland";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "India";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Indonesia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Iraq";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Israel";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Italy";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Jamaica";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "Japan";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Jersey";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Jordan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Kazakhstan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Kenya";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Kiribati";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Kosovo";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Kuwait";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Kyrgyzstan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Lao P.D.R.";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Latvia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Lebanon";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Lesotho";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Liberia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Libyan Arab Jamahiriya";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Liechtenstein";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Lithuania";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Luxembourg";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Macau SAR China";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Madagascar";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Malawi";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "Malaysia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Maldives";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Mali";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Malta";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Marshall Islands";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Martinique";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Mauritania";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Mauritius";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Mayotte";
// UPDATE Countries SET region =  4, shipping_cost = 240 WHERE name = "Mexico";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Monaco";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Mongolia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Montserrat";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Morocco";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Mozambique";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Myanmar";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Namibia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Nepal";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Nevis";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "New Caledonia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "New Zealand";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Nicaragua";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Niger";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Nigeria";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Niue";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Northern Mariana Islands";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Norway";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Oman";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Pakistan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Palau";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Panama";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Papua New Guinea";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Paraguay";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Peru";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Poland";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Portugal";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Puerto Rico";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Qatar";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Romania";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Rwanda";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Samoa";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "San Marino";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Sao Tome and Principe";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "Saudi Arabia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Senegal";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Serbia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Seychelles";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Sierra Leone";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "Singapore";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Slovakia";
// UPDATE Countries SET region =  1, shipping_cost = 170 WHERE name = "Slovenia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Solomon Islands";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Somalia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Somaliland, Rep. (N. Somalia)";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "South Africa";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "South Sudan";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Spain";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Sri Lanka";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Saint Eustatius";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Saint Maarten";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Sudan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Suriname";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Sweden";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "Switzerland";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Tahiti";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "Taiwan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Tajikistan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Thailand";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Timor-Leste";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Togo";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Tonga";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Trinidad and Tobago";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Tunisia";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Turkey";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Turkmenistan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Turks and Caicos Islands";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Tuvalu";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Uganda";
// UPDATE Countries SET region =  3, shipping_cost = 245 WHERE name = "Ukraine";
// UPDATE Countries SET region =  5, shipping_cost = 260 WHERE name = "United Arab Emirates";
// UPDATE Countries SET region =  2, shipping_cost = 225 WHERE name = "United Kingdom";
// UPDATE Countries SET region =  4, shipping_cost = 240 WHERE name = "United States";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Uruguay";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Uzbekistan";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Vanuatu";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Venezuela, Bolivarian Republic of Venezuela";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Vietnam";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Zambia";
// UPDATE Countries SET region =  6, shipping_cost = 350 WHERE name = "Zimbabwe";