
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "../../../../components/Table/TableContainer"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import WeldingSession from "../SessionCards/WeldingSession"
import Loading from "../../../../components/Loading"
import { getTranslation } from "../../../../Common/Functions"

const WeldingUsageReport: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const analytics: Analytics = useSelector((state: RootState) => state.analytics)
    const weldingUsageReport = analytics.weldingUsageReport
    const [selectedSession, setSelectedSession] = useState(null)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        dispatch(AnalyticsActions.getLabels({ arguments: { hashed: true } }))
    }, [dispatch])

    const handleColumnClick = (row, idx) => {
        if (row["User"] === "~Total") return
        const session_id = row["Session ID"]
        setSelectedSession(session_id)
        setModal(true)
    }

    if (weldingUsageReport?.status === "idle") return <Fragment></Fragment>
    if (weldingUsageReport?.status === "pending") return <Loading isOpen={true} message={weldingUsageReport.message} />
    if (weldingUsageReport?.data?.table === undefined || weldingUsageReport.data.table.Date.length === 0) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4">Not enough data to display this report. Try to select a wider range of dates.</h1>

    let table = {
        "User": {
            idx: 0,
            rows: weldingUsageReport.data.table["User"],
            type: "string",
        },
        "Part": {
            idx: 1,
            rows: weldingUsageReport.data.table["Part"].map(p => getTranslation(p)),
            type: "string",
        },
        "Method": {
            idx: 2,
            rows: weldingUsageReport.data.table["Method"].map(p => getTranslation(p)),
            type: "string",
        },
        "Date": {
            idx: 3,
            rows: weldingUsageReport.data.table["Date"],
            type: "date",
        },
        "Avg. Work Angle": {
            idx: 4,
            rows: weldingUsageReport.data.table["Avg. Work Angle"],
            type: "number",
        },
        "Avg. Travel Angle": {
            idx: 5,
            rows: weldingUsageReport.data.table["Avg. Travel Angle"],
            type: "number",
        },
        "Avg. Speed": {
            idx: 6,
            rows: weldingUsageReport.data.table["Avg. Speed"],
            type: "number",
        },
        "Avg. Distance": {
            idx: 7,
            rows: weldingUsageReport.data.table["Avg. Distance"],
            type: "number",
        },
        "Session ID": {
            idx: 8,
            rows: weldingUsageReport.data.table["Session ID"],
            type: "string",
        },
    }
    const hidden_cols = ["Session ID"]

    return (
        <div className="flex flex-col justify-center items-center w-full p-5 gap-6 mb-14">
            <TableContainer
                total="User"
                table={table}
                hide={hidden_cols}
                row_click_handler={handleColumnClick}
                translate_table={true}
            />
            {selectedSession === null ? null : <WeldingSession
                modal={modal}
                setModal={setModal}
                selectedSession={selectedSession}
            />}
        </div>
    )
}


export default WeldingUsageReport


// {
//     "date":"2023-06-12 12:16:29",
//     "weldingMethod":"MIG",
//     "speedEntries":[74.15404,15.6800222,9.703653,73.0342941,32.63896,52.294693,52.784977,68.02165,16.44672,10.8731937,89.45813,45.08363,68.2764359,32.4954033,55.3045959,30.4805527,67.023056,45.21264,11.6076536,24.8252277,9.581321,6.85987,8.134575,42.7724037,24.5055332,70.08382,36.1495857,26.3192616,12.3507395,24.7741966,48.2139626,42.86921,34.9031,47.0831871],
//     "workAngleEntries":[44.0086975,43.951725,43.9397774,44.05451,44.062252,44.1234131,44.1913452,44.3084831,44.3224335,44.291954,44.2721977,44.25334,44.1550179,44.0788078,43.97008,43.91654,43.78152,43.6883354,43.66928,43.59184,43.5560036,43.5475464,43.5392151,43.56294,43.5478363,43.6611328,42.8801041,42.3965073,42.04769,42.2069778,41.39328,35.9648438,35.9259033,35.9518738],
//     "travelAngleEntries":[33.28012,33.331604,33.33307,33.4653,33.542572,33.60077,33.657196,33.74121,33.7966,33.77765,33.7716064,33.75284,33.72223,33.675293,33.56189,33.5249329,33.48404,33.58542,33.6377258,33.60202,33.54071,33.56198,33.6051331,33.6625671,33.69348,34.0811768,34.1558533,33.7735,33.61447,33.930603,34.2581177,25.3918457,25.2219238,24.8935852],
//     "distanceEntries":[0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,127.538811,127.783524,127.891075,127.274231,125.661766,123.766983,119.515335,116.272614,115.362717,112.526794,111.142113,110.343361,109.99128,113.585594,116.806419,140.564178,146.7671,122.786407,114.307396,121.315033,109.611755,0.0,0.0,0.0],
//     "heightOffset":0.0
// }