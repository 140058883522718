import { IonButton, IonItem } from "@ionic/react"
import { useEffect, useState } from "react"
import { STATIC_URL } from "../../../../Common/constants"
import ChartContainer from "../../../Analytics/Charts/ChartContainer"
import LineChart from "../../../Analytics/Charts/LineChart"
import Translate from "../../../../components/Translate"
import { getTranslation } from "../../../../Common/Functions"

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return [windowSize, setWindowSize];
}

const OverallJSX = ({
    ActivityLine,
    UserActivity,
    AppsActivity,
    BrowseActivity,
    setSelectedSession,
    setModal,
    range,
    setRange,
    children
}) => {

    const [size, setSize] = useWindowSize()
    const [width, setWidth] = useState(document.getElementById("overall-contianer")?.offsetWidth || 1200)
    useEffect(() => setWidth(document.getElementById("overall-contianer")?.offsetWidth === undefined ? 1200 : document.getElementById("overall-contianer")?.offsetWidth < 800 ? 800 : document.getElementById("overall-contianer")?.offsetWidth), [size])
    return (
        <div className="w-full">
            {children}
            <div id="overall-contianer" className="w-[1200px] max-w-[95%] mx-auto my-8"> {/* Container */}
                <div className="flex flex-col md:flex-row items-center justify-between mb-8">
                    <h1 className="text-xl font-semibold text-slate-700"><Translate label="General Dashboard Report" /></h1>
                    <div className="flex justify-end">
                        <div className="mt-3 md:mt-0">
                            {["OVERALL", "WEEK", "MONTH", "YTD"].map(item => (
                                <IonButton
                                    color={range === item ? "light" : "primary"}
                                    onClick={e => {
                                        e.preventDefault()
                                        setRange(item)
                                    }}
                                >
                                    <Translate label={item} />
                                </IonButton>
                            ))}
                        </div>
                    </div>
                </div>

                {ActivityLine.can_display === false || UserActivity.length === 0 || BrowseActivity.length === 0 ? <h1 className="text-center text-slate-800 font-medium">Not enough data to display all the available statistics.</h1> : null}
                {ActivityLine.can_display === false ? null : <ChartContainer
                    maxHeight={320}
                    maxWidth={width + 60}
                    data={ActivityLine.data}
                    Chart={LineChart}
                    title={getTranslation("User activity over time on all applications")}
                    properties={{
                        axisLeft: {
                            legend: <Translate label="Time Spent (min)" />,
                            legendOffset: -40,
                            legendPosition: 'middle'
                        },
                        enableGridX: false,
                        enableDots: false,
                        layers: ['grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip'],
                        enableSlices: "x",
                        axisBottom: false,
                        enableArea: true,
                    }}
                // stacked={true}
                />}

                <div className="lg:grid grid-cols-5 gap-8 mt-10">

                    <div className="col-span-2">
                        {UserActivity.length === 0 ? null : <div className="bg-white rounded-lg shadow p-4 w-full mb-8">
                            <h1 className="text-slate-700 font-semibold text-lg px-2 mb-5"><Translate label="Most active users" /></h1>
                            <span className="block max-h-[400px] overflow-auto">
                                {UserActivity.slice(0, UserActivity.length - 1).map((user, index) => (
                                    <IonItem>
                                        <span className="flex items-center justify-between w-full">
                                            <h1 className="text-slate-700 font-semibold">{index + 1}. {user.user.username}.</h1>
                                            <h1 className="text-slate-700">{user.value}</h1>
                                        </span>
                                    </IonItem>
                                ))}
                                <IonItem>
                                    <span className="flex items-center justify-between w-full">
                                        <h1 className="text-slate-800 font-bold"><Translate label="Total Time Painted" /></h1>
                                        <h1 className="text-slate-800 font-bold">{UserActivity[UserActivity.length - 1]?.value}</h1>
                                    </span>
                                </IonItem>
                            </span>
                        </div>}

                        {AppsActivity.length <= 1 ? null : <div className="bg-white rounded-lg shadow p-4 w-full mb-8">
                            <h1 className="text-slate-700 font-semibold text-lg px-2 mb-5"><Translate label="Most Played Applications" /></h1>
                            <span className="block max-h-[400px] overflow-auto">
                                {AppsActivity.map((app, index) => (
                                    <IonItem>
                                        <span className="flex items-center justify-between w-full">
                                            <h1 className="text-slate-700 font-semibold">{index + 1}. {app.application.name}</h1>
                                            <h1 className="text-slate-700">{app.value}</h1>
                                        </span>
                                    </IonItem>
                                ))}
                            </span>
                        </div>}
                    </div>

                    <div className="col-span-3">
                        {BrowseActivity.length === 0 ? null : <div className="bg-white rounded-lg shadow p-4 w-full">
                            <h1 className="text-slate-700 font-semibold text-lg px-2 mb-5"><Translate label="Activity Timeline" /></h1>
                            <span className="block max-h-[400px] overflow-auto">
                                {BrowseActivity.map(item => (
                                    <IonItem onClick={e => {
                                        setModal(true)
                                        setSelectedSession({
                                            session_id: item.item.session_id,
                                            application_id: item.application.id
                                        })
                                    }} className="cursor-pointer" >
                                        <span className="flex flex-col lg:flex-row lg:items-center justify-between w-full">
                                            <h1 className="text-slate-700 flex items-center">
                                                <img src={STATIC_URL + item.application.logo_path} className="hidden lg:block w-8 mr-2" />
                                                <b className="mr-1">{item.user.username}. </b> launched <b className="ml-1">{item.application.name}</b>
                                            </h1>
                                            <h1 className="text-slate-700">{item.item.date}</h1>
                                        </span>
                                    </IonItem>
                                ))}
                            </span>
                        </div>}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default OverallJSX