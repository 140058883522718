import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonModal, IonPopover, IonTitle, IonToolbar } from "@ionic/react"
import { useState, Fragment } from "react"
import { BsQuestionLg } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { toggleAuthCheck } from "../../Redux/User/Slice"


const ConfirmCredentials = ({
    modal,
    handleSubmit,
    data,
    setData
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [popover, togglePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const check_auth = useSelector((state: RootState) => state.user.check_auth)

    return <IonModal ref={modal} trigger="open-modal" onDidDismiss={e => modal.current.isOpen = false}>
        <IonPopover
            onDidDismiss={e => togglePopover({ open: false, event: undefined })}
            isOpen={popover.open} event={popover.event}
            size="auto" side="left" alignment="start"
        >
            <p className="p-3">As an additional security measure for users who could share their devices with others, credential confirmation is introduced. </p>
            <IonButton onClick={e => dispatch(toggleAuthCheck())}>{check_auth === true ? "Disable" : "Enable"}</IonButton>
        </IonPopover>
        <IonHeader>
            <IonToolbar>
                <IonTitle>
                    <span className="flex items-center justify-between">
                        <p>Confirm credentials</p>
                        <BsQuestionLg onClick={e => togglePopover({ open: true, event: e.nativeEvent })} />
                    </span>
                </IonTitle>
            </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding">
            {check_auth === true ?
                <Fragment>
                    <IonItem>
                        <IonLabel position="stacked">Username</IonLabel>
                        <IonInput type="text" placeholder="Your Username" onIonChange={e => setData({ ...data, auth_username: e.target.value })} />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Password</IonLabel>
                        <IonInput type="password" placeholder="Your Password" onIonChange={e => setData({ ...data, auth_password: e.target.value })} />
                    </IonItem>
                </Fragment>
                : <h3 className="text-lg text-center">Are you sure?</h3>}
            <span className="flex items-center justify-center mt-5">
                <IonButton className="w-1/3" onClick={handleSubmit}>Confirm</IonButton>
                <h3 className="h-full mx-auto"> . </h3>
                <IonButton color="medium" className="w-1/3" onClick={e => modal.current.isOpen = false}>Cancel</IonButton>
            </span>
        </IonContent>
    </IonModal >
}

export default ConfirmCredentials