import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { Fragment, useEffect } from "react"
import { TailSpin } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { print_pdf } from "../../../../Common/print"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { UserState } from "../../../../Redux/User/Interface"
import ChartContainer from "../../Charts/ChartContainer"
import LineChart from "../../Charts/LineChart"
import { getTranslation } from "../../../../Common/Functions"
import Translate from "../../../../components/Translate"


const average = array => array.length === 0 ? 0 : (array.reduce((a, b) => a + b) / array.length).toFixed(2)

function groupArrayIntoSections(array) {
    const groupedArray = [];
    let section = [];

    for (let i = 0; i < array.length; i++) {
        section.push(array[i]);

        if (section.length === 3 || i === array.length - 1) {
            const average = section.reduce((sum, value) => sum + value, 0) / section.length;
            groupedArray.push(average);
            section = [];
        }
    }

    return groupedArray;
}

function calculateStdDevFromReference(values, min, max) {
    let numEntries = values?.length;
    let numCount = 0;
    let mean = 0;
    let squaredDiffSum = 0;
    const referenceValue = min + ((max - min) / 2);
    for (numCount = 0; numCount < numEntries; numCount++) {
        let currentRowValue = values[numCount];

        if (currentRowValue > max + (max / 2)) {
            currentRowValue = max + (max / 2);
        }
        if (currentRowValue < min - (min / 2)) {
            currentRowValue = min - (min / 2);
        }

        mean += currentRowValue;
        squaredDiffSum += Math.pow(currentRowValue - referenceValue, 2);
    }
    if (numCount > 1) {
        mean /= numCount;
        return {
            middle: Math.sqrt(squaredDiffSum / (numCount - 1)).toFixed(1),
            upper: (referenceValue + Math.sqrt(squaredDiffSum / (numCount - 1))).toFixed(1),
            lower: (referenceValue - Math.sqrt(squaredDiffSum / (numCount - 1))).toFixed(1),
        };
    } else {
        return { middle: 0, upper: 0, lower: 0 }
    }
}

const WeldingSession: React.FC<{
    modal: boolean
    setModal: Function
    selectedSession: string
}> = ({ modal, setModal, selectedSession }) => {
    const dispatch = useDispatch<AppDispatch>()
    const app_id: number = parseInt(useParams<{ id: string }>().id)

    const analytics: Analytics = useSelector((state: RootState) => state.analytics)
    const applicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)

    const current_app = applicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === app_id)
    const getSessionLabel = (session, label) => label === 127 ? analytics.sessions.data.find(s => s.session_id === session && s.label_id === label) === undefined ? {} : 
        JSON.parse(analytics.sessions.data.find(s => s.session_id === session && s.label_id === label)?.score) :
        analytics.sessions.data.find(s => s.session_id === session && s.label_id === label)

    useEffect(() => {
        if (selectedSession === null) return
        const session_exists = analytics.sessions.data.find(s => s.session_id === selectedSession) !== undefined
        if (session_exists === false) dispatch(AnalyticsActions.getSessions({ arguments: { session_id: selectedSession } }))
    }, [selectedSession, analytics.sessions.data, dispatch])

    const user = UserReducer.users.find(u => u !== null && u !== undefined && u.id === analytics.sessions.data.find(s => s.session_id === selectedSession)?.user_id)
    const start = new Date(getSessionLabel(selectedSession, 125)?.score).getTime()
    const end = new Date(getSessionLabel(selectedSession, 126)?.score).getTime()
    const duration = (end - start) / 60000

    let data_info = {
        distanceEntries: [],
        speedEntries: [],
        travelAngleEntries: [],
        workAngleEntries: [],
    }

    try {
        data_info = JSON.parse(analytics.sessions.data.find(s => s.session_id === selectedSession && s.label_id === 127)?.score)
        for (const key in data_info) {
            if (["distanceEntries", "speedEntries", "travelAngleEntries", "workAngleEntries"].includes(key)) {
                data_info[key] = groupArrayIntoSections(data_info[key]).map((item, index) => ({ x: index, y: item }))
            }
        }
    } catch (e) { }

    return (
        <IonModal id='welding-session-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
            <IonContent>
                <IonToolbar>
                    <IonTitle><Translate label="Session Info" /></IonTitle>
                    <IonButtons slot="end">
                        <IonButton color="light" onClick={() => setModal(false)}>
                            <Translate label="Close" />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <div className="p-2 bg-slate-100">
                    <span className={analytics.sessions.status === "pending" || analytics.sessions.data.find(s => s.session_id === selectedSession) === undefined ? "flex justify-center items-center  h-[680px]" : ""}>
                        <TailSpin
                            height="30"
                            width="30"
                            color="#4fa94d"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={analytics.sessions.status === "pending"}
                        />
                    </span>
                    {analytics.sessions.status === "pending" || analytics.sessions.data.find(s => s.session_id === selectedSession) === undefined ? null : <Fragment>
                        <div id="welding-report-print" className="max-w-[800px] mx-auto">
                            <h1 className="text-lg text-slate-800 text-center mb-2">{user.username} <Translate label="Welded" /> <b><Translate label={getSessionLabel(selectedSession, 129)?.score} /></b> - <b><Translate label={getSessionLabel(selectedSession, 135)?.score} /></b> <Translate label="Method" /> ({getSessionLabel(selectedSession, 125)?.score})</h1>
                            <div className="flex justify-between items-center w-full mx-auto mt-2">
                                <div className="w-full bg-white px-4 py-2 rounded-lg shadow mx-auto">
                                    <h3 className="text-slate-600 font-medium text-lg"><Translate label="Session Info" /></h3>
                                    <span className="grid grid-cols-1 md:grid-cols-2">
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Session Duration" /> (min)</span>: <span className="text-slate-600">{duration.toFixed(2)}</span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Speed" /> (cm/s) / Ref.</span>: <span className="text-slate-600">{
                                            calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.speedEntries, 8, 15).lower + "-" + calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.speedEntries, 8, 15).upper
                                        }/8-15</span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Work Angle" /> (°) / Ref.</span>: <span className="text-slate-600">{
                                            calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.workAngleEntries, 20, 40).lower + "-" + calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.workAngleEntries, 20, 40).upper
                                        }/20-40</span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Travel Angle" /> (°) / Ref.</span>: <span className="text-slate-600">{
                                            calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.travelAngleEntries, 15, 30).lower + "-" + calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.travelAngleEntries, 15, 30).upper
                                        }/15-30</span></p>
                                        {getSessionLabel(selectedSession, 135)?.score === "STICK" ? null : <p className="text-base"><span className="text-slate-900"><Translate label="Distance" /> (cm) / Ref.</span>: <span className="text-slate-600">{
                                            calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.distanceEntries, 15, 30).lower + "-" + calculateStdDevFromReference(getSessionLabel(selectedSession, 127)?.distanceEntries, 15, 30).upper
                                        }/15-30</span></p>}
                                    </span>
                                </div>
                            </div>
                            <div className="hidden print:flex md:flex justify-between items-center w-[800px] mx-auto mt-2">
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[
                                        {
                                            id: getTranslation("Work Angle"),
                                            color: "#f87171",
                                            data: data_info.workAngleEntries
                                        }
                                    ]}

                                    maxHeight={180}
                                    maxWidth={455}
                                    title={getTranslation("Average Work Angle")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(20);
                                                const yEnd = yScale(40);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(248, 113, 113, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[
                                        {
                                            id: getTranslation("Travel Angle"),
                                            color: "#10b981",
                                            data: data_info.travelAngleEntries
                                        }
                                    ]}

                                    maxHeight={180}
                                    maxWidth={455}
                                    title={getTranslation("Average Travel Angle")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(16, 185, 129, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                            <div className="hidden print:flex md:flex justify-between items-center w-[800px] mx-auto mt-2">
                                {getSessionLabel(selectedSession, 135)?.score === "STICK" ? null : <ChartContainer
                                    Chart={LineChart}
                                    data={[{
                                        id: getTranslation("Distance"),
                                        color: "#3b82f6",
                                        data: data_info.distanceEntries
                                    }]}
                                    maxHeight={180}
                                    maxWidth={455}
                                    title={getTranslation("Average Distance")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Distance (mm)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />}
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[{
                                        id: getTranslation("Speed"),
                                        color: "#3b82f6",
                                        data: data_info.speedEntries
                                    }]}
                                    maxHeight={180}
                                    maxWidth={getSessionLabel(selectedSession, 135)?.score === "STICK" ? 860 : 455}
                                    title={getTranslation("Average Speed")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Speed (mm / s)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(8);
                                                const yEnd = yScale(15);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>

                            <div className="flex flex-col gap-2 print:hidden md:hidden justify-between items-center w-full mx-auto mt-2">
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[
                                        {
                                            id: getTranslation("Work Angle"),
                                            color: "#f87171",
                                            data: data_info.workAngleEntries
                                        }
                                    ]}

                                    maxHeight={180}
                                    maxWidth={undefined}
                                    title={getTranslation("Average Work Angle")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(20);
                                                const yEnd = yScale(40);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(248, 113, 113, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[
                                        {
                                            id: getTranslation("Travel Angle"),
                                            color: "#10b981",
                                            data: data_info.travelAngleEntries
                                        }
                                    ]}

                                    maxHeight={180}
                                    maxWidth={undefined}
                                    title={getTranslation("Average Travel Angle")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(16, 185, 129, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                            <div className="flex flex-col gap-2 print:hidden md:hidden justify-between items-center w-full mx-auto mt-2">
                                {getSessionLabel(selectedSession, 135)?.score === "STICK" ? null : <ChartContainer
                                    Chart={LineChart}
                                    data={[{
                                        id: getTranslation("Distance"),
                                        color: "#3b82f6",
                                        data: data_info.distanceEntries
                                    }]}
                                    maxHeight={180}
                                    maxWidth={undefined}
                                    title={getTranslation("Average Distance")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Distance (mm)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />}
                                <ChartContainer
                                    Chart={LineChart}
                                    data={[{
                                        id: getTranslation("Speed"),
                                        color: "#3b82f6",
                                        data: data_info.speedEntries
                                    }]}
                                    maxHeight={180}
                                    maxWidth={undefined}
                                    title={getTranslation("Average Speed")}
                                    properties={{
                                        enableGridX: false,
                                        enableDots: false,
                                        axisBottom: null,
                                        axisLeft: { legend: 'Speed (mm / s)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(8);
                                                const yEnd = yScale(15);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                        </div>
                        <span className="flex items-center justify-center my-5">
                            <IonButton onClick={e => print_pdf(document.getElementById("welding-report-print").cloneNode(true), "Usage Report", current_app, false)}><Translate label="EXPORT" /></IonButton>
                        </span>
                    </Fragment>}
                </div>
            </IonContent>
        </IonModal>
    )
}

export default WeldingSession