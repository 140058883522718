import { createSlice } from "@reduxjs/toolkit";
import { PurchaseReducers } from "./Actions"
import { PurchasesState } from "./Interface";


const init: PurchasesState = {
    createStatus: "idle",
    createMessage: "",
    readStatus: "idle",
    readMessage: "",
    updateStatus: "idle",
    updateMessage: "",
    deleteStatus: "idle",
    deleteMessage: "",
    purchases: [],
    checkout_id: null,
    cart: {
        carts: [],
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
    }
}

const UserSlice = createSlice({
    name: "user",
    initialState: init,
    reducers: {
    },
    extraReducers(builder) {
        for (const action in PurchaseReducers) {
            builder.addCase(action, PurchaseReducers[action])
        }
    }
})


export default UserSlice.reducer