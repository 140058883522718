import { useIonAlert } from "@ionic/react";
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux";
import { STATIC_URL } from "../../../Common/constants"
import { InfoTypes } from "../../../Common/Interfaces/Application";
import { BrandingImages, DLC, ImageType, Purchase } from "../../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { AppDispatch } from "../../../Redux/Store"

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return [windowSize, setWindowSize];
}

const getCursorPosition = (canvas, event) => {
    const rect = canvas.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top
    return { x, y }
}

const PreviewCanvas: React.FC<{
    branding: DLC, dlc_images: Array<BrandingImages>, image_types: Array<ImageType>, purchase: Purchase, dlc_images_red: {
        createStatus: InfoTypes
        createMessage: string
        readStatus: InfoTypes
        readMessage: string
        updateStatus: InfoTypes
        updateMessage: string
        deleteStatus: InfoTypes
        deleteMessage: string
        dlc_images: Array<BrandingImages | null>
    }
}> = ({
    branding,
    dlc_images,
    image_types,
    purchase,
    dlc_images_red
}) => {
        const dispatch = useDispatch<AppDispatch>()
        const [size, setSize] = useWindowSize()
        const ref: React.LegacyRef<HTMLCanvasElement> = useRef()
        const [fakeEvent, setFakeEvent] = useState(false)
        const [ionAlert] = useIonAlert()
        const [submitted, setSubmitted] = useState(false)

        useEffect(() => {
            if(submitted === false) return
            if (dlc_images_red.createStatus === "fulfilled" || dlc_images_red.updateStatus === "fulfilled") ionAlert({
                header: "Success", message: "Branding profile updated successfully.", buttons: ["OK"]
            })
        }, [dlc_images, dlc_images_red.createStatus, dlc_images_red.updateStatus, ionAlert, submitted])

        useEffect(() => {
            let img = new Image();
            img.src = STATIC_URL + branding?.branding_scene_path;
            img.onload = function () {
                let ctx = ref.current.getContext('2d');

                const ratio = img.height / img.width
                img.width = ref.current.parentElement.clientWidth
                img.height = Math.floor(ratio * ref.current.parentElement.clientWidth)
                const relative_ratio_x = ref.current.parentElement.clientWidth / 1920
                const relative_ratio_y = ref.current.parentElement.clientHeight / 1080

                ref.current.width = img.width
                ref.current.height = img.height

                ctx.drawImage(img, 0, 0, img.width, img.height);
                let _ret = image_types.map(image => {
                    const exists = dlc_images.find(img => img.type_id === image.id)
                    if (exists === undefined) {
                        ctx.beginPath()
                        ctx.rect(image.offset_left * relative_ratio_x, image.offset_top * relative_ratio_y, img.width * image.scale, img.width * image.scale)
                        ctx.fillStyle = "#F3F4F6"
                        ctx.fill()
                        ctx.stroke()

                        ctx.beginPath()
                        ctx.fillStyle = "#000"
                        ctx.fill()
                        ctx.font = "20px sans-serif";
                        ctx.fillText(image.name, image.offset_left * relative_ratio_x + (img.width * image.scale / 4), image.offset_top * relative_ratio_y + (img.width * image.scale / 2), 100)
                        ctx.stroke()
                    } else {
                        let brand_img = new Image()
                        brand_img.src = STATIC_URL + exists.image_path
                        brand_img.onload = function () {
                            brand_img.width = img.width * image.scale
                            brand_img.height = img.height * image.scale
                            ctx.drawImage(brand_img, image.offset_left * relative_ratio_x, image.offset_top * relative_ratio_y, brand_img.width, brand_img.width);
                        }
                    }
                })
            }
        }, [size, dlc_images, fakeEvent, branding?.branding_scene_path, image_types])

        useEffect(() => {
            const interval = setInterval(() => {
                setFakeEvent(!fakeEvent)
            }, 1500)

            return () => clearInterval(interval)
        }, [fakeEvent])


        const handleClick = e => {
            setSubmitted(false)
            const coords = getCursorPosition(e.target, e)
            const relative_ratio_x = ref.current.parentElement.clientWidth / 1920
            const relative_ratio_y = ref.current.parentElement.clientHeight / 1080
            let clicked = null;
            for (const image of image_types) {
                let x_from = image.offset_left * relative_ratio_x
                let y_from = image.offset_top * relative_ratio_y
                let x_to = x_from + ref.current.parentElement.clientWidth * image.scale
                let y_to = y_from + ref.current.parentElement.clientWidth * image.scale
                if (coords.x >= x_from && coords.x <= x_to && coords.y >= y_from && coords.y <= y_to) {
                    clicked = image
                    break
                }
            }

            if (clicked !== null) {
                document.getElementById(clicked.name).click()
            }
        }

        const handleFileChange = (e, type_id) => {
            const type = image_types.find(t => t.id === type_id)
            const type_aspect_ratio = parseInt(type.prefered_aspect_ratio.split(":")[0]) / parseInt(type.prefered_aspect_ratio.split(":")[1])
            const file = e.target.files[0]
            const img = new Image();
            const url = window.URL || window.webkitURL;
            const objectUrl = url.createObjectURL(file);
            img.onload = function () {
                if (img.width / img.height !== type_aspect_ratio) {
                    return ionAlert({
                        header: "Aspect Ratio Error",
                        message: "Image aspect ratio must be " + type.prefered_aspect_ratio,
                        buttons: ["OK"]
                    })
                }

                const current_img = dlc_images.find(i => i.type_id === type_id)
                const data = new FormData()
                if (current_img !== undefined) {
                    data.append("id", current_img.id.toString())
                    data.append("branding_image", file)
                    dispatch(ApplicationActions.updateDlcImage({ image: data }))
                } else {
                    data.append("type_id", type_id.toString())
                    data.append("purchase_id", purchase?.id?.toString())
                    data.append("branding_image", file)
                    dispatch(ApplicationActions.createDlcImage({ image: data }))
                }
                setSubmitted(true)
                url.revokeObjectURL(objectUrl);
            };
            img.src = objectUrl;
        }

        return (
            <div>
                <canvas onClick={e => handleClick(e)} ref={ref}>

                </canvas>
                {image_types.map(t => <input onChange={e => handleFileChange(e, t.id)} name={t.name} type="file" accept="image/*" className="hidden" id={t.name} />)}

            </div>
        )
    }

export default PreviewCanvas

// str = "One, Two, Three, Four"
// str = str[::-1].replace(",", "dna ", 1)[::-1]
// print(str)
// >>> 'One, Two, Three and Four'