import React from "react"
import { GiVrHeadset } from "react-icons/gi"
import { Device, Organization, User } from "../../Common/Interfaces/Entities"


const DeviceCard: React.FC<{ device: Device, user: User, organization: Organization | null }> = ({ device, user, organization }) => (
    <div className="bg-white p-6 shadow rounded w-full">
        <div className="flex items-center border-b border-gray-200 pb-6">
            <GiVrHeadset className="text-cythero-primary w-12 h-12" />
            <div className="flex items-start justify-between w-full">
                <div className="pl-3 w-full">
                    <p className="focus:outline-none text-xl font-medium leading-5 text-slate-800">{user.username}'s Device</p>
                    <p className="focus:outline-none text-sm leading-normal pt-2 text-slate-600">{device.device_number}</p>
                </div>
            </div>
        </div>
        <div className="px-2">
            <p className="text-sm leading-5 py-1 text-slate-700">{organization === null ? "Device belongs to " + user.username : "Device belongs to " + organization?.name}</p>
            <p className="text-sm leading-5 py-1 text-slate-700">{device.last_log === null ? "No activity recorded" : "Last logged in on " + device.last_log.date + " from " + device.last_log.location + " ("+device.last_log.ip_address+")" + " using " + device.last_log.user_agent}</p>
        </div>
    </div>
)

export default DeviceCard