import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { useEffect, useState } from "react"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { IonButton, IonContent, IonHeader, IonModal, useIonAlert } from "@ionic/react"
import Loading from "../../components/Loading"
import { FaWindowClose } from "react-icons/fa"
import { setUserState } from "../../Redux/User/Slice"
import { DataGrid } from '@mui/x-data-grid'
import { darken, lighten, styled } from '@mui/material/styles';
import Input from "../../components/Form/Input"

const getBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
const getHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
const getSelectedBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
const getSelectedHoverBackgroundColor = (color: string, mode: string) => mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Banned': {
    backgroundColor: getBackgroundColor(
      theme.palette.grey[500],
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

const IndependentUsers: React.FC<{}> = ({ }) => {
  const dispatch = useDispatch<AppDispatch>()
  const UserReducer: UserState = useSelector((state: RootState) => state.user)
  const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
  const [confirmationModal] = useIonAlert()
  const [banned, setBanned] = useState(null)
  const [accountAllocatedSeats, setAccountAllocatedSeats] = useState(null)
  const [submitType, setSubmittype] = useState(null)

  useEffect(() => {
    dispatch(UserActions.readIndependentUsers({ arguments: {} }))
    dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
  }, [])

  useEffect(() => {
    if (UserReducer?.independentUser?.updateStatus === undefined) return
    if (UserReducer.independentUser.updateStatus === "fulfilled") {
      setTimeout(() => {
        setBanned(null)
        dispatch(setUserState({
          ...UserReducer,
          independentUser: {
            ...UserReducer.independentUser,
            updateStatus: "idle"
          }
        }))
      }, 1500)
    } else if (UserReducer.independentUser.updateStatus === "rejected") {
      setTimeout(() => {
        dispatch(setUserState({
          ...UserReducer,
          independentUser: {
            ...UserReducer.independentUser,
            updateStatus: "idle"
          }
        }))
        setBanned(null)
      }, 1500)
    }
  }, [UserReducer?.independentUser?.updateStatus])

  if (UserReducer?.independentUser?.updateStatus === undefined) return <Loading isOpen={true} message="Loading data... Please wait." />
  if (ApplicationsReducer.application.readStatus === "pending" || UserReducer.independentUser.readStatus === "pending") return <Loading isOpen={true} message="Loading data... Please wait." />

  const columns = [
    { field: 'id', headerName: 'ID', width: 40, hide: true },
    { field: 'device_id', headerName: 'Device ID', width: 400 },
    { field: 'account_id', headerName: 'Account Name', width: 200 },
    { field: 'application_id', headerName: 'Application', width: 120 },
    { field: 'date_registered', headerName: 'Register Date', type: 'date', width: 100 },
    { field: 'ip_address', headerName: 'IP', width: 140 },
    { field: 'location', headerName: 'Location', width: 180 },
    { field: 'last_session', headerName: 'Last Play', width: 140, type: 'date' },
    { field: 'status', headerName: 'Status', width: 100 },
  ];

  const rows = UserReducer.independentUser?.sessions?.map(user => {
    const account = UserReducer.independentUser?.accounts.find(account => account.account_id === user.account_id)
    console.log(user.date)
    return {
      id: user.id,
      device_id: user.device_id,
      account_id: user.account_id,
      application_id: ApplicationsReducer.application.applications.find(app => app !== null && app !== undefined && app.id === user.application_id).name,
      date_registered: new Date(user.date),
      ip_address: user.ip_address,
      location: user.location,
      last_session: new Date(user.last_session),
      status: account.banned === 1 ? "Banned" : "Active",
    }
  }) || []

  function copyToClipboard(text) {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);

    tempInput.value = text;
    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");
    document.body.removeChild(tempInput);
  }

  return (
    <div className="w-full mx-auto">
      <div className="w-[1440px] max-w-[95%] mx-auto bg-white mb-12 mt-12">
        <span className="flex justify-between items-center px-3 py-1 bg-slate-700">
          <p className="text-white font-bold uppercase text-xl">List of Independent users</p>
        </span>
        <StyledDataGrid
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick={true}
          isRowSelectable={() => true}
          getRowClassName={(params) => {
            return `super-app-theme--${params.row.status === "Banned" ? "Banned" : ""}`
          }}
          onRowDoubleClick={(e) => {
            setBanned(e.id)
            setAccountAllocatedSeats(
              UserReducer.independentUser.accounts.find(acc => acc.account_id === UserReducer.independentUser.sessions.find(s => s.id === e.id).account_id).available_seats
            )
          }}
          onCellClick={e => copyToClipboard(e.formattedValue)}
        />
      </div>
      <IonModal key={"delete_User_key"} isOpen={banned !== null} onWillDismiss={e => {
        setBanned(null)
      }}>
        <IonHeader>
          <div className="w-full bg-cythero-primary px-2 py-3 flex justify-between items-center">
            <h1 className="font-bold text-white">Manage Independant user</h1>
            <FaWindowClose className="text-white text-lg" onClick={e => {
              setBanned(null)
            }} />
          </div>
        </IonHeader>
        <IonContent>
          <div className="w-full h-full bg-slate-100 p-4 relative">
            <h1 className="text-slate-800 font-bold mb-2">Manage user status:</h1>
            <IonButton className="mb-2" color={"danger"} disabled={UserReducer.independentUser.updateStatus === "pending"} onClick={e => {
              const session_to_ban = UserReducer.independentUser.sessions?.find(s => s !== null && s !== undefined && s.id === banned)
              const account = UserReducer.independentUser.accounts.find(acc => acc.account_id === session_to_ban.account_id)
              const data = new FormData()
              data.append("account_id", session_to_ban.account_id.toString())
              data.append("banned", (account?.banned === 1 ? 0 : 1).toString())
              setSubmittype("banm")
              dispatch(UserActions.updateIndependentUsers({ "user": data }))
            }}>{
                UserReducer.independentUser.accounts.find(acc => acc.account_id === UserReducer.independentUser.sessions.find(session => session.id === banned)?.account_id)?.banned === 1 ? "Unban" : "Ban"
              }</IonButton>

            {submitType === "ban" && banned !== null && UserReducer.independentUser.updateStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
            {submitType === "ban" && banned !== null && UserReducer.independentUser.updateStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
            {submitType === "ban" && banned !== null && UserReducer.independentUser.updateStatus === "fulfilled" ?
              <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{
                UserReducer.independentUser.accounts.find(acc => acc.account_id === UserReducer.independentUser.sessions.find(session => session.id === banned).account_id)?.banned === 1 ? "User has been banned." : "User has been unbanned."
              }</h1>
              : null}
            <h1 className="text-slate-800 font-bold mb-2">Allocate Devices:</h1>
            <div className="flex items-center gap-3">
              <input
                name="available_seats"
                onChange={e => setAccountAllocatedSeats(e.target.value)}
                placeholder="Allocated Seats"
                type="text"
                value={accountAllocatedSeats}
                accept={"*"}
                className="bg-slate-100 px-2 py-1 border-2 w-[50px] text-center"
              />
              <IonButton className="mb-2" color={"primary"} disabled={UserReducer.independentUser.updateStatus === "pending"} onClick={e => {
                const session_to_update = UserReducer.independentUser.sessions?.find(s => s !== null && s !== undefined && s.id === banned)
                const data = new FormData()
                data.append("account_id", session_to_update.account_id.toString())
                data.append("available_seats", accountAllocatedSeats)
                setSubmittype("update")
                dispatch(UserActions.updateIndependentUsers({ "user": data }))
              }}>Update</IonButton>
            </div>
            {submitType === "update" && banned !== null && UserReducer.independentUser.updateStatus === "rejected" ? <h1 className="bg-red-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
            {submitType === "update" && banned !== null && UserReducer.independentUser.updateStatus === "pending" ? <h1 className="bg-blue-500 text-white font-bold px-2 py-1 mt-2 rounded-md">{UserReducer.independentUser.updateMessage}</h1> : null}
            {submitType === "update" && banned !== null && UserReducer.independentUser.updateStatus === "fulfilled" ?
              <h1 className="bg-green-500 text-white font-bold px-2 py-1 mt-2 rounded-md">User has been updated</h1>
              : null}
          </div>
        </IonContent>
      </IonModal>
    </div>
  )
}

export default IndependentUsers