import { IonButton } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "../../../../../components/Form/Select"
import { AnalyticsActions } from "../../../../../Redux/Analytics/Actions"
import { clearReport, setReportFilters } from "../../../../../Redux/Analytics/Slice"
import { AppDispatch, RootState } from "../../../../../Redux/Store"
import { UserActions } from "../../../../../Redux/User/Actions"
import Translate from "../../../../../components/Translate"


const PartReportFilters = (props) => {
    const dispatch = useDispatch<AppDispatch>()

    const userReducer = useSelector((state: RootState) => state.user)
    const analReducer = useSelector((state: RootState) => state.analytics)

    const [selectedUsers, setSelectedUsers] = useState([])
    const [selectedFarts, setSelectedFarts] = useState(null)

    useEffect(() => {
        dispatch(clearReport("partReport"))
        dispatch(AnalyticsActions.getFarts({}))
        dispatch(UserActions.readUsers({}))
    }, [dispatch])

    const handleSubmit = e => {
        e.preventDefault()
        let user_ids = [...selectedUsers]

        const form = new FormData()
        form.append("users", JSON.stringify(user_ids))
        form.append("parts", JSON.stringify(selectedFarts))
        dispatch(setReportFilters({
            "report" : "partReport",
            "filters": {
                "users" : user_ids,
                "parts" : selectedFarts
            }
        }))
        dispatch(AnalyticsActions.partReport({ filters: form }))

    }


    return (
        <div className="bg-white p-4 rounded-lg shadow">
            <h1 className="text-slate-800 font-bold text-lg"><Translate label="Report Filters" /></h1>
            {userReducer.current.organization_id === null ? null : <Select
                value={selectedUsers}
                name="user_ids"
                placeholder={<Translate label="Select Users" />}
                handleChange={e => setSelectedUsers(e.target.value)}
                displayField="username"
                valueField="id"
                options={userReducer.users.filter(u => u !== null && u !== undefined && u.archived !== 1 && u.organization_id === userReducer.current.organization_id && u.type_id === 1)}
                multiple={true}
            />}
            <Select
                value={selectedFarts}
                name="parts"
                placeholder={<Translate label="Select Part" />}
                handleChange={e => setSelectedFarts(e.target.value)}
                displayField="score"
                valueField="score"
                options={analReducer?.partsData?.data || []}
                multiple={false}
            />
            {selectedUsers.length === 0 || selectedFarts === null ? null : <IonButton onClick={e => handleSubmit(e)}><Translate label="GENERATE REPORT" /></IonButton>}
        </div>
    )
}

export default PartReportFilters