import { NavLink } from "react-router-dom"

const SectionSelector = ({
    option
}) => {

    const regular_class = "text-sm p-2 bg-cythero-primary text-white text-center rounded font-bold"
    const selected_class = "text-sm p-2 bg-cythero-light text-white text-center rounded font-semibold"

    return (
        <div className="col-span-12 w-full px-3 py-6 justify-center flex space-x-4 md:space-x-0 md:space-y-4 md:flex-col md:col-span-2 md:justify-start ">
            <NavLink to="/user/profile/info" className={option === "info" ? selected_class : regular_class}>Profile</NavLink>
            <NavLink to="/user/profile/address" className={option === "address" ? selected_class : regular_class}>Address</NavLink>
        </div>
    )
}

export default SectionSelector