import UserCard from "./UserCard";
import { TiUserAdd } from "react-icons/ti"
import { IonButton, IonFab } from "@ionic/react";
import { NavLink } from "react-router-dom";

const UserList = ({
    current,
    users,
    orgnizations
}) => (
    <span className="w-11/12 mx-auto my-10 flex flex-wrap gap-6 items-center mb-40">
        {users.filter(u => u !== undefined && u !== null).map(user => <UserCard
            key={user.id}
            current={current}
            orgnizations={orgnizations}
            firstName={user.firstName}
            username={user.username}
            lastName={user.lastName}
            email={user.email}
            organization_id={user.organization_id}
            type_id={user.type_id}
            id={user.id}
        />)}
        {current.type_id >= 3 ? <IonFab vertical="bottom" horizontal="end">
            <IonButton>
                <NavLink to="/users/create"><TiUserAdd className="text-3xl" /></NavLink>
            </IonButton>
        </IonFab> : null}
    </span>
)

export default UserList