import { Device } from "../../../Common/Interfaces/Entities"
import { GiVrHeadset } from "react-icons/gi"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { IonButton } from "@ionic/react"
import { BsFillTrashFill } from "react-icons/bs"
import { OrganizationActions } from "../../../Redux/Organizations/Actions"
import { useState } from "react"
import Confirmation, { ConfirmationInterface } from "../../../components/Confirmation/Confirmation"
import Translate from "../../../components/Translate"
import { getTranslation } from "../../../Common/Functions"

const DeviceCard: React.FC<{ device: Device }> = ({ device }) => {
    const dispatch = useDispatch<AppDispatch>()
    const users = useSelector((state: RootState) => state.user.users)
    const [showAlert, setShowAlert] = useState<ConfirmationInterface>({ open: false, function: null, message: "" })

    const deleteDevice = () => {
        const form = new FormData()
        form.append("id", device.id.toString())

        dispatch(OrganizationActions.deleteDevice({ device: form }))
    }

    return (
        <div className="my-2 pb-2 flex items-center w-full border-solid border-b-2 border-slate-100">
            <Confirmation showAlert={showAlert} setShowAlert={setShowAlert} />
            <GiVrHeadset className="w-12 h-12 mr-2 text-cythero-primary" />
            <span className="text-slate-800 w-full mr-2">
                <p className="mb-2 text-sm break-all">{device.device_number} {device.device_nickname === null ? null : "(" + device.device_nickname + ")"}</p>
                <p className="text-xs">
                    {device.last_log === null ?
                        "No available logs to display" :
                        users[device.last_log.user_id].username + " accessed from " +
                        device.last_log.ip_address + (device.last_log.location === "Not Available" ? "" : " (" + device.last_log.location + ")") +
                        " on " + device.last_log.date.slice(4, 22)
                    }
                </p>
                {device.last_log === null ? null : <p className="text-xs">{device.last_log.user_agent}</p>}
            </span>
            <IonButton onClick={e => setShowAlert({
                open: true,
                function: deleteDevice,
                message: getTranslation("Are you sure you want to delete this device") + "?"
            })} color={"danger"} className="text-xs text-white"><BsFillTrashFill /></IonButton>
        </div>
    )
}

export default DeviceCard