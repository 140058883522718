import { IonButton, IonItem, IonPopover } from "@ionic/react"
import { Fragment } from "react"
import { FaEllipsisH } from "react-icons/fa"
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from "react-icons/md"
import { TailSpin } from "react-loader-spinner"
import ProductCard from "./ProductCard"
import Translate from "../../../components/Translate"

const PurchaseHistoryJSX = ({
    application_id,
    organization_id,
    loading,
    purchases,
    count,
    showMore,
    togglePopover,
    popover,
    selected,
    setSelected,
    setShowMore
}) => (
    <div className="p-8 bg-white rounded-lg shadow mb-4">
        <h3 className="flex justify-between items-center">
            <p className="text-slate-700 font-medium text-lg"><Translate label="Purchased Applications" /></p>
            {application_id === null ? null :
                <Fragment>
                    <FaEllipsisH className="text-slate-800 cursor-pointer" onClick={e => togglePopover({ open: true, event: e.nativeEvent })} />

                    <IonPopover
                        onDidDismiss={e => togglePopover({ open: false, event: undefined })}
                        isOpen={popover.open} event={popover.event}
                        size="auto" side="bottom" alignment="center">
                        <IonItem className="cursor-pointer" onClick={e => { togglePopover({ open: false, event: undefined }); setSelected("") }}>
                            {selected === "" ? <MdOutlineRadioButtonChecked className="text-cythero-primary mr-3" /> : <MdOutlineRadioButtonUnchecked className="text-cythero-primary mr-3" />}
                            <p className="text-slate-700 font-semibold">All</p>
                        </IonItem>
                        <IonItem className="cursor-pointer" onClick={e => { togglePopover({ open: false, event: undefined }); setSelected("application") }}>
                            {selected === "application" ? <MdOutlineRadioButtonChecked className="text-cythero-primary mr-3" /> : <MdOutlineRadioButtonUnchecked className="text-cythero-primary mr-3" />}
                            <p className="text-slate-700 font-semibold">Applications</p>
                        </IonItem>
                        <IonItem className="cursor-pointer" onClick={e => { togglePopover({ open: false, event: undefined }); setSelected("dlc") }}>
                            {selected === "dlc" ? <MdOutlineRadioButtonChecked className="text-cythero-primary mr-3" /> : <MdOutlineRadioButtonUnchecked className="text-cythero-primary mr-3" />}
                            <p className="text-slate-700 font-semibold">DLCs</p>
                        </IonItem>
                        <IonItem className="cursor-pointer" onClick={e => { togglePopover({ open: false, event: undefined }); setSelected("package") }}>
                            {selected === "package" ? <MdOutlineRadioButtonChecked className="text-cythero-primary mr-3" /> : <MdOutlineRadioButtonUnchecked className="text-cythero-primary mr-3" />}
                            <p className="text-slate-700 font-semibold">Packages</p>
                        </IonItem>
                        <IonItem className="cursor-pointer" onClick={e => { togglePopover({ open: false, event: undefined }); setSelected("license") }}>
                            {selected === "license" ? <MdOutlineRadioButtonChecked className="text-cythero-primary mr-3" /> : <MdOutlineRadioButtonUnchecked className="text-cythero-primary mr-3" />}
                            <p className="text-slate-700 font-semibold">Licenses</p>
                        </IonItem>
                    </IonPopover>
                </Fragment>
            }
        </h3>
        <span className="flex justify-center items-center mb-5">
            <TailSpin
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
            />
        </span>
        {purchases.length > 0 ? null : <p className="text-slate-600">There are no purchases</p>}
        {purchases.map(p => <ProductCard
            key={p.id}
            purchase={p}
            application_id={application_id}
            organization_id={organization_id}
        />)}
        {count > 5 ? <IonButton className="mt-5" onClick={e => setShowMore(!showMore)}>{showMore ? "Show Less" : "Show More"}</IonButton> : null}
    </div>
)

export default PurchaseHistoryJSX