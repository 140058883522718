import { IonButton, IonFab } from "@ionic/react"
import { Fragment } from "react"
import { BiAddToQueue } from "react-icons/bi"
import { BsChevronCompactRight, BsFillPencilFill } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../../Common/constants"

const BrowseApplicationPackagesJSX = ({
    packages,
    dlcs,
    application,
    current
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/" + application.id}>{application.name}</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/packages/" + application.id}>Browse Packages</NavLink>
        </span>
        <div>
            {packages.length === 0 ? <h1 className="text-slate-700 font-bold text-lg my-4">No packages available yet. Stay tuned.</h1> : <Fragment>
                {packages.map(_package => <span className="block bg-white my-3 p-5 rounded-lg shadow" key={_package.id}>
                    <span className="flex mb-4">
                        <img className="w-20 h-20 mr-6" src={STATIC_URL + _package.cover_path} alt="package cover photo" />
                        <span className="w-full">
                            <span className="flex justify-between w-full">
                                <h1 className="text-lg text-cythero-primary ">{_package.name}</h1>
                                {current.type_id === 4 ? <NavLink to={"/applications/edit_package/" + _package.id}><BsFillPencilFill className="text-cythero-primary" /></NavLink> : null}
                            </span>
                            <p className="text-slate-800">{_package.description}</p>
                        </span>
                    </span>
                    <div className="flex flex-wrap gap-5 justify-center lg:justify-start">
                        {_package.items.map(item => item.application_id !== null ? (
                            <div className="w-[280px] flex items-center bg-slate-50 shadow-md rounded">
                                <img src={STATIC_URL + application.cover_path} alt="application cover photo" className="w-16 mr-4" />
                                <span className="">
                                    {application.name} x{item.quantity}
                                </span>
                            </div>
                        ) : (
                            <div className="w-[280px] flex items-center bg-slate-50 shadow-md rounded">
                                <img src={STATIC_URL + dlcs.find(d => d.id === item.dlc_id).cover_path} alt="dlc cover photo" className="w-16 mr-4" />
                                <span>
                                    {dlcs.find(d => d.id === item.dlc_id).name} x{item.quantity}
                                </span>
                            </div>
                        ))}
                    </div>
                </span>)}
            </Fragment>}
        </div>

        {current.type_id === 4 ? <IonFab vertical="bottom" horizontal="end" >
            <IonButton>
                <NavLink to={"/applications/create_package/" + application.id}><BiAddToQueue className="text-3xl" /></NavLink>
            </IonButton>
        </IonFab> : null}
    </div>
)

export default BrowseApplicationPackagesJSX