import { IonButton } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import DateSelect, { DateInterface, dateToDateStr } from "../../../../../components/Form/DateSelect"
import Select from "../../../../../components/Form/Select"
import { AnalyticsActions } from "../../../../../Redux/Analytics/Actions"
import { clearReport, setReportFilters } from "../../../../../Redux/Analytics/Slice"
import { AppDispatch, RootState } from "../../../../../Redux/Store"
import { UserActions } from "../../../../../Redux/User/Actions"
import Translate from "../../../../../components/Translate"


const UsageReportFilters = (props) => {
    const dispatch = useDispatch<AppDispatch>()
    const userReducer = useSelector((state: RootState) => state.user)
    const [filters, setFilters] = useState<{
        selectedUsers: Array<number>,
        selectedDates: {
            from: DateInterface | null,
            to: DateInterface | null
        }
    }>({
        selectedUsers: [],
        selectedDates: {
            from: null,
            to: null
        }
    })

    useEffect(() => {
        dispatch(clearReport("usageReport"))
        dispatch(UserActions.readUsers({}))
    }, [dispatch])

    const handleSubmit = e => {
        e.preventDefault()
        let user_ids = [userReducer.current.id, ...filters.selectedUsers]

        const form = new FormData()
        form.append("user_ids", JSON.stringify(user_ids))
        form.append("organization_id", userReducer.current.organization_id.toString())
        form.append("date_from", dateToDateStr(filters.selectedDates.from, "00:00:00"))
        form.append("date_to", dateToDateStr(filters.selectedDates.to, "23:59:59"))
        dispatch(setReportFilters({
            "report" : "usageReport",
            "filters": {
                "user_ids" : user_ids,
                "organization_id" : userReducer.current.organization_id,
                "date_from": dateToDateStr(filters.selectedDates.from, "00:00:00"),
                "date_to": dateToDateStr(filters.selectedDates.to, "23:59:59"),
            }
        }))

        dispatch(AnalyticsActions.usageReport({ filters: form }))

    }

    return (
        <div className="bg-white p-4 rounded-lg shadow">
            <h1 className="text-slate-800 font-bold text-lg"><Translate label="Report Filters" /></h1>
            {userReducer.current.organization_id === null ? null : <Select
                value={filters.selectedUsers}
                name="selectedUsers"
                placeholder={<Translate label="Select Users" />}
                handleChange={e => setFilters({...filters, [e.target.name] : e.target.value})}
                displayField="username"
                valueField="id"
                options={userReducer.users.filter(u => u !== null && u !== undefined && u.archived !== 1 && u.organization_id === userReducer.current.organization_id && u.type_id === 1)}
                multiple={true}
            />}
            <DateSelect 
                handleChange={e => setFilters({...filters, [e.target.name] : e.target.value})}
                name="selectedDates"
                placeholder={<Translate label="Select Date Range" />}
                value={filters.selectedDates}
            />
            {filters.selectedUsers.length === 0 || filters.selectedDates.to === null ? null : <IonButton onClick={e => handleSubmit(e)}><Translate label="GENERATE REPORT" /></IonButton>}
        </div>
    )
}

export default UsageReportFilters