import { ResponsiveBar } from '@nivo/bar'

const BarChart = ({ stacked, data, properties, increaseMl }) => (
    <ResponsiveBar
        data={data}
        margin={{ top: 10, right: 30, bottom: 60, left: increaseMl === true ? 150 : 50 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: stacked,
            reverse: false
        }}
        // sliceTooltip={data.tooltip}
        yFormat=" >-.2f"
        curve="monotoneX"
        axisTop={null}
        axisRight={null}
        colors={{ datum: "color" }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 3,
            tickRotation: 10,
        }}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 60,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 120,
                itemHeight: 15,
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        {...properties}
        tooltip={({ id, value, color }) => (
            <div className='bg-white p-1 rounded flex items-center justify-center gap-1 shadow'>
                <span className='block w-4 h-4' style={{ background: color }}> </span>
                <p> {id}: <strong>{value}</strong> </p>
            </div>
        )}
    />
)

export default BarChart