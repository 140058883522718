import { Fragment } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../../../Redux/Store"
import { UserState } from "../../../../Redux/User/Interface"
import ChartContainer from "../../Charts/ChartContainer"
import LineChart from "../../Charts/LineChart"
import CandleChart from "../../Charts/CandleChart"
import PieChart from "../../Charts/PieChart"
import Loading from "../../../../components/Loading"
import { getTranslation } from "../../../../Common/Functions"
import Translate from "../../../../components/Translate"


const WeldingUserReport = props => {

    const weldingUserReport = useSelector((state: RootState) => state.analytics.weldingUserReport)
    const userReducer: UserState = useSelector((state: RootState) => state.user)

    if (weldingUserReport.status === "pending") return <Loading isOpen={true} message="Report is being generated. Please wait." />
    if (weldingUserReport.status === "idle") return <Fragment></Fragment>

    const ret = []
    const colors = ["#f87171", "#10b981", "#3b82f6", "#6e71f1", "#c34cf1"]
    for (const user_id in weldingUserReport.data) {
        const methods_arrays = {}
        const user_methods_pie = []
        let _ret = weldingUserReport.filters?.methods?.map((m, idx) => {
            methods_arrays[m] = []
            user_methods_pie.push({
                id: getTranslation(m), label: getTranslation(m), color: colors[idx],
                value: weldingUserReport.data[user_id][m]["Butt Joint"]["Number Of Sessions"] + 
                       weldingUserReport.data[user_id][m]["Lap Joint"]["Number Of Sessions"] + 
                       weldingUserReport.data[user_id][m]["Tee Joint"]["Number Of Sessions"] +
                       weldingUserReport.data[user_id][m]["Corner Joint"]["Number Of Sessions"] +
                       weldingUserReport.data[user_id][m]["Edge Joint"]["Number Of Sessions"]
            })
        })
        console.log(methods_arrays)
        let average_angles = {
            "Butt Joint": { "work": { ...methods_arrays }, "travel": { ...methods_arrays } },
            "Tee Joint": { "work": { ...methods_arrays }, "travel": { ...methods_arrays } },
            "Lap Joint": { "work": { ...methods_arrays }, "travel": { ...methods_arrays } },
            "Corner Joint": { "work": { ...methods_arrays }, "travel": { ...methods_arrays } }, 
            "Edge Joint": { "work": { ...methods_arrays }, "travel": { ...methods_arrays } }
        }
        let average_distance = { "Butt Joint": { ...methods_arrays }, "Lap Joint": { ...methods_arrays }, "Tee Joint": { ...methods_arrays }, "Corner Joint": { ...methods_arrays }, "Edge Joint": { ...methods_arrays } }
        let average_speed = { "Butt Joint": { ...methods_arrays }, "Lap Joint": { ...methods_arrays }, "Tee Joint": { ...methods_arrays }, "Corner Joint": { ...methods_arrays }, "Edge Joint": { ...methods_arrays } }
        let num_sessions = 0
        let time_spent = 0
        const user = userReducer.users[user_id]

        for (const key in weldingUserReport.data[user_id]) {
            if (key === "user_id" || weldingUserReport.filters.methods.includes(key) === false) continue
            const method_data = weldingUserReport.data[user_id][key]
            for (const part of ["Butt Joint", "Lap Joint", "Tee Joint", "Corner Joint", "Edge Joint"]) {
                average_angles[part]["work"][key] = [...average_angles[part]["work"][key], ...method_data[part]["Average Work Angle"]?.map((value, index) => ({ x: index + average_angles[part]["work"][key].length, y: value }))]
                average_angles[part]["travel"][key] = [...average_angles[part]["travel"][key], ...method_data[part]["Average Travel Angle"]?.map((value, index) => ({ x: index + average_angles[part]["travel"][key].length, y: value }))]
                average_distance[part][key] = [...average_distance[part][key], ...method_data[part]["Average Distance"]?.map((value, index) => ({ x: index + average_distance[part][key].length, y: value }))]
                average_speed[part][key] = [...average_speed[part][key], ...method_data[part]["Average Speed"]?.map((value, index) => ({ x: index + average_speed[part][key].length, y: value }))]
                num_sessions += method_data[part]["Number Of Sessions"]
                time_spent += method_data[part]["Time Spent"]
            }
        }

        ret.push(
            <div className="w-full print:pt-6" style={{ "pageBreakAfter": "always" }}>
                <div className="flex gap-4 mb-4">
                    <div className="w-5/12">
                        <ChartContainer
                            data={user_methods_pie}
                            maxHeight={160}
                            maxWidth={undefined}
                            title={getTranslation("Training Methods Distribution")}
                            Chart={PieChart}
                        />
                    </div>
                    <div className="bg-white px-3 py-3 pt-6 w-7/12 rounded shadow">
                        <h1 className="text-slate-700 font-bold text-lg mb-3"><Translate label="Trainee Performance Report" /></h1>
                        <p className="text-slate-700"><Translate label="User" />: <b>{user.username}</b></p>
                        <p className="text-slate-700"><Translate label="Date" />: <b>{weldingUserReport.filters.date_from} - {weldingUserReport.filters.date_to}</b></p>
                        <p className="text-slate-700"><Translate label="Method" />: <b>{weldingUserReport.filters?.methods?.join(", ")}</b></p>
                        <p className="text-slate-700"><Translate label="Number of sessions" />: <b>{num_sessions}</b></p>
                        <p className="text-slate-700"><Translate label="Session Duration" />: <b>{(time_spent / 60).toFixed(2)} min</b></p>
                    </div>
                </div>
                {weldingUserReport.filters?.methods.map(method => ["Butt Joint", "Lap Joint", "Tee Joint", "Corner Joint", "Edge Joint"].map(part => average_distance[part][method].length < 3 ? null : (
                    <div className="mb-4 break-after-all print:pt-2" style={{ "pageBreakAfter": "always" }}>
                        <h1 className="font-bold text-center text-lg text-slate-800 mb-3 print:py-2 print:bg-slate-800 print:text-white rounded-md"><Translate label={method} /> (<Translate label={part} />)</h1>
                        <span>
                            <div className="flex justify-between items-center w-full gap-4 mt-4">
                                <ChartContainer
                                    Chart={CandleChart}
                                    data={[
                                        {
                                            id: getTranslation("Work Angle"),
                                            color: "#f87171",
                                            data: average_angles[part]["work"][method]
                                            // data: average_angles[part]["work"][method].map(d => ({ ...d, z: d.y, y: 30 }))
                                        }
                                    ]}
                                    maxHeight={200}
                                    maxWidth={450}
                                    title={getTranslation("Average Work Angle")}
                                    properties={{
                                        sliceTooltip: (pt) => {
                                            try {
                                                return (
                                                    <div className='flex p-2 gap-1 bg-white shadow-md items-center justify-center'>
                                                        <span className='block w-4 h-4 bg-red-400'></span>
                                                        <p> <strong>Work Angle: </strong> {parseFloat(pt.slice.points[0].data.z).toFixed(2)}</p>
                                                    </div>
                                                )
                                            } catch(e) {
                                                return <div></div>
                                            }
                                        },
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "60", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(248, 113, 113, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                        // layers: [
                                        //     'grid', 'markers', 'areas', 'slices', 'axes', 'legends',
                                        //     ({ yScale, innerHeight, innerWidth }) => {
                                        //         const yStart = yScale(20);
                                        //         const yEnd = yScale(40);

                                        //         return (
                                        //             <rect
                                        //                 pointerEvents="none"
                                        //                 x={0} y={yEnd}
                                        //                 width={innerWidth}
                                        //                 height={yStart - yEnd}
                                        //                 fill="rgba(248, 113, 113, 0.3)"
                                        //             />
                                        //         );
                                        //     },
                                        //     ({ series, xScale, yScale }) => {
                                        //         return (
                                        //             <g>
                                        //                 {series.map((s) =>
                                        //                     s.data.map((point) => {
                                        //                         return (
                                        //                             <rect
                                        //                                 className="fill-red-400"
                                        //                                 key={point.id}
                                        //                                 x={xScale(point.data.x) - 2}
                                        //                                 y={yScale(point.data.y) - 2 - (point.data.z*2)}
                                        //                                 width={4}
                                        //                                 height={(point.data.z * 4) + 4}
                                        //                             />
                                        //                         )
                                        //                     })
                                        //                 )}
                                        //             </g>
                                        //         );
                                        //     }
                                        // ],
                                        // enableSlices: "x",
                                    }}
                                />
                                <ChartContainer
                                    Chart={CandleChart}
                                    data={[
                                        {
                                            id: getTranslation("Travel Angle"),
                                            color: "#10b981",
                                            data: average_angles[part]["travel"][method]
                                        }
                                    ]}

                                    maxHeight={200}
                                    maxWidth={450}
                                    title={getTranslation("Average Travel Angle")}
                                    properties={{
                                        axisLeft: { legend: 'Angle (Degrees)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(16, 185, 129, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                            <div className="flex justify-between items-center w-full gap-4 mt-4">
                                {method === "STICK" ? null : <ChartContainer
                                    Chart={CandleChart}
                                    data={[{
                                        id: getTranslation("Distance"),
                                        color: "#3b82f6",
                                        data: average_distance[part][method]
                                    }]}
                                    maxHeight={200}
                                    maxWidth={450}
                                    title={getTranslation("Average Distance")}
                                    properties={{
                                        axisLeft: { legend: 'Distance (mm)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(15);
                                                const yEnd = yScale(30);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />}
                                <ChartContainer
                                    Chart={CandleChart}
                                    data={[{
                                        id: getTranslation("Speed"),
                                        color: "#3b82f6",
                                        data: average_speed[part][method]
                                    }]}
                                    maxHeight={200}
                                    maxWidth={method === "STICK" ? 860 : 450}
                                    title={getTranslation("Average Speed")}
                                    properties={{
                                        axisLeft: { legend: 'Speed (mm / s)', legendOffset: -40, legendPosition: 'middle' },
                                        yScale: { type: 'linear', min: '0', max: "auto", stacked: false, reverse: false, },
                                        layers: [
                                            'grid', 'markers', 'areas', 'lines', 'slices', 'points', 'axes', 'legends', 'tooltip',
                                            ({ yScale, innerHeight, innerWidth }) => {
                                                const yStart = yScale(8);
                                                const yEnd = yScale(15);

                                                return (
                                                    <rect
                                                        pointerEvents="none"
                                                        x={0} y={yEnd}
                                                        width={innerWidth}
                                                        height={yStart - yEnd}
                                                        fill="rgba(59, 130, 246, 0.3)"
                                                    />
                                                );
                                            },
                                        ],
                                        enableSlices: "x",
                                    }}
                                />
                            </div>
                        </span>
                    </div>
                )))}
            </div>
        )
    }

    if (ret.length === 0) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4">Not enough data to display this report. Try to select a wider range of dates.</h1>
    return (
        <div id="user-report-container" className="w-[800px] mx-auto">
            {ret}
        </div>
    )
}

export default WeldingUserReport


// TODO: Containers Manager
// Example use case: Consoles Admin Panel API needs to scale up to serve more users
// What happens?
// Database is running an instance-based system and sync-up system where one user can only write to one database instance in a session and will not be able to switch until sync happens
// Sync will run periodically every X minutes.
// In the case of a query on a table that could be edited by 2 users from different instances, most recent edit takes place after the sync.
// API is served as a "container" that has all instructions it needs in order to instantiate itself. 