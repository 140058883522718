import { insertInHash, removeFromHash } from "../../Common/Functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { setUserOrganization } from "../User/Slice"
import { OrganizationsState as SliceInterface } from "./Interface"

export const OrganizationCreator = {
    "createOrganization": createHandler({
        name: "organizations/createOrganization",
        method: "POST",
        route: "api/organizations/organizations",
        key: "organization",
        skipAuth: false,
        dispatchAfterSuccess: setUserOrganization,
        getValueFromResponseSuccess: (response) => response.organization.id,
        pending: (state:SliceInterface, action) => {
            state.organization.createStatus = "pending"
            state.organization.createMessage = "Creating Organization... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.organization.createStatus = "fulfilled"
            state.organization.createMessage = "Organization has been created"
            state.organization.organizations = insertInHash(state.organization.organizations, action.payload.organization, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.organization.createStatus = "rejected"
            state.organization.createMessage = action.payload.msg
            return state
        }
    }),
    "readOrganization": createHandler({
        name: "organizations/readOrganization",
        method: "GET",
        route: "api/organizations/organizations",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.organization.readStatus = "pending"
            state.organization.readMessage = "Loading Organization(s)... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.organization.readStatus = "fulfilled"
            state.organization.readMessage = "Organization has been created"

            if (action.payload?.organization !== undefined) state.organization.organizations = insertInHash(state.organization.organizations, action.payload.organization, "id")
            else if (action.payload.organizations.includes(null)) state.organization.organizations = action.payload.organizations
            else for (let org of action.payload.organizations) state.organization.organizations = insertInHash(state.organization.organizations, org, "id")

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.organization.readStatus = "rejected"
            state.organization.readMessage = action.payload.msg
            return state
        }
    }),
    "updateOrganization": createHandler({
        name: "organizations/updateOrganization",
        method: "PUT",
        route: "api/organizations/organizations",
        key: "organization",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.organization.updateStatus = "pending"
            state.organization.updateMessage = "Updating organization... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.organization.updateStatus = "fulfilled"
            state.organization.updateMessage = "organization updated succesfully."
            state.organization.organizations = insertInHash(state.organization.organizations, action.payload.organization, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.organization.updateStatus = "rejected"
            state.organization.updateMessage = action.payload.msg
            return state
        }
    }),
    "deleteOrganization": createHandler({
        name: "organizations/deleteOrganization",
        method: "DELETE",
        route: "api/organizations/organizations",
        key: "organization",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.organization.deleteStatus = "pending"
            state.organization.deleteMessage = "Deleting Organization... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.organization.deleteStatus = "fulfilled"
            state.organization.deleteMessage = "Organization deleted."
            state.organization.organizations = removeFromHash(state.organization.organizations, null, "organizationn_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.organization.deleteStatus = "rejected"
            state.organization.deleteMessage = action.payload.msg
            return state
        }
    }),

    "createLicense": createHandler({
        name: "organizations/createLicense",
        method: "POST",
        route: "api/organizations/license",
        key: "license",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.license.createStatus = "pending"
            state.license.createMessage = "Creating License... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.license.createStatus = "fulfilled"
            state.license.createMessage = "License has been created"
            state.license.licenses = insertInHash(state.license.licenses, action.payload.license, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.license.createStatus = "rejected"
            state.license.createMessage = action.payload.msg
            return state
        }
    }),
    "readLicense": createHandler({
        name: "organizations/readLicense",
        method: "GET",
        route: "api/organizations/license",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.license.readStatus = "pending"
            state.license.readMessage = "Loading License(s)... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.license.readStatus = "fulfilled"
            state.license.readMessage = "License has been created"

            if (action.payload?.license !== undefined) state.license.licenses = insertInHash(state.license.licenses, action.payload.license, "id")
            else if (action.payload.licenses.includes(null)) state.license.licenses = action.payload.licenses
            else for (let org of action.payload.licenses) state.license.licenses = insertInHash(state.license.licenses, org, "id")

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.license.readStatus = "rejected"
            state.license.readMessage = action.payload.msg
            return state
        }
    }),
    "updateLicense": createHandler({
        name: "organizations/updateLicense",
        method: "PUT",
        route: "api/organizations/license",
        key: "license",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.license.updateStatus = "pending"
            state.license.updateMessage = "Updating license... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.license.updateStatus = "fulfilled"
            state.license.updateMessage = "license updated succesfully."
            state.license.licenses = insertInHash(state.license.licenses, action.payload.license, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.license.updateStatus = "rejected"
            state.license.updateMessage = action.payload.msg
            return state
        }
    }),
    "deleteLicense": createHandler({
        name: "organizations/deleteLicense",
        method: "DELETE",
        route: "api/organizations/license",
        key: "license",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.license.deleteStatus = "pending"
            state.license.deleteMessage = "Deleting License... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.license.deleteStatus = "fulfilled"
            state.license.deleteMessage = "License deleted."
            state.license.licenses = removeFromHash(state.license.licenses, null, "licensen_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.license.deleteStatus = "rejected"
            state.license.deleteMessage = action.payload.msg
            return state
        }
    }),

    "createDevice": createHandler({
        name: "organizations/createDevice",
        method: "POST",
        route: "api/organizations/devices",
        key: "device",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.device.createStatus = "pending"
            state.device.createMessage = "Creating Device... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.device.createStatus = "fulfilled"
            state.device.createMessage = "Device has been created"
            state.device.devices = insertInHash(state.device.devices, action.payload.device, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.device.createStatus = "rejected"
            state.device.createMessage = action.payload.msg
            return state
        }
    }),
    "readDevice": createHandler({
        name: "organizations/readDevice",
        method: "GET",
        route: "api/organizations/devices",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.device.readStatus = "pending"
            state.device.readMessage = "Loading Device(s)... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.device.readStatus = "fulfilled"
            state.device.readMessage = "Device has been created"
            if (action.payload?.device !== undefined) state.device.devices = insertInHash(state.device.devices, action.payload.device, "id")
            else if (action.payload?.devices.includes(null)) state.device.devices = action.payload.devices
            else for (let org of action.payload.devices) {
                state.device.devices = insertInHash(state.device.devices, org, "id")
            }

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.device.readStatus = "rejected"
            state.device.readMessage = action.payload.msg
            return state
        }
    }),
    "updateDevice": createHandler({
        name: "organizations/updateDevice",
        method: "PUT",
        route: "api/organizations/devices",
        key: "device",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.device.updateStatus = "pending"
            state.device.updateMessage = "Updating device... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.device.updateStatus = "fulfilled"
            state.device.updateMessage = "device updated succesfully."
            state.device.devices = insertInHash(state.device.devices, action.payload.device, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.device.updateStatus = "rejected"
            state.device.updateMessage = action.payload.msg
            return state
        }
    }),
    "deleteDevice": createHandler({
        name: "organizations/deleteDevice",
        method: "DELETE",
        route: "api/organizations/devices",
        key: "device",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.device.deleteStatus = "pending"
            state.device.deleteMessage = "Deleting Device... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.device.deleteStatus = "fulfilled"
            state.device.deleteMessage = "Device deleted."
            state.device.devices = removeFromHash(state.device.devices, null, action.payload.device_id)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.device.deleteStatus = "rejected"
            state.device.deleteMessage = action.payload.msg
            return state
        }
    }),
    "createSettingTypes": createHandler({
        name: "organizations/createSettingTypes",
        method: "POST",
        route: "api/organizations/setting_types",
        key: "setting",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.settings.createStatus = "pending"
            state.settings.createMessage = "Creating setting type... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.settings.createStatus = "fulfilled"
            state.settings.createMessage = "Setting type has been created"
            state.settings.settings = insertInHash(state.settings.settings, action.payload.setting_type, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.settings.createStatus = "rejected"
            state.settings.createMessage = action.payload.msg
            return state
        }
    }),
    "readSettingTypes": createHandler({
        name: "organizations/readSettingTypes",
        method: "GET",
        route: "api/organizations/setting_types",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.settings.readStatus = "pending"
            state.settings.readMessage = "Loading settings types... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.settings.readStatus = "fulfilled"
            state.settings.readMessage = "Settings types have been loaded."
            if (action.payload.setting_types.includes(null)) state.settings.settings = action.payload.setting_types
            else {
                state.settings.settings = []
                for (const s of action.payload.setting_types) state.settings.settings = insertInHash(state.settings.settings, s, "id")
            }
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.settings.readStatus = "rejected"
            state.settings.readMessage = action.payload.msg
            return state
        }
    }),
    "updateSettingTypes": createHandler({
        name: "organizations/updateSettingTypes",
        method: "PUT",
        route: "api/organizations/setting_types",
        key: "setting",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.settings.updateStatus = "pending"
            state.settings.updateMessage = "Updating setting type... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.settings.updateStatus = "fulfilled"
            state.settings.updateMessage = "Settings type has been updated."
            state.settings.settings = insertInHash(state.settings.settings, action.payload.setting_type, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.settings.updateStatus = "rejected"
            state.settings.updateMessage = action.payload.msg
            return state
        }
    }),
    "createSetting": createHandler({
        name: "organizations/createSetting",
        method: "POST",
        route: "api/organizations/organization_settings",
        key: "setting",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.settings.createSettingStatus = "pending"
            state.settings.createSettingMessage = "Creating setting type... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.settings.createSettingStatus = "fulfilled"
            state.settings.createSettingMessage = "Setting type has been created"
            state.settings.settings = insertInHash(state.settings.settings, action.payload.setting_type, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.settings.createSettingStatus = "rejected"
            state.settings.createSettingMessage = action.payload.msg
            return state
        }
    }),
    "updateSetting": createHandler({
        name: "organizations/updateSetting",
        method: "PUT",
        route: "api/organizations/organization_settings",
        key: "setting",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.settings.updateSettingStatus = "pending"
            state.settings.updateSettingMessage = "Creating setting type... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.settings.updateSettingStatus = "fulfilled"
            state.settings.updateSettingMessage = "Setting type has been created"
            state.settings.settings = insertInHash(state.settings.settings, action.payload.setting_type, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.settings.updateSettingStatus = "rejected"
            state.settings.updateSettingMessage = action.payload.msg
            return state
        }
    }),
    "grantUserAccess" : createHandler({
        name: "organizations/createAccess",
        method: "POST",
        route: "api/organizations/applications_access",
        key: "access",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.access.createStatus = "pending"
            state.access.createMessage = "Granting access... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.access.createStatus = "fulfilled"
            state.access.createMessage = "User has been granted access to application."
            state.access.ApplicationAccess = [...state.access.ApplicationAccess, action.payload.application_access]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.access.createStatus = "rejected"
            state.access.createMessage = action.payload.msg
            return state
        }
    }),
    "getUserAccess" : createHandler({
        name: "organizations/readAccess",
        method: "GET",
        route: "api/organizations/applications_access",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.access.readStatus = "pending"
            state.access.readMessage = "Loading users' access... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.access.readStatus = "fulfilled"
            state.access.readMessage = "User application access' have been loaded"
            state.access.ApplicationAccess = action.payload.application_access
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.access.readStatus = "rejected"
            state.access.readMessage = action.payload.msg
            return state
        }
    }),
    "removeUserAccess" : createHandler({
        name: "organizations/deleteAccess",
        method: "DELETE",
        route: "api/organizations/applications_access",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.access.deleteStatus = "pending"
            state.access.deleteMessage = "Removing user access from application... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.access.deleteStatus = "fulfilled"
            state.access.deleteMessage = "User access has been removed."
            state.access.ApplicationAccess = action.payload.application_access
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.access.deleteStatus = "rejected"
            state.access.deleteMessage = action.payload.msg
            return state
        }
    }),
    "createDemoOrganization": createHandler({
        name: "organizations/createDemoOrganization",
        method: "POST",
        route: "api/demo_organizations/create_organization",
        key: "organization",
        skipAuth: false,
        getValueFromResponseSuccess: (response) => response.organization.id,
        pending: (state:SliceInterface, action) => {
            state.demoOrganizations.createStatus = "pending"
            state.demoOrganizations.createMessage = "Creating Organization... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.demoOrganizations.createStatus = "fulfilled"
            state.demoOrganizations.createMessage = "Organization has been created"
            state.demoOrganizations.organizations = insertInHash(state.demoOrganizations.organizations, action.payload.organization, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.demoOrganizations.createStatus = "rejected"
            state.demoOrganizations.createMessage = action.payload.msg
            return state
        }
    }),
    "readDemoOrganization": createHandler({
        name: "organizations/readDemoOrganization",
        method: "GET",
        route: "api/demo_organizations/get_demo_organizations",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.demoOrganizations.readStatus = "pending"
            state.demoOrganizations.readMessage = "Loading Organization(s)... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.demoOrganizations.readStatus = "fulfilled"
            state.demoOrganizations.readMessage = "Organizations have been loaded"
            state.demoOrganizations.organizations = []
            for (let org of action.payload.organizations) state.demoOrganizations.organizations = insertInHash(state.demoOrganizations.organizations, org, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.demoOrganizations.readStatus = "rejected"
            state.demoOrganizations.readMessage = action.payload.msg
            return state
        }
    }),
    "updateDemoOrganization": createHandler({
        name: "organizations/updateDemoOrganization",
        method: "PUT",
        route: "api/demo_organizations/update_organization",
        key: "organization",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.demoOrganizations.updateStatus = "pending"
            state.demoOrganizations.updateMessage = "Updating organization... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.demoOrganizations.updateStatus = "fulfilled"
            state.demoOrganizations.updateMessage = "organization updated succesfully."
            state.demoOrganizations.organizations = insertInHash(state.demoOrganizations.organizations, action.payload.organization, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.demoOrganizations.updateStatus = "rejected"
            state.demoOrganizations.updateMessage = action.payload.msg
            return state
        }
    }),
}

export const [OrganizationActions, OrganizationReducers] = buildFromCreator(OrganizationCreator)