import { IonButton } from "@ionic/react"
import { Fragment } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { print_pdf } from "../../../../Common/print"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { RootState } from "../../../../Redux/Store"
import { UserState } from "../../../../Redux/User/Interface"
import BarChart from "../../Charts/BarChart"
import ChartContainer from "../../Charts/ChartContainer"
import DataContainer from "../../Charts/DataContainer"
import PieChart from "../../Charts/PieChart"
import Translate from "../../../../components/Translate"
import Loading from "../../../../components/Loading"
import { getTranslation } from "../../../../Common/Functions"


const UserReport = props => {

    const app_id = parseInt(useParams<{ id : string}>().id)
    const applicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const current_app = applicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === app_id)

    const userReport = useSelector((state: RootState) => state.analytics.userReport)
    const userReducer:UserState = useSelector((state:RootState) => state.user)

    if (userReport.status === "pending") return <Loading isOpen={true} message="Report is being generated. Please wait." />
    if (userReport.status === "idle") return <Fragment></Fragment>

    const ret = []

    for (const key in userReport.data) {
        const data = userReport.data[key]
        if (data.table?.Grade.length < 3) continue
        let pieData = [
            { id: "A", label: getTranslation("Grade") +" A", color: "#4CAF50", value: data.table?.Grade?.filter((grade) => grade === "A").length },
            { id: "B", label: getTranslation("Grade") +" B", color: "#8BC34A", value: data.table?.Grade?.filter((grade) => grade === "B").length },
            { id: "C", label: getTranslation("Grade") +" C", color: "#fbbf24", value: data.table?.Grade?.filter((grade) => grade === "C").length },
            { id: "D", label: getTranslation("Grade") +" D", color: "#FF9800", value: data.table?.Grade?.filter((grade) => grade === "D").length },
            { id: "F", label: getTranslation("Grade") +" F", color: "#F44336", value: data.table?.Grade?.filter((grade) => grade === "F").length }
        ]

        const coverages = { parts: [], high: [], good: [], low: [] }
        data.table?.Part?.forEach((x, index) => {
            coverages.parts.push(`${index}-${data.table.Part[index]}`);
            coverages.high.push((data.table["Primer High Coverage"][index] + data.table["Base High Coverage"][index] + data.table["Clear High Coverage"][index]) / 3);
            coverages.good.push((data.table["Primer Good Coverage"][index] + data.table["Base Good Coverage"][index] + data.table["Clear Good Coverage"][index]) / 3);
            coverages.low.push((data.table["Primer Low Coverage"][index] + data.table["Base Low Coverage"][index] + data.table["Clear Low Coverage"][index]) / 3);
        })

        const line = []
        coverages.high.slice(-10).forEach((x, idx) => {
            line.push({
                "part" : coverages.parts.slice(-10)[idx],
                [getTranslation("Low Coverage")]: Math.round(coverages.low[idx]),
                [getTranslation("Low Coverage")+"Color"]: "#3b82f6",
                [getTranslation("Good Coverage")]: Math.round(coverages.good[idx]),
                [getTranslation("Good Coverage")+"Color"]: "#10b981",
                [getTranslation("High Coverage")]: Math.round(coverages.high[idx]),
                [getTranslation("High Coverage")+"Color"]: "#f87171",
            })
        })

        let latestSession = data.table?.Part.length - 1

        const counts = {};
        data.table?.Part?.forEach((x) => (counts[x] = (counts[x] || 0) + 1));
        let maxItem = "";
        Object.keys(counts).forEach((item) => {
            if (counts[item] > (counts[maxItem] || 0)) maxItem = item;
        });

        ret.push((
            <div id={"user-"+key+"-report"} className="flex flex-col justify-center items-center w-full px-5 gap-6 mb-14 break-after-all pt-4" style={{
                "pageBreakAfter" : "always"
            }}>
                <h1 className="text-lg text-slate-800 font-bold text-center">{userReducer.users[key].username}'s report ({userReport.filters.date_from} to {userReport.filters.date_to})</h1>
                <div className="flex flex-col lg:flex-row gap-6 w-full items-center justify-center">
                    <DataContainer
                        maxHeight={160}
                        maxWidth={280}
                        title={<Translate label="General Information" />}
                        jsx={(
                            <span className="flex flex-col gap-2 p-4 -mt-10">
                                <label className="flex justify-between"><b><Translate label="User" /> :</b> <p>{userReducer.users[key]?.username}</p></label>
                                <label className="flex justify-between"><b><Translate label="Times Played" />:</b> <p>{data?.table?.Grade?.length}</p></label>
                                <label className="flex justify-between"><b><Translate label="Most Painted Part" />:</b> <p>{maxItem}</p></label>
                                <label className="flex justify-between"><b><Translate label="Total Training Time" />:</b> <p>{Math.round(data?.table["Total Time Played"].reduce((sum, a) => sum + a, 0) / 60)} min.</p></label>
                                <IonButton onClick={e => print_pdf(document.getElementById("user-"+key+"-report").cloneNode(true), "Trainee Report", current_app)}><Translate label="EXPORT" /></IonButton>
                            </span>
                        )}
                    />
                    <ChartContainer
                        maxHeight={175}
                        maxWidth={300}
                        data={pieData}
                        Chart={PieChart}
                        title={<Translate label="Grades Brakdown" />}
                    />
                    <DataContainer
                        maxHeight={160}
                        maxWidth={280}
                        title="Latest Session"
                        jsx={(
                            <span className="flex flex-col gap-2 p-4 -mt-10">
                                <label className="flex justify-between"><b><Translate label="Part" />:</b> <p>{data?.table?.Part[latestSession]}</p></label>
                                <label className="flex justify-between"><b><Translate label="Grade" />:</b> <p>{data?.table["Grade"][latestSession]}</p></label>
                                <label className="flex justify-between"><b><Translate label="Coverage" />:</b> <p>{((data?.table["Primer Good Coverage"][latestSession] + data?.table["Base Good Coverage"][latestSession] + data?.table["Clear Good Coverage"][latestSession]) / 3).toFixed(1)}%</p></label>
                                <label className="flex justify-between"><b><Translate label="Paint Used" /></b> <p>{data?.table["Total Paint Used"][latestSession]?.toFixed(1)} ml.</p></label>
                                <label className="flex justify-between"><b><Translate label="Time Taken" /></b> <p>{data?.table["Total Time Spent"][latestSession]?.toFixed(1)} min.</p></label>
                            </span>
                        )}
                    />
                </div>
                <div className="flex flex-col lg:flex-row gap-6 w-full justify-center items-center">
                    <ChartContainer
                        maxHeight={220}
                        maxWidth={900}
                        data={line}
                        Chart={BarChart}
                        title={<Translate label="Coverage Graph (Last 10 sessions)" />}
                        properties={{
                            axisLeft: {
                                legend: getTranslation('Coverage') + ' (%)',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            },
                            keys: [
                                getTranslation("Low Coverage"),
                                getTranslation("Good Coverage"),
                                getTranslation("High Coverage"),
                            ],
                            indexBy: "part",
                            colors: [ "#3b82f6", "#10b981", "#f87171"  ]
                        }}
                    />
                </div>
            </div>
        ))
    }
    if (ret.length === 0) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4"><Translate label="Not enough data to display this report. Try to select a wider range of dates." /></h1>
    return (
        <div id="user-report-container">
            {ret}
        </div>
    )
}

export default UserReport