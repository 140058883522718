import { Fragment } from "react"
import { NavLink } from "react-router-dom"
import ReactCodeInput from 'react-verification-code-input'
import Info from "../../../components/Info/Info"

const StepOne = ({
    user,
    setUser,
    handleSubmit,
    submitted,
    setSubmitted,
    UserReducer
}) => (
    <Fragment>
        <div className="text-center lg:mt-12">
            <h2 className="text-4xl font-bold text-center text-gray-200 lg:text-gray-700"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup></h2>

            <p className="mt-3 text-gray-200 lg:text-gray-500 ">Enter your email address to recieve a code to reset your password</p>
        </div>

        <div className="mt-8">
            <form onSubmit={e => handleSubmit(e, 1)}>
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Your Email Address</label>
                    <input
                        value={user.email}
                        onChange={e => {
                            setSubmitted(false)
                            setUser({ ...user, email: e.target.value })
                        }}
                        type="email"
                        name="email"
                        placeholder="your@email.com"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mt-6">
                    {submitted === false || UserReducer.resetPassword.emailStatus === "idle" ? <button
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cythero-primary rounded-md hover:bg-cythero-light focus:outline-none focus:bg-cythero-light focus:ring focus:ring-cythero-light focus:ring-opacity-50">
                        Send Code
                    </button> : <Info
                        type={UserReducer.resetPassword.emailStatus}
                        message={UserReducer.resetPassword.emailMessage}
                    />}
                </div>

            </form>

        </div>
    </Fragment>
)

const StepTwo = ({
    user,
    setUser,
    handleSubmit,
    submitted,
    setSubmitted,
    UserReducer
}) => (
    <Fragment>
        <div className="text-center lg:mt-12">
            <h2 className="text-4xl font-bold text-center text-gray-200 lg:text-gray-700"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup></h2>

            <p className="mt-3 text-gray-200 lg:text-gray-500 ">Enter the code you recieved.</p>
        </div>

        <div className="mt-8">
            <form onSubmit={e => handleSubmit(e, 2)}>
                <div className="flex flex-col items-center">
                    <ReactCodeInput
                        onChange={e => {
                            setSubmitted(false)
                            setUser({ ...user, reset_code: e })
                        }}
                        placeholder="123456"
                        values={user.reset_code.split("")}
                        className="border-cythero-primary"
                    />
                </div>

                <div className="mt-6">
                    {submitted === false || UserReducer.resetPassword.verifyStatus === "idle" ? <button
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cythero-primary rounded-md hover:bg-cythero-light focus:outline-none focus:bg-cythero-light focus:ring focus:ring-cythero-light focus:ring-opacity-50">
                        Verify Code
                    </button> : <Info
                        type={UserReducer.resetPassword.verifyStatus}
                        message={UserReducer.resetPassword.verifyMessage}
                    />}
                </div>

            </form>

        </div>
    </Fragment>
)

const StepThree = ({
    user,
    setUser,
    handleSubmit,
    submitted,
    setSubmitted,
    UserReducer
}) => (
    <Fragment>
        <div className="text-center lg:mt-12">
            <h2 className="text-4xl font-bold text-center text-gray-200 lg:text-gray-700"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup></h2>

            <p className="mt-3 text-gray-200 lg:text-gray-500 ">Enter New Password.</p>
        </div>

        <div className="mt-8">
            <form onSubmit={e => handleSubmit(e, 3)}>
                <div className="mt-6">
                    <label htmlFor="new_password" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">New Password</label>
                    <input
                        value={user.new_password}
                        onChange={e => {
                            setSubmitted(false)
                            setUser({ ...user, new_password: e.target.value })
                        }}
                        type="password"
                        name="new_password"
                        placeholder="Your new password"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                <div className="mt-6">
                    <label htmlFor="confirm_new_password" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Confirm Password</label>
                    <input
                        value={user.confirm_new_password}
                        onChange={e => {
                            setSubmitted(false)
                            setUser({ ...user, confirm_new_password: e.target.value })
                        }}
                        type="password"
                        name="confirm_new_password"
                        placeholder="Your new password"
                        className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>

                {user.reset_code.length === 0 || user.keep_showing === true? <div className="mt-8">
                    <label htmlFor="confirm_new_password" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Please re-type the reset code you recieved previously</label>
                    <ReactCodeInput
                        onChange={e => {
                            setSubmitted(false)
                            setUser({ ...user, reset_code: e, keep_showing: true })
                        }}
                        placeholder="123456"
                        values={user.reset_code.split("")}
                        className="border-cythero-primary"
                    />
                </div> : null}

                <div className="mt-6">
                    {submitted === false || UserReducer.resetPassword.resetStatus === "idle" ? <button
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cythero-primary rounded-md hover:bg-cythero-light focus:outline-none focus:bg-cythero-light focus:ring focus:ring-cythero-light focus:ring-opacity-50">
                        Change Password
                    </button> : <Info
                        type={UserReducer.resetPassword.resetStatus}
                        message={UserReducer.resetPassword.resetMessage}
                    />}
                </div>

            </form>

        </div>
    </Fragment>
)

const ForgotPasswordJSX = ({
    user,
    setUser,
    handleSubmit,
    submitted,
    setSubmitted,
    UserReducer
}) => (
    <Fragment>
        {UserReducer.resetPassword.resetStatus === "idle" && UserReducer.resetPassword.emailStatus !== "fulfilled" ? <StepOne
            user={user}
            setUser={setUser}
            handleSubmit={handleSubmit}
            submitted={submitted}
            setSubmitted={setSubmitted}
            UserReducer={UserReducer}
        /> : UserReducer.resetPassword.resetStatus === "idle" && UserReducer.resetPassword.verifyStatus !== "fulfilled" ? <StepTwo
            user={user}
            setUser={setUser}
            handleSubmit={handleSubmit}
            submitted={submitted}
            setSubmitted={setSubmitted}
            UserReducer={UserReducer}
        /> : UserReducer.resetPassword.resetStatus !== "fulfilled" ? <StepThree
            user={user}
            setUser={setUser}
            handleSubmit={handleSubmit}
            submitted={submitted}
            setSubmitted={setSubmitted}
            UserReducer={UserReducer}
        /> : <Fragment>
            <div className="text-center lg:mt-12">
                <h2 className="text-4xl font-bold text-center text-gray-200 lg:text-gray-700"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup></h2>
                <p className="mt-3 text-cythero-primary text-lg ">Success.</p>
            </div>

            <div className="mt-8">
                <p className="mt-6 text-sm text-center text-gray-200 lg:text-gray-400">Go back to <NavLink to="/auth/login" className="text-cythero-light lg:text-cythero-primary focus:outline-none focus:underline hover:underline font-bold">Login</NavLink>.</p>
            </div>
        </Fragment>}
    </Fragment>
)

export default ForgotPasswordJSX