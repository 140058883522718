import { useDispatch, useSelector } from "react-redux";
import { DemoOrganization } from "../../Common/Interfaces/Entities";
import { ApplicationsState } from "../../Redux/Applications/Interface";
import { AppDispatch, RootState } from "../../Redux/Store";
import { STATIC_URL } from "../../Common/constants";
import { useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { AiFillEye } from "react-icons/ai";
import { OrganizationActions } from "../../Redux/Organizations/Actions";

const DemoOrganizationCard: React.FC<DemoOrganization> = ({ id, application_id, name, devices, num_devices }) => {
    const dispatch = useDispatch<AppDispatch>()

    const [updated, setUpdated] = useState({
        name: name,
        num_devices: num_devices
    })

    const [mode, setMode] = useState("view")
    const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const app = ApplicationsReducer.application.applications[application_id]

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("id", id.toString())
        data.append("name", updated.name)
        data.append("num_devices", updated.num_devices.toString())
        dispatch(OrganizationActions.updateDemoOrganization({ organization: data }))
    }

    return (
        mode === "view" ? <div className="bg-white shadow rounded-md pb-2 overflow-hidden">
            <h1 className="p-2 bg-cythero-primary font-bold text-xl text-white mb-2 flex items-center justify-between">
                {name}
                <MdModeEditOutline className="cursor-pointer" onClick={e => setMode("edit")} />
            </h1>
            <h3 className="px-2 font-bold text-md text-slate-800 mb-1">Available Devices: {num_devices}</h3>
            <h3 className="px-2 font-bold text-md text-slate-800 mb-4">Used Devices: {devices.length}</h3>
            <h3 className="px-2 font-bold text-md text-slate-800 mb-1">Application</h3>
            <span className="ml-4 flex items-center gap-2 ">
                <img src={STATIC_URL + app.logo_path} className="w-8" />
                <h3 className="font-bold text-lg text-slate-800">{app.name}</h3>
            </span>
        </div> : <div className="bg-white shadow rounded-md pb-2 overflow-hidden">
            <h1 className="p-2 bg-cythero-primary font-bold text-xl text-white mb-2 flex items-center justify-between">
                {name}
                <AiFillEye className="cursor-pointer" onClick={e => setMode("view")} />
            </h1>
            <div className="px-2">
                <label className="font-bold text-salate-800 text-sm">Organization Name</label>
                <input className="mb-2 border border-slate-400 w-full rounded-md px-2 py-1/2" type="text" value={updated.name} onChange={e => setUpdated({ ...updated, name: e.target.value })} />

                <label className="font-bold text-salate-800 text-sm">Number of seats (devics)</label>
                <input className="mb-2 border border-slate-400 w-full rounded-md px-2 py-1/2" type="number" value={updated.num_devices} onChange={e => setUpdated({ ...updated, num_devices: parseInt(e.target.value) })} />

                <button onClick={e => handleSubmit(e)} className="block ml-auto bg-cythero-primary text-white font-bold px-2 py-1/2 rounded-md">Updated</button>
            </div>
        </div>
    )
}

export default DemoOrganizationCard