import { IonButton, IonItem, IonLabel, IonList } from "@ionic/react"
import Input from "../../../components/Form/Input"
import Select from "../../../components/Form/Select"
import { Fragment } from "react"
import Loading from "../../../components/Loading"
import Translate from "../../../components/Translate"
import { getTranslation } from "../../../Common/Functions"

const CreateUserJSX = ({
    type_id,
    organizations,
    userTypes,
    handleSubmit,
    setUser,
    user,
    userReducer,
    owned_applications,
    languages
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <Loading isOpen={userReducer.createUserStatus === "pending"} message={userReducer.createUserMessage} />
        {/* <span className="flex items-center w-full mx-auto text-slate-700">
            <NavLink className="hover:underline" to="/users">Users</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to="/users/create">Create User</NavLink>
        </span> */}
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl"><Translate label="Create a new user" /></h1>
            <span className="w-full block pb-10 px-4">
                {type_id === 4 ? <Select
                    value={user.organization_id}
                    multiple={false}
                    options={organizations}
                    displayField="name"
                    valueField="id"
                    name="organization_id"
                    placeholder="Select user organization"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value, applications_access: [] })}
                /> : null}
                <Select
                    value={user.type_id}
                    multiple={false}
                    options={userTypes.filter(t => t.id <= type_id)}
                    displayField="name"
                    valueField="id"
                    name="type_id"
                    placeholder={getTranslation("Select user type")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Select
                    value={user.applications_access}
                    multiple={true}
                    options={owned_applications}
                    displayField="name"
                    valueField="id"
                    name="applications_access"
                    placeholder={getTranslation("Select Application Access")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="firstName"
                    value={user.firstName}
                    type="text"
                    placeholder={getTranslation("Enter first name (Optional)")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="lastName"
                    value={user.lastName}
                    type="text"
                    placeholder={getTranslation("Enter last name (Optional)")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="email"
                    value={user.email}
                    type="email"
                    placeholder={getTranslation("Enter email")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="username"
                    value={user.username}
                    type="text"
                    placeholder={getTranslation("Enter username")}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                {
                    user.type_id === 1 || user.tpye_id === null ? null : <Fragment>
                        <Input
                            name="password"
                            value={user.password}
                            type="password"
                            placeholder={getTranslation("Enter password")}
                            handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                        />
                        <Input
                            name="confirm"
                            value={user.confirm}
                            type="password"
                            placeholder={getTranslation("Confirm password")}
                            handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                        />
                    </Fragment>
                }
                <Select
                    value={user.language_id}
                    multiple={false}
                    options={languages.filter(l => l !== null && l !== undefined)}
                    displayField="name"
                    valueField="id"
                    name="language_id"
                    placeholder={"Language"}
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <IonList>
                    <IonItem>
                        <IonLabel position="stacked"><Translate label="Generate a pin" /></IonLabel>
                        <div className="mt-3 flex">
                            <input className="px-2 w-20 mr-2 text-center" type="text" value={user.pin} placeholder={getTranslation("User Pin")} onChange={e => { }} />
                            <IonButton className="h-8" onClick={e => {
                                let pin = parseInt(Math.random() * 10000)
                                while (pin < 1000 || pin > 9999) pin = parseInt(Math.random() * 10000)
                                setUser({ ...user, pin: pin.toString() })
                            }}><Translate label="GENERATE NEW" /></IonButton>
                            <IonButton color={'danger'} className="h-8" onClick={e => {
                                setUser({ ...user, pin: "" })
                            }}><Translate label="CLEAR" /></IonButton>
                        </div>
                    </IonItem>
                </IonList>
                {/* <Input
                    name="pin"
                    value={user.pin}
                    type="text"
                    placeholder="Enter 4 digit pin"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                /> */}
                <IonButton className="mt-5" onClick={e => handleSubmit(e)}><Translate label="CREATE USER" /></IonButton>
            </span>
        </div>
    </div>
)

export default CreateUserJSX