import { STATIC_URL } from "../../../Common/constants";
import { BsChevronCompactRight, BsFillPencilFill } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import { Fragment } from "react"
import { IonButton, IonFab } from "@ionic/react"
import { BiAddToQueue } from "react-icons/bi"

const DlcsJsx = ({
    current,
    appliation,
    dlcs,
    dlc_types
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700 mb-6">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/" + appliation.id}>{appliation.name}</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/dlcs/" + appliation.id}>DLCs</NavLink>
        </span>
        <div>
            {dlcs.length === 0 ? <h1 className="text-slate-700 font-bold text-lg my-4">No DLCs available yet. Stay tuned.</h1> : <Fragment>
                {dlcs.map(dlc => <span className="block bg-white my-3 p-5 rounded-lg shadow" key={dlc.id}>
                    <span className="flex mb-4">
                        <img className="w-20 h-20 mr-6" src={STATIC_URL + dlc.cover_path} alt="dlc cover photo" />
                        <span className="w-full">
                            <span className="flex justify-between w-full">
                                <h1 className="text-lg text-cythero-primary ">{dlc.name}</h1>
                                {current.type_id === 4 ? <NavLink to={"/applications/dlc/edit/" + dlc.id}><BsFillPencilFill className="text-cythero-primary" /></NavLink> : null}
                            </span>
                            <p className="text-slate-600 text-sm mb-5">{dlc_types[dlc.dlc_type_id].name}</p>
                        </span>
                    </span>
                    <p className="text-slate-800">{dlc.description}</p>
                </span>)}
            </Fragment>}
        </div>

        {current.type_id === 4 ? <IonFab vertical="bottom" horizontal="end" >
            <IonButton>
                <NavLink to={"/applications/dlcs/create/" + appliation.id}><BiAddToQueue className="text-3xl" /></NavLink>
            </IonButton>
        </IonFab> : null}

    </div>
)

export default DlcsJsx