import { useState } from 'react'
import {
    IonButtons,
    IonButton,
    IonModal,
    IonContent,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonLabel,
    IonInput,
} from '@ionic/react'
import "@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css"
import { Calendar } from "@amir04lm26/react-modern-calendar-date-picker"

export interface DateInterface {
    day: number,
    month: number,
    year: number,
}

export const dateToDateStr = (date: DateInterface, time = null) => {
    return date.year + "-" + date.month + "-" + date.day +
        " " + (time === null ? (time.getHours() < 10 ? "0" + time.getHours() : time.getHours()) + ":" +
            (time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes()) + ":" +
            (time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds()) : time)
}

export const dateTostr = value => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    if (value !== null && value.day !== undefined) {
        return value.day + " " + months[value.month - 1] + " " + value.year
    } else if (value === null) return "Select Date"
    else if (value.from !== null) {
        let ret = value.from.day + " " + months[value.from.month - 1] + " " + value.from.year + " - "
        if (value.to !== null) ret += value.to.day + " " + months[value.to.month - 1] + " " + value.to.year
        return ret
    } else return "Select Date"
}

const DateSelect = ({
    name,
    placeholder,
    value,
    handleChange
}) => {
    const [modal, setModal] = useState(false)

    const setDate = type => {
        let from, to
        const today = new Date()
        if (type === "day") {
            from = today
            to = today
        } else if (type === "week") {
            var first = today.getDate() - today.getDay()
            var last = first + 6

            from = new Date(today.setDate(first))
            to = new Date(today.setDate(last))
        } else if (type === "month") {
            from = new Date(today.getFullYear(), today.getMonth(), 1)
            to = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        }
        const event = {
            target: {
                value: {
                    from: {
                        day: from.getDate() < 10 ? "0" + from.getDate() : from.getDate(),
                        month: (from.getMonth() + 1) < 10 ? "0" + (from.getMonth() + 1) : (from.getMonth() + 1),
                        year: from.getFullYear()
                    },
                    to: {
                        day: to.getDate() < 10 ? "0" + to.getDate() : to.getDate(),
                        month: (to.getMonth() + 1) < 10 ? "0" + (to.getMonth() + 1) : (to.getMonth() + 1),
                        year: to.getFullYear()
                    }
                }, name: name
            }
        }
        handleChange(event)
        setModal(false)
    }

    return (
        <IonList>
            <IonItem>
                <IonLabel onClick={e => setModal(true)} position="stacked">{placeholder}</IonLabel>
                <IonInput onIonChange={e => { }} value={dateTostr(value)} onClick={e => setModal(true)} />
                <IonModal id='date-modal-container' trigger="open-modal" isOpen={modal} onDidDismiss={e => setModal(false)}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle>Select Date</IonTitle>
                            <IonButtons slot="end">
                                <IonButton color="light" onClick={() => setModal(false)}>
                                    Continue
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                        {value === null || value?.from === undefined ? null : <IonToolbar className='flex'>
                            <span className='flex items-center justify-center'>
                                <IonButton color="light" onClick={() => setDate("day")}>Daily</IonButton>
                                <IonButton color="light" onClick={() => setDate("week")}>Weekly</IonButton>
                                <IonButton color="light" onClick={() => setDate("month")}>Monthly</IonButton>
                            </span>
                        </IonToolbar>}
                        <Calendar
                            onChange={e => handleChange({ target: { value: e, name: name } })}
                            value={{
                                from: value.from === null ? null : {
                                    day: parseInt(value.from.day),
                                    month: parseInt(value.from.month),
                                    year: parseInt(value.from.year),
                                }, to : value.to === null ? null : {
                                    day: parseInt(value.to.day),
                                    month: parseInt(value.to.month),
                                    year: parseInt(value.to.year),
                                }
                            }}
                            shouldHighlightWeekends
                        />
                    </IonContent>
                </IonModal>
            </IonItem>
        </IonList>
    );
}

export default DateSelect;