import { useEffect, useState } from "react"
import OrganizationSearch from "./OrganizationSearch"
import OrganizationList from "./OrganizationList"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { OrganizationActions } from "../../Redux/Organizations/Actions"

import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import CreateDemoOrganization from "./CreateDemoOrganization"
import Loading from "../../components/Loading"

const Container: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [filter, setFilter] = useState("")

    const OrganizationsReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    useEffect(() => {
        dispatch(OrganizationActions.readDemoOrganization({ arguments: {} }))
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
    }, [])

    return (
        <div className="bg-grey-100 w-full min-h-screen">
            <Loading isOpen={OrganizationsReducer.demoOrganizations.readStatus === "pending"} message={OrganizationsReducer.demoOrganizations.readMessage} />
            <Loading isOpen={OrganizationsReducer.demoOrganizations.updateStatus === "pending"} message={OrganizationsReducer.demoOrganizations.updateMessage} />
            <Loading isOpen={ApplicationsReducer.application.readStatus === "pending"} message={ApplicationsReducer.application.readMessage} />
            <OrganizationSearch
                filter={filter}
                setFilter={setFilter}
            />
            <OrganizationList filter={filter} />
            <CreateDemoOrganization />
        </div>
    )
}

export default Container