import { useSelector } from "react-redux"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { RootState } from "../../Redux/Store"
import DemoOrganizationCard from "./DemoOrganizationCard"

const OrganizationList: React.FC<{ filter: string }> = ({ filter }) => {

    const OrganizationsReducer: OrganizationsState = useSelector((state:RootState) => state.organizations)

    const render_orgs = OrganizationsReducer.demoOrganizations.organizations.filter(org => 
        org !== null &&
        org !== undefined &&
        (
            filter.length === 0 || 
            org.name.toLowerCase().startsWith(filter.toLowerCase())
        )
    )

    return (
        <div>
            {render_orgs.length === 0 ? <div className="flex flex-col gap-2 items-center justify-center w-[900px] max-w-[95%] mx-auto">
                <img src="/assets/images/no_data.png" className="w-64" />
                <h1 className="text-slate-800 font-bold text-lg">There is no organization found with the specified filter</h1>
            </div> : <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-11/12 mx-auto">
                {render_orgs.map(org => <DemoOrganizationCard {...org} />)}
            </div>}
        </div>
    )
}

export default OrganizationList