import { useParams } from "react-router"
import SectionSelector from "./SectionSelector"
import AddressesContainer from "./AddressInformation/AddressesContainer"
import EditUserForm from "./UserInformation/EditUserForm"



const ProfilePageContainer = ({}) => {

    const option:string = useParams<{option:string}>().option

    const components = {
        "info" : <EditUserForm />,
        "address" : <AddressesContainer />,
        "organization" : <EditUserForm />
    }

    return (
        <div className="grid grid-cols-12">
            <SectionSelector option={option} />
            {components[option]}
        </div>
    )
}

export default ProfilePageContainer