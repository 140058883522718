import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import { setUserState } from "../../../Redux/User/Slice"
import ForgotPasswordJSX from "./ForgotPasswordJSX"


const ForgotPassword: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>()
    const UserReducer: UserState = useSelector((state: RootState) => state.user)

    const [user, setUser] = useState({
        email: "",
        reset_code: "",
        new_password: "",
        confirm_new_password: "",
        keep_showing: false
    })
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (UserReducer.resetPassword.resetStatus === "fulfilled") {
            dispatch(setUserState({
                resetPassword: {
                    emailStatus: "idle",
                    emailMessage: "",

                    verifyStatus: "idle",
                    verifyMessage: "",

                    resetStatus: "idle",
                    resetMessage: "",
                    userEmail: ""
                }
            }))
        }
    }, [])

    const handleSubmit = (e, step) => {
        e.preventDefault()
        setSubmitted(true)
        if (step === 1) {
            const data = new FormData()
            data.append("email", user.email)
            dispatch(setUserState({
                resetPassword: {
                    ...UserReducer.resetPassword,
                    userEmail: user.email
                }
            }))
            dispatch(UserActions.forgotPassword({ user: data }))

        }
        else if (step === 2) {
            const data = new FormData()
            data.append("email", user.email.length === 0 ? UserReducer.resetPassword.userEmail : user.email)
            data.append("reset_code", user.reset_code.slice(0, 3) + " " + user.reset_code.slice(3))
            dispatch(UserActions.verifyCode({ user: data }))
        } else if (step === 3) {
            const data = new FormData()
            if (user.new_password.length < 5) return dispatch(setUserState({
                resetPassword: {
                    ...UserReducer.resetPassword,
                    resetStatus: "warning",
                    resetMessage: "Password too weak"
                }
            }))
            if (user.new_password !== user.confirm_new_password) return dispatch(setUserState({
                resetPassword: {
                    ...UserReducer.resetPassword,
                    resetStatus: "warning",
                    resetMessage: "Passwords do not match."
                }
            }))
            data.append("email", user.email.length === 0 ? UserReducer.resetPassword.userEmail : user.email)
            data.append("reset_code", user.reset_code.slice(0, 3) + " " + user.reset_code.slice(3))
            data.append("new_password", user.new_password)
            dispatch(UserActions.resetPassword({ user: data }))
        }
    }


    return <ForgotPasswordJSX
        user={user}
        setUser={setUser}
        handleSubmit={handleSubmit}
        submitted={submitted}
        setSubmitted={setSubmitted}
        UserReducer={UserReducer}
    />
}

export default ForgotPassword