import { BsChevronCompactRight } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import Select from "../../../components/Form/Select"
import Input from "../../../components/Form/Input"
import { IonButton } from "@ionic/react"

const EditReleaseJSX = ({
    appliation,
    release_types,
    release,
    setRelease,
    handleSubmit
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700 mb-6">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/releases/" + appliation.id}>Releases</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/release/edit/" + release.id}>Edit Release</NavLink>
        </span>
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">Edit Release Information</h1>
            <span className="w-full block pb-10 px-4">
                <Select
                    value={release.version_type_id}
                    multiple={false}
                    options={release_types.filter(r => r !== null)}
                    displayField="name"
                    valueField="id"
                    name="version_type_id"
                    placeholder="Select release type"
                    handleChange={e => setRelease({ ...release, [e.target.name]: e.target.value })}
                />
                <Input
                    name="version"
                    value={release.version}
                    type="text"
                    placeholder="Version (eg. 0.2.4)"
                    handleChange={e => setRelease({ ...release, [e.target.name]: e.target.value })}
                />
                <Input
                    name="notes"
                    value={release.notes}
                    type="textarea"
                    placeholder="Enter notes"
                    handleChange={e => setRelease({ ...release, [e.target.name]: e.target.value })}
                />
                <Input accept=".zip" name="application_file" handleChange={e => setRelease({ ...release, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select file (optional)" />
                <IonButton className="mt-5" onClick={handleSubmit}>Update release information</IonButton>
            </span>
        </div>
    </div>
)

export default EditReleaseJSX