
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationPackages, Applications, DLC } from "../../../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { UserState } from "../../../../Redux/User/Interface"
import BrowseApplicationPackagesJSX from "./BrowseApplicationPackagesJSX"
import Loading from "../../../../components/Loading"

const BrowseApplicationPackages:React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const appId: number = parseInt(useParams<{ id: string }>().id)
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const userReducer: UserState = useSelector((state:RootState) => state.user)

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: { id: appId } }))
        dispatch(ApplicationActions.readDlcTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readPackages({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
    }, [dispatch, appId])

    if (
        appsReducer.application.readStatus !== "fulfilled" ||
        appsReducer.packages.readStatus !== "fulfilled" ||
        appsReducer.dlc.readStatus !== "fulfilled" ||
        appsReducer.dlc_types.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading packages info... Pelase wait." />

    const application: Applications = appsReducer.application.applications[appId]
    const packages:Array<ApplicationPackages> = appsReducer.packages.packages.filter(p => p !== null && p !== undefined && p.application_id === appId)
    const dlcs:Array<DLC> = appsReducer.dlc.dlcs.filter(d => d !== null && d !== undefined)
    
    return <BrowseApplicationPackagesJSX 
        packages={packages}
        dlcs={dlcs}
        application={application}
        current={userReducer.current}
    />
}

export default BrowseApplicationPackages