import React, { Fragment, useEffect, useState } from "react"
import { useIonAlert } from "@ionic/react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { ApplicationVersions } from "../../../Common/Interfaces/Entities"
import EditReleaseJSX from "./EditReleaseJSX"
import Loading from "../../../components/Loading"



interface releaseStateInterface extends ApplicationVersions {
    application_file: any
}

const EditRelease: React.FC = ({

}) => {
    const release_id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const [infoAlert] = useIonAlert()

    const apps: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [submitted, setSubmitted] = useState(false)
    const [release, setRelease] = useState<releaseStateInterface>(null)

    useEffect(() => {
        dispatch(ApplicationActions.readVersions({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readReleaseTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
    }, [])

    useEffect(() => {
        if (submitted === false) return
        if (apps.version.updateStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: apps.version.updateMessage,
        })
        if (apps.version.updateStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: apps.version.updateMessage,
        })
    }, [apps.version.updateStatus])

    const handleSubmit = () => {
        if (release.version.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please follow the conventional version format (Major.Minor.Patch)'
        })
        if (release.notes.length < 120) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter more descriptive notes (120 - 500 letters)'
        })
        if (release.notes.length > 500) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Notes too long (please enter less than 500 letters)'
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("id", release.id.toString())
        data.append("application_id", release.application_id.toString())
        data.append("version_type_id", release.version_type_id.toString())
        data.append("version", release.version)
        data.append("notes", release.notes)
        if (release.application_file !== null) data.append("application_file", release.application_file)

        dispatch(ApplicationActions.updateVersion({ version: data }))
    }

    if (
        apps.version.readStatus !== "fulfilled" ||
        apps.version_types.readStatus !== "fulfilled" ||
        apps.application.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading releases data... Please wait." />
    if (release === null) setRelease({ ...apps.version.versions[release_id], application_file: null })
    return (
        <Fragment>
            {apps.version.updateStatus === "pending" ? <Loading isOpen={true} message="Updating release info... Please wait." /> : null}
            {release === null ? null : <EditReleaseJSX
                appliation={apps.application.applications[release.application_id]}
                release_types={apps.version_types.types}
                release={release}
                setRelease={setRelease}
                handleSubmit={handleSubmit}
            />}
        </Fragment>
    )
}

export default EditRelease