import React, { Fragment, useEffect, useState } from "react"
import { useIonAlert } from "@ionic/react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { DLC } from "../../../Common/Interfaces/Entities"
import EditReleaseJSX from "./EditDlcJSX"
import DlcImageType from "./DlcImageType"
import CreateImageType from "./CreateImageType"
import Loading from "../../../components/Loading"



interface dlcStateInterface extends DLC {
    dlc_cover: any
}

const EditDlc: React.FC = () => {
    const dlc_id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const [infoAlert] = useIonAlert()

    const apps: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [submitted, setSubmitted] = useState(false)
    const [dlc, setDlc] = useState<dlcStateInterface>(null)

    useEffect(() => {
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readImageType({ arguments: {} }))
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
    }, [dispatch])

    useEffect(() => {
        if (submitted === false) return
        if (apps.dlc.updateStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: apps.dlc.updateMessage,
        })
        if (apps.dlc.updateStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: apps.dlc.updateMessage,
        })
    }, [apps.dlc.updateStatus, apps.dlc.updateMessage, dispatch, submitted, infoAlert])

    const handleSubmit = () => {
        if (dlc.name.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a more descriptive name'
        })
        if (dlc.description.length < 60) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please specify more information in description (60 - 1000 letters)'
        })
        if (dlc.description.length > 1000) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Notes too long (please enter less than 1000 letters)'
        })
        if (dlc.price === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Price must be a numerical value.'
        })
        if(dlc.dlc_type_id === 5 && dlc.subscription_duration === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'You must enter subscription duration.'
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("id", dlc.id.toString())
        data.append("application_id", dlc.application_id.toString())
        data.append("dlc_type_id", dlc.dlc_type_id.toString())
        data.append("name", dlc.name)
        data.append("description", dlc.description)
        data.append("price", dlc.price.toString())
        if (dlc.subscription_duration !== null) data.append("subscription_duration", dlc.subscription_duration.toString())
        else data.append("subscription_duration", "0")
        data.append("multiple_purchase", dlc.multiple_purchase.toString())
        if (dlc.dlc_cover !== null) data.append("dlc_cover", dlc.dlc_cover)
        if (dlc.branding_scene_path !== null) data.append("branding_scene", dlc.branding_scene_path)

        dispatch(ApplicationActions.updateDlc({ dlc: data }))
    }

    if (
        apps.dlc.readStatus !== "fulfilled" ||
        apps.dlc_types.readStatus !== "fulfilled" ||
        apps.application.readStatus !== "fulfilled" ||
        apps.dlc_types.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading DLCs data... Please wait." />
    if (dlc === null) setDlc({ ...apps.dlc.dlcs[dlc_id], dlc_cover: null, branding_scene_path: null })
    const image_types = apps.image_types.types.filter(t => t.dlc_id === dlc_id)
    return (
        <Fragment>
            {apps.dlc.updateStatus === "pending" ? <Loading isOpen={true} message="Updating DLC info... Please wait." /> : null}
            {dlc === null ? null : <EditReleaseJSX
                appliation={apps.application.applications[dlc.application_id]}
                dlc_types={apps.dlc_types.types}
                dlc={dlc}
                setDlc={setDlc}
                handleSubmit={handleSubmit}
            />}
            {dlc === null || dlc.dlc_type_id !== 3 ? null : <span className="w-11/12 mx-auto flex flex-wrap">
                {image_types.sort((a, b) => a.id > b.id ? 1 : -1).map(t => <DlcImageType
                    key={t.id}
                    id={t.id}
                    dlc_id={t.dlc_id}
                    name={t.name}
                    prefered_aspect_ratio={t.prefered_aspect_ratio}
                    prefered_size={t.prefered_size}
                    offset_left={t.offset_left}
                    offset_top={t.offset_top}
                    scale={t.scale}
                />)}
                <CreateImageType 
                    key={-8}
                    dlc_id={dlc_id}
                />
            </span>}
        </Fragment>
    )
}

export default EditDlc