import { insertInHash, removeFromHash } from "../../Common/Functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { ApplicationsState as SliceInterface } from "./Interface";


export const ApplicationCreator = {
    createApplication: createHandler({
        name: "apps/createApplication",
        method: "POST",
        route: "api/applications/applications",
        key: "application",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.application.createStatus = "pending"
            state.application.createMessage = "Creating Application... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.application.createStatus = "fulfilled"
            state.application.createMessage = "Application has been created"
            state.application.applications = insertInHash(state.application.applications, action.payload.application, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.application.createStatus = "rejected"
            state.application.createMessage = action.payload.msg
            return state
        }
    }),
    readApplication: createHandler({
        name: "apps/readApplication",
        method: "GET",
        route: "api/applications/applications",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.application.readStatus = "pending"
            state.application.readMessage = "Loading Application(s)... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.application.readStatus = "fulfilled"
            state.application.readMessage = "Application has been created"
            
            if (action.payload?.app !== undefined) state.application.applications = insertInHash(state.application.applications, action.payload.app, "id")
            else if (action.payload.apps.includes(null)) state.application.applications = action.payload.apps
            else for (let app of action.payload.apps) state.application.applications = insertInHash(state.application.applications, app, "id")

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.application.readStatus = "rejected"
            state.application.readMessage = action.payload.msg
            return state
        }
    }),
    updateApplication: createHandler({
        name: "apps/updateApplication",
        method: "PUT",
        route: "api/applications/applications",
        key: "application",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.application.updateStatus = "pending"
            state.application.updateMessage = "Updating application... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.application.updateStatus = "fulfilled"
            state.application.updateMessage = "application updated succesfully."
            state.application.applications = insertInHash(state.application.applications, action.payload.application, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.application.updateStatus = "rejected"
            state.application.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteApplication: createHandler({
        name: "apps/deleteApplication",
        method: "DELETE",
        route: "api/applications/applications",
        key: "application",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.application.deleteStatus = "pending"
            state.application.deleteMessage = "Deleting Application... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.application.deleteStatus = "fulfilled"
            state.application.deleteMessage = "Application deleted."
            state.application.applications = removeFromHash(state.application.applications, null, "applicationn_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.application.deleteStatus = "rejected"
            state.application.deleteMessage = action.payload.msg
            return state
        }
    }),



    createVersion: createHandler({
        name: "apps/createVersion",
        method: "POST",
        route: "api/applications/application_versions",
        key: "version",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.version.createStatus = "pending"
            state.version.createMessage = "Uploading new version... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.version.createStatus = "fulfilled"
            state.version.createMessage = "Application has been updated"
            state.version.versions = insertInHash(state.version.versions, action.payload.version, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.version.createStatus = "rejected"
            state.version.createMessage = action.payload.msg
            return state
        }
    }),
    readVersions: createHandler({
        name: "apps/readVersions",
        method: "GET",
        route: "api/applications/application_versions",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.version.readStatus = "pending"
            state.version.readMessage = "Loading Versions... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.version.readStatus = "fulfilled"
            state.version.readMessage = "Versions have been loaded"
            if (Array.isArray(action.payload.versions) === false) state.version.versions = insertInHash(state.version.versions, action.payload.versions, "id")
            else if (action.payload.versions.includes(null)) state.version.versions = action.payload.versions
            else for (let app of action.payload.versions) state.version.versions = insertInHash(state.version.versions, app, "id")

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.version.readStatus = "rejected"
            state.version.readMessage = action.payload.msg
            return state
        }
    }),
    updateVersion: createHandler({
        name: "apps/updateVersion",
        method: "PUT",
        route: "api/applications/application_versions",
        key: "version",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.version.updateStatus = "pending"
            state.version.updateMessage = "Updating version data... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.version.updateStatus = "fulfilled"
            state.version.updateMessage = "Version data updated."
            state.version.versions = insertInHash(state.version.versions, action.payload.version, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.version.updateStatus = "rejected"
            state.version.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteVersion: createHandler({
        name: "apps/deleteVersion",
        method: "DELETE",
        route: "api/applications/application_versions",
        key: "version",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.version.deleteStatus = "pending"
            state.version.deleteMessage = "Deleting Version... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.version.deleteStatus = "fulfilled"
            state.version.deleteMessage = "Version deleted."
            state.version.versions = removeFromHash(state.version.versions, null, "applicationn_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.version.deleteStatus = "rejected"
            state.version.deleteMessage = action.payload.msg
            return state
        }
    }),


    createDlc: createHandler({
        name: "apps/createDlc",
        method: "POST",
        route: "api/applications/dlcs",
        key: "dlc",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc.createStatus = "pending"
            state.dlc.createMessage = "Uploading new version... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc.createStatus = "fulfilled"
            state.dlc.createMessage = "Application has been updated"
            state.dlc.dlcs = insertInHash(state.dlc.dlcs, action.payload.dlc, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc.createStatus = "rejected"
            state.dlc.createMessage = action.payload.msg
            return state
        }
    }),
    readDlcs: createHandler({
        name: "apps/readDlcs",
        method: "GET",
        route: "api/applications/dlcs",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc.readStatus = "pending"
            state.dlc.readMessage = "Loading DLCs... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc.readStatus = "fulfilled"
            state.dlc.readMessage = "DLCs have been loaded"
            
            if (action.payload?.dlc !== undefined) state.dlc.dlcs = insertInHash(state.dlc.dlcs, action.payload.dlc, "id")
            else if (action.payload.dlcs.includes(null)) state.dlc.dlcs = action.payload.dlcs
            else {
                state.dlc.dlcs = []
                for (let app of action.payload.dlcs) state.dlc.dlcs = insertInHash(state.dlc.dlcs, app, "id")
            }

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc.readStatus = "rejected"
            state.dlc.readMessage = action.payload.msg
            return state
        }
    }),
    updateDlc: createHandler({
        name: "apps/updateDlc",
        method: "PUT",
        route: "api/applications/dlcs",
        key: "dlc",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc.updateStatus = "pending"
            state.dlc.updateMessage = "Updating DLC data... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc.updateStatus = "fulfilled"
            state.dlc.updateMessage = "DLC data updated."
            state.dlc.dlcs = insertInHash(state.dlc.dlcs, action.payload.dlc, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc.updateStatus = "rejected"
            state.dlc.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteDlc: createHandler({
        name: "apps/deleteDlc",
        method: "DELETE",
        route: "api/applications/dlcs",
        key: "dlc",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc.deleteStatus = "pending"
            state.dlc.deleteMessage = "Deleting DLC... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc.deleteStatus = "fulfilled"
            state.dlc.deleteMessage = "DLC deleted."
            state.dlc.dlcs = removeFromHash(state.dlc.dlcs, null, "applicationn_id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc.deleteStatus = "rejected"
            state.dlc.deleteMessage = action.payload.msg
            return state
        }
    }),

    createDlcImage: createHandler({
        name: "apps/createDlcImage",
        method: "POST",
        route: "api/applications/dlc_images",
        key: "image",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc_image.createStatus = "pending"
            state.dlc_image.createMessage = "Creating DLC images... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc_image.createStatus = "fulfilled"
            state.dlc_image.createMessage = "Images have been uploaded"
            state.dlc_image.dlc_images = [...state.dlc_image.dlc_images, action.payload.image]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc_image.createStatus = "rejected"
            state.dlc_image.createMessage = action.payload.msg
            return state
        }
    }),
    readDlcImages: createHandler({
        name: "apps/readDlcImages",
        method: "GET",
        route: "api/applications/dlc_images",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc_image.readStatus = "pending"
            state.dlc_image.readMessage = "Loading DLC images... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc_image.readStatus = "fulfilled"
            state.dlc_image.readMessage = "DLC images have been loaded"
            state.dlc_image.dlc_images = action.payload.images
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc_image.readStatus = "rejected"
            state.dlc_image.readMessage = action.payload.msg
            return state
        }
    }),
    updateDlcImage: createHandler({
        name: "apps/updateDlcImage",
        method: "PUT",
        route: "api/applications/dlc_images",
        key: "image",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc_image.updateStatus = "pending"
            state.dlc_image.updateMessage = "Updating DLC image... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc_image.updateStatus = "fulfilled"
            state.dlc_image.updateMessage = "DLC image has been updated"
            state.dlc_image.dlc_images = [...state.dlc_image.dlc_images.filter(img => img !== undefined && img !== null &&  img.id !== action.payload.image.id), action.payload.image]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc_image.updateStatus = "rejected"
            state.dlc_image.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteDlcImage: createHandler({
        name: "apps/deleteDlcImage",
        method: "DELETE",
        route: "api/applications/dlc_images",
        key: "image",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc_image.deleteStatus = "pending"
            state.dlc_image.deleteMessage = "Deleting DLC image... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc_image.deleteStatus = "fulfilled"
            state.dlc_image.deleteMessage = "DLC image has been deleted"
            state.dlc_image.dlc_images = state.dlc_image.dlc_images.filter(image => image.id !== action.payload.image_id)
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc_image.deleteStatus = "rejected"
            state.dlc_image.deleteMessage = action.payload.msg
            return state
        }
    }),
    createImageType: createHandler({
        name: "apps/createImageType",
        method: "POST",
        route: "api/applications/branding_image_types",
        key: "type",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.image_types.createStatus = "pending"
            state.image_types.createMessage = "Creating image type... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.image_types.createStatus = "fulfilled"
            state.image_types.createMessage = "Branding image type created."
            state.image_types.types = [...state.image_types.types, action.payload.type]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.image_types.createStatus = "rejected"
            state.image_types.createMessage = action.payload.msg
            return state
        }
    }),
    readImageType: createHandler({
        name: "apps/readImageType",
        method: "GET",
        route: "api/applications/branding_image_types",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.image_types.readStatus = "pending"
            state.image_types.readMessage = "Loading branding image types... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.image_types.readStatus = "fulfilled"
            state.image_types.readMessage = "Branding image types loaded."
            state.image_types.types = action.payload.types
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.image_types.readStatus = "rejected"
            state.image_types.readMessage = action.payload.msg
            return state
        }
    }),
    updateImageType: createHandler({
        name: "apps/updateImageType",
        method: "PUT",
        route: "api/applications/branding_image_types",
        key: "type",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.image_types.updateStatus = "pending"
            state.image_types.updateMessage = "Updating branding image type... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.image_types.updateStatus = "fulfilled"
            state.image_types.updateMessage = "Branding image type updated successfully."
            state.image_types.types = [...state.image_types.types.filter(t => t.id !== action.payload.type.id), action.payload.type]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.image_types.updateStatus = "rejected"
            state.image_types.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteImageType: createHandler({
        name: "apps/deleteImageType",
        method: "DELETE",
        route: "api/applications/branding_image_types",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.image_types.deleteStatus = "pending"
            state.image_types.deleteMessage = "Deleting branding image type... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.image_types.deleteStatus = "fulfilled"
            state.image_types.deleteMessage = "Branding image type deleted successfully."
            state.image_types.types = state.image_types.types.filter(t => t.id !== parseInt(action.payload.deleted_id))
            state.dlc_image.dlc_images = state.dlc_image.dlc_images.filter(img => img !== null && img !== undefined && img.type_id !== parseInt(action.payload.deleted_id))
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.image_types.deleteStatus = "rejected"
            state.image_types.deleteMessage = action.payload.msg
            return state
        }
    }),

    readPlatforms: createHandler({
        name: "apps/readPlatforms",
        method: "GET",
        route: "api/general/platforms",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.platforms.readStatus = "pending"
            state.platforms.readMessage = "Loading platforms... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.platforms.readStatus = "fulfilled"
            state.platforms.readMessage = "Platforms have been loaded"
            state.platforms.platforms = action.payload.platforms

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.platforms.readStatus = "rejected"
            state.platforms.readMessage = action.payload.msg
            return state
        }
    }),
    readDlcTypes: createHandler({
        name: "apps/readDlcTypes",
        method: "GET",
        route: "api/general/dlc_types",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dlc_types.readStatus = "pending"
            state.dlc_types.readMessage = "Loading DLC Types... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dlc_types.readStatus = "fulfilled"
            state.dlc_types.readMessage = "DLC Types have been loaded"
            state.dlc_types.types = action.payload.types

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dlc_types.readStatus = "rejected"
            state.dlc_types.readMessage = action.payload.msg
            return state
        }
    }),
    readReleaseTypes: createHandler({
        name: "apps/readReleaseTypes",
        method: "GET",
        route: "api/general/release_version_types",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.version_types.readStatus = "pending"
            state.version_types.readMessage = "Loading Version Types... Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.version_types.readStatus = "fulfilled"
            state.version_types.readMessage = "Version Types have been loaded"
            state.version_types.types = action.payload.types

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.version_types.readStatus = "rejected"
            state.version_types.readMessage = action.payload.msg
            return state
        }
    }),

    createPackage: createHandler({
        name: "apps/createPackage",
        method: "POST",
        route: "api/applications/application_packages",
        key: "package",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.createStatus = "pending"
            state.packages.createMessage = "Creating application package... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.createStatus = "fulfilled"
            state.packages.createMessage = "Package created."
            state.packages.packages = insertInHash(state.packages.packages, action.payload.package, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.packages.createStatus = "rejected"
            state.packages.createMessage = action.payload.msg
            return state
        }
    }),
    readPackages: createHandler({
        name: "apps/readPackages",
        method: "GET",
        route: "api/applications/application_packages",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.readStatus = "pending"
            state.packages.readMessage = "Loading application packages... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.readStatus = "fulfilled"
            state.packages.readMessage = "Application packages loaded."
            if (action.payload.packages.includes(null)) state.packages.packages = action.payload.packages
            else if (action.payload.packages.length === 1) state.packages.packages = insertInHash(state.packages.packages, action.payload.packages[0], "id")
            else for (const pkg of action.payload.packages) state.packages.packages = insertInHash(state.packages.packages, pkg, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.packages.readStatus = "rejected"
            state.packages.readMessage = action.payload.msg
            return state
        }
    }),
    updatePackage: createHandler({
        name: "apps/updatePackage",
        method: "PUT",
        route: "api/applications/application_packages",
        key: "package",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.updateStatus = "pending"
            state.packages.updateMessage = "Updating application package... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.updateStatus = "fulfilled"
            state.packages.updateMessage = "Application package updated successfully."
            state.packages.packages = insertInHash(state.packages.packages, action.payload.package, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.image_types.updateStatus = "rejected"
            state.image_types.updateMessage = action.payload.msg
            return state
        }
    }),
    deletePackage: createHandler({
        name: "apps/deletePackage",
        method: "DELETE",
        route: "api/applications/application_packages",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.deleteStatus = "pending"
            state.packages.deleteMessage = "Deleting application package... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.deleteStatus = "fulfilled"
            state.packages.deleteMessage = "Application package deleted successfully."
            state.packages.packages = removeFromHash(state.packages.packages, null, parseInt(action.payload.package_id))
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.packages.deleteStatus = "rejected"
            state.packages.deleteMessage = action.payload.msg
            return state
        }
    }),

    createPackageItem: createHandler({
        name: "apps/createPackageItem",
        method: "POST",
        route: "api/applications/package_items",
        key: "item",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.createItemStatus = "pending"
            state.packages.createItemMessage = "Creating package item... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.createItemStatus = "fulfilled"
            state.packages.createItemMessage = "Package item created."
            state.packages.packages = insertInHash(state.packages.packages, action.payload.package, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.packages.createItemStatus = "rejected"
            state.packages.createItemMessage = action.payload.msg
            return state
        }
    }),
    updatePackageItem: createHandler({
        name: "apps/updatePackageItem",
        method: "PUT",
        route: "api/applications/package_items",
        key: "item",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.updateItemStatus = "pending"
            state.packages.updateItemMessage = "Updating package item... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.updateItemStatus = "fulfilled"
            state.packages.updateItemMessage = "Package item updated successfully."
            state.packages.packages = insertInHash(state.packages.packages, action.payload.package, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.packages.updateItemStatus = "rejected"
            state.packages.updateItemMessage = action.payload.msg
            return state
        }
    }),
    deletePackageItem: createHandler({
        name: "apps/deletePackageItem",
        method: "DELETE",
        route: "api/applications/package_items",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.packages.deleteItemStatus = "pending"
            state.packages.deleteItemMessage = "Deleting package item... Please wait."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.packages.deleteItemStatus = "fulfilled"
            state.packages.deleteItemMessage = "Package item deleted successfully."
            state.packages.packages[parseInt(action.payload.package_id)] = {
                ...state.packages.packages[parseInt(action.payload.package_id)],
                items: state.packages.packages[parseInt(action.payload.package_id)].items.filter(item => item.id !== parseInt(action.payload.item_id))
            }
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.packages.deleteItemStatus = "rejected"
            state.packages.deleteItemMessage = action.payload.msg
            return state
        }
    }),
}

export const [ApplicationActions, ApplicationReducers] = buildFromCreator(ApplicationCreator)