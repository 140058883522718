import { useDispatch, useSelector } from "react-redux"
import AdministratorJSX from "./AdministratorJSX"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { useEffect, useState } from "react"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { OrganizationsState } from "../../../../Redux/Organizations/Interface"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { UserState } from "../../../../Redux/User/Interface"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { OrganizationActions } from "../../../../Redux/Organizations/Actions"

function getUniqueItems(...arrays) {
    const mergedArray = [].concat(...arrays);
    const uniqueSet = new Set(mergedArray);
    const uniqueArray = Array.from(uniqueSet);
    return uniqueArray;
}

const Administrator: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const AnalyticsReducer: Analytics = useSelector((state: RootState) => state.analytics)
    const ApplicationsReucer: ApplicationsState = useSelector((state: RootState) => state.apps)

    useEffect(() => {
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(AnalyticsActions.adminReport({ arguments: {} }))
    }, [])

    const apps_list = AnalyticsReducer.adminReport.data.weekday_data === undefined ? [] : Object.keys(AnalyticsReducer.adminReport.data.weekday_data).map(key => ApplicationsReucer.application.applications[AnalyticsReducer.adminReport.data.weekday_data[key].app_id])

    const per_day_graph = {
        "data": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, idx) => {
            const ret = { "day": day }
            for (const key of Object.keys(AnalyticsReducer.adminReport.data?.weekday_data || {})) {
                ret[key] = (AnalyticsReducer.adminReport.data.weekday_data[key]["activity"][idx] / 3600).toFixed(2)
            }
            return ret
        }),
        "keys": Object.keys(AnalyticsReducer.adminReport.data?.weekday_data || {}),
        "colors": Object.keys(AnalyticsReducer.adminReport.data?.weekday_data || {}).map(key => ApplicationsReucer.application.applications[AnalyticsReducer.adminReport.data.weekday_data[key].app_id].primary_color)
    }
    const organization_application_usage = {
        "keys": getUniqueItems(...Object.keys(AnalyticsReducer.adminReport.data?.totals || {}).map(key => Object.keys(AnalyticsReducer.adminReport.data?.totals[key]["apps"]))),
        "colors": getUniqueItems(...Object.keys(AnalyticsReducer.adminReport.data?.totals || {}).map(key => Object.keys(AnalyticsReducer.adminReport.data?.totals[key]["apps"]))).map(app => ApplicationsReucer.application.applications.find(a => a !== null && a !== undefined && a?.name === app).primary_color),
        "data": Object.keys(AnalyticsReducer.adminReport.data?.totals || {}).map(organization => {
            const ret = { "Organization": organization }
            for (const application in AnalyticsReducer.adminReport.data?.totals[organization]["apps"]) {
                ret[application] = (AnalyticsReducer.adminReport.data?.totals[organization]["apps"][application] / 60).toFixed()
                ret[application + "Color"] = ApplicationsReucer.application.applications.find(a => a !== null && a !== undefined && a?.name === application).primary_color
            }

            return ret
        })
    }

    const most_played_apps = [
        ...Object.keys(AnalyticsReducer.adminReport.data?.weekday_data || {}).map(key => ({
            id: key,
            label: key,
            color: ApplicationsReucer.application.applications[AnalyticsReducer.adminReport.data.weekday_data[key].app_id].primary_color,
            value: (Object.keys(AnalyticsReducer.adminReport.data?.totals).map(org => AnalyticsReducer.adminReport.data?.totals[org]["apps"][key]).reduce((a, b) => {
                let sum = 0
                if (isNaN(parseInt(a)) === false && a !== undefined) sum += a
                if (isNaN(parseInt(b)) === false && b !== undefined) sum += b
                return sum
            }, 0) / 3600).toFixed(2)
        }))
    ]


    return (
        <AdministratorJSX
            apps_list={apps_list}
            per_day_graph={per_day_graph}
            most_played_apps={most_played_apps}
            organization_application_usage={organization_application_usage}
        />
    )
}

export default Administrator