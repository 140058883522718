import { IonButton } from "@ionic/react"
import { print_pdf } from "../../../../Common/print"
import ChartContainer from "../../../Analytics/Charts/ChartContainer"
import LineChart from "../../../Analytics/Charts/LineChart"
import Translate from "../../../../components/Translate"
import { DataGrid } from "@mui/x-data-grid"

const SprayverseJSX = ({
    ActivityLine,
    SettingsStatistics,
    range,
    setRange,
    application,
    table_data,
    setSelecterUser,
    children
}) => {
    const tickValues = [1, 10]; // Custom tick values
    const tickFormat = (value) => value.toString();
    return (
        <div className="w-full">
            {children}
            <div id="overall-contianer" className="w-[1000px] max-w-[95%] mx-auto my-8"> {/* Container */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                    <h1 className="text-xl font-semibold text-slate-700"><Translate label="SprayVerse Dashboard Report" /></h1>
                    <div className="mt-3 md:mt-0">
                        {["OVERALL", "WEEK", "MONTH", "YTD"].map(item => (
                            <IonButton
                                key={item}
                                color={range === item ? "light" : "primary"}
                                onClick={e => {
                                    e.preventDefault()
                                    setRange(item)
                                    console.log(item)
                                }}
                            >
                                <Translate label={item} />
                            </IonButton>
                        ))}
                        <IonButton className="ml-5" onClick={e => {
                            e.preventDefault()
                            print_pdf(document.getElementById("printable_sprayverse_dashboard").cloneNode(true), "Sprayverse Dashboard Report", application)
                        }}><Translate label="EXPORT" /></IonButton>
                    </div>
                </div>
                <div id="printable_sprayverse_dashboard" className="print:w-[1000px] print:mx-auto">
                    <div className="mb-8 grid grid-cols-2 md:grid-cols-4 print:grid-cols-4 gap-2">
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            <h1 className="text-slate-600 pt-2 px-2 mb-2 text-sky-500 font-semibold"><Translate label="Primer Coat Price" />:</h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Material Used" />:</span>
                                <span>{SettingsStatistics["Primer Coat Price"]["Paint Used"].toFixed(2)}L</span>
                            </h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Price per Liter" />:</span>
                                <span>{SettingsStatistics["Currency"]}{SettingsStatistics["Primer Coat Price"]["$/liter of paint"].toFixed(2)}</span>
                            </h1>

                            <h1 className="text-md font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-sky-500 to-cyan-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{SettingsStatistics["Currency"]}{SettingsStatistics["Primer Coat Price"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            <h1 className="text-slate-600 pt-2 px-2 mb-2 text-teal-600 font-semibold"><Translate label="Base Coat Price" />:</h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Material Used" />:</span>
                                <span>{SettingsStatistics["Base Coat Price"]["Paint Used"].toFixed(2)}L</span>
                            </h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Price per Liter" />:</span>
                                <span>{SettingsStatistics["Currency"]}{SettingsStatistics["Base Coat Price"]["$/liter of paint"].toFixed(2)}</span>
                            </h1>

                            <h1 className="text-md font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-teal-600 to-teal-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{SettingsStatistics["Currency"]}{SettingsStatistics["Base Coat Price"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            <h1 className="text-slate-600 pt-2 px-2 mb-2 text-indigo-600 font-semibold"><Translate label="Clear Coat Price" />:</h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Material Used" />:</span>
                                <span>{SettingsStatistics["Clear Coat Price"]["Paint Used"].toFixed(2)}L</span>
                            </h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Price per Liter" />:</span>
                                <span>{SettingsStatistics["Currency"]}{SettingsStatistics["Clear Coat Price"]["$/liter of paint"].toFixed(2)}</span>
                            </h1>

                            <h1 className="text-md font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-indigo-600 to-indigo-400 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{SettingsStatistics["Currency"]}{SettingsStatistics["Clear Coat Price"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            <h1 className="text-slate-600 pt-2 px-2 mb-2 text-emerald-600 font-semibold"><Translate label="Total" />:</h1>
                            <h1 className="text-md text-slate-800 font-semibold flex justify-between items-center px-2">
                                <span><Translate label="Material Used" />:</span>
                                <span>{(SettingsStatistics["Clear Coat Price"]["Paint Used"] +
                                    SettingsStatistics["Base Coat Price"]["Paint Used"] +
                                    SettingsStatistics["Primer Coat Price"]["Paint Used"]).toFixed(2)}L</span>
                            </h1>
                            <h1 className="text-md text-white font-semibold flex justify-between items-center px-2">
                                .
                            </h1>

                            <h1 className="text-md font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-emerald-600 to-emerald-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{SettingsStatistics["Currency"]}{(SettingsStatistics["Clear Coat Price"]["Money Saved"] +
                                    SettingsStatistics["Base Coat Price"]["Money Saved"] +
                                    SettingsStatistics["Primer Coat Price"]["Money Saved"]).toFixed(2)}</span>
                            </h1>
                        </span>
                    </div>
                    {table_data.length === 0 || ActivityLine["1"].length < 3 ? <h1 className="text-center text-slate-800 font-medium"><Translate label="Not enough data to display this report. Try to select a wider range of dates." /></h1> : null}
                    <div className="flex flex-col gap-6 w-full justify-center items-center" style={{ "pageBreakAfter": "always" }}>
                        {ActivityLine["1"].length < 3 ? null : <ChartContainer
                            maxHeight={320}
                            maxWidth={1060}
                            data={[{ id: <Translate label="Time Spent (min)" />, color: application.primary_color, label: "Low Coverage", data: ActivityLine["1"] },]}
                            Chart={LineChart}
                            title={<Translate label="User activity over time on SprayVerse" />}
                            stacked={true}
                            properties={{
                                axisLeft: {
                                    legend: <Translate label="Time Spent (min)" />,
                                    legendOffset: -40,
                                    legendPosition: 'middle'
                                },
                                enableGridX: ActivityLine["1"].length > 25 ? false : true,
                                enableDots: ActivityLine["1"].length > 25 ? false : true,
                                layers: ActivityLine["1"].length > 25 ? ['grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip'] : ['grid', 'markers', 'areas', 'lines', 'slices', 'axes', 'legends', 'tooltip', 'dots'],
                                enableSlices: "x",
                                axisBottom: ActivityLine["1"].length > 25 ? {
                                    tickValues,
                                    tickPadding: 10,
                                    format: tickFormat,
                                } : {
                                    tickPadding: 23
                                },
                                enableArea: true
                            }}
                        />}
                    </div>

                    <div
                        className={`print:hidden bg-white my-5 print:w-[1000px] mx-auto rounded-xl overflow-hidden print:shadow-sm shadow-md print:block mui-table-contianer-printable`}
                        style={{
                            maxHeight: ((table_data.length * 52) + 100) + "px"
                        }}
                    >
                        <DataGrid
                            rows={table_data}
                            columns={[
                                { field: 'user_name', headerName: 'User', width: 250 },
                                { field: 'time_spent', headerName: 'Total Time Spent', width: 150 },
                                { field: 'material_used', headerName: 'Avg/Target Mat. Used (ml)', width: 200 },
                                { field: 'coverage', headerName: 'Avg/Target Coverage (%)', width: 200, },
                                { field: 'grade', headerName: 'Avg. Grade', width: 100, },
                            ]}
                            disableColumnSelector={true}
                            disableRowSelectionOnClick={true}
                            onRowClick={e => setSelecterUser(e.id)}
                            isRowSelectable={() => false}
                        />
                    </div>
                    {table_data.length === 0 ? null : <div
                        className="hidden print:block overflow-x-scroll rounded-xl mb-24 mt-2"
                    >
                        <table 
                            className="w-full rounded-lg overflow-hidden min-w-[800px] shadow pb-12"
                            style={{ borderTop: "10px solid #f3f4f6", borderBottom: "10px solid #f3f4f6" }}
                        >
                            <thead>
                                <tr>
                                    <th className="cursor-pointer w-1/5 py-2 px-1 border border-white-400 bg-slate-800 text-white"><Translate label="User" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Total Time Spent" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Avg. Material Used (ml) / Target" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Avg. Coverage (%) / Target" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Average Grade" /></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {table_data.map(data =>
                                    <tr key={data.user.id} onClick={e => setSelecterUser(data.user.id)} className="cursor-pointer hover:bg-slate-100">
                                        <td className="text-center p-2 shadow">{data.user.username}</td>
                                        <td className="text-center p-2 shadow">{data.time_spent}</td>
                                        <td className="text-center p-2 shadow">{data.material_used}</td>
                                        <td className="text-center p-2 shadow">{data.coverage}</td>
                                        <td className="text-center p-2 shadow">{data.grade}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>}

                </div>
            </div>
        </div>
    )
}

export default SprayverseJSX