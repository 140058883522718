import { ApplicationActions } from "../../../Redux/Applications/Actions"
import ApplicationSearch from "../ApplicationSearch/ApplicationSearch"
import ApplicationsContainerJSX from "./ApplicationsContainerJSX"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import ApplicationsList from "./ApplicationsList"

import "./Applications.css"
import Loading from "../../../components/Loading"

const ApplicationsContainer: React.FC = () => {

    const dispatch = useDispatch<AppDispatch>()
    const application = useSelector((state: RootState) => state.apps.application)
    const user = useSelector((state: RootState) => state.user.current)
    useEffect(() => { dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } })) }, [dispatch])

    const [filter, setFilter] = useState<string>("")
    const apps = application.applications.filter(a => (a !== null && a !== undefined) && a.id !== 8 && (filter === "" || a.name.toLowerCase().startsWith(filter.toLowerCase())))


    return application.readStatus === "pending" ?
        <Loading isOpen={true} message={application.readMessage} /> :
        <ApplicationsContainerJSX>
            <ApplicationSearch filter={filter} setFilter={setFilter} applications={apps} />
            <ApplicationsList applications={apps} user={user} />
        </ApplicationsContainerJSX>
}

export default ApplicationsContainer