import React, { Fragment } from "react"
import { Applications, User } from "../../../Common/Interfaces/Entities"
import ApplicationSelectJsx from "./ApplicationSelectJsx"
import { Redirect } from "react-router-dom"


interface ApplicationsSelectInterface {
    id: string | undefined
    route: string
    user_apps: Array<Applications>,
    current_user: User
}

const ApplicationSelect: React.FC<ApplicationsSelectInterface> = ({ id, route, user_apps, current_user }) => (
    <Fragment>
        {
            user_apps.length === 0 ? <Redirect to="/applications/" /> :
            id === undefined ? <Redirect to={"/dashboard/" + user_apps[0].id} /> :
            null
        }
        <ApplicationSelectJsx appliations={user_apps} route={route} current_user={current_user} />
    </Fragment>
)

export default ApplicationSelect