import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { BiImageAdd } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import { ImageType } from "../../../Common/Interfaces/Entities"
import Input from "../../../components/Form/Input"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import Loading from "../../../components/Loading"

const checkCharBetweenNumbers = (str, char) => {
    const split = str.split(char)
    if (split.length !== 2) return false
    else if (isNaN(split[0]) || isNaN(split[1])) return false
    return true
}

const CreateImageType: React.FC<{ dlc_id: number }> = ({
    dlc_id
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [modal, setModal] = useState<boolean>(false)
    const [infoAlert] = useIonAlert()
    const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [submitted, setSubmitted] = useState(false)
    const [type, setType] = useState<ImageType>({
        id: null,
        dlc_id: dlc_id,
        name: "",
        prefered_aspect_ratio: "",
        prefered_size: "",
        offset_left: 0,
        offset_top: 0,
        scale: 0,
    })

    useEffect(() => {
        if (submitted !== true) return
        if (["fulfilled", "rejected"].includes(ApplicationsReducer.image_types.createStatus)) infoAlert({
            header: ApplicationsReducer.image_types.createStatus === "fulfilled" ? 'Success' : "Error",
            buttons: ['OK'], message: ApplicationsReducer.image_types.createMessage
        })
        if (ApplicationsReducer.image_types.createStatus === "fulfilled") setTimeout(() => setModal(false), 100)
    }, [ApplicationsReducer.image_types.createStatus, ApplicationsReducer.image_types.createMessage, infoAlert, submitted])

    const handleCreate = e => {
        if (type.name.length < 5) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Please enter more specific image name (label).' })
        else if (!checkCharBetweenNumbers(type.prefered_aspect_ratio, ":")) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Aspect ratio must be formated in A:B format where A and B are whole positive numbers.' })
        else if (!checkCharBetweenNumbers(type.prefered_size, "x")) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Size must be formated in AxB format where A and B are whole positive numbers.' })
        else if (isNaN(type.offset_left)) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Offset left must be a numerical value' })
        else if (isNaN(type.offset_top)) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Offset top must be a numerical value' })
        else if (type.scale <= 0 || isNaN(type.scale)) return infoAlert({ header: 'Error.', buttons: ['OK'], message: 'Scale must be a positiv decimal number.' })

        const data = new FormData()
        data.append("dlc_id", type.dlc_id.toString())
        data.append("name", type.name)
        data.append("prefered_aspect_ratio", type.prefered_aspect_ratio)
        data.append("prefered_size", type.prefered_size)
        data.append("offset_left", type.offset_left.toString())
        data.append("offset_top", type.offset_top.toString())
        data.append("scale", type.scale.toString())
        dispatch(ApplicationActions.createImageType({ type: data }))
        setSubmitted(true)
    }

    useEffect(() => {
        if (modal === false) {
            setType({
                id: null,
                dlc_id: dlc_id,
                name: "",
                prefered_aspect_ratio: "",
                prefered_size: "",
                offset_left: 0,
                offset_top: 0,
                scale: 0,
            })
            setSubmitted(false)
        }
    }, [modal, dlc_id])

    return (
        <div className="w-48 mx-auto">
            <Loading isOpen={submitted === true && ApplicationsReducer.image_types.createStatus === "pending"} message={ApplicationsReducer.image_types.createMessage} />
            <IonModal id='image_type-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
                <IonContent>
                    <IonToolbar>
                        <IonTitle>Create Image Type</IonTitle>
                        <IonButtons slot="end">
                            <IonButton onClick={e => setModal(false)}>CLOSE</IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <Input
                        placeholder="Enter image type name"
                        name="name"
                        handleChange={e => setType({ ...type, name: e.target.value })}
                        type="text"
                        accept="*"
                        value={type.name}
                    />
                    <Input
                        placeholder="Enter prefered image aspect ration (e.g 16:9)"
                        name="prefered_aspect_ratio"
                        handleChange={e => setType({ ...type, prefered_aspect_ratio: e.target.value })}
                        type="text"
                        accept="*"
                        value={type.prefered_aspect_ratio}
                    />
                    <Input
                        placeholder="Enter prefered image size (e.g 1920x1080)"
                        name="prefered_size"
                        handleChange={e => setType({ ...type, prefered_size: e.target.value })}
                        type="text"
                        accept="*"
                        value={type.prefered_size}
                    />
                    <Input
                        placeholder="Enter offset left in pixels"
                        name="offset_left"
                        handleChange={e => setType({ ...type, offset_left: e.target.value })}
                        type="text"
                        accept="*"
                        value={type.offset_left}
                    />
                    <Input
                        placeholder="Enter offset top in pixels"
                        name="offset_top"
                        handleChange={e => setType({ ...type, offset_top: e.target.value })}
                        type="text"
                        accept="*"
                        value={type.offset_top}
                    />
                    <Input
                        placeholder="Enter scale in decimal format (0.5 = 1/2, 2=2/1, 1.5=3/2 etc.)"
                        name="scale"
                        handleChange={e => setType({ ...type, scale: e.target.value })}
                        type="text"
                        accept="*"
                        value={type.scale}
                    />
                    <IonButton className="block mx-5" onClick={e => handleCreate(e)}>Create</IonButton>
                </IonContent>
            </IonModal>
            <span className="w-32 h-32 mt-12 shadow rounded p-4 mx-auto mb-5 flex flex-col items-center justify-center bg-white cursor-pointer" onClick={e => setModal(true)}>
                <BiImageAdd className="text-6xl text-cythero-primary" />
                <p className="text-slate-700 font-bold">Add New</p>
            </span>
        </div>
    )
}

export default CreateImageType