import "./Chart.css"

const DataContainer = ({
    maxHeight,
    maxWidth,
    jsx,
    title,
}) => (
    <div
        className={`chart-container rounded-lg shadow overflow-hidden w-full h-full flex-col bg-white`}
        style={{
            "maxWidth": maxWidth + 'px',
            "minHeight": maxHeight + "px"
        }}
    >
        <div className="">
            <div className="w-full p-4">
                <h3 className="text-slate-700 font-medium text-lg px-3 pt-2">{title}</h3>
            </div>
        </div>
        <div className="flex w-full h-full px-[10px] pb-4 items-center overflow-x-auto pt-8">
            <span style={{
                "height": maxHeight + "px",
                "minWidth": (maxWidth - 20) + "px"
            }}> {jsx}</span>
        </div>
    </div>
)

export default DataContainer