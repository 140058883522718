import { IonButton } from "@ionic/react"
import { BsChevronCompactRight } from "react-icons/bs"
import { MdAddBox } from "react-icons/md"
import { NavLink } from "react-router-dom"
import Input from "../../../../components/Form/Input"
import Select from "../../../../components/Form/Select"

const get_new_id = items => {
    const sorted = items.sort((a, b) => a.id > b.id ? 1 : -1)
    return sorted[items.length - 1].id + 1
}

const CreateApplicationPackageJSX = ({
    _package,
    setPackage,
    dlcs,
    application,
    handleSubmit
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/packages/" + application.id}>{application.name} Packages</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/create_package/" + application.id}>Create Package</NavLink>
        </span>
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">Create new package</h1>
            <span className="w-full block pb-10 px-4">
                <Input
                    name="name"
                    value={_package.name}
                    type="text"
                    placeholder="Package Name"
                    handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.value })}
                />
                <Input
                    name="description"
                    value={_package.description}
                    type="textarea"
                    placeholder="Enter description about this package"
                    handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.value })}
                />
                <Input
                    name="price"
                    value={_package.price}
                    type="number"
                    placeholder="Enter package price in US Dollars"
                    handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.value })}
                />
                <Input accept="image/*" name="cover" handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select package cover photo" />
                <div className="my-5 mx-auto flex flex-col items-center justify-center">
                    {_package.items.sort((a, b) => a.id > b.id ? 1 : -1).map(item => (<span className="flex w-full" key={item.dlc_id || item.application_id}>
                        <span className="w-1/3">
                            <Select
                                value={item.type}
                                multiple={false}
                                options={["Application", "DLC"].map(i => ({ name: i, value: i.toLowerCase() + "_id" }))}
                                displayField="name"
                                valueField="value"
                                name="type"
                                placeholder="Select product type"
                                handleChange={e => setPackage({
                                    ..._package, items: [
                                        ..._package.items.filter(i => i.id !== item.id),
                                        { ...item, type: e.target.value, dlc_id: null, application_id: null }
                                    ]
                                })}
                            />
                        </span>
                        <span className="w-1/3">
                            <Select
                                value={item.type === "application_id" ? item.application_id : item.type === "dlc_id" ? item.dlc_id : null}
                                multiple={false}
                                options={item.type === "application_id" ? [application] : item.type === "dlc_id" ? dlcs : []}
                                displayField="name"
                                valueField="id"
                                name="product_id"
                                placeholder="Select product"
                                handleChange={e => setPackage({
                                    ..._package, items: [
                                        ..._package.items.filter(i => i.id !== item.id),
                                        {
                                            ...item,
                                            dlc_id: null,
                                            application_id: null,
                                            [item.type]: e.target.value
                                        }
                                    ]
                                })}
                            />
                        </span>
                        <span className="w-1/3 flex items-center">
                            <Input
                                name="quantity"
                                value={item.quantity}
                                type="number"
                                placeholder="Enter number of products in package"
                                handleChange={e => setPackage({
                                    ..._package, items: [
                                        ..._package.items.filter(i => i.id !== item.id),
                                        {
                                            ...item,
                                            quantity: e.target.value
                                        }
                                    ]
                                })}
                                disabled={item.type === "application_id"}
                            />
                            <IonButton color={"danger"} className="ml-5" onClick={e => setPackage({
                                ..._package,
                                items: _package.items.filter(i => i.id !== item.id)
                            })}>delete</IonButton>
                        </span>
                    </span>))}

                    <IonButton onClick={e => setPackage({
                        ..._package,
                        items: [
                            ..._package.items,
                            {
                                id: _package.items.length === 0 ? 1 : get_new_id(_package.items),
                                type: "dlc_id",
                                dlc_id: null,
                                application_id: null,
                                quantity: 1
                            }
                        ]
                    })}><MdAddBox className="mr-3" /> Add More Items</IonButton>
                </div>
                <IonButton className="mt-5" onClick={e => handleSubmit(e)}>Create package</IonButton>
            </span>
        </div>
    </div>
)

export default CreateApplicationPackageJSX