import { IonButton } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { print_pdf } from "../../../../../Common/print"
import DateSelect, { DateInterface, dateToDateStr, dateTostr } from "../../../../../components/Form/DateSelect"
import Select from "../../../../../components/Form/Select"
import { AnalyticsActions } from "../../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../../Redux/Analytics/Interface"
import { clearReport, setReportFilters } from "../../../../../Redux/Analytics/Slice"
import { ApplicationsState } from "../../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../../Redux/Store"
import { UserActions } from "../../../../../Redux/User/Actions"
import Translate from "../../../../../components/Translate"


const UserReport = (props) => {

    const app_id = parseInt(useParams<{ id : string}>().id)
    const applicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const current_app = applicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === app_id)

    const dispatch = useDispatch<AppDispatch>()
    const userReducer = useSelector((state: RootState) => state.user)
    const AnalyticsState:Analytics = useSelector((state:RootState) => state.analytics)
    const [filters, setFilters] = useState<{
        selectedUsers: Array<number>,
        selectedDates: {
            from: DateInterface | null,
            to: DateInterface | null
        }
    }>({
        selectedUsers: [],
        selectedDates: {
            from: null,
            to: null
        }
    })

    useEffect(() => {
        dispatch(clearReport("userReport"))
        dispatch(UserActions.readUsers({}))
    }, [dispatch])

    const handleSubmit = e => {
        e.preventDefault()
        let user_ids = filters.selectedUsers

        const form = new FormData()
        form.append("user_ids", JSON.stringify(user_ids))
        form.append("date_from", dateToDateStr(filters.selectedDates.from, "00:00:00"))
        form.append("date_to", dateToDateStr(filters.selectedDates.to, "23:59:59"))
        dispatch(setReportFilters({
            "report" : "userReport",
            "filters": {
                "user_ids" : user_ids,
                "date_from": dateTostr(filters.selectedDates.from),
                "date_to": dateTostr(filters.selectedDates.to),
            }
        }))
        dispatch(AnalyticsActions.userReport({ filters: form }))
    }


    return (
        <div className="bg-white p-4 rounded-lg shadow">
            <h1 className="text-slate-800 font-bold text-lg"><Translate label={"Report Filters"} /></h1>
            {userReducer.current.organization_id === null ? null : <Select
                value={filters.selectedUsers}
                name="selectedUsers"
                placeholder={(<Translate label={"Select Users"} />)}
                handleChange={e => setFilters({...filters, [e.target.name] : e.target.value})}
                displayField="username"
                valueField="id"
                options={userReducer.users.filter(u => u !== null && u !== undefined && u.archived !== 1 && u.organization_id === userReducer.current.organization_id && u.type_id === 1)}
                multiple={true}
            />}
            <DateSelect
                handleChange={e => setFilters({...filters, [e.target.name] : e.target.value})}
                name="selectedDates"
                placeholder={(<Translate label={"Select Date Range"} />)}
                value={filters.selectedDates}
            />
            {filters.selectedUsers.length === 0 || filters.selectedDates.to === null ? null : <IonButton onClick={e => handleSubmit(e)}><Translate label="GENERATE REPORT" /></IonButton>}
            {filters.selectedUsers.length === 0 || filters.selectedDates.to === null || AnalyticsState.userReport.status !== "fulfilled" ? null : <IonButton
                onClick={e => print_pdf(document.getElementById("user-report-container").cloneNode(true), "Trainee Report", current_app)}
            ><Translate label="PRINT ALL" /></IonButton>}
        </div>
    )
}

export default UserReport

