import { useIonAlert } from "@ionic/react"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import CreateApplicationPackageJSX from "./CreateApplicationPackageJSX"
import Loading from "../../../../components/Loading"

const CreateApplicationPackages: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()

    const appId: number = parseInt(useParams<{ id: string }>().id)
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    const [ionAlert] = useIonAlert()

    const [_package, setPackage] = useState<{
        name: string
        description: string
        price: number
        items: Array<{
            id: number
            type: "application_id" | "dlc_id" | null
            dlc_id: number | null
            application_id: number | null
            quantity: number
        }>
        cover: any
    }>({
        name: "",
        description: "",
        price: null,
        items: [{
            id: 1,
            type: "application_id",
            dlc_id: null,
            application_id: appId,
            quantity: 1,
        }],
        cover: null
    })
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: { id: appId } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
    }, [appId, dispatch])

    useEffect(() => {
        if (submitted === false || appsReducer.packages.createStatus === "pending") return

        ionAlert({
            header: appsReducer.packages.createStatus === "fulfilled" ? "Success" : appsReducer.packages.createStatus === "rejected" ? "Error" : "",
            message: appsReducer.packages.createMessage,
            buttons: ["OK"]
        })
        if (appsReducer.packages.createStatus === "fulfilled") {
            setPackage({
                name: "",
                description: "",
                price: null,
                items: [{
                    id: 1,
                    type: "application_id",
                    dlc_id: null,
                    application_id: appId,
                    quantity: 1,
                }],
                cover: null
            })
        }

    }, [dispatch, submitted, appsReducer.packages.createStatus, appsReducer.packages.createMessage, appId, ionAlert])

    const handleSubmit = (e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>) => {
        e.preventDefault()

        if (_package.name.length < 5) return ionAlert({ message: "Please enter more descriptive name.", buttons: ["OK"], header: "Warning" })
        if (_package.description.length < 120) return ionAlert({ message: "Please enter a better description (120+ chaacters).", buttons: ["OK"], header: "Warning" })
        if (_package.description.length > 1000) return ionAlert({ message: "Description cannot be longer than 1000 characters.", buttons: ["OK"], header: "Warning" })
        if (_package.price < 1) return ionAlert({ message: "Price must be greater than €1 EUR", buttons: ["OK"], header: "Warning" })
        if (_package.cover === null) return ionAlert({ message: "Please select a cover photo for this package.", buttons: ["OK"], header: "Warning" })
        if (_package.items.length === 0) return ionAlert({ message: "You cannot create a package without any items.", buttons: ["OK"], header: "Warning" })

        setSubmitted(true)
        const data = new FormData()
        data.append("name", _package.name)
        data.append("description", _package.description)
        data.append("price", _package.price.toString())
        data.append("application_id", appId.toString())
        data.append("cover", _package.cover)
        data.append("items", JSON.stringify(_package.items.map(item => item.type === "application_id" ? ({
            application_id: item.application_id,
            quantity: item.quantity
        }) : ({
            dlc_id: item.dlc_id,
            quantity: item.quantity
        }))))

        dispatch(ApplicationActions.createPackage({ package: data }))
    }

    if (appsReducer.application.readStatus !== "fulfilled" ||
        appsReducer.dlc.readStatus !== "fulfilled") return <Loading isOpen={true} message="Loading application data... Please wait." />

    const application_dlcs = appsReducer.dlc.dlcs.filter(dlc => dlc !== null && dlc !== undefined && dlc.application_id === appId)
    const application = appsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === appId)

    return (
        <Fragment>
            <Loading isOpen={appsReducer.packages.createStatus === "pending"} message={appsReducer.packages.createMessage} />
            <CreateApplicationPackageJSX
                _package={_package}
                setPackage={setPackage}
                dlcs={application_dlcs}
                application={application}
                handleSubmit={handleSubmit}
            />
        </Fragment>
    )
}

export default CreateApplicationPackages