import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistReducer } from 'redux-persist'

import UserReducer from './User/Slice'
import AnalyticsReducer from './Analytics/Slice'
import OrganizationsReducer from './Organizations/Slice'
import ApplicationsReducer from './Applications/Slice'
import PurchasesReducer from './Purchases/Slice'
import LanguagesReducer from './Languages/Slice'
// import localForage from "localforage"

const persistConfig = {
    key: 'cythero_'+(localStorage.getItem("version") === null ? "0.0" : localStorage.getItem("version")),
    storage,
    // blacklist: ['organizations', 'apps', 'shop', 'language', 'analytics'],
    // whitelist: ['user']
}

export const store = configureStore({
    reducer: persistReducer(persistConfig, combineReducers({
        user: UserReducer,
        analytics: AnalyticsReducer,
        organizations: OrganizationsReducer,
        apps: ApplicationsReducer,
        shop: PurchasesReducer,
        language: LanguagesReducer
    })),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})


export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch