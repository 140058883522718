import React from "react"
import { IonPage } from "@ionic/react"
import { useParams } from "react-router-dom"
import Login from "./Login/Login"
import Register from "./Register/Register"
import ForgotPassword from "./ForgotPassword/ForgotPassword"

const AuthContainer: React.FC = props => {

    const params = useParams<{ option: string }>()

    return (
        <IonPage className="bg-white overflow-y-auto mh-custom-auth">
            <div className="flex justify-center lg:h-screen">
                <div className="hidden lg:block lg:w-2/3 auth-bg ">
                    <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                        <div>
                            <h2 className="text-4xl font-bold text-white"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup></h2>

                            <p className="max-w-xxl mt-3 text-gray-300">Cythero is at the forefront of immersive technologies providing your business adaptable virtual solutions that are changing the way we learn, train, and engage. With a decade of work experience, we transform how companies deliver training and perform assessments by creating leading edge virtual software and simulation products. Reach out today to see how we can help you begin your virtual journey.</p>
                            <p className="max-w-xxl text-gray-300">Nikola Rendevski, PhD</p>
                            <p className="max-w-xxl text-gray-300">CEO </p>
                        </div>
                    </div>
                    <ul className="circles">
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </ul>
                </div>
                <div className="flex justify-center items-center auth-bg-sm w-full mx-auto lg:w-2/6 min-h-screen h-screen">
                    <div className="w-full flex justify-center py-10 overflow-y-auto">
                        <div className="flex-1 max-w-md bg-white bg-opacity-20 backdrop-blur-lg rounded p-6 mx-5">
                            {params.option === "login" ? <Login /> :
                                params.option === "register" ? <Register /> :
                                    params.option === "forgot_password" ? <ForgotPassword /> :
                                        null}
                        </div>
                    </div>
                </div>
            </div>
        </IonPage>
    )
}

export default AuthContainer