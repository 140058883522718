import { ResponsiveLine } from '@nivo/line'

const CandleChart = ({ stacked, data, properties /* see data tab */ }) => {

    return (
        <ResponsiveLine
            data={data}
            margin={{ top: 10, right: 30, bottom: 60, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
                type: 'linear',
                min: 'auto',
                max: 'auto',
                stacked: stacked,
                reverse: false
            }}
            yFormat=" >-.2f"
            curve="monotoneX"
            axisTop={null}
            axisRight={null}
            colors={{ datum: "color" }}
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabelYOffset={-12}
            useMesh={true}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 3,
                tickRotation: 10,
            }}
            legends={[
                {
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 60,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 120,
                    itemHeight: 15,
                    itemOpacity: 1,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            {...properties}
            layers={[...properties.layers]}
        />
    )
}

export default CandleChart