
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { OrganizationActions } from "../../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../../Redux/Organizations/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import UserList from "./UserList"
import UserSearch from "./UserSearch"
import Loading from "../../../components/Loading"

const BrowseUsers: React.FC = ({

}) => {
    const dispatch = useDispatch<AppDispatch>()
    const [filter, setFilter] = useState("")
    const userReducer:UserState = useSelector((state: RootState) => state.user)
    const organizationsReducer:OrganizationsState = useSelector((state: RootState) => state.organizations)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(UserActions.readUserTypes({ arguments: { hashed: true } }))
    }, [])

    if (
        userReducer.readUserStatus !== "fulfilled" ||
        userReducer.userTypes.readStatus !== "fulfilled" || 
        organizationsReducer.organization.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading user info... Please wait." />

    const users = userReducer.users.filter(user =>
        user !== null &&
        (userReducer.current.type_id === 4 || (userReducer.current.type_id === 3 && userReducer.current.organization_id === user.organization_id)) &&
        (
            filter === "" || (user.username.toLowerCase().startsWith(filter.toLowerCase())) ||
            (user.username).toLowerCase().startsWith(filter.toLowerCase())
        )
    )

    return <Fragment>
        <UserSearch
            filter={filter}
            setFilter={setFilter}
            users={users}
        />
        <UserList 
            current={userReducer.current}
            users={users} 
            orgnizations={organizationsReducer.organization.organizations}
        />
    </Fragment>
}

export default BrowseUsers