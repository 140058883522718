

const get_to_write = (name, is_portrait) => {
    if (is_portrait) return `
    <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <title>${name} (${new Date().toString().slice(0, 15)})</title>
    <script src="https://cdn.tailwindcss.com"></script>
    <style>
        @page { size: 21cm 29.7cm ; margin: 0px; }
        body { position: unset !important; }
        @media print { body { width: 21.5cm; height: 29.7cm; margin: 0px auto; } }
    </style>`

    return `
    <meta name="viewport" content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    <title>${name} (${new Date().toString().slice(0, 15)})</title>
    <script src="https://cdn.tailwindcss.com"></script>
    <style>
        @page { size: 29.7cm 21cm; margin: 0px; }
        body { position: unset !important; }
        @media print { body { height: 21cm; width: 29.7cm; margin: 0px auto; } }
    </style>`
}

const build_font_page_banner = application => {
    return `
        <div class="px-4 py-2 w-full flex items-center justify-between mb-4" style="background: ${application.primary_color}">
            <img width="100px" src="https://www.cythero.com/wp-content/uploads/2022/03/logo_navbar.svg" />
            <h3 class="text-lg font-bld text-white">${application.name} (${new Date().toString().slice(0, 15)})</h3>
            <img width="30px" class="ml-[100px]" src="https://static.cythero.com/${application.logo_path}" />
        </div>

    `
}

export const print_pdf = (selector, name, application, portrait = false) => {
    const newWin = window.open()
    newWin.document.write(document.getElementsByTagName("html")[0].outerHTML)
    setTimeout(() => {
        newWin.document.getElementById("root").style.display = "none"
        newWin.document.getElementsByTagName("body")[0].appendChild(selector)
        newWin.document.getElementsByTagName("body")[0].classList.add("bg-slate-100")
        newWin.document.getElementsByTagName("body")[0].insertAdjacentHTML('beforebegin', build_font_page_banner(application))
        newWin.document.head.insertAdjacentHTML("afterbegin", get_to_write(name, portrait));
        newWin.document.body.innerHTML = newWin.document.body.innerHTML.replaceAll("flex-col lg:flex-row", "flex-row")
        newWin.document.body.innerHTML = newWin.document.body.innerHTML.replaceAll('<ion-button class="md button button-solid ion-activatable ion-focusable ion-activated">Export to PDF</ion-button>', "")
        newWin.document.body.innerHTML = newWin.document.body.innerHTML.replaceAll('<ion-button class="md button button-solid ion-activatable ion-focusable">Export to PDF</ion-button>', "")
        newWin.document.body.innerHTML = newWin.document.body.innerHTML.replaceAll('transform="translate(300,3)"', 'transform="translate(240,3)"')
        document.getElementById("root").style.display = "block"
        newWin.print()
        newWin.close()
    }, 1500)
}