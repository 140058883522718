import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import { Fragment, useState } from "react"
import { useDispatch } from "react-redux"
import { STATIC_URL } from "../../../../Common/constants"
import { Applications, DLC } from "../../../../Common/Interfaces/Entities"
import Input from "../../../../components/Form/Input"
import { ApplicationActions } from "../../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch } from "../../../../Redux/Store"
import Loading from "../../../../components/Loading"

const PackageItem: React.FC<{
    id: number
    type: "application_id" | "dlc_id"
    dlc_id: number | null
    application_id: number | null
    quantity: number
    application: Applications
    dlcs: Array<DLC>
    appsReducer:ApplicationsState
}> = ({
    id,
    type,
    dlc_id,
    application_id,
    quantity,
    application,
    dlcs,
    appsReducer
}) => {
        const dlc = dlcs.find(d => d.id === dlc_id)
        const dispatch = useDispatch<AppDispatch>()

        const [modal, setModal] = useState<boolean>(false)
        const [qty, setQty] = useState<number>(quantity)
        const [submitted, setSubmitted] = useState(false)

        const [ionAlert] = useIonAlert()

        const handleEdit = e => {
            if (isNaN(qty) || qty < 1) return ionAlert({ header: "Warning!", message: "Quantity must be a positive numerical value", buttons: ["OK"] })

            setSubmitted(true)
            const data = new FormData()
            data.append("id", id.toString())
            data.append("quantity", qty.toString())
            dispatch(ApplicationActions.updatePackageItem({ item: data }))
        }

        const handleDelete = e => {
            setSubmitted(true)
            dispatch(ApplicationActions.deletePackageItem({ arguments: { id : id}}))
        }

        return (
            <div className="w-[180px] bg-white shadow rounded-lg overflow-hidden">
                <Loading isOpen={appsReducer.packages.updateItemStatus === "pending"} message={appsReducer.packages.updateItemMessage} />
                <Loading isOpen={appsReducer.packages.deleteItemStatus === "pending"} message={appsReducer.packages.deleteItemMessage} />
                <IonModal id='package_item-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle>Edit package item</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={e => setModal(false)}>CLOSE</IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <Input
                            placeholder="Enter quantity of selected item"
                            name="scale"
                            handleChange={e => setQty(e.target.value)}
                            type="number"
                            disabled={type === "application_id"}
                            accept="*"
                            value={qty}
                        />
                        <div className="flex items-center justify-center">
                            <IonButton className="block mx-5" onClick={e => handleEdit(e)}>UPDATE</IonButton>
                            <h1 className="text-center">OR</h1>
                            <IonButton color={"danger"} className="block mx-5" onClick={e => handleDelete(e)}>DELETE</IonButton>
                        </div>
                    </IonContent>
                </IonModal>
                {type === "application_id" ? <Fragment>
                    <img style={{
                        "background": application.primary_color
                    }} alt="application cover photo" className="h-[180px] shadow" src={STATIC_URL + application.cover_path} />
                    <h1 className="text-slate-700 font-bold px-1 py-4">{application.name} x{qty}</h1>
                </Fragment> : <Fragment>
                    <img style={{
                        "background": application.primary_color
                    }} className="h-[180px] shadow" alt="dlc cover photo" src={STATIC_URL + dlc.cover_path} />
                    <h1 className="text-slate-700 font-bold px-1 py-4">{dlc.name} x{qty}</h1>
                </Fragment>}
                <IonButton onClick={e => setModal(true)} className="w-[80%] mx-[10%] mb-4">Edit</IonButton>
            </div>
        )
    }

export default PackageItem