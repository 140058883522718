import GeneralInformation from "./GeneralInformation"
import PreviewCanvas from "./PreviewCanvas"

const ManageBrandingJSX = ({
    purchase,
    branding,
    application,
    images,
    image_types,
    dlc_images
}) => (
    <div className="mx-3 lg:mx-4 mt-4 mb-24">
        <h1 className="text-2xl text-slate-700 font-medium mb-4">Manage your branding:</h1>
        
        <GeneralInformation 
            branding={branding}
            purchase={purchase}
            application={application}
        />
        <PreviewCanvas 
            branding={branding}
            dlc_images={images}
            image_types={image_types}
            purchase={purchase}
            dlc_images_red={dlc_images}
        />
    </div>
)

export default ManageBrandingJSX