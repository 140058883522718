import React, { useEffect, useRef, useState, Fragment } from 'react';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { SelectInterface } from '../../Common/Interfaces/Application';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { RiArrowDownSFill } from "react-icons/ri"


function useOutsideAlerter(ref, setShowing) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShowing(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setShowing]);
}

const getValues = (options, value, multiple, displayField, valueField) => {
    if (multiple === false) return options.find(option => option[valueField] === value) === undefined ? [] : [options.find(option => option[valueField] === value)[displayField]]
    return options.filter(option => value.includes(option[valueField])).map(item => item[displayField])
}

const Select: React.FC<SelectInterface> = ({
    multiple,
    options,
    displayField,
    valueField,
    name,
    placeholder,
    value,
    handleChange
}) => {
    const wrapperRef = useRef(null);

    const [showing, setShowing] = useState<boolean>(false)
    const [filter, setFilter] = useState<string>("")
    useOutsideAlerter(wrapperRef, setShowing);

    const values = getValues(options, value, multiple, displayField, valueField)

    const handleSelect = v => {
        if (v === "All") {
            if (value.length === options.length) return handleChange({
                target: {
                    name: name,
                    value: []
                }
            })
            else return handleChange({
                target: {
                    name: name,
                    value: options.map(opt => opt[valueField])
                }
            })
        }
        if (multiple === false) {
            handleChange({
                target: {
                    name: name,
                    value: v
                }
            })
            setShowing(false)
        } else {
            handleChange({
                target: {
                    name: name,
                    value: value.includes(v) ? value.filter(item => item !== v) : [...value, v]
                }
            })
        }
    }
    return (
        <div ref={wrapperRef} className='relative'>

            <IonList className='select-list cursor-pointer'>
                <IonItem className='select-list' onClick={e => setShowing(!showing)}>
                    <IonLabel position="stacked">{placeholder}</IonLabel>
                    <span className='my-2 w-full flex justify-between items-center'>
                        <p className='flex'>
                            {values.length === 0 ? placeholder : null}
                            {values.join(", ")}
                        </p>
                        <RiArrowDownSFill />
                    </span>
                </IonItem>
            </IonList>
            {showing === false ? null : <div className='absolute w-[98.5%] left-2 z-10 mb-2 shadow-lg'>
                {options.length < 10 ? null : <span className='flex'>
                    <input value={filter} onChange={e => setFilter(e.target.value)} className='w-full outline-none p-2 border border-cythero-primary' placeholder='Type to filter...' />
                    <button onClick={e => {
                        setFilter("")
                        handleChange({
                            target: {
                                name: name,
                                value: multiple === true ? [] : undefined
                            }
                        })
                    }} className='bg-cythero-primary text-white min-w-[100px]'>Clear</button>
                </span>}
                <span className='block w-full max-h-64 bg-white overflow-y-auto mt-1 rounded shadow-lg'>
                    {typeof options !== typeof [] ? null :
                        <Fragment>
                            {multiple === false ? null : <p key={"Select All"} className='h-12 flex items-center px-3 cursor-pointer hover:bg-slate-100' onClick={e => handleSelect("All")}>
                                {value.length === options.length ? <MdCheckBox className='w-4 h-4 mr-2 text-cythero-primary' /> : <MdCheckBoxOutlineBlank className='w-4 h-4 text-cythero-primary mr-2' />}
                                Select All
                            </p>}
                            {options?.filter(opt => filter === "" || opt[displayField].toLowerCase().includes(filter.toLowerCase())).map(opt =>
                                <p key={opt[displayField]} className='h-12 flex items-center px-3 cursor-pointer hover:bg-slate-100' onClick={e => handleSelect(opt[valueField])}>
                                    {multiple === true ? value.includes(opt[valueField]) ? <MdCheckBox className='w-4 h-4 mr-2 text-cythero-primary' /> : <MdCheckBoxOutlineBlank className='w-4 h-4 text-cythero-primary mr-2' /> : null}
                                    {opt[displayField]}
                                </p>
                            )}
                        </Fragment>

                    }
                </span>
            </div>}
        </div>
    )
}
export default Select;