import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserState } from "../../../Redux/User/Interface"
import ReleasesJSX from "./ReleasesJSX"
import Loading from "../../../components/Loading"

const Releases: React.FC = ({

}) => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()

    const apps: ApplicationsState = useSelector((state: RootState) => state.apps)
    const user: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(ApplicationActions.readVersions({ arguments: { application_id: id } }))
        dispatch(ApplicationActions.readReleaseTypes({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { id: id } }))
    }, [])

    if (
        apps.version.readStatus !== "fulfilled" ||
        apps.version_types.readStatus !== "fulfilled" ||
        apps.application.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading releases data... Please wait." />
    const releasese = apps.version.versions.filter(v => v !== null && v !== undefined &&  v.application_id === id)
    return (
        <ReleasesJSX
            current={user.current}
            appliation={apps.application.applications[id]}
            releases={releasese}
            release_types={apps.version_types.types}
        />
    )
}

export default Releases