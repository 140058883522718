import Sprayverse from "./Dashboards/Sprayverse/Sprayverse";
import WeldVR from "./Dashboards/WeldVR/WeldVR";
import Overall from "./Dashboards/Overall/Overall";
import Administrator from "./Dashboards/AdministratorDashboard/Administrator";

export default [
    { "application_id": 1, "component": <Sprayverse /> },
    { "application_id": 7, "component": <WeldVR /> },
    { "application_id": 0, "component": <Overall /> },
    { "application_id": -1, "component": <Administrator /> },
]