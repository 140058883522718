
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import CreateSetting from "./CreateSetting"
import SettingsContainerJSX from "./SettingsContainerJSX"
import Loading from "../../components/Loading"

const SettingsContainer: React.FC = props => {

    const dispatch = useDispatch<AppDispatch>()

    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const OrganizationsReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({}))
        dispatch(PurchaseActions.readPurchases({ arguments: { organization_id: UserReducer.current.organization_id } }))
        dispatch(OrganizationActions.readSettingTypes({ arguments: { hashed: true } }))
    }, [])

    if (
        AppsReducer.application.readStatus !== "fulfilled" ||
        ShopReducer.readStatus !== "fulfilled" ||
        OrganizationsReducer.settings.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading settings... Please wait." />

    const filled_settings = OrganizationsReducer.settings.settings.filter(s => [null, undefined].includes(s) === false && s.settings.find(s => s.organization_id === UserReducer.current.organization_id) !== undefined).sort((a, b) => a.id > b.id ? 1 : -1)
    const unfilled_settings = OrganizationsReducer.settings.settings.filter(s => [null, undefined].includes(s) === false && s.settings.find(s => s.organization_id === UserReducer.current.organization_id) === undefined).sort((a, b) => a.id > b.id ? 1 : -1)

    return (
        <SettingsContainerJSX
            filled={filled_settings}
            unfilled={unfilled_settings}
            current={UserReducer.current}
        >
            <CreateSetting
                current={UserReducer.current}
                applications={AppsReducer.application.applications}
                organizationsReducer={OrganizationsReducer}
            />
        </SettingsContainerJSX>
    )
}

export default SettingsContainer