import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { PurchaseActions } from "../../../../Redux/Purchases/Actions"
import { useParams } from "react-router"
import { PurchasesState } from "../../../../Redux/Purchases/Interface"
import Select from "../../../../components/Form/Select"
import { ApplicationActions } from "../../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { IonButton } from "@ionic/react"
import Translate from "../../../../components/Translate"
import { UserState } from "../../../../Redux/User/Interface"
import { UserActions } from "../../../../Redux/User/Actions"

const BulkCreate: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()
    const org_id: number = parseInt(useParams<{ organization_id: string }>().organization_id)
    const PurchaseReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const ApplicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const UsersReducer: UserState = useSelector((state: RootState) => state.user)

    const [submitted, setSubmitted] = useState(false)
    const [users, setUsers] = useState([])

    useEffect(() => {
        dispatch(PurchaseActions.readPurchases({ args: { organization_id: org_id } }))
        dispatch(ApplicationActions.readApplication({ args: {} }))
    }, [])

    const handleSubmit = () => {
        const data = new FormData()
        data.append('users', JSON.stringify(users))
        data.append('organization_id', org_id.toString())

        dispatch(UserActions.createUsers({ users: data }))
        setSubmitted(true)
    }

    const purchased_apps = PurchaseReducer.purchases.filter(p => p !== null && p !== undefined && p.organization_id === org_id && p.confirmed === 1 && p.application_id !== null)

    return (
        <div>
            <div className="bg-white mx-4 mt-4 p-2 rounded-md shadow flex items-center justify-between">
                <h1 className="text-lg font-bold text-slate-800">Bulk Create</h1>
                <input
                    type="number"
                    className="border border-slate-300 rounded-md px-2 py-1"
                    placeholder="Enter number of users"
                    onChange={e => {
                        const val = parseInt(e.target.value)
                        const users = Array.from({ length: val }).map((_, i) => ({
                            organization_id: org_id,
                            type_id: 1,
                            firstName: "",
                            lastName: "",
                            email: "",
                            username: "",
                            password: "",
                            apps_access: purchased_apps.map(p => p.application_id)
                        }))
                        setUsers(users)
                    }}
                />
            </div>
            <div className="w-[calc(100vw-2ren)] relative mt-4 mx-4 p-2 bg-white rounded-md shadow">
                <table className="bg-white w-full">
                    <thead>
                        <tr>
                            <th className="text-center font-bold text-slate-800 py-2 w-[250px]">Email</th>
                            <th className="text-center font-bold text-slate-800 py-2 w-[170px]">First Name</th>
                            <th className="text-center font-bold text-slate-800 py-2 w-[170px]">Last Name</th>
                            <th className="text-center font-bold text-slate-800 py-2 w-[170px]">User Name</th>
                            <th className="text-center font-bold text-slate-800 py-2 w-[170px]">Password</th>
                            <th colSpan={5} className="text-center font-bold text-slate-800 w-[200px] py-2">Apps Access</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, nth) => (
                            <tr key={nth}>
                                <td className="px-1">
                                    <input
                                        className="border border-slate-300 rounded-md p-1 w-full"
                                        onChange={e => setUsers(users.map((u, i) => i === nth ? { ...u, email: e.target.value } : u))}
                                        value={user.email}
                                        type="email"
                                    />
                                </td>
                                <td className="px-1">
                                    <input
                                        className="border border-slate-300 rounded-md p-1"
                                        onChange={e => setUsers(users.map((u, i) => i === nth ? { ...u, firstName: e.target.value } : u))}
                                        value={user.firstName}
                                    />
                                </td>
                                <td className="px-1">
                                    <input
                                        className="border border-slate-300 rounded-md p-1"
                                        onChange={e => setUsers(users.map((u, i) => i === nth ? { ...u, lastName: e.target.value } : u))}
                                        value={user.lastName}
                                    />
                                </td>
                                <td className="px-1">
                                    <input
                                        className="border border-slate-300 rounded-md p-1"
                                        onChange={e => setUsers(users.map((u, i) => i === nth ? { ...u, username: e.target.value } : u))}
                                        value={user.username}
                                    />
                                </td>
                                <td className="px-1">
                                    <input
                                        className="border border-slate-300 rounded-md p-1"
                                        onChange={e => setUsers(users.map((u, i) => i === nth ? { ...u, password: e.target.value } : u))}
                                        value={user.password}
                                        type="password"
                                    />
                                </td>
                                <td className="px-1">
                                    <Select
                                        displayField="name"
                                        valueField="id"
                                        options={purchased_apps.map(p => ({
                                            id: p.application_id,
                                            name: ApplicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === p.application_id)?.name
                                        }))}
                                        value={user.apps_access}
                                        handleChange={e => setUsers(users.map((u, i) => i === nth ? { ...u, apps_access: e.target.value } : u))}
                                        multiple={true}
                                        placeholder="Select Apps"
                                        name="apps_access"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {submitted === true ? <div>
                {UsersReducer.createUserStatus === "pending" ? <Translate label="Loading..." /> :
                UsersReducer.createUserStatus === "fulfilled" ? <Translate label="Users created successfully" /> :
                UsersReducer.createUserStatus === "rejected" ? <Translate label="Error creating users" /> :
                null}
            </div> : <IonButton onClick={e => handleSubmit()} className="my-6 mx-auto block w-[300px]"><Translate label="Submit" /></IonButton>}
        </div>
    )
}

export default BulkCreate