import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { User } from "../../../Common/Interfaces/Entities"
import Select from "../../../components/Form/Select"
import { OrganizationActions } from "../../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../../Redux/Organizations/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import Loading from "../../../components/Loading"
import Translate from "../../../components/Translate"

const AddUser: React.FC<{
    application_id: number
    users: Array<User>
    modal: boolean
    setModal: Function
}> = ({
    application_id,
    users,
    modal,
    setModal
}) => {

    const dispatch = useDispatch<AppDispatch>()
    
    const [userId, setUserId] = useState<number>(null)
    const [submitted, setSubmitted] = useState<boolean>(false)
    
    const OrganizationsReducer:OrganizationsState = useSelector((state:RootState) => state.organizations)

    const [ionAlert] = useIonAlert()

    useEffect(() => {
        if (submitted === false) return
        if (OrganizationsReducer.access.createStatus === "fulfilled") {
            ionAlert({
                "message": "Granted access successfully.",
                "header": "Success.",
                "buttons": ["OK"]
            })
        } else if (OrganizationsReducer.access.createStatus === "rejected") {
            ionAlert({
                "message": OrganizationsReducer.access.createMessage || "Error granting access to user.",
                "header": "Error.",
                "buttons": ["OK"],
            })
        }
    }, [OrganizationsReducer.access.createStatus, OrganizationsReducer.access.createMessage, ionAlert, submitted])

    const handleAccess = e => {
        setSubmitted(true)
        const data = new FormData()
        data.append("application_id", application_id.toString())
        data.append("user_id", userId.toString())

        dispatch(OrganizationActions.grantUserAccess({ access : data }))
    }

    return (
        <IonModal id='grant-access-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
            <Loading isOpen={OrganizationsReducer.access.createStatus === "pending"} message="Granting access to user... Please wait." />
            <IonContent>
                <IonToolbar>
                    <IonTitle><Translate label="Grant Access To Application" /></IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={e => setModal(false)}><Translate label="Close" /></IonButton>
                    </IonButtons>
                </IonToolbar>
                <Select
                    displayField="username"
                    valueField="id"
                    name="user_id"
                    multiple={false}
                    placeholder={(<Translate label={"Select Users"} />)}
                    value={userId}
                    options={users}
                    handleChange={e => setUserId(e.target.value)}
                />
                <IonButton className="block mx-5" onClick={e => handleAccess(e)}><Translate label="GRAND ACCESS" /></IonButton>
            </IonContent>
        </IonModal>
    )
}

export default AddUser