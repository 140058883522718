import { createSlice } from "@reduxjs/toolkit";
import { OrganizationReducers } from "./Actions"
import { OrganizationsState } from "./Interface";


const init: OrganizationsState = {
    organization: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        organizations: []
    },
    license: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        licenses: []
    },
    device: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        devices: [],
    },
    settings: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",

        createSettingStatus: "idle",
        createSettingMessage: "",
        updateSettingStatus: "idle",
        updateSettingMessage: "",

        settings: []
    },
    access: {
        ApplicationAccess: [],

        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
    },

    demoOrganizations: {
        organizations: [],
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
    }
}

const OrganizationSlice = createSlice({
    name: "organizations",
    initialState: init,
    reducers: {
    },
    extraReducers(builder) {
        for (const action in OrganizationReducers) {
            builder.addCase(action, OrganizationReducers[action])
        }
    }
})


export default OrganizationSlice.reducer