import { MdAddToPhotos } from "react-icons/md"
import { NavLink } from "react-router-dom"
import OrganizationCard from "../OrganizationCard"
import { IonFab, IonFabButton } from "@ionic/react"

const BrowseOrganizationsJSX = ({
    search,
    setSearch,
    organizations,
    current
}) => (
    <div>
        <input value={search} onChange={e => setSearch(e.target.value)} placeholder="Search...." type="text" className="block mx-auto px-4 py-2 my-6 w-[92%] max-w-[920px] rounded-md shadow-md" />
        <div className="mx-auto px-4 my-6 mb-20 grid grid-cols-1 lg:grid-cols-2 gap-4 max-w-[950px]">
            {organizations.sort((a, b) => a.id > b.id ? 1 : -1).map(organization => <OrganizationCard key={"org_card_" + organization.id} organization={organization} as_link={true} />)}
            {current.type_id === 4 ?
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <NavLink to="/organization/new">
                        <IonFabButton>
                            <MdAddToPhotos className="text-3xl" />
                        </IonFabButton>
                    </NavLink>
                </IonFab> : null}
        </div>
    </div>
)


export default BrowseOrganizationsJSX