import { MdAddBox } from "react-icons/md"
import Search from "../../Organizations/Users/Search"
import UserCard from "./UserCard"
import Translate from "../../../components/Translate"

const UserAccessBrowserJSX = ({
    app_users,
    setModal,
    search,
    setSearch,
    children
}) => (
    <div className="p-8 bg-white rounded-lg shadow mb-4">
        <h3 className="flex justify-between items-center">
            <p className="text-slate-700 font-medium text-lg"><Translate label="User's access to applicaiton" /></p>
            <MdAddBox onClick={e => setModal(true)} className="text-2xl text-cythero-primary cursor-pointer" />
        </h3>
        <Search search={search} setSearch={setSearch} />
        {app_users.map(u => <UserCard key={u.id} access={u} />)}
        {children}
    </div>
)

export default UserAccessBrowserJSX