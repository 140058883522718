import { buildFromCreator, createHandler } from "../ReduxTemplates"
import { Analytics as SliceInterface } from "./Interface"


const AnalyticsCreator = {
    dashboardReport: createHandler({
        name: "analytics/dashboardReport",
        method: "POST",
        route: "api/reports/dashboard_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.dashboardReport.status = "pending"
            state.dashboardReport.message = "Fetching Dashboard Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.dashboardReport.status = "fulfilled"
            state.dashboardReport.message = "Fetch success"
            state.dashboardReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.dashboardReport.status = "rejected"
            state.dashboardReport.message = action.payload.msg
            return state
        },
    }),

    adminReport: createHandler({
        name: "analytics/adminReport",
        method: "POST",
        route: "api/analytics/admin_reports",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.adminReport.status = "pending"
            state.adminReport.message = "Fetching Dashboard Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.adminReport.status = "fulfilled"
            state.adminReport.message = "Fetch success"
            state.adminReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.adminReport.status = "rejected"
            state.adminReport.message = action.payload.msg
            return state
        },
    }),


    sprayverseReport: createHandler({
        name: "analytics/sprayverseReport",
        method: "POST",
        route: "api/reports/sprayverse_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.sprayverseReport.status = "pending"
            state.sprayverseReport.message = "Fetching Sprayverse Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.sprayverseReport.status = "fulfilled"
            state.sprayverseReport.message = "Fetch success"
            state.sprayverseReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.sprayverseReport.status = "rejected"
            state.sprayverseReport.message = action.payload.msg
            return state
        },
    }),
    userReport: createHandler({
        name: "analytics/userReport",
        method: "POST",
        route: "api/reports/sprayverse_user_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.userReport.status = "pending"
            state.userReport.message = "Fetching User Reports.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.userReport.status = "fulfilled"
            state.userReport.message = "Fetch success"
            state.userReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.userReport.status = "rejected"
            state.userReport.message = action.payload.msg
            return state
        },
    }),
    partReport: createHandler({
        name: "analytics/partReport",
        method: "POST",
        route: "api/reports/sprayverse_part_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.partReport.status = "pending"
            state.partReport.message = "Fetching Part Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.partReport.status = "fulfilled"
            state.partReport.message = "Fetch success"
            state.partReport.data = action.payload.Parts
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.partReport.status = "rejected"
            state.partReport.message = action.payload.msg
            return state
        },
    }),
    usageReport: createHandler({
        name: "analytics/usageReport",
        method: "POST",
        route: "api/reports/usage_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.usageReport.status = "pending"
            state.usageReport.message = "Building Usage Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.usageReport.status = "fulfilled"
            state.usageReport.message = "Report built successfully."
            state.usageReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.usageReport.status = "rejected"
            state.usageReport.message = action.payload.msg
            return state
        },
    }),


    WeldVRReport: createHandler({
        name: "analytics/WeldVRReport",
        method: "POST",
        route: "api/analytics/weld_vr_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.weldingReport.status = "pending"
            state.weldingReport.message = "Fetching WeldVR Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.weldingReport.status = "fulfilled"
            state.weldingReport.message = "Fetch success"
            state.weldingReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.weldingReport.status = "rejected"
            state.weldingReport.message = action.payload.msg
            return state
        },
    }),
    weldingUsageReport: createHandler({
        name: "analytics/weldingUsageReport",
        method: "POST",
        route: "api/reports/welding_sessions_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.weldingUsageReport.status = "pending"
            state.weldingUsageReport.message = "Building Usage Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.weldingUsageReport.status = "fulfilled"
            state.weldingUsageReport.message = "Report built successfully."
            state.weldingUsageReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.weldingUsageReport.status = "rejected"
            state.weldingUsageReport.message = action.payload.msg
            return state
        },
    }),
    weldingUserReport: createHandler({
        name: "analytics/weldingUserReport",
        method: "POST",
        route: "api/reports/welding_user_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.weldingUserReport.status = "pending"
            state.weldingUserReport.message = "Building User Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.weldingUserReport.status = "fulfilled"
            state.weldingUserReport.message = "Report built successfully."
            state.weldingUserReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.weldingUserReport.status = "rejected"
            state.weldingUserReport.message = action.payload.msg
            return state
        },
    }),
    weldingPartReport: createHandler({
        name: "analytics/weldingPartReport",
        method: "POST",
        route: "api/reports/welding_user_report",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.weldingPartReport.status = "pending"
            state.weldingPartReport.message = "Building User Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.weldingPartReport.status = "fulfilled"
            state.weldingPartReport.message = "Report built successfully."
            state.weldingPartReport.data = action.payload
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.weldingPartReport.status = "rejected"
            state.weldingPartReport.message = action.payload.msg
            return state
        },
    }),
    getFarts: createHandler({
        name: "analytics/getParts",
        method: "GET",
        route: "api/reports/get_parts",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.partsData.status = "pending"
            state.partsData.message = "Fetching Part Report.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.partsData.status = "fulfilled"
            state.partsData.message = "Fetch success"
            state.partsData.data = action.payload.parts
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.partsData.status = "rejected"
            state.partsData.message = action.payload.msg
            return state
        },
    }),
    getSessions: createHandler({
        name: "analytics/getSessions",
        method: "GET",
        route: "api/reports/sessions",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.sessions.status = "pending"
            state.sessions.message = "Fetching session data.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.sessions.status = "fulfilled"
            state.sessions.message = "Session loaded"
            state.sessions.data = [...state.sessions.data, ...action.payload.analytics]
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.sessions.status = "rejected"
            state.sessions.message = action.payload.msg
            return state
        },
    }),
    getLabels: createHandler({
        name: "analytics/getLabels",
        method: "GET",
        route: "api/reports/anal_labels",
        key: "filters",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.labels.status = "pending"
            state.labels.message = "Fetching analytics information.. Please wait.."
            return state
        },
        fulfilled: (state:SliceInterface, action) => {
            state.labels.status = "fulfilled"
            state.labels.message = "Analytic labels loaded."
            state.labels.data = action.payload.labels
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.labels.status = "rejected"
            state.labels.message = action.payload.msg
            return state
        },
    }),
}

export const [AnalyticsActions, AnalyticsReducer] = buildFromCreator(AnalyticsCreator)