import { createSlice } from "@reduxjs/toolkit";
import { AnalyticsReducer } from "./Actions"
import { Analytics } from "./Interface";



const init: Analytics = {
    dashboardReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },

    sprayverseReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },
    userReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },
    partReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },
    usageReport: {
        status: "idle", message: "",
        data: [], filters: {}
    },

    weldingReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },
    weldingUsageReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },
    weldingUserReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },
    weldingPartReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },

    adminReport: {
        status: "idle", message: "",
        data: {}, filters: {}
    },


    sessions: {
        status: "idle", message: "",
        data: [], filters: {}
    },
    partsData: {
        status: "idle", message: "",
        data: [], filters: {}
    },
    labels: {
        status: "idle", message: "",
        data: [], filters: {}
    },
}

const analyticsSlice = createSlice({
    name: "user",
    initialState: init,
    reducers: {
        clearReport: (state: Analytics, action) => {
            if (state[action.payload] === undefined) {
                state[action.payload] = {
                    status: "idle",
                    message: "",
                    data: {},
                    filters: {}
                }
                return state
            }
            state[action.payload].data = []
            state[action.payload].status = "idle"
            state[action.payload].message = ""
            return state
        },
        setReportFilters: (state: Analytics, action) => {
            if (state[action.payload.report] === undefined) return state
            state[action.payload.report].filters = action.payload.filters
            return state
        }
    },
    extraReducers(builder) {
        for (const action in AnalyticsReducer) {
            builder.addCase(action, AnalyticsReducer[action])
        }
    }
})

export const { clearReport, setReportFilters } = analyticsSlice.actions
export default analyticsSlice.reducer