import { getTranslation } from "../../../Common/Functions"
import Translate from "../../../components/Translate"


const Search = ({
    search, setSearch
}) => (
    <div className="flex justify-center">
  <div className="mb-3 w-full mt-4">
    <input
      type="search"
      className="
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        focus:text-gray-700 focus:bg-white focus:border-cythero-primary focus:outline-none
      "
      value={search}
      onChange={e => setSearch(e.target.value)}
      placeholder={getTranslation("Search User(s)")}
    />
  </div>
</div>
)

export default Search