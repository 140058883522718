import { IonButton, IonItem, useIonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OrganizationSettings, OrganizationSettingsTypes } from "../../Common/Interfaces/Entities";
import { ApplicationsState } from "../../Redux/Applications/Interface";
import { OrganizationActions } from "../../Redux/Organizations/Actions";
import { OrganizationsState } from "../../Redux/Organizations/Interface";
import { AppDispatch, RootState } from "../../Redux/Store";
import Loading from "../../components/Loading";

const UpdateSetting: React.FC<{ setting: OrganizationSettingsTypes, current: OrganizationSettings, set_rounded: Boolean }> = ({
    setting,
    current,
    set_rounded
}) => {

    const dispatch = useDispatch<AppDispatch>()

    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const OrgReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const current_app = AppsReducer.application.applications.find(a => [null, undefined].includes(a) === false && a.id === setting.application_id)
    const [ionAlert] = useIonAlert()
    const [value, setValue] = useState(current.value)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (submitted === false) return
        ionAlert({
            "message" : "Settings updated successfully.",
            "header" : "Success.",
            "buttons" : [ "OK" ]
        })
        setSubmitted(false)
    }, [submitted, OrgReducer.settings.updateSettingStatus])

    const handleUpdate = e => {
        e.preventDefault()
        setSubmitted(true)

        const data = new FormData()
        data.append("id", current.id.toString())
        data.append("value", value.toString())
        dispatch(OrganizationActions.updateSetting({ setting: data }))
    }

    return (
        <IonItem className={set_rounded ? "rounded-bl rounded-br overflow-hidden w-[950px] mx-auto" : "w-[950px] mx-auto"}>
            <Loading isOpen={submitted && OrgReducer.settings.updateSettingStatus === "pending"} message="Updating settings... Please wait." />
            <span className="w-full flex p-2 items-center justify-between">
                <h1 className="w-3/12 text-slate-700 font-bold">{current_app?.name}</h1>
                <h1 className="w-5/12 text-slate-700 font-semibold">{setting.name}</h1>
                <input onChange={e => {
                    setSubmitted(false)
                    setValue(e.target.value)
                }} value={value} className="w-2/12 p-2 border border-solid border-gray-300 rounded" />
                <IonButton onClick={e => handleUpdate(e)} className="w-1/12">Update</IonButton>
            </span>
        </IonItem>
    )
}

export default UpdateSetting