
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import TableContainer from "../../../../components/Table/TableContainer"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import SprayverseSession from "../SessionCards/SprayverseSession"
import Loading from "../../../../components/Loading"


const UsageReport: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const analytics: Analytics = useSelector((state: RootState) => state.analytics)
    const usageReport = analytics.usageReport
    const [selectedSession, setSelectedSession] = useState(null)
    const [modal, setModal] = useState(false)

    useEffect(() => {
        dispatch(AnalyticsActions.getLabels({ arguments: { hashed: true } }))
    }, [dispatch])

    const handleColumnClick = (row, idx) => {
        if (row["User"] === "~Total") return
        const session_id = row["Session ID"]
        setSelectedSession(session_id)
        setModal(true)
    }

    if (usageReport.status === "idle") return <Fragment></Fragment>
    if (usageReport.status === "pending") return <Loading isOpen={true} message={usageReport.message} />
    if (usageReport.data.table === undefined || usageReport.data.table.Date.length === 0) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4">Not enough data to display this report. Try to select a wider range of dates.</h1>

    let table = {
        "User": {
            idx: 0,
            rows: usageReport.data.table["User"],
            type: "string",
        },
        "Part": {
            idx: 1,
            rows: usageReport.data.table["Part"],
            type: "string",
        },
        "Date": {
            idx: 2,
            rows: usageReport.data.table["Date"],
            type: "date",
        },
        "Paint Used (ml)": {
            idx: 3,
            rows: usageReport.data.table["Paint Used (ml)"],
            type: "number",
        },
        "Total Time Spent (min)": {
            idx: 4,
            rows: usageReport.data.table["Total Time Spent (min)"],
            type: "number",
        },
        "Grade": {
            idx: 5,
            rows: usageReport.data.table["Grade"],
            type: "string",
        },
        "Session ID": {
            idx: 6,
            rows: usageReport.data.table["Session ID"],
            type: "string",
        },
    }
    const hidden_cols = ["Session ID"]

    return (
        <div className="flex flex-col justify-center items-center w-full p-5 gap-6 mb-14">
            <TableContainer
                total="User"
                table={table}
                hide={hidden_cols}
                row_click_handler={handleColumnClick}
                translate_table={true}
            />
            {selectedSession === null ? null : <SprayverseSession
                modal={modal}
                setModal={setModal}
                selectedSession={selectedSession}
            />}
        </div>
    )
}


export default UsageReport