import { Fragment, useEffect, useState } from "react"
import { TbSortAscending2, TbSortDescending2 } from "react-icons/tb"
import Translate from "../Translate"

const transform = table => {
    const ret = []
    for (let i = 0; i < table[Object.keys(table)[0]].rows.length; i++) {
        let obj = {}
        for (const key of Object.keys(table)) {
            obj[key] = table[key].rows[i]
        }
        ret.push(obj)
    }
    return ret
}

const _sort = (rows, sortBy, table, total) => {
    let append_row = null
    if (total !== null) {
        for (let i = 0; i < rows.length; ++i) {
            if (rows[i][total].includes("~Total")) append_row = rows.splice(i, 1)[0]
        }
    }

    if (table[sortBy.key].type === "string") {
        rows = rows.sort((a, b) => a[sortBy.key].localeCompare(b[sortBy.key]))
        if (sortBy.ascending === true) rows = rows.reverse()
    } else if (table[sortBy.key].type === "number") {
        rows = rows.sort((a, b) => parseFloat(a[sortBy.key]) > parseFloat(b[sortBy.key]) ? 1 : -1)
        if (sortBy.ascending === true) rows = rows.reverse()
    } else if (table[sortBy.key].type === "date") {
        rows = rows.sort((a, b) => new Date(a[sortBy.key]).getTime() > new Date(b[sortBy.key]).getTime() ? 1 : -1)
        if (sortBy.ascending === true) rows = rows.reverse()
    }
    if (append_row !== null) rows.unshift(append_row)

    return rows
}

const TableContainer: React.FC<{ total: string, table: Object, hide: Array<string>, row_click_handler: Function, translate_table?: true | false }> = ({
    total,
    table,
    hide,
    row_click_handler,
    translate_table = false
}) => {
    const [sortBy, setSortBy] = useState<{ key: string | null, ascending: boolean }>({ key: null, ascending: true })
    const [rows, setRows] = useState(transform(table))
    const [page, setPage] = useState(1)

    useEffect(() => {
        setRows(transform(table))
    }, [table])

    const sort = (key: string) => {
        let set = { ...sortBy }
        if (sortBy.key === key) set = { ...sortBy, ascending: !sortBy.ascending }
        else set = { key: key, ascending: true }
        setSortBy(set)
        setRows(_sort(rows, set, table, total))
    }

    useEffect(() => {
        if (sortBy.key !== null || total === null) return
        sort(total)
    }, [sort, sortBy.key, total])

    const width = (11.5 * (Object.keys(table).length - hide.length)) + "rem"
    const num_pages = Math.ceil(rows.length / 10)
    return (
        <Fragment>
            <div className="overflow-x-auto mx-auto max-w-[100%]">
                <div className={"header flex border-b-2 border-slate-300"} style={{ minWidth: width }}>
                    {Object.keys(table).map(key => hide.includes(key) ? null : (
                        <p key={key} onClick={e => sort(key)} className="min-w-44 w-44 mx-2 h-12 flex items-center justify-between text-slate-600 cursor-pointer">
                            {translate_table === true ? <Translate label={key} /> : key} {sortBy.key === key ? sortBy.ascending === true ? <TbSortAscending2 /> : <TbSortDescending2 /> : null}
                        </p>
                    ))}
                </div>
                <div className="body" style={{ minWidth: width }}>
                    {total === null ? null : rows.map((row, idx) => row[total].includes("~Total") === true ? <div
                        key={idx}
                        className="row w-full flex my-2 w-[calc(100%-4px)] mx-auto py-2 bg-slate-100 font-bold shadow rounded"
                    >
                        {Object.keys(table).map(key => hide.includes(key) ? null : (
                            <p key={key} className="w-44 mx-2 py-1 text-slate-800">{row[key]}</p>
                        ))}
                    </div> : null)}
                    {rows.filter((_, idx) => (((idx === 0 && page === 1) || idx >= ((page - 1) * 10)) && idx < (page * 10)))
                        .map((row, idx) => total === null || row[total].includes("~Total") === false ? (
                            <div
                                key={idx}
                                className="row w-full flex my-2 py-2 bg-white hover:bg-slate-50 cursor-pointer rounded"
                                onClick={() => row_click_handler(row, idx)}
                            >
                                {Object.keys(table).map(key => hide.includes(key) ? null : (
                                    <p key={key} className="w-44 mx-2 py-1 text-slate-800">{row[key]}</p>
                                ))}
                            </div>
                        ) : null)}
                </div>
            </div>
            <div className='flex items-center justify-center'>
                <div className="flex justify-center items-center space-x-4">
                    <div onClick={e => {
                        if (page - 10 >= 1) setPage(page - 10)
                        else setPage(1)
                    }} className="border rounded-md bg-gray-100 px-2 py-1 text-3xl leading-6 text-slate-400 transition hover:bg-gray-200 hover:text-slate-500 cursor-pointer shadow-sm"> {"<<"} </div>
                    <div onClick={e => {
                        if (page - 1 >= 1) setPage(page - 1)
                    }} className="border rounded-md bg-gray-100 px-2 py-1 text-3xl leading-6 text-slate-400 transition hover:bg-gray-200 hover:text-slate-500 cursor-pointer shadow-sm"> {"<"} </div>
                    <div className="text-slate-500">{page} / {num_pages}</div>
                    <div onClick={e => {
                        if (page + 1 <= num_pages) setPage(page + 1)
                    }} className="border rounded-md bg-gray-100 px-2 py-1 text-3xl leading-6 text-slate-400 transition hover:bg-gray-200 hover:text-slate-500 cursor-pointer shadow-sm"> {">"} </div>
                    <div onClick={e => {
                        if (page + 10 <= num_pages) setPage(page + 10)
                        else setPage(num_pages)
                    }} className="border rounded-md bg-gray-100 px-2 py-1 text-3xl leading-6 text-slate-400 transition hover:bg-gray-200 hover:text-slate-500 cursor-pointer shadow-sm"> {">>"} </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TableContainer
