import "./Chart.css"

const ChartContainer = ({
    maxHeight,
    maxWidth,
    data,
    title,
    Chart,
    extraJsx = null,
    properties = null,
    stacked = false,
    bg = "bg-white"
}) => (
    <div
        className={`${maxWidth === undefined ? bg + " w-full" : bg + ""} chart-container rounded-lg print:shadow-sm shadow overflow-hidden w-full h-full flex-colve`}
        style={{
            "maxWidth": maxWidth === undefined ? "unset" : (maxWidth - 60) + "px",
            "minHeight": maxHeight + "px"
        }}
    >
        {title.length === 0 ? null : <div className="">
            <div className="w-full p-4 pb-0">
                <h3 className="text-slate-700 font-semibold text-lg px-3 pt-2">{title}</h3>
            </div>
        </div>}
        <div className="flex w-full px-[10px] pb-4 items-center overflow-x-auto orflow-y-showing pt-8 print:pt-4">
            <span className={maxWidth === undefined ? "w-full" : ""} style={{
                "height": maxHeight + "px",
                "minWidth": maxWidth === undefined ? "unset" : (maxWidth - 100) + "px",
                "maxWidth": maxWidth === undefined ? "unset" : (maxWidth - 80) + "px"
            }}> <Chart increaseMl={title === "Engagement per organization per application"} stacked={stacked} properties={properties} data={data} /></span>
        </div>
        {extraJsx}
    </div>
)

export default ChartContainer