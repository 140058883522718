import React, { useEffect, useState } from "react"
import LoginJSX from "./LoginJSX"
import { UserActions } from "../../../Redux/User/Actions"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { Redirect } from "react-router-dom"

const Login: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const [user, setUser] = useState({ email: "", password: "" })

    const [submitted, setSubmitted] = useState<Boolean>(false)
    const [redirect, setRedirect] = useState<string | null>(null)
    const userSlice = useSelector((state:RootState) => state.user)

    const handleSubmit = (e: any) => {
        e.preventDefault()
        setSubmitted(true)

        const form = new FormData()
        form.append("email", user.email)
        form.append("password", user.password)
        form.append("from_web", "true")
        dispatch(UserActions.login({ "user": form }))
    }

    useEffect(() => {
        if (submitted === false) return
        if (userSlice.loginStatus === "fulfilled") {
            setTimeout(() => {
                setRedirect("/home")
            }, 1000)
        }
    }, [userSlice])

    const handleChange = (e:any) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setSubmitted(false)
    }

    return redirect === null ? <LoginJSX
        user={user}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitted={submitted}
        userSlice={userSlice}
    /> : <Redirect to={redirect} />

}

export default Login