
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { Applications, Organization } from "../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import BrandingProfiles from "./BrandingManagement/BrandingProfiles"
import DevicesList from "./ApplicationDevices/DevicesList"
import PurchaseHistory from "../Organizations/PurchaseHistory/PurchaseHistory"
import ApplicationInfo from "./ApplicationInfo"
import ApplicationSettingsJSX from "./ApplicationSettings/ApplicationSettingsJSX"
import UserAccessBrowser from "./UserAccess/UserAccessBrowser"
import Loading from "../../components/Loading"

const ApplicationPage: React.FC = () => {
    const app_id: number = parseInt(useParams<{ org_id: string, app_id: string }>().app_id)
    const org_id: number = parseInt(useParams<{ org_id: string, app_id: string }>().org_id)
    const dispatch = useDispatch<AppDispatch>()

    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const OrganizationReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: { id: app_id } }))
        dispatch(ApplicationActions.readPackages({ arguments: { hashed: true } }))
        dispatch(OrganizationActions.readSettingTypes({ arguments: { hashed: true } }))
        dispatch(OrganizationActions.readDevice({ arguments: { hashed: true } }))
        dispatch(OrganizationActions.getUserAccess({ arguments: { } }))
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ arguments: {organization_id : UserReducer.current.organization_id } }))
    }, [app_id, dispatch])

    if (
        OrganizationReducer.settings.readStatus !== "fulfilled" ||
        OrganizationReducer.device.readStatus !== "fulfilled" ||
        OrganizationReducer.access.readStatus !== 'fulfilled' ||
        AppsReducer.application.readStatus !== "fulfilled" ||
        AppsReducer.packages.readStatus !== "fulfilled" ||
        UserReducer.readUserStatus !== "fulfilled" ||
        ShopReducer.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading application data... Please wait." />

    const application: Applications = AppsReducer.application.applications[app_id]
    const organization: Organization = OrganizationReducer.organization.organizations[org_id]

    const filled_settings = OrganizationReducer.settings.settings.filter(s => [null, undefined].includes(s) === false && s.application_id === app_id && s.settings.find(s => s.organization_id === org_id) !== undefined).sort((a, b) => a.id > b.id ? 1 : -1)
    const unfilled_settings = OrganizationReducer.settings.settings.filter(s => [null, undefined].includes(s) === false && s.application_id === app_id && s.settings.find(s => s.organization_id === org_id) === undefined).sort((a, b) => a.id > b.id ? 1 : -1)

    return (
        <div className="mx-3 lg:mx-4 mt-6 mb-24">
            <div className="w-full max-w-[950px] mx-auto my-10">
                <ApplicationInfo application={application} />
                <BrandingProfiles organization={organization} application_id={application.id} />
                <PurchaseHistory organization={organization} application_id={application.id} />
                <DevicesList organization={organization} application_id={application.id} />
                <UserAccessBrowser 
                    UserReducer={UserReducer}
                    OrganizationReducer={OrganizationReducer}
                    organization_id={org_id}
                    application_id={app_id}
                />
                <ApplicationSettingsJSX 
                    filled={filled_settings}
                    unfilled={unfilled_settings}
                    organization_id={org_id}
                />
            </div>
        </div>
    )
}

export default ApplicationPage