import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { Fragment, useEffect } from "react"
import { TailSpin } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { print_pdf } from "../../../../Common/print"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { UserState } from "../../../../Redux/User/Interface"
import BarChart from "../../Charts/BarChart"
import ChartContainer from "../../Charts/ChartContainer"
import Translate from "../../../../components/Translate"
import { getTranslation } from "../../../../Common/Functions"

const grades = ["", "F", "D", "C", "B", "A"]

const SprayverseSession: React.FC<{
    modal: boolean
    setModal: Function
    selectedSession: string
}> = ({
    modal, setModal, selectedSession
}) => {
        const dispatch = useDispatch<AppDispatch>()
        const app_id: number = parseInt(useParams<{ id: string }>().id)

        const analytics: Analytics = useSelector((state: RootState) => state.analytics)
        const applicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
        const UserReducer: UserState = useSelector((state: RootState) => state.user)

        const current_app = applicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === 1)
        const getSessionLabel = (session, label) => analytics.sessions.data.find(s => s.session_id === session && s.label_id === label)

        useEffect(() => {
            if (selectedSession === null) return
            const session_exists = analytics.sessions.data.find(s => s.session_id === selectedSession) !== undefined
            if (session_exists === false) dispatch(AnalyticsActions.getSessions({ arguments: { session_id: selectedSession } }))
        }, [selectedSession, analytics.sessions.data, dispatch])

        const user = UserReducer.users.find(u => u !== null && u !== undefined && u.id === analytics.sessions.data.find(s => s.session_id === selectedSession)?.user_id)
        const session_type = getSessionLabel(selectedSession, 112)?.score === "0" ? "new" : "old"
        return (
            <IonModal id='painting-session-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
                <IonContent>
                    <IonToolbar>
                        <IonTitle><Translate label="Session Info" /></IonTitle>
                        <IonButtons slot="end">
                            <IonButton color="light" onClick={() => setModal(false)}>
                                <Translate label="Close" />
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                    <div className="p-2 bg-slate-100">
                        <span className={analytics.sessions.status === "pending" || analytics.sessions.data.find(s => s.session_id === selectedSession) === undefined ? "flex justify-center items-center  h-[680px]" : ""}>
                            <TailSpin
                                height="30"
                                width="30"
                                color="#4fa94d"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={analytics.sessions.status === "pending"}
                            />
                        </span>
                        {analytics.sessions.status === "pending" || analytics.sessions.data.find(s => s.session_id === selectedSession) === undefined ? null : <Fragment>
                            <div id="usage-report-print" className="max-w-[820px] print:w-[820px] mx-auto">
                                <h1 className="text-lg text-slate-800 text-center mb-2">{user.username} <Translate label="painted" /> <b>{getSessionLabel(selectedSession, 124)?.score}</b> (<b>{getSessionLabel(selectedSession, 122)?.score}</b>)</h1>
                                <div className="flex mb-2 flex-col print:flex-row md:flex-row justify-between mx-auto gap-2 items-center">
                                    <ChartContainer
                                        maxHeight={170}
                                        maxWidth={460}
                                        data={[
                                            {
                                                [getTranslation("Coverage")]: getTranslation("Primer"),
                                                [getTranslation("Low Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 108)?.score)),
                                                [getTranslation("Low Coverage")+"Color"]: "#3b82f6",
                                                [getTranslation("Good Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 107)?.score)),
                                                [getTranslation("Good Coverage")+"Color"]: "#10b981",
                                                [getTranslation("High Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 109)?.score)),
                                                [getTranslation("High Coverage")+"Color"]: "#f87171",
                                            },
                                            {
                                                [getTranslation("Coverage")]: getTranslation("Base"),
                                                [getTranslation("Low Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 114)?.score)),
                                                [getTranslation("Low Coverage")+"Color"]: "#3b82f6",
                                                [getTranslation("Good Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 113)?.score)),
                                                [getTranslation("Good Coverage")+"Color"]: "#10b981",
                                                [getTranslation("High Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 115)?.score)),
                                                [getTranslation("High Coverage")+"Color"]: "#f87171",
                                            },
                                            {
                                                [getTranslation("Coverage")]: getTranslation(session_type === "new" ? "Top Coat" : "Clear"),
                                                [getTranslation("Low Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 120)?.score)),
                                                [getTranslation("Low Coverage")+"Color"]: "#3b82f6",
                                                [getTranslation("Good Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 119)?.score)),
                                                [getTranslation("Good Coverage")+"Color"]: "#10b981",
                                                [getTranslation("High Coverage")]: Math.round(parseFloat(getSessionLabel(selectedSession, 121)?.score)),
                                                [getTranslation("High Coverage")+"Color"]: "#f87171",
                                            },
                                            {
                                                [getTranslation("Coverage")]: getTranslation("Overall"),
                                                [getTranslation("Low Coverage")]: Math.round((
                                                    parseFloat(getSessionLabel(selectedSession, 108)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 114)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 120)?.score)
                                                ) / 3),
                                                [getTranslation("Low Coverage")+"Color"]: "#3b82f6",
                                                [getTranslation("Good Coverage")]: Math.round((
                                                    parseFloat(getSessionLabel(selectedSession, 107)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 113)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 119)?.score)
                                                ) / 3),
                                                [getTranslation("Good Coverage")+"Color"]: "#10b981",
                                                [getTranslation("High Coverage")]: Math.round((
                                                    parseFloat(getSessionLabel(selectedSession, 109)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 115)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 121)?.score)
                                                ) / 3).toFixed(2),
                                                [getTranslation("High Coverage")+"Color"]: "#f87171",
                                            }
                                        ].filter((v, idx) => !(idx === 1 && session_type === "new"))}
                                        Chart={BarChart}
                                        title={<Translate label="Coverage Graph" />}
                                        properties={{
                                            axisLeft: {
                                                legend: getTranslation('Coverage') + " (%)",
                                                legendOffset: -40,
                                                legendPosition: 'middle'
                                            },
                                            keys: [
                                                getTranslation("Low Coverage"),
                                                getTranslation("Good Coverage"),
                                                getTranslation("High Coverage"),
                                            ],
                                            indexBy: getTranslation("Coverage"),
                                            colors: ["#3b82f6", "#10b981", "#f87171"]
                                        }}
                                    />
                                    <ChartContainer
                                        maxHeight={170}
                                        maxWidth={460}
                                        data={[
                                            {
                                                [getTranslation("Time")]: "",
                                                [getTranslation("Primer")]: (getSessionLabel(selectedSession, 104)?.score / 60).toFixed(2),
                                                [getTranslation("Primer")+"Color"]: "#3b82f6",
                                                [getTranslation("Base")]: (getSessionLabel(selectedSession, 110)?.score / 60).toFixed(2),
                                                [getTranslation("Base")+"Color"]: "#10b981",
                                                [getTranslation(session_type === "new" ? "Top Coat" : "Clear")]: (getSessionLabel(selectedSession, 116)?.score / 60).toFixed(2),
                                                [getTranslation(session_type === "new" ? "Top Coat" : "Clear")+"Color"]: "#f87171",
                                            },
                                        ]}
                                        Chart={BarChart}
                                        title={<Translate label="Engagement Graph" />}
                                        properties={{
                                            axisLeft: {
                                                legend: getTranslation('Engagement (min)'),
                                                legendOffset: -25,
                                                legendPosition: 'middle'
                                            },
                                            keys: session_type === "old" ? [
                                                getTranslation("Primer"),
                                                getTranslation("Base"),
                                                getTranslation("Clear"),
                                            ] : [
                                                getTranslation("Primer"),
                                                getTranslation("Top Coat"),
                                            ],
                                            indexBy: getTranslation("Time"),
                                            colors: ["#3b82f6", "#10b981", "#f87171"],
                                            layout: "horizontal"
                                        }}
                                    />
                                </div>
                                <div className="flex mb-2 gap-2 flex-col print:flex-row md:flex-row justify-between items-center w-full print:w-[820px] md:w-[820px] mx-auto mt-2">
                                    <div className={`mx-auto w-full ${session_type === "new" ? "md:w-1/2" : "md:w-1/3"} bg-white px-4 py-2 rounded-lg shadow mx-auto`}>
                                        <h3 className="text-slate-600 font-medium text-lg"><Translate label="Primer Coat Info" /></h3>
                                        <span>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Time Spent (min)" /></span>: <span className="text-slate-600">{(getSessionLabel(selectedSession, 104)?.score / 60).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Material Used" /> (ml)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 105)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Grade" /></span>: <span className="text-slate-600">{getSessionLabel(selectedSession, 106)?.score}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Low Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 108)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Good Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 107)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="High Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 109)?.score).toFixed(2)}</span></p>
                                        </span>
                                    </div>
                                    {session_type === "new" ? null : <div className="mx-auto w-full md:w-1/3 bg-white px-4 py-2 rounded-lg shadow mx-auto">
                                        <h3 className="text-slate-600 font-medium text-lg"><Translate label="Base Coat Info" /></h3>
                                        <span>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Time Spent (min)" /></span>: <span className="text-slate-600">{(getSessionLabel(selectedSession, 110)?.score / 60).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Material Used" /> (ml)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 111)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Grade" /></span>: <span className="text-slate-600">{getSessionLabel(selectedSession, 112)?.score}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Low Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 114)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Good Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 113)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="High Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 115)?.score).toFixed(2)}</span></p>
                                        </span>
                                    </div>}
                                    <div className={`mx-auto w-full ${session_type === "new" ? "md:w-1/2" : "md:w-1/3"} bg-white px-4 py-2 rounded-lg shadow`}>
                                        <h3 className="text-slate-600 font-medium text-lg"><Translate label="Clear Coat Info" /></h3>
                                        <span>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Time Spent (min)" /></span>: <span className="text-slate-600">{(getSessionLabel(selectedSession, 116)?.score / 60).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Material Used" /> (ml)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 117)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Grade" /></span>: <span className="text-slate-600">{getSessionLabel(selectedSession, 118)?.score}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Low Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 120)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="Good Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 119)?.score).toFixed(2)}</span></p>
                                            <p className="text-base"><span className="text-slate-900"><Translate label="High Coverage" /> (%)</span>: <span className="text-slate-600">{parseFloat(getSessionLabel(selectedSession, 121)?.score).toFixed(2)}</span></p>
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-2 w-full bg-white px-4 py-2 rounded-lg shadow mx-auto">
                                    <h3 className="text-slate-600 font-medium text-lg"><Translate label="Overall Info" />:</h3>
                                    <span className="grid grid-cols-1 print:grid-cols-2 md:grid-cols-2">
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Time Spent (min)" /></span>: <span className="text-slate-600">
                                            {
                                                ((
                                                    parseFloat(getSessionLabel(selectedSession, 104)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 110)?.score) +
                                                    parseFloat(getSessionLabel(selectedSession, 116)?.score)
                                                ) / 60).toFixed(2)
                                            }
                                        </span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Material Used" /> (ml)</span>: <span className="text-slate-600">
                                            {(parseFloat(getSessionLabel(selectedSession, 105)?.score) + parseFloat(getSessionLabel(selectedSession, 111)?.score) + parseFloat(getSessionLabel(selectedSession, 117)?.score)).toFixed(2)}
                                        </span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Grade" /></span>: <span className="text-slate-600">
                                            {session_type === "new" ? grades[Math.round(
                                                (
                                                    (grades.indexOf(getSessionLabel(selectedSession, 106)?.score)) +
                                                    (grades.indexOf(getSessionLabel(selectedSession, 118)?.score))
                                                ) / 2
                                            )] : grades[Math.round(
                                                (
                                                    (grades.indexOf(getSessionLabel(selectedSession, 106)?.score)) +
                                                    (grades.indexOf(getSessionLabel(selectedSession, 112)?.score)) +
                                                    (grades.indexOf(getSessionLabel(selectedSession, 118)?.score))
                                                ) / 3
                                            )]}
                                        </span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Low Coverage" /> (%)</span>: <span className="text-slate-600">{
                                            ((
                                                parseFloat(getSessionLabel(selectedSession, 108)?.score) +
                                                parseFloat(getSessionLabel(selectedSession, 114)?.score) +
                                                parseFloat(getSessionLabel(selectedSession, 120)?.score)
                                            ) / (session_type === "new" ? 2 : 3)).toFixed(2)
                                        }</span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="Good Coverage" /> (%)</span>: <span className="text-slate-600">{
                                            ((
                                                parseFloat(getSessionLabel(selectedSession, 107)?.score) +
                                                parseFloat(getSessionLabel(selectedSession, 113)?.score) +
                                                parseFloat(getSessionLabel(selectedSession, 119)?.score)
                                            ) / (session_type === "new" ? 2 : 3)).toFixed(2)
                                        }</span></p>
                                        <p className="text-base"><span className="text-slate-900"><Translate label="High Coverage" /> (%)</span>: <span className="text-slate-600">{
                                            ((
                                                parseFloat(getSessionLabel(selectedSession, 109)?.score) +
                                                parseFloat(getSessionLabel(selectedSession, 115)?.score) +
                                                parseFloat(getSessionLabel(selectedSession, 121)?.score)
                                            ) / (session_type === "new" ? 2 : 3)).toFixed(2)
                                        }</span></p>
                                    </span>
                                </div>
                            </div>
                            <span className="flex items-center justify-center my-5">
                                <IonButton onClick={e => print_pdf(document.getElementById("usage-report-print").cloneNode(true), "Usage Report", current_app)}><Translate label="EXPORT TO PDF" /></IonButton>
                            </span>
                        </Fragment>}
                    </div>
                </IonContent>
            </IonModal>
        )
    }

export default SprayverseSession