import React, { useEffect, useState } from "react"
import RegisterJSX from "./RegisterJSX"
import { UserActions } from "../../../Redux/User/Actions"
import { setUserState } from "../../../Redux/User/Slice"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { validateEmail } from "../../../Common/Functions"
import { Redirect } from "react-router-dom"


type RegisterTypes = "user" | "organization" | null

const Register: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
    })

    const [submitted, setSubmitted] = useState<Boolean>(false)
    const [redirect, setRedirect] = useState<string | null>(null)
    const [registerType, setRegisterType] = useState<RegisterTypes>(null)
    const userSlice = useSelector((state: RootState) => state.user)

    useEffect(() => {
        if (submitted === false) return
        if (userSlice.registerStatus === "fulfilled") {
            setTimeout(() => {
                if (registerType === "user") setRedirect("/dashboard")
                else if (registerType === "organization") setRedirect("/organization/new")
            }, 1000)
        }
    }, [dispatch, userSlice, submitted, registerType])

    const handleSubmit = (e: any, type: RegisterTypes) => {
        e.preventDefault()
        setSubmitted(true)
        setRegisterType(type)

        // if (user.firstName.length < 3) return dispatch(setUserState({ registerStatus: "warning", registerMessage: "Enter more descriptive firstname" }))
        // if (user.lastName.length < 3) return dispatch(setUserState({ registerStatus: "warning", registerMessage: "Enter more descriptive lastname" }))
        if (validateEmail(user.email) === null) return dispatch(setUserState({ registerStatus: "warning", registerMessage: "Enter valid email address" }))
        if (user.username.length < 5) return dispatch(setUserState({ registerStatus: "warning", registerMessage: "Enter more descriptive username" }))
        if (user.password.length < 5) return dispatch(setUserState({ registerStatus: "warning", registerMessage: "Enter more secure password (5+ letters)" }))
        if (user.password !== user.confirmPassword) return dispatch(setUserState({ registerStatus: "warning", registerMessage: "Passwords do not match" }))

        const form = new FormData()
        form.append("firstName", user.firstName)
        form.append("from_web", "true")
        form.append("lastName", user.lastName)
        form.append("email", user.email)
        form.append("username", user.username)
        form.append("password", user.password)
        dispatch(UserActions.register({ "user": form }))
    }

    const handleChange = (e: any) => {
        setUser({ ...user, [e.target.name]: e.target.value })
        setSubmitted(false)
    }

    return redirect === null ? <RegisterJSX
        user={user}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        submitted={submitted}
        userSlice={userSlice}
    /> : <Redirect to={redirect} />

}

export default Register