import { useDispatch, useSelector } from "react-redux"
import { STATIC_URL } from "../../Common/constants"
import { Organization } from "../../Common/Interfaces/Entities"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { IonButton } from "@ionic/react"
import { NavLink } from "react-router-dom"
import { UserActions } from "../../Redux/User/Actions"
import Translate from "../../components/Translate"

const OrganizationCard: React.FC<{ organization: Organization, as_link: true | false }> = ({
    organization,
    as_link = false
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const userReducer: UserState = useSelector((state: RootState) => state.user)
    const manager = userReducer.users.find(u => u !== null && u !== undefined && u.organization_id === organization.id && u.type_id >= 3)

    const logInAs = e => {
        // return
        // e.preventDefault()
        const data = new FormData()
        data.append("organization_id", organization.id.toString())
        dispatch(UserActions.loginAs({ user: data }))
    }

    if (as_link === true) return (
        <NavLink to={"/organization/" + organization.id}>
            <div className="px-8 py-4 bg-white rounded-lg shadow mb-4 h-32 flex">
                <span className="flex items-center justify-center mr-5 w-1/5">
                    <img className="w-full h-auto" src={STATIC_URL + organization.profile_picture} alt="organization profile" />
                </span>
                <span className="col-span-2 flex flex-col justify-center w-full">
                    <h1 className="text-md font-bold text-slate-600">{organization.name}</h1>
                    <h1 className="text-sm text-slate-600 -mt-1">{organization.email}</h1>
                    <h3 className="text-sm text-slate-600 mb-2 -mt-1"><Translate label="Managed By" />: {manager === undefined ? "No Manager" : manager.username}</h3>
                    {userReducer.current.type_id === 4 ? <IonButton routerLink="/dashboard/0" onClick={e => logInAs(e)}><p className="py-2">Log In As</p></IonButton> : null}
                </span>
            </div>
        </NavLink>
    )

    return (
        <div className="px-8 py-4 bg-white rounded-lg shadow mb-4 h-32 flex">
            <span className="flex items-center justify-center mr-5 w-1/5">
                <img className="w-full h-auto" src={STATIC_URL + organization.profile_picture} alt="organization profile" />
            </span>
            <span className="col-span-2 flex flex-col justify-center w-full">
                <h1 className="text-md font-bold text-slate-600">{organization.name}</h1>
                <h1 className="text-sm text-slate-600 -mt-1">{organization.email}</h1>
                <h3 className="text-sm text-slate-600 mb-2 -mt-1"><Translate label="Managed By" />: {manager === undefined ? "No Manager" : manager.username}</h3>
                {userReducer.current.type_id === 4 ? <IonButton href="/dashboard/0" onClick={e => logInAs(e)}><p className="py-2">Log In As</p></IonButton> : null}
            </span>
        </div>
    )
}

export default OrganizationCard