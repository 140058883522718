import React, { Fragment, useEffect, useState } from "react"
import { useIonAlert } from "@ionic/react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import CreateDlcJSX from "./CreateDlcJSX"
import Loading from "../../../components/Loading"

const CreateDlc: React.FC = () => {
    const application_id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const [infoAlert] = useIonAlert()

    const apps: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [submitted, setSubmitted] = useState(false)
    const [dlc, setDlc] = useState({
        name:"",
        description:"",
        dlc_type_id: null,
        dlc_cover: null,
        branding_scene: null,
        price: null,
        multiple_purchase: 0,
        subscription_duration: null
    })

    useEffect(() => {
        dispatch(ApplicationActions.readDlcTypes({ arguments: { hashed: true } }))
    }, [dispatch])

    useEffect(() => {
        if (submitted === false) return
        if (apps.dlc.createStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: apps.dlc.createMessage,
        })
        if (apps.dlc.createStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: apps.dlc.createMessage,
        })
    }, [apps.dlc.createStatus, apps.dlc.createMessage, submitted, infoAlert])

    const handleSubmit = () => {
        if(dlc.dlc_type_id === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select DLC type.'
        })
        if (dlc.name.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a more descriptive name'
        })
        if (dlc.description.length < 60) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please specify more information in description (60 - 500 letters)'
        })
        if (dlc.description.length > 1000) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Notes too long (please enter less than 500 letters)'
        })
        if(dlc.dlc_cover === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select DLC cover photo.'
        })
        if(dlc.dlc_type_id === 3 && dlc.branding_scene === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select branding scene photo.'
        })
        if(dlc.price === null || isNaN(parseInt(dlc.price))) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Price must be a numerical value.'
        })
        if(dlc.dlc_type_id === 5 && dlc.subscription_duration === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'You must enter subscription duration.'
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("application_id", application_id.toString())
        data.append("dlc_type_id", dlc.dlc_type_id.toString())
        data.append("name", dlc.name)
        data.append("description", dlc.description)
        data.append("dlc_cover", dlc.dlc_cover)
        if (dlc.dlc_type_id === 3) data.append("branding_scene", dlc.branding_scene)
        data.append("price", dlc.price.toString())
        data.append("subscription_duration", dlc.subscription_duration.toString())
        data.append("multiple_purchase", dlc.multiple_purchase.toString())

        dispatch(ApplicationActions.createDlc({ dlc: data }))
    }

    if (apps.dlc_types.readStatus !== "fulfilled") 
        return <Loading isOpen={true} message="Loading DLCs info... Please wait." />

    return (
        <Fragment>
            {apps.dlc.createStatus === "pending" ? <Loading isOpen={true} message="Creating new DLC... Please wait." /> : null}
            {dlc === null ? null : <CreateDlcJSX
                appliation={apps.application.applications[application_id]}
                dlc_types={apps.dlc_types.types}
                dlc={dlc}
                setDlc={setDlc}
                handleSubmit={handleSubmit}
            />}
        </Fragment>
    )
}

export default CreateDlc