import { IonButton, IonContent, IonModal } from "@ionic/react"
import { Fragment, useState } from "react"
import { User } from "../../../../Common/Interfaces/Entities"
import SprayverseSession from "../../../Analytics/Reports/SessionCards/SprayverseSession"
import Translate from "../../../../components/Translate"
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md"

const UserSessions: React.FC<{
    sessions: Array<{
        application_id: number
        average_grade: string
        part: string
        session_end: string
        session_id: string
        session_start: string
        time_spent: number
        user_id: number
    }>
    user: User
    setSelectrUser: Function
}> = ({
    sessions,
    user,
    setSelectrUser
}) => {
        const [selectedSession, setSelectedSession] = useState(null)
        const [modal, setModal] = useState(false)
        const [sort, setSort] = useState<string>('session_start')
        const [ascend, setAscend] = useState<number>(-1)
        return (
            <Fragment>
                <IonModal id='session-info-container' trigger="open-modal" isOpen={user !== null} onDidDismiss={e => setSelectrUser(null)}>
                    <IonContent>
                        <div>
                            <div className="flex fixed w-full">
                                <span className="text-center block w-3/12 text-white bg-slate-800 py-2 cursor-pointer flex items-center justify-center" onClick={e => {
                                    setSort("session_start")
                                    if (sort === "session_start") setAscend((ascend * -1))
                                }}><Translate label="Date" /> {sort === "session_start" ? ascend === 1 ? <MdArrowDropDown className="text-lg" /> : <MdArrowDropUp className="text-lg" /> : null} </span>
                                <span className="text-center block w-5/12 text-white bg-slate-800 py-2 cursor-pointer flex items-center justify-center" onClick={e => {
                                    setSort("time_spent")
                                    if (sort === "time_spent") setAscend((ascend * -1))
                                }}><Translate label="Paint Time" /> {sort === "time_spent" ? ascend === 1 ? <MdArrowDropDown className="text-lg" /> : <MdArrowDropUp className="text-lg" /> : null}</span>
                                <span className="text-center block w-2/12 text-white bg-slate-800 py-2 cursor-pointer flex items-center justify-center" onClick={e => {
                                    setSort("part")
                                    if (sort === "part") setAscend((ascend * -1))
                                }}><Translate label="Part" /> {sort === "part" ? ascend === 1 ? <MdArrowDropDown className="text-lg" /> : <MdArrowDropUp className="text-lg" /> : null}</span>
                                <span className="text-center block w-2/12 text-white bg-slate-800 py-2 cursor-pointer flex items-center justify-center" onClick={e => {
                                    setSort("average_grade")
                                    if (sort === "average_grade") setAscend((ascend * -1))
                                }}><Translate label="Grade" /> {sort === "average_grade" ? ascend === 1 ? <MdArrowDropDown className="text-lg" /> : <MdArrowDropUp className="text-lg" /> : null}</span>
                            </div>
                            <div className="pt-10">
                                {sessions.sort((a, b) => a[sort] > b[sort] ? 1 * ascend : -1 * ascend).map(session => (
                                    <div className="flex hover:bg-slate-200 cursor-pointer" onClick={e => {
                                        setSelectedSession(session.session_id)
                                        setModal(true)
                                    }}>
                                        <span className="py-2 text-center block w-3/12 text-slate-500 font-bold shadow">{session.session_start.slice(0, 16)}</span>
                                        <span className="py-2 text-center block w-5/12 text-slate-500 font-bold shadow">
                                            {Math.floor(session.time_spent) > 0 ? Math.floor(session.time_spent) + " min" : null} {Math.round((session.time_spent - Math.floor(session.time_spent)) * 60) + " sec"}
                                        </span>
                                        <span className="py-2 text-center block w-2/12 text-slate-500 font-bold shadow">{session.part}</span>
                                        <span className="py-2 text-center block w-2/12 text-slate-500 font-bold shadow">{session.average_grade}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="py-4">
                            <IonButton color="primary" className="block w-1/3 mx-auto" onClick={() => setSelectrUser(null)}>
                                <Translate label="Close" />
                            </IonButton>
                        </div>
                    </IonContent>
                </IonModal>
                <SprayverseSession
                    selectedSession={selectedSession}
                    modal={modal}
                    setModal={setModal}
                />
            </Fragment>
        )
    }

export default UserSessions