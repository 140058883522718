import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../Common/constants"
import { BsChevronCompactRight, BsHeadsetVr, BsWindows } from "react-icons/bs"
import { IonButton, IonFab, IonFabButton, IonFabList } from "@ionic/react"
import { BsGearFill } from "react-icons/bs"
import DlcCard from "./DlcCard"
import { FaShoppingCart } from "react-icons/fa"
import PackageCard from "./PackageCard"
import { getTranslation } from "../../../Common/Functions"
import Translate from "../../../components/Translate"

const ApplicationScreenJSX = ({
    current,
    price,
    cover_path,
    description,
    id,
    logo_path,
    name,
    primary_color,
    supported_platforms,
    versions,
    dlcs,
    dlc_types,
    version_types,
    product_owned,
    product_in_cart,
    packages,
    handleCart
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700">
            <NavLink className="hover:underline" to="/applications">Applications</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/" + id}>{name}</NavLink>
        </span>
        <div className="w-full mx-auto mt-6 bg-white rounded-xl shadow-md overflow-hidden">
            <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 lg:w-1/3 flex items-center justify-center" style={{ "background": primary_color }}>
                    <img className="w-full" src={STATIC_URL + cover_path} alt="Application Cover" />
                </div>
                <div className="md:flex-1 p-4">
                    <span className="flex gap-4 items-center mb-8">
                        <img className="w-24 h-24" src={STATIC_URL + logo_path} alt="application logo" />
                        <span>
                            <h2 className="mb-1 leading-tight tracking-tight font-bold text-gray-800 text-2xl md:text-3xl">{name}</h2>
                            <p className="text-gray-700 text-sm">By: <a href="https://www.cythero.com" target="blank" className="text-cythero-primary hover:underline">Cythero VR</a></p>
                            <p className="text-gray-700 text-sm flex items-center gap-2">Platforms: {supported_platforms.includes("Desktop") ? <BsWindows /> : null} {supported_platforms.includes("Mobile") ? <BsHeadsetVr /> : null}</p>
                            <p className="text-gray-700 text-sm">Price: €{price} EUR</p>
                        </span>
                    </span>
                    <span className="block ml-2 my-4">
                        <p className="text-gray-700 mb-4">{description}</p>

                        {versions[versions.length - 1] === undefined ? <h3 className="text-slate-700">Stay tuned for first release!</h3> : <div>
                            <p className="text-gray-700 flex gap-2">Latest Version: <NavLink to={"/applications/releases/" + id} className="text-cythero-primary hover:underline">{versions[versions.length - 1].version}</NavLink> </p>
                            <p className="text-gray-700 flex gap-2">Release Type: {version_types[versions[versions.length - 1].version_type_id].name} </p>
                            <p className="text-gray-700 flex gap-2">Release Notes: {versions[versions.length - 1].notes} </p>
                        </div>}
                    </span>
                    {product_owned === false ?
                        <IonButton onClick={e => handleCart(e)} className="bg-cythero-primary text-lg mt-2"><FaShoppingCart className="mr-4" />{product_in_cart === undefined ? getTranslation("ADD TO CART") : getTranslation("REMOVE FROM CART")}</IonButton> :
                        <p className="ml-2 mb-3 text-cythero-primary font-bold"><Translate label="You own this product" />.</p>
                    }
                </div>
            </div>
        </div>
        <div className="w-full mx-auto">
            <h1 className="my-4 text-slate-700 mx-4">DLCs:</h1>
            <span className="flex flex-wrap gap-8">
                {dlcs.length === 0 ?
                    "There are no DLCs available for this application" :
                    dlcs.map(d =>
                        <DlcCard
                            key={d.id}
                            dlc_types={dlc_types}
                            dlc={d}
                            application_id={id}
                            current={current}
                        />
                    )}
            </span>
        </div>

        <div className="w-full mx-auto">
            <h1 className="my-4 text-slate-700 mx-4">Packages:</h1>
            <span className="flex flex-wrap gap-8">
                {packages.length === 0 ?
                    "There are no packages available for this application" :
                    packages.map(p =>
                        <PackageCard
                            key={p.id}
                            _package={p}
                            application_id={id}
                            current={current}
                        />
                    )}
            </span>
        </div>
        
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton>
                <BsGearFill className="text-3xl" />
            </IonFabButton>
            <IonFabList side="start">
                <NavLink className="mx-2 px-2 py-1 bg-cythero-primary text-slate-50 rounded-2xl shadow-lg" to={"/applications/dlcs/" + id}>DLCs</NavLink>
                <NavLink className="mx-2 px-2 py-1 bg-cythero-primary text-slate-50 rounded-2xl shadow-lg" to={"/applications/releases/" + id}>Releases</NavLink>
                {current.type_id === 4 ? <NavLink className="mx-2 px-2 py-1 bg-cythero-primary text-slate-50 rounded-2xl shadow-lg" to={"/applications/packages/" + id}>Packages</NavLink> : null}
                {current.type_id === 4 ? <NavLink className="mx-2 px-2 py-1 bg-cythero-primary text-slate-50 rounded-2xl shadow-lg" to={"/applications/edit/" + id}>Edit</NavLink> : null}
            </IonFabList>
        </IonFab>
    </div>
)

export default ApplicationScreenJSX