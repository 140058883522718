import { useState } from "react"
import { useSelector } from "react-redux"
import { Organization, Purchase } from "../../../Common/Interfaces/Entities"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { RootState } from "../../../Redux/Store"
import BrandingProfilesJSX from "./BrandingProfilesJSX"


const BrandingProfiles: React.FC<{ organization: Organization, application_id: number | null }> = ({
    organization,
    application_id
}) => {
    const purchasesReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const [showMore, setShowMore] = useState(false)
    const [search, setSearch] = useState("")

    const organization_purchases: Array<Purchase> = purchasesReducer.purchases.filter((p: Purchase) =>
        p !== null &&
        p !== undefined &&
        p.confirmed === 1 &&
        p.organization_id === organization.id &&
        (
            search === "" ||
            (
                p.custom_identifier !== null &&
                p.custom_identifier.toLowerCase().includes(search.toLowerCase())
            )
        ) &&
        p.product.type.includes("branding") &&
        (application_id === null || p.product.application_id === application_id)
    ).reverse()
    if (organization_purchases.length === 0 && search.length === 0) return <div></div>
    return <BrandingProfilesJSX
        loading={purchasesReducer.readStatus !== "fulfilled"}
        purchases={showMore === true ? organization_purchases : [...organization_purchases].splice(0, 5)}
        count={organization_purchases.length}
        showMore={showMore}
        setShowMore={setShowMore}
        search={search}
        setSearch={setSearch}
    />
}

export default BrandingProfiles