import { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { app_analytics } from "../../Common/constants"
import { userOwnsProduct } from "../../Common/Functions"
import { Applications, Purchase, User } from "../../Common/Interfaces/Entities"


const UserOwnsAnalytics:React.FC<{application: Applications, purchases:Array<Purchase>, user: User}> = ({application, purchases, user, children}) => {
    const owned = userOwnsProduct(purchases, user, "dlc", app_analytics[application.id])
    if (owned === false && user?.allow_analytics !== true) return (
        <div className="bg-cythero-primary text-white font-bold gap-2 text-center p-5">
            <h1 className="px-3 pb-2">You don't have access to the {application.name} analytics and reports.</h1>
            <h1 className="px-3 pb-8">Please purchase the analytics extension in order to access the reporting system.</h1>
            <NavLink className=" bg-white text-cythero-primary p-2 rounded" to={"/applications/"+application.id}>Go to shop</NavLink>
        </div>
    )

    return <Fragment> {children} </Fragment>
}

export default UserOwnsAnalytics