import { IonButton } from "@ionic/react"
import { BsChevronCompactRight } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import Input from "../../../../components/Form/Input"

const EditApplicationPackageJSX = ({
    _package,
    setPackage,
    dlcs,
    application,
    handleUpdate
}) => (
    <div className="py-6 mx-auto mb-4 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/packages/" + application.id}>{application.name} Packages</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/edit_package/" + _package.id}>Edit Package</NavLink>
        </span>
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">Update Package</h1>
            <span className="w-full block pb-10 px-4">
                <Input
                    name="name"
                    value={_package.name}
                    type="text"
                    placeholder="Package Name"
                    handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.value })}
                />
                <Input
                    name="description"
                    value={_package.description}
                    type="textarea"
                    placeholder="Enter description about this package"
                    handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.value })}
                />
                <Input
                    name="price"
                    value={_package.price}
                    type="number"
                    placeholder="Enter package price in EUR"
                    handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.value })}
                />
                <Input accept="image/*" name="cover" handleChange={e => setPackage({ ..._package, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select package cover photo" />
                <IonButton className="mt-5" onClick={e => handleUpdate(e)}>Update package</IonButton>
            </span>
        </div>
    </div>
)

export default EditApplicationPackageJSX