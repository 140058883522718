import { IonButton, IonContent, IonFab, IonHeader, IonModal } from "@ionic/react"
import { useEffect, useState } from "react"
import { AiFillCloseSquare } from "react-icons/ai"
import { MdAddToPhotos } from "react-icons/md"
import Select from "../../components/Form/Select"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import Loading from "../../components/Loading"

const CreateDemoOrganization: React.FC<{}> = ({ }) => {
    const dispatch = useDispatch<AppDispatch>()

    const ApplicationsReducer:ApplicationsState = useSelector((state:RootState) => state.apps)
    const OrganizationReducer:OrganizationsState = useSelector((state:RootState) => state.organizations)

    const [open, setOpen] = useState(false)
    const [updated, setUpdated] = useState({
        name: "",
        num_devices: 0,
        application_id: null
    })

    useEffect(() => {
        if (OrganizationReducer.demoOrganizations.createStatus === "fulfilled") setOpen(false)
    }, [OrganizationReducer.demoOrganizations.createStatus])

    useEffect(() => {
        if (open === false) setUpdated({
            name: "",
            num_devices: 0,
            application_id: null
        })
    }, [open])

    const handleSubmit = e => {
        e.preventDefault()
        const data = new FormData()
        data.append("name", updated.name)
        data.append("num_devices", updated.num_devices.toString())
        data.append("application_id", updated.application_id.toString())
        dispatch(OrganizationActions.createDemoOrganization({ organization: data }))
    }

    return (
        <div>
            <Loading isOpen={OrganizationReducer.demoOrganizations.createStatus === "pending"} message={OrganizationReducer.demoOrganizations.createMessage} />
            <IonFab vertical="bottom" horizontal="end" >
                <IonButton onClick={e => setOpen(true)}>
                    <MdAddToPhotos className="text-3xl" />
                </IonButton>
            </IonFab>

            <IonModal isOpen={open} onWillDismiss={e => setOpen(false)}>
                <IonHeader>
                    <div className="bg-cythero-primary p-2 text-white font-bold flex justify-between items-center">
                        <p>CREATE A NEW DEMO ORGANIZATION</p>
                        <AiFillCloseSquare className="text-2xl cursor-pointer" onClick={e => setOpen(false)} />
                    </div>
                </IonHeader>
                <IonContent>
                    <div className="w-full h-full bg-slate-50 p-4">
                        <h1 className="text-slate-800 font-bold mb-4">Enter the required details for this demo organization</h1>

                        <label className="font-bold text-slate-800 text-sm">Organization Name</label>
                        <input placeholder="Enter organizaiton name" className="mb-2 border border-slate-400 w-full rounded-md px-2 py-1" type="text" value={updated.name} onChange={e => setUpdated({ ...updated, name: e.target.value })} />

                        <label className="font-bold text-slate-800 text-sm">Number of seats (devics)</label>
                        <input placeholder="Enter number of seats" className="mb-2 border border-slate-400 w-full rounded-md px-2 py-1" type="number" value={updated.num_devices} onChange={e => setUpdated({ ...updated, num_devices: parseInt(e.target.value) })} />
                        
                        <Select
                            displayField="name"
                            handleChange={e => setUpdated({...updated, application_id: e.target.value})}
                            multiple={false}
                            name="Select Application"
                            options={ApplicationsReducer.application.applications.filter(a => a !== undefined && a !== null)}
                            placeholder="Select an application"
                            value={updated.application_id}
                            valueField="id"
                        />
                        <button onClick={e => handleSubmit(e)} className="bg-cythero-primary text-white font-bold px-2 py-1 rounded block ml-auto mt-4">Create</button>

                        {OrganizationReducer.demoOrganizations.createStatus === "rejected" ? <span className="p-1 text-white font-bold block mt-2 rounded-md text-center bg-red-500">{OrganizationReducer.demoOrganizations.createMessage}</span> : null}

                    </div>
                </IonContent>
            </IonModal>
        </div>
    )
}

export default CreateDemoOrganization