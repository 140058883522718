const Loading: React.FC<{ isOpen: true | false, message: string }> = ({ isOpen, message }) => isOpen === false ? null : (
    <div className="fixed top-0 left-0 bg-slate-900/20 w-screen h-screen flex items-center justify-center" style={{"zIndex" : "10000"}}>
        <div className="bg-white shadow-md rounded p-4 flex items-center justify-center gap-4 max-w-[300px]">
            <div
                className="inline-block min-h-[30px] min-w-[30px] animate-spin rounded-full border-2 border-solid border-cythero-primary border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status">
            </div>
            <p>{message}</p>
        </div>
    </div>
)

export default Loading