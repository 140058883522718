import { createSlice } from "@reduxjs/toolkit";
import { LanguageReducers } from "./Actions"
import { LanguagesState } from "./Interface";


const init: LanguagesState = {
    languages: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        languages: []
    },
    translations: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        translations: {}
    }
}

const LanguageSlice = createSlice({
    name: "language",
    initialState: init,
    reducers: { },
    extraReducers(builder) {
        for (const action in LanguageReducers) {
            builder.addCase(action, LanguageReducers[action])
        }
    }
})


export default LanguageSlice.reducer