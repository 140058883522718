import { BsChevronCompactRight, BsFillPencilFill } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import { Fragment } from "react"
import { IonButton, IonFab } from "@ionic/react"
import { BiAddToQueue } from "react-icons/bi"



const ReleasesJSX = ({
    current,
    appliation,
    releases,
    release_types
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700 mb-6">
            <BsChevronCompactRight className="mr-2" />
            <NavLink to={"/applications/" + appliation.id}>{appliation.name}</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/releases/" + appliation.id}>Releases</NavLink>
        </span>
        <div>
            {releases.length === 0 ? <h1 className="text-slate-700 font-bold text-lg my-4">No releases available yet. Stay tuned.</h1> : <Fragment>
                {releases.map(r => <span className="block bg-white my-3 p-5 rounded-lg shadow" key={r.id}>
                    <span className="flex justify-between">
                        <h1 className="text-lg text-cythero-primary ">{appliation.name} v{r.version}</h1>
                        {current.type_id === 4 ? <NavLink to={"/applications/release/edit/" + r.id}><BsFillPencilFill className="text-cythero-primary" /></NavLink> : null}
                    </span>
                    <p className="text-slate-600 text-sm mb-5">{release_types[r.version_type_id].name} release</p>
                    <p className="text-slate-800">{r.notes}</p>
                </span>).reverse()}
            </Fragment>}
        </div>

        {current.type_id === 4 ? <IonFab vertical="bottom" horizontal="end" >
            <IonButton>
                <NavLink to={"/applications/releases/create/" + appliation.id}><BiAddToQueue className="text-3xl" /></NavLink>
            </IonButton>
        </IonFab> : null}

    </div>
)

export default ReleasesJSX