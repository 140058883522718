import { Fragment } from "react"
import { NavLink } from "react-router-dom"
import Info from "../../../components/Info/Info"


const LoginJSX = props => {

    return (
        <Fragment>
            <div className="text-center lg:mt-12">
                <h2 className="text-4xl font-bold text-center text-gray-200 lg:text-gray-700"><span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup></h2>

                <p className="mt-3 text-gray-200 lg:text-gray-500 ">Sign in to access your account</p>
            </div>

            <div className="mt-8">
                <form onSubmit={e => props.handleSubmit(e)}>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Email Address</label>
                        <input
                            value={props.user.email}
                            onChange={e => props.handleChange(e)}
                            type="email"
                            name="email"
                            placeholder="centaurus@example.com"
                            className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>

                    <div className="mt-6">
                        <div className="flex justify-between mb-2">
                            <label htmlFor="password" className="text-sm text-gray-200 lg:text-gray-600">Password</label>
                            <NavLink to="/auth/forgot_password" className="text-sm text-gray-200 lg:text-gray-400 focus:text-cythero-primary hover:text-cythero-primary hover:underline">Forgot password?</NavLink>
                        </div>

                        <input
                            value={props.user.password}
                            onChange={e => props.handleChange(e)}
                            type="password"
                            name="password"
                            placeholder="Your Password"
                            className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>

                    <div className="mt-6">
                        {props.submitted === false ? <button
                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cythero-primary rounded-md hover:bg-cythero-light focus:outline-none focus:bg-cythero-light focus:ring focus:ring-cythero-light focus:ring-opacity-50">
                            Sign in
                        </button> : <Info type={props.userSlice.loginStatus} message={props.userSlice.loginMessage} />}
                    </div>

                </form>

                {/* <p className="mt-6 text-sm text-center text-gray-200 lg:text-gray-400">Don't have an account yet? <NavLink to="/auth/register" className="text-cythero-light lg:text-cythero-primary focus:outline-none focus:underline hover:underline font-bold">Sign up</NavLink>.</p> */}
            </div>
        </Fragment>
    )
}

export default LoginJSX