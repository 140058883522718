import { IonItem, IonPopover, useIonAlert } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { BsTrashFill } from "react-icons/bs";
import { FaEllipsisV } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux";
import { UserApplicationAccess } from "../../../Common/Interfaces/Entities";
import Confirmation, { ConfirmationInterface } from "../../../components/Confirmation/Confirmation";
import { OrganizationActions } from "../../../Redux/Organizations/Actions";
import { OrganizationsState } from "../../../Redux/Organizations/Interface";
import { AppDispatch, RootState } from "../../../Redux/Store";
import { UserState } from "../../../Redux/User/Interface";
import Loading from "../../../components/Loading";
import { getTranslation } from "../../../Common/Functions";


const UserCard: React.FC<{ access: UserApplicationAccess }> = ({ access }) => {
    const dispatch = useDispatch<AppDispatch>()
    const [popover, togglePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [infoAlert] = useIonAlert()
    const [showAlert, setShowAlert] = useState<ConfirmationInterface>({ open: false, function: null, message: "" })

    const userReducer:UserState = useSelector((state:RootState) => state.user)
    const OrganizationReducer:OrganizationsState = useSelector((state:RootState) => state.organizations)
    const user = userReducer.users.find(u => u !== null && u !== undefined && u.id === access.user_id)

    useEffect(() => {
        if (submitted === false) return
        setSubmitted(false)
        togglePopover({ open: false, event: undefined })
        if (OrganizationReducer.access.deleteStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: OrganizationReducer.access.deleteMessage,
        })
        if (OrganizationReducer.access.deleteStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: OrganizationReducer.access.deleteMessage || "Unexpected error occurred.",
        })
    }, [OrganizationReducer.access.deleteStatus, OrganizationReducer.access.deleteMessage, infoAlert, submitted])

    const handleDelete = () => {
        setSubmitted(true)
        dispatch(OrganizationActions.removeUserAccess({ arguments: { id: access.id } }))
    }

    return (
        <div className="grid grid-cols-12 border-solid border-b-2 border-slate-100 py-2 mb-2">
            <Confirmation setShowAlert={setShowAlert} showAlert={showAlert} />
            <BiUserCircle className="col-span-1 w-8 h-10 text-slate-800" />
            <Loading 
                isOpen={submitted === true && OrganizationReducer.access.deleteStatus === "pending"}
                message="Removing user access.. Please wait." />

            <span className="col-span-10 ml-3">
                <p className="text-base font-bold text-slate-600"> {(user.username)}</p>
                <p className="text-sm text-slate-600 -mt-1"> {user.email} ({userReducer.userTypes.types[user.type_id]?.name})</p>
            </span>

            <span className="col-span-1 flex items-center justify-end text-slate-800 cursor-pointer">
                {userReducer.current.type_id >= user.type_id ? <FaEllipsisV onClick={e => togglePopover({ open: true, event: e.nativeEvent })} /> : null}
            </span>

            {userReducer.current.type_id >= user.type_id ? <IonPopover
                onDidDismiss={e => togglePopover({ open: false, event: undefined })}
                isOpen={popover.open} event={popover.event}
                size="auto" side="bottom" alignment="center">

                {userReducer.current.type_id >= 3 ? <IonItem className="text-red-500 cursor-pointer hover:text-red-600" detail={false} onClick={e => {
                    setShowAlert({
                        open: true, function: handleDelete,
                        message: getTranslation("Are you sure you want to delete this user's access from this application?")
                    })
                }}>
                    <BsTrashFill className="mr-3" />
                    <p>{getTranslation("Remove Access")}</p>
                </IonItem> : null}

            </IonPopover> : null}
        </div>
    )
}



export default UserCard