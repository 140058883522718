import { IonButton, IonButtons, IonContent, IonModal, IonTitle, IonToolbar, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { MdAddToPhotos } from "react-icons/md"
import { useDispatch } from "react-redux"
import { Applications, DLC } from "../../../../Common/Interfaces/Entities"
import Input from "../../../../components/Form/Input"
import Select from "../../../../components/Form/Select"
import { ApplicationActions } from "../../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch } from "../../../../Redux/Store"
import Loading from "../../../../components/Loading"


const PackageItemCreate: React.FC<{
    appsReducer: ApplicationsState
    package_id: number
    application: Applications
    dlcs: Array<DLC>
}> = ({
    appsReducer,
    package_id,
    application,
    dlcs
}) => {
        const dispatch = useDispatch<AppDispatch>()
        const [modal, setModal] = useState(false)
        const [submitted, setSubmitted] = useState(false)
        const [ionAlert] = useIonAlert()

        const [item, setItem] = useState<{
            type: "dlc_id" | "application_id"
            dlc_id: number | null
            application_id: number | null
            quantity: number
        }>({
            type: "dlc_id",
            dlc_id: null,
            application_id: null,
            quantity: 1,
        })

        useEffect(() => {
            if (submitted === false) return
            if (appsReducer.packages.createItemStatus === "fulfilled") {
                ionAlert({ "message": "Package Item successfully added to the list", "header": "Success", "buttons": ["OK"] })
                setItem({
                    type: "dlc_id",
                    dlc_id: null,
                    application_id: null,
                    quantity: 1,
                })
            }
            if (appsReducer.packages.createItemStatus === "rejected") ionAlert({ "message": appsReducer.packages.createItemMessage, "header": "Error!", "buttons": ["OK"] })
        }, [submitted, appsReducer.packages.createItemStatus,appsReducer.packages.createItemMessage, ionAlert])

        const handleSubmit = e => {
            if (item.dlc_id === null && item.application_id === null) return ionAlert({ message: "Please select Application or DLC as a product. ", header: "Warning!", buttons: ["OK"] })
            if (isNaN(item.quantity) || item.quantity < 1) return ionAlert({ message: "Quantity must be a positive number greater than 1 ", header: "Warning!", buttons: ["OK"] })

            setSubmitted(true)
            const data = new FormData()
            data.append("package_id", package_id.toString())
            data.append("quantity", item.quantity.toString())
            if (item.type === "dlc_id") data.append("dlc_id", item.dlc_id.toString())
            else if (item.type === "application_id") data.append("application_id", item.application_id.toString())
            dispatch(ApplicationActions.createPackageItem({ item: data }))
        }


        return (
            <div className="w-[180px] flex items-center justify-center">
                <Loading isOpen={appsReducer.packages.createItemStatus === "pending"} message={appsReducer.packages.createItemMessage} />

                <IonModal id='create_package_item-modal-container' isOpen={modal} onDidDismiss={e => setModal(false)}>
                    <IonContent>
                        <IonToolbar>
                            <IonTitle>Create package item</IonTitle>
                            <IonButtons slot="end">
                                <IonButton onClick={e => setModal(false)}>CLOSE</IonButton>
                            </IonButtons>
                        </IonToolbar>
                        <Select
                            value={item.type}
                            multiple={false}
                            options={["Application", "DLC"].map(i => ({ name: i, value: i.toLowerCase() + "_id" }))}
                            displayField="name"
                            valueField="value"
                            name="type"
                            placeholder="Select product type"
                            handleChange={e => setItem({
                                ...item, type: e.target.value, dlc_id: null, application_id: null
                            })}
                        />
                        <Select
                            value={item.type === "application_id" ? item.application_id : item.type === "dlc_id" ? item.dlc_id : null}
                            multiple={false}
                            options={item.type === "application_id" ? [application] : item.type === "dlc_id" ? dlcs : []}
                            displayField="name"
                            valueField="id"
                            name="product_id"
                            placeholder="Select product"
                            handleChange={e => setItem({
                                ...item,
                                dlc_id: null,
                                application_id: null,
                                [item.type]: e.target.value
                            })}
                        />
                        <Input
                            name="quantity"
                            value={item.quantity}
                            type="number"
                            placeholder="Enter number of products in package"
                            handleChange={e => setItem({ ...item, quantity: e.target.value })}
                            disabled={item.type === "application_id"}
                            accept="*/*"
                        />
                        <IonButton className="block mx-5" onClick={e => handleSubmit(e)}>CREATE</IonButton>
                    </IonContent>
                </IonModal>


                <div className="w-[140px] h-[140px] flex flex-col items-center justify-center rounded bg-white shadow cursor-pointer" onClick={e => setModal(true)}>
                    <MdAddToPhotos className="text-6xl text-cythero-primary" />
                    <h1 className="text-slate-700 font-bold mt-2">Add New</h1>
                </div>
            </div>
        )
    }

export default PackageItemCreate