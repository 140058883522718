import { IonButton, IonItem, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { OrganizationSettingsTypes } from "../../Common/Interfaces/Entities"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import Loading from "../../components/Loading"

const SetNewSetting: React.FC<{ setting: OrganizationSettingsTypes, organization_id: number, set_rounded:Boolean }> = ({
    setting,
    organization_id,
    set_rounded
}) => {

    const dispatch = useDispatch<AppDispatch>()

    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const OrgReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const current_app = AppsReducer.application.applications.find(a => [null, undefined].includes(a) === false && a.id === setting.application_id)
    const [ionAlert] = useIonAlert()
    const [value, setValue] = useState("")
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        if (submitted === false) return
        ionAlert({
            "message": "Settings updated successfully.",
            "header": "Success.",
            "buttons": ["OK"]
        })
        setSubmitted(false)
    }, [submitted, OrgReducer.settings.createSettingStatus])

    const handleUpdate = e => {
        e.preventDefault()
        setSubmitted(true)
        if (value.length === 0) return ionAlert({
            message: "Please enter a proper value based on the setting type",
            header: "Warning",
            buttons: ["OK"]
        })
        const data = new FormData()
        data.append("value", value.toString())
        data.append("organization_id", organization_id.toString())
        data.append("setting_id", setting.id.toString())
        dispatch(OrganizationActions.createSetting({ setting: data }))
    }

    return (
        <IonItem className={set_rounded ? "rounded-bl rounded-br overflow-hidden w-[950px] mx-auto" : "w-[950px] mx-auto"}>
            <Loading isOpen={submitted && OrgReducer.settings.createSettingStatus === "pending"} message="Updating settings... Please wait." />
            <span className="w-full flex p-2 items-center justify-between">
                <h1 className="w-3/12 text-slate-700 font-bold">{current_app.name}</h1>
                <h1 className="w-5/12 text-slate-700 font-semibold">{setting.name}</h1>
                <input onChange={e => {
                    setSubmitted(false)
                    setValue(e.target.value)
                }} value={value} placeholder={"Def: " + setting._default} className="w-2/12 p-2 border border-solid border-gray-300 rounded" />
                <IonButton onClick={e => handleUpdate(e)} className="w-1/12">Update</IonButton>
            </span>
        </IonItem>
    )
}

export default SetNewSetting