import { createSlice } from "@reduxjs/toolkit";
import { ApplicationReducers } from "./Actions"
import { ApplicationsState } from "./Interface";





const init: ApplicationsState = {
    application: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        applications: []
    },
    packages: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        createItemStatus: "idle",
        createItemMessage: "",
        updateItemStatus: "idle",
        updateItemMessage: "",
        deleteItemStatus: "idle",
        deleteItemMessage: "",
        packages: []
    },
    version: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        versions: []
    },
    dlc: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        dlcs: [],
    },
    dlc_image: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        dlc_images: [],
    },
    image_types: {
        createStatus: "idle",
        createMessage: "",
        readStatus: "idle",
        readMessage: "",
        updateStatus: "idle",
        updateMessage: "",
        deleteStatus: "idle",
        deleteMessage: "",
        types: [],
    },
    dlc_types: {
        readStatus: "idle",
        readMessage: "",
        types: [],
    },
    platforms: {
        readStatus: "idle",
        readMessage: "",
        platforms: [],
    },
    version_types: {
        readStatus: "idle",
        readMessage: "",
        types: [],
    },
}

const ApplicationsSlice = createSlice({
    name: "apps",
    initialState: init,
    reducers: {
    },
    extraReducers(builder) {
        for(const action in ApplicationReducers) {
            builder.addCase(action, ApplicationReducers[action])
        }
    }
})


export default ApplicationsSlice.reducer