import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../Redux/Store"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { PurchasesState } from "../../Redux/Purchases/Interface"
import { UserState } from "../../Redux/User/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { UserActions } from "../../Redux/User/Actions"
import { PurchaseActions } from "../../Redux/Purchases/Actions"

import PurchasesContainerJSX from "./PurchasesContainerJSX"
import { Purchase } from "../../Common/Interfaces/Entities"
import { ApplicationActions } from "../../Redux/Applications/Actions"
import { ApplicationsState } from "../../Redux/Applications/Interface"
import { useLocation } from "react-router"
import Loading from "../../components/Loading"
import { getTranslation } from "../../Common/Functions"

export const getPrice = (purchases: Array<Purchase>, apps: ApplicationsState, order_number: string) => {
    const packages = []
    let price = 0
    for (const pur of purchases) {
        if (pur.order_number.startsWith(order_number) === false) continue
        if (pur.package_id !== null) {
            if (packages[pur.package_id] === undefined) packages[pur.package_id] = []
            if (packages[pur.package_id].includes(pur.order_number) === false) packages[pur.package_id].push(pur.order_number)
        } else {
            if (pur.dlc_id !== null) price += apps.dlc.dlcs.find(d => d !== null && d !== undefined && d.id === pur.dlc_id).price
            if (pur.application_id !== null) price += apps.application.applications.find(a => a !== null && a !== undefined && a.id === pur.application_id).price
        }
    }

    for (let i = 0; i < packages.length; i++) {
        let item = packages[i]
        if (item === undefined) continue
        let pkg = apps.packages.packages.find(p => p !== null && p !== undefined && p.id === i)
        price += pkg.price * item.length
    }
    return price
}


const PurchasesContainer: React.FC = props => {

    const dispatch = useDispatch<AppDispatch>()
    const location: string = useLocation<{ pathname: string }>().pathname

    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const OrganizationsReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)

    const [redirect, setRedirect] = useState<string>("")

    useEffect(() => {
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ arguments: { hashed: true } }))
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readPackages({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
    }, [dispatch])


    if (
        OrganizationsReducer.organization.readStatus !== "fulfilled" ||
        ShopReducer.readStatus !== "fulfilled" ||
        UserReducer.readUserStatus !== "fulfilled" ||
        AppsReducer.application.readStatus !== "fulfilled" ||
        AppsReducer.dlc.readStatus !== "fulfilled" ||
        AppsReducer.packages.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading purchases data... Please wait." />


    let purchases: Array<Purchase>
    if (UserReducer.current.type_id === 4) purchases = ShopReducer.purchases.filter((p: Purchase) => p !== null && p !== undefined)
    else if ([2, 3].includes(UserReducer.current.type_id)) purchases = ShopReducer.purchases.filter((p: Purchase) => p !== null && p !== undefined && p.organization_id === UserReducer.current.organization_id)
    else purchases = ShopReducer.purchases.filter((p: Purchase) => p !== null && p !== undefined && p.user_id === UserReducer.current.id)


    const orders = {}

    for (const pur of purchases) {
        const key = pur.order_number.split("/")[0]
        if (orders[key] === undefined) orders[key] = {
            confirmed: pur.confirmed === 1 ? "Yes" : "No",
            purchase_date: pur.purchase_date,
            price: getPrice(purchases, AppsReducer, key),
            customer: pur.organization_id !== null ? OrganizationsReducer.organization.organizations[pur.organization_id] : UserReducer.users[pur.user_id],
            customer_type: pur.organization_id !== null ? "organization" : "user",
            order_number: key,
            purchases: []
        }
        orders[key] = { ...orders[key], purchases: [...orders[key].purchases, pur] }

    }

    const table = {
        [getTranslation("Customer")]: {
            rows: Object.keys(orders).map(key => orders[key].customer.name || orders[key].customer.username),
            idx: 0,
            type: "string",
        },
        [getTranslation("Customer Type")]: {
            rows: Object.keys(orders).map(key => orders[key].customer_type),
            idx: 1,
            type: "string",
        },
        [getTranslation("Purchase Date")]: {
            rows: Object.keys(orders).map(key => orders[key].purchase_date),
            idx: 2,
            type: "date",
        },
        [getTranslation("Order Number")]: {
            rows: Object.keys(orders).map(key => orders[key].order_number),
            idx: 3,
            type: "string",
        },
        [getTranslation("Price")]: {
            rows: Object.keys(orders).map(key => orders[key].price),
            idx: 4,
            type: "number",
        },
        [getTranslation("Confirmed")]: {
            rows: Object.keys(orders).map(key => orders[key].confirmed),
            idx: 5,
            type: "string",
        },
    }



    return (
        <PurchasesContainerJSX
            orders={table}
            redirect={redirect}
            setRedirect={setRedirect}
            location={location}
        />
    )
}

export default PurchasesContainer