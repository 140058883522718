import { IonApp, setupIonicReact } from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';
import RootRouter from './Router/RootRouter';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from './Redux/User/Slice';
import { API_URL } from './Common/constants';
import { AppDispatch } from './Redux/Store';
import '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
// import { Plugins } from '@capacitor/core';
// import { OneSignal } from 'onesignal-capacitor';

// const { PushNotifications } = Plugins;


setupIonicReact();

// function OneSignalInit(): void {
//   try {
//     OneSignal.setExternalUserId("User ID 123");
//     OneSignal.setAppId("875daff9-8947-4da8-b3b8-4534f7b5b1db");
//     const id = OneSignal.getIds()
//     OneSignal.setNotificationOpenedHandler(function (jsonData) {
//       console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
//       // Test notification callback
//     });
//   } catch (e) {
//     console.log(e)
//   }
// }
// OneSignalInit()

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    fetch(API_URL + "meta")
      .then(res => res.json())
      .then(data => {
        const latest = data.version
        const current = 'cythero_' + (localStorage.getItem("version") === null ? "0.0" : localStorage.getItem("version"))
        if (current.split("_")[1] !== latest) {
          localStorage.clear();
          localStorage.setItem("version", latest)
          dispatch(logout())
          window.location.reload()
        }
      })
  }, [dispatch])

  return (
    <IonApp>
      <RootRouter />
    </IonApp>
  )
};

export default App;