import { IonButton, IonItem } from "@ionic/react"
import CartItem from "./CartItem"
import Translate from "../../components/Translate"


const PaymentContainer = () => {
    
}


const Item = ({ item, reducer }) => {
    const product_type = item.dlc_id !== null ? "dlc" : item.application_id !== null ? "application" : "package"

    let product
    if (product_type === "dlc")
        product = reducer.dlc.dlcs.find(d => d !== null && d !== undefined && d.id === item.dlc_id)
    else if (item.package_id !== null)
        product = reducer.packages.packages.find(d => d !== null && d !== undefined && d.id === item.package_id)
    else
        product = reducer.application.applications[item.application_id]

    if (product === undefined) return <></>

    return (
        <IonItem style={{ "--background": "#F1F5F9" }}>
            <span className="w-full flex justify-between">
                <p>{product.name} x {item.quantity}</p>
                <p>€{(item.quantity * product.price).toFixed(2)} EUR</p>
            </span>
        </IonItem>
    )

}

const CartContainerJSX = ({
    cartItems,
    ApplicationsReducer,
    getTotal,
    handleSubmit
}) => (
    <div className="shadow lg:my-10 mx-auto rounded-lg flex w-full lg:w-[95%] lg:max-w-[1200px] flex lg:flex-row flex-col h-screen lg:h-auto">
        <div className="w-full lg:w-3/5 bg-white inline rounded-tl-lg md:rounded-tr-lg lg:rounded-bl-lg">
            <h1 className="text-slate-800 font-bold text-2xl mx-4 md:mx-8 my-6"><Translate label="Shopping Cart" /></h1>
            <span className="block lg:overflow-y-auto">
                {cartItems.length === 0 ? <h1 className="font-bold text-lg text-center text-slate-700 mb-10"><Translate label="No items in the cart." /></h1> : null}
                {cartItems.map(c => <CartItem key={c.id} item={c} appsReducer={ApplicationsReducer} />)}
            </span>
        </div>
        <div className="w-full lg:w-2/5 bg-slate-100 lg:rounded-lg px-4 lg:py-12 py-20 pt-8 flex flex-col justify-between">
            <span>
                <h1 className="text-slate-800 text-lg font-bold ml-4 mb-3"><Translate label="Purchase Receipt" />:</h1>
                {cartItems.map(item => <Item item={item} reducer={ApplicationsReducer} />)}
                <IonItem style={{ "--background": "#F1F5F9" }} className="mt-3 mb-3">
                    <span className="w-full flex justify-between">
                        <p className="font-bold"><Translate label="Total Amount"/></p>
                        <p>€{getTotal()} EUR</p>
                    </span>
                </IonItem>
            </span>
            <IonButton onClick={e => handleSubmit(e)}><Translate label="CHECKOUT" /></IonButton>
            {/* <h1 className="text-xs mt-4 text-slate-700">
                Purchases go through manual approval because third-party payment service is currently unavailable.
                Contact us at <a className="text-cythero-primary font-semibold" href="mailto:support@cythero.com">support@cythero.com</a> for more info about the payment process.
            </h1> */}
        </div>
        {/* <div className="credit-card-container w-full lg:w-2/5 bg-gray-800 lg:rounded-lg px-4 lg:py-12 py-20 pt-8">
            <CreditCard />
        </div> */}
    </div>
)

export default CartContainerJSX