import { IonButton } from "@ionic/react"
import { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { print_pdf } from "../../../../Common/print"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { RootState } from "../../../../Redux/Store"
import { UserState } from "../../../../Redux/User/Interface"
import ChartContainer from "../../Charts/ChartContainer"
import LineChart from "../../Charts/LineChart"
import PieChart from "../../Charts/PieChart"
import Translate from "../../../../components/Translate"
import Loading from "../../../../components/Loading"
import { getTranslation } from "../../../../Common/Functions"

const PartReport = props => {

    const app_id = 1
    const applicationsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const current_app = applicationsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === app_id)

    const partReport = useSelector((state: RootState) => state.analytics.partReport)
    const data = partReport.data[0]

    const [selected, setSelected] = useState("Overall")

    const [pieGrades, setPieGrades] = useState([])
    const [timeTaken, setTimeTaken] = useState([])
    const [paintUsed, setPaintUsed] = useState([])
    const [coverage, setCoverage] = useState([])

    useEffect(() => {
        let coverages = []
        let grades = []

        if (data) {
            let highCoverage = 0
            let goodCoverage = 0
            let lowCoverage = 0

            if (selected === "Overall") {
                highCoverage = (data["Average High Coverage (Primer)"] + data["Average High Coverage (Base)"] + data["Average High Coverage (Clear)"]) / 3
                goodCoverage = (data["Average Good Coverage (Primer)"] + data["Average Good Coverage (Base)"] + data["Average Good Coverage (Clear)"]) / 3
                lowCoverage = (data["Average Low Coverage (Primer)"] + data["Average Low Coverage (Base)"] + data["Average Low Coverage (Clear)"]) / 3
            } else {
                highCoverage = data[`Average High Coverage (${selected})`]
                goodCoverage = data[`Average Good Coverage (${selected})`]
                lowCoverage = data[`Average Low Coverage (${selected})`]
            }
            if (Math.round(highCoverage) > 0) coverages.push({ id: getTranslation("High"), label: getTranslation("High"), value: Math.round(highCoverage), color: "#f87171" })
            if (Math.round(goodCoverage) > 0) coverages.push({ id: getTranslation("Good"), label: getTranslation("Good"), value: Math.round(goodCoverage), color: "#10b981" })
            if (Math.round(lowCoverage) > 0) coverages.push({ id: getTranslation("Low"), label: getTranslation("Low"), value: Math.round(lowCoverage), color: "#3b82f6" })
            coverages.push({ id: getTranslation("No Coverage"), label: getTranslation("No Coverage"), value: 100 - (Math.round(highCoverage) + Math.round(goodCoverage) + Math.round(lowCoverage)), color: "#AAA" })

            const gradeA = data[`${selected} Grade`]?.filter((grade) => grade === "A").length
            const gradeB = data[`${selected} Grade`]?.filter((grade) => grade === "B").length
            const gradeC = data[`${selected} Grade`]?.filter((grade) => grade === "C").length
            const gradeD = data[`${selected} Grade`]?.filter((grade) => grade === "D").length
            const gradeF = data[`${selected} Grade`]?.filter((grade) => grade === "F").length

            gradeA > 0 && grades.push({ id: "A", label: getTranslation("Grade") + " A", value: gradeA, color: "#4CAF50" })
            gradeB > 0 && grades.push({ id: "B", label: getTranslation("Grade") + " B", value: gradeB, color: "#8BC34A" })
            gradeC > 0 && grades.push({ id: "C", label: getTranslation("Grade") + " C", value: gradeC, color: "#fbbf24" })
            gradeD > 0 && grades.push({ id: "D", label: getTranslation("Grade") + " D", value: gradeD, color: "#FF9800" })
            gradeF > 0 && grades.push({ id: "F", label: getTranslation("Grade") + " F", value: gradeF, color: "#F44336" })

            const times = data[`${selected} Time`].slice(-10)
            const paints = data[`${selected} Paint Used`].slice(-10)
            const dates = data["Session End"].slice(-10).map(d => d.slice(5, d.length - 3))

            setTimeTaken([{ id: "Sessions", color: "#10b981", data: times?.map((time, index) => ({ x: dates[index], y: (time / 60).toFixed(2) })) }])
            setPaintUsed([{ id: "Sessions", color: "#10b981", data: paints?.map((paint, index) => ({ x: dates[index], y: Math.round(paint) })) }])
        }
        setCoverage([...coverages])
        setPieGrades([...grades])

    }, [selected, data])

    if (partReport.status === "idle") return <Fragment></Fragment>
    if (partReport.status === "pending") return <Loading isOpen={true} message="Report is being generated. Please wait." />
    if (partReport.status === "fulfilled" && partReport.data[0]["Base Good Coverage"]?.length < 3) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4">Not enough data to display this report. Try to select another part or more users.</h1>
    if (data === undefined) return <h1 className="text-slate-700 font-bold text-xl text-center mt-4">Error generating report.</h1>
    return (
        <div>
            <div id="part-report-print" className="justify-center items-center w-full p-5 gap-6 mb-14">
                <h1 className="text-lg text-slate-800 font-bold text-center"><Translate label="Part Report" /> ({data["Parts"][0]})</h1>
                <h1 className="mb-4 text-lg text-slate-800 font-bold text-center">Included Users ({partReport.filters?.users?.map(uid => {
                    const usr = UserReducer.users.find(u => u !== null && u?.id === uid)
                    if (usr === undefined) return null
                    return usr.username
                }).join(", ")})</h1>
                <div className="flex flex-col lg:flex-row gap-6 w-full items-center justify-center">
                    <ChartContainer
                        data={pieGrades}
                        maxHeight={200}
                        maxWidth={400}
                        title={<Translate label="Grades Brakdown" />}
                        Chart={PieChart}
                        extraJsx={(
                            <span className="flex flex-col">
                                <span className="flex gap-x-2 justify-evenly text-black font-semibold px-4">
                                    <button className={`w-1/4 rounded text-xs cursor-pointer hover:bg-green-600 ${selected === "Overall" && "bg-green-600 text-white"}`} onClick={() => setSelected("Overall")}><Translate label="OVERALL" /></button>
                                    <button className={`w-1/4 rounded text-xs cursor-pointer hover:bg-green-600 ${selected === "Primer" && "bg-green-600 text-white"}`} onClick={() => setSelected("Primer")}><Translate label="Primer" /></button>
                                    <button className={`w-1/4 rounded text-xs cursor-pointer hover:bg-green-600 ${selected === "Base" && "bg-green-600 text-white"}`} onClick={() => setSelected("Base")}><Translate label="Base" /></button>
                                    <button className={`w-1/4 rounded text-xs cursor-pointer hover:bg-green-600 ${selected === "Clear" && "bg-green-600 text-white"}`} onClick={() => setSelected("Clear")}><Translate label="Clear" /></button>
                                </span>
                                <span className="flex flex-col gap-2 p-4">
                                    <label className="flex justify-between"><b><Translate label="Times Played" />:</b> <p>{data["Times Played"]}</p></label>
                                    <label className="flex justify-between"><b><Translate label="Average Grade" />:</b> <p>{data["Average Grade Overall"]}</p></label>
                                </span>
                            </span>
                        )}
                    />
                    <ChartContainer
                        Chart={LineChart}
                        data={timeTaken}
                        maxHeight={326}
                        maxWidth={800}
                        title={<Translate label="Time Taken" />}
                        properties={{
                            axisLeft: {
                                legend: 'Time (mins)',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }
                        }}
                    />
                </div>
                <div className="pagebreak" style={{ pageBreakBefore: "always" }}> </div>
                <div className="flex flex-col lg:flex-row gap-6 w-full items-center justify-center pt-6">
                    <ChartContainer
                        data={coverage}
                        maxHeight={200}
                        maxWidth={400}
                        title={<Translate label="Coverage Breakdown" />}
                        Chart={PieChart}
                        extraJsx={(
                            <span className="flex flex-col p-4">
                                {coverage.filter(c => c.label !== "No Coverage").map(c => (<label key={c.value + c.label} className="flex justify-between"><b>{c.label}:</b> <p>{c.value}</p></label>))}
                            </span>
                        )}
                    />
                    <ChartContainer
                        Chart={LineChart}
                        data={paintUsed}
                        maxHeight={300}
                        maxWidth={800}
                        title={<Translate label="Paint Used" />}
                        properties={{
                            axisLeft: {
                                legend: 'Paint (ml)',
                                legendOffset: -40,
                                legendPosition: 'middle'
                            }
                        }}
                    />
                </div>
            </div>
            <span className="flex items-center justify-center mb-8">
                <IonButton onClick={e => print_pdf(document.getElementById("part-report-print").cloneNode(true), "Part Report", current_app)}><Translate label="EXPORT TO PDF" /></IonButton>
            </span>
        </div>
    )
}

export default PartReport