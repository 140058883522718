import { IonButton } from "@ionic/react"
import Input from "../../../components/Form/Input"

const EditUserFormJSX = ({
    user,
    setUser,
    checkForm
}) => (
    <div className="col-span-12  h-full pb-12 md:col-span-10">
        <div className="bg-white mt-6 rounded-lg shadow mx-4 overflow-hidden">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">Edit Profile</h1>
            <span className="w-full block pb-10 px-4">
                <Input
                    name="firstName"
                    value={user.firstName}
                    type="text"
                    placeholder="Enter first name (Optional)"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="lastName"
                    value={user.lastName}
                    type="text"
                    placeholder="Enter last name (Optional)"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="email"
                    value={user.email}
                    type="email"
                    placeholder="Enter email"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="username"
                    value={user.username}
                    type="text"
                    placeholder="Enter username"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="password"
                    value={user.password}
                    type="password"
                    placeholder="Enter password"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="confirm"
                    value={user.confirm}
                    type="password"
                    placeholder="Confirm password"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <Input
                    name="pin"
                    value={user.pin}
                    type="text"
                    placeholder="Enter 4 digit pin (optional)"
                    handleChange={e => setUser({ ...user, [e.target.name]: e.target.value })}
                />
                <IonButton className="mt-5" onClick={checkForm}>Update User</IonButton>
            </span>
        </div>
    </div>
)

export default EditUserFormJSX