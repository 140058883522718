import { ResponsivePie } from '@nivo/pie'

const PieChart = ({ data }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 0, right: 100, bottom: 20, left: 0 }}
        innerRadius={0.6}
        padAngle={3}
        colors={{ datum: "data.color" }}
        cornerRadius={4}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        legends={[
            {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 6,
                itemDirection: "left-to-right",
                itemWidth: 100,
                itemHeight: 20,
                itemTextColor: "#000",
                symbolSize: 16,
                symbolShape: "circle",
            },
        ]}
    />
)

export default PieChart