import { useIonAlert } from "@ionic/react"
import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { decrypt } from "../../../Common/decrypt"
import { validateEmail } from "../../../Common/Functions"
import { OrganizationActions } from "../../../Redux/Organizations/Actions"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import EditUserJSX from "./EditUserJsx"
import Loading from "../../../components/Loading"
import { LanguagesState } from "../../../Redux/Languages/Interface"

const EditUser: React.FC<{ location: { state: { prevLocation: string } } }> = ({ location }) => {
    const history = useHistory()
    const dispatch = useDispatch<AppDispatch>()
    const userId: number = parseInt(useParams<{ id: string }>().id)

    const userReducer = useSelector((state: RootState) => state.user)
    const LanguagesReducer: LanguagesState = useSelector((state: RootState) => state.language)
    const organizationsReduecr = useSelector((state: RootState) => state.organizations.organization)

    const [infoAlert] = useIonAlert()
    const [user, setUser] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(UserActions.readUserTypes({ arguments: { hashed: true } }))
    }, [dispatch])

    useEffect(() => {
        if (location.state === null) return
        window.addEventListener("popstate", () => history.push(location.state.prevLocation))
        return () => window.addEventListener("popstate", () => history.push(location.state.prevLocation))
    }, [history, location.state])


    useEffect(() => {
        if (submitted === false) return
        if (userReducer.updateUserStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: userReducer.updateUserMessage,
        })
        if (userReducer.updateUserStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: userReducer.updateUserMessage,
        })
    }, [userReducer.updateUserStatus, userReducer.updateUserMessage, infoAlert, submitted])

    const checkForm = () => {
        if (user.type_id === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select user type.'
        })
        // if (user.firstName.length < 2) return infoAlert({
        //     header: 'Error.', buttons: ['OK'], message: 'First name must contain more letters.'
        // })
        // if (user.lastName.length < 2) return infoAlert({
        //     header: 'Error.', buttons: ['OK'], message: 'Last name must contain more letters.'
        // })
        if (validateEmail(user.email) === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Invalid email.'
        })
        if (user.username.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Username must be at least 5 letters'
        })
        if (user?.password !== undefined) {
            if (user.password.length < 5) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Enter more secure password'
            })
            if (user.password !== user.confirm) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Passwords do not match'
            })
        }
        if (user.pin !== undefined) {
            if (user.pin.length !== 4) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Pin must be 4 numbers'
            })
            if (/^\d+$/.test(user.pin) !== true) return infoAlert({
                header: 'Error.', buttons: ['OK'], message: 'Pin must be numerical'
            })
        }
        const form = new FormData()
        form.append("id", user.id)
        form.append("organization_id", userReducer.current.type_id === 3 && userReducer.current.organization_id !== null ? userReducer.current.organization_id : user.organization_id)
        form.append("type_id", user.type_id)
        form.append("firstName", user.firstName)
        form.append("lastName", user.lastName)
        form.append("email", user.email)
        form.append("username", user.username)
        form.append("language_id", user.language_id)
        if (user?.password !== undefined) form.append("password", user.password)
        if (user?.pin !== undefined) form.append("pin", user.pin)
        setSubmitted(true)
        dispatch(UserActions.updateUser({ user: form }))
    }

    if (userReducer.readUserStatus !== "fulfilled" || userReducer.userTypes.readStatus !== "fulfilled" || organizationsReduecr.readStatus !== "fulfilled") return <Loading isOpen={true} message="Loading user information... Plese wait." />
    if (user === null) setUser({ ...userReducer.users[userId], pin: decrypt(userReducer.users[userId].pin) })
    return <Fragment>
        {userReducer.updateUserStatus === "pending" ? <Loading isOpen={true} message="Updating user data... Please wait." /> : null}
        <EditUserJSX
            checkForm={checkForm}
            setUser={setUser}
            user={user}
            organizations={organizationsReduecr.organizations.filter(o => o !== null && o !== undefined)}
            userTypes={userReducer.userTypes.types.filter(t => t! += null && t !== undefined)}
            type_id={userReducer.current.type_id}
            languages={LanguagesReducer.languages.languages}
        />
    </Fragment>
}

export default EditUser