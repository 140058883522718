import { IonButton } from "@ionic/react"
import { STATIC_URL } from "../../Common/constants"
import Loading from "../../components/Loading"
import Translate from "../../components/Translate"

const PurchasePageJSX = ({
    is_confirmed,
    confirm_purchase,
    packages,
    independent,
    total_price,
    purchase_date,
    confirmPackage,
    confirmItem,
    unconfirmPackage,
    unconfirmItem,
    PurchaseReducer,
    UserReducer,
    order_number
}) => (
    <div className="w-[900px] max-w-[95%] mx-auto mt-7">
        <Loading isOpen={PurchaseReducer.updateStatus === "pending"} message={PurchaseReducer.updateMessage} />
        <h1 className="text-slate-800 font-semibold text-xl mb-2"><Translate label="Purchase Information" />:</h1>
        <div className="rounded-lg overflow-hidden bg-white p-2 text-slate-800 shadow">
            <h1 className="flex justify-between items-center mb-2 font-bold"><Translate label="Total Amount" />: <span>€{total_price} EUR</span></h1>
            <h1 className="flex justify-between items-center mb-2 font-bold"><Translate label="Order Number" />: <span>{order_number}</span></h1>
            <h1 className="flex justify-between items-center font-bold"><Translate label="Purchase Date" />: <span>{purchase_date}</span></h1>
            {is_confirmed === 1 ? null : <IonButton onClick={e => confirm_purchase()} className="block w-[220px] ml-auto mt-4">Continue to checkout</IonButton>}
        </div>
        {Object.keys(packages).length > 0 ? <div>
            <h1 className="text-slate-800 font-semibold text-xl mb-2 mt-3"><Translate label="Purchased Packages" />:</h1>
            {Object.keys(packages).map(pkg => <div className="bg-white rounded-lg shadow p-3 mb-4">
                <span className="flex items-center mb-4">
                    <img src={STATIC_URL + packages[pkg].package.cover_path} alt="package cover" className="w-14 mr-4" />
                    <span>
                        <h1 className="font-bold text-lg text-slate-800">{packages[pkg].package.name}</h1>
                        <p className="text-slate-800"><Translate label="Status" />: {packages[pkg].items[0].confirmed === 1 ? <Translate label="Confirmed" /> : <Translate label="Pending" />}</p>
                        <p className="text-emerald-600"><Translate label="Price" />: €{packages[pkg].package.price} EUR</p>
                    </span>
                </span>
                <p className="text-justify mb-2">{packages[pkg].package.description}</p>
                <p className="text-md font-bold text-slate-800 mb-2 "><Translate label="Purchased Products" />: </p>
                <div className="flex flex-wrap">
                    {packages[pkg].items.map(item => <div className="flex items-center p-2 bg-slate-100 mr-2 mb-2 rounded shadow-md">
                        <img src={STATIC_URL + item.product.cover_path} className="w-8 mr-2" />
                        <h1 className="text-slate-800 font-bold">{item.product.name}</h1>
                    </div>)}
                </div>
                {UserReducer.current.type_id === 4 ? packages[pkg].items[0].confirmed === 0 ? <IonButton onClick={e => confirmPackage(pkg)}>Confirm</IonButton> : <IonButton color={"danger"} onClick={e => unconfirmPackage(pkg)}>Un-confirm</IonButton> : null}
            </div>)}
        </div> : null}
        {independent.length > 0 ? <div>
            <h1 className="text-slate-800 font-semibold text-xl mb-2 mt-3"><Translate label="Purchased Products" />:</h1>
            {independent.map(item => <div className="bg-white rounded-lg shadow p-3 mb-4">
                <span className="flex items-center mb-2">
                    <img src={STATIC_URL + item.product.cover_path} alt="package cover" className="w-14 mr-4" />
                    <span>
                        <h1 className="font-bold text-lg text-slate-800">{item.product.name}</h1>
                        <p className="text-slate-800"><Translate label="Status" />: {item.confirmed === 1 ? <Translate label="Confirmed" /> : <Translate label="Pending" />}</p>
                        <p className="text-emerald-600"><Translate label="Price" />: €{item.product.price} EUR</p>
                    </span>
                </span>
                <p className="text-justify mb-2">{item.product.description}</p>
                {UserReducer.current.type_id === 4 ? item.confirmed === 0 ? <IonButton onClick={e => confirmItem(item.id)}>Confirm</IonButton> : <IonButton color={"danger"} onClick={e => unconfirmItem(item.id)}>Un-confirm</IonButton> : null}
            </div>)}
        </div> : null}

    </div>
)

export default PurchasePageJSX