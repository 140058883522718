import { useIonAlert } from "@ionic/react"
import { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { validateEmail } from "../../../Common/Functions"
import { Address, Countries } from "../../../Common/Interfaces/Entities"
import { AppDispatch } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import AddressForm from "./AddressFrom"
import Loading from "../../../components/Loading"


const EditAddress:React.FC<{
    countries: Array<Countries>
    userReducer: UserState
    userAddress: Address | undefined
}> = ({
    userAddress,
    userReducer,
    countries
}) => {
    
    
    const dispatch = useDispatch<AppDispatch>()
    const [infoAlert] = useIonAlert()
    
    const [address, setAddress] = useState<Address>({...userAddress})
    const [submitted, setSubmitted] = useState<Boolean>(false)


    useEffect(() => {
        if (submitted === false) return
        if (userReducer.address.updateStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: userReducer.address.updateMessage,
        })
        if (userReducer.address.updateStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: userReducer.address.updateMessage,
        })
    }, [userReducer.address.updateStatus])

    const handleSubmit = () => {
        if (address.country_id === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select a country.'})
        if (address.city.length < 2) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a valid city name'})
        if (address.zip_code.length < 2) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a valid zip code'})
        if (address.address.length < 8) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a valid address'})
        if (address.contact_phone.length < 7) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a valid phone number'})
        if (validateEmail(address.contact_email) === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter a valid email address'})
        if (address.contact_name.length < 4 || address.contact_name.includes(" ") === false) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter first and last name.'})

        setSubmitted(true)
        const addressForm = new FormData()
        addressForm.append("id", address.id.toString())
        addressForm.append("user_id", userReducer.current.id.toString())
        addressForm.append("country_id", address.country_id.toString())
        addressForm.append("city", address.city)
        addressForm.append("address", address.address)
        addressForm.append("zip_code", address.zip_code)
        addressForm.append("contact_phone", address.contact_phone)
        addressForm.append("contact_email", address.contact_email)
        addressForm.append("contact_name", address.contact_name)

        dispatch(UserActions.updateAddress({ "address": addressForm }))
    }

    return (
        <Fragment>
            {<Loading isOpen={userReducer.address.updateStatus ==="pending"} message="Updating address information... Please wait." />}
            <AddressForm 
                address={address}
                setAddress={setAddress}
                handleSubmit={handleSubmit}
                isNew={false}
                countries={countries}
            />
        </Fragment>
    )

}

export default EditAddress