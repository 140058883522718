import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { OrganizationActions } from "../../Redux/Organizations/Actions";
import { OrganizationsState } from "../../Redux/Organizations/Interface";
import { AppDispatch, RootState } from "../../Redux/Store";
import { logout } from "../../Redux/User/Slice";
import NavJsx from "./NavJsx"

import { AiTwotoneSetting } from "react-icons/ai"
import { GoOrganization } from "react-icons/go"
import { RiShoppingBag3Fill, RiShoppingCartLine } from "react-icons/ri"
import { IoMdAnalytics } from "react-icons/io"
import { SiGoogletranslate } from "react-icons/si"
import { MdEmail } from "react-icons/md"
import { BsFillMenuButtonWideFill, BsGrid3X3GapFill } from "react-icons/bs"
import { LanguageActions } from "../../Redux/Languages/Actions";
import { UserState } from "../../Redux/User/Interface";
import Translate from "../Translate";

const Nav: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const [show, setShow] = useState(false);
    const location = useLocation()
    const user:UserState = useSelector((state: RootState) => state.user)
    const Organization: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(LanguageActions.readLanguage({ arguments: { hashed: true } }))
        dispatch(LanguageActions.readTranslation({ arguments: { hashed: true } }))
    }, [dispatch])

    const routes = [
        {
            pathName: "/dashboard",
            icon: <BsFillMenuButtonWideFill />,
            name: <Translate label="Dashboard" />,
            types_condition: [2, 3, 4]
        },
        {
            icon: <IoMdAnalytics />,
            name: <Translate label="Reports" />,
            types_condition: [2, 3, 4],
            sub_routes: [
                { name: "Sprayverse", route: "/reports/1" },
                { name: "WeldVR", route: "/reports/7" }
            ]
        },
        {
            pathName: "/settings",
            icon: <AiTwotoneSetting />,
            name: <Translate label="Settings" />,
            types_condition: [4]
        },
        {
            pathName: "/languages",
            icon: <SiGoogletranslate />,
            name: <Translate label="Languages" />,
            types_condition: [4]
        },
        {
            pathName: "/organization",
            icon: <GoOrganization />,
            name: <Translate label="Organization" />,
            types_condition: [1, 2, 3, 4]
        },
        {
            pathName: "/demo_organizations",
            icon: <GoOrganization />,
            name: <Translate label="Demo Org...'s" />,
            types_condition: [4]
        },
        {
            pathName: "/registered_emails",
            icon: <MdEmail />,
            name: <Translate label="Emails" />,
            types_condition: [4]
        },
        {
            pathName: "/independant_users",
            icon: <MdEmail />,
            name: "Independent Users",
            types_condition: [4]
        },
        {
            pathName: "/applications",
            icon: <BsGrid3X3GapFill />,
            name: <Translate label="Applications" />,
            types_condition: [4]
        },
        {
            pathName: "/cart",
            icon: <RiShoppingCartLine />,
            name: <Translate label="Shopping Cart" />,
            types_condition: [4]
        },
        {
            pathName: "/purchases",
            icon: <RiShoppingBag3Fill />,
            name: <Translate label="Purchases" />,
            types_condition: [4]
        }
    ]

    return user.current === null ? <Redirect to="/auth/login" /> : <NavJsx
        routes={routes}
        show={show}
        setShow={setShow}
        children={props.children}
        location={location}
        user={user.current}
        logout={() => dispatch(logout())}
        logo={user.current === null || Organization.organization.organizations[user.current.organization_id] === undefined ? "" : Organization.organization.organizations[user.current.organization_id].profile_picture}
        selected={selected}
        setSelected={setSelected}
    />
}

export default Nav