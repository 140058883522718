import { useIonAlert } from "@ionic/react"
import { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import ConfirmCredentials from "../ConfirmCredentials"
import UserCardJSX from "./UserCardJSX"
import Loading from "../../../components/Loading"

const UserCard = ({
    current,
    orgnizations,
    firstName,
    lastName,
    username,
    email,
    organization_id,
    type_id,
    id
}) => {
    const dispatch = useDispatch<AppDispatch>()
    const modal = useRef<HTMLIonModalElement>(null);

    const UserReducer:UserState = useSelector((state: RootState) => state.user)
    const [infoAlert] = useIonAlert()


    const [popover, togglePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [auth, setAuth] = useState<{
        auth_username: string
        auth_password: string
        archived: 1 | undefined
        organization_id: null | undefined
    }>({
        auth_username: "",
        auth_password: "",
        archived: undefined,
        organization_id: undefined
    })

    useEffect(() => {
        if (auth.auth_username === "") return
        if (UserReducer.updateUserStatus === "fulfilled" || UserReducer.updateUserStatus === "rejected") {
            setAuth({
                auth_username: "",
                auth_password: "",
                archived: undefined,
                organization_id: undefined
            })
        }
        if (UserReducer.updateUserStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: UserReducer.updateUserMessage,
        })
        if (UserReducer.updateUserStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: UserReducer.updateUserMessage || "Unexpected error occurred.",
        })
    }, [UserReducer.updateUserStatus])

    const handleDelete = () => {
        setAuth({
            auth_username: "",
            auth_password: "",
            archived: 1,
            organization_id: undefined
        })
        togglePopover({...popover, open: false})
        modal.current.isOpen = true
    }

    const handleKick = () => {
        setAuth({
            auth_username: "",
            auth_password: "",
            archived: undefined,
            organization_id: null
        })
        togglePopover({...popover, open: false})
        modal.current.isOpen = true
    }

    const handleSubmit = () => {
        modal.current.isOpen = false
        const form = new FormData()
        form.append("id", id)
        if (UserReducer.check_auth === true) {
            form.append("auth_username", auth.auth_username)
            form.append("auth_password", auth.auth_password)
        }
        if (auth.archived !== undefined) form.append("archived", auth.archived.toString())
        if (auth.organization_id !== undefined) form.append("organization_id", auth.organization_id)
        dispatch(UserActions.updateUser({ user: form }))
    }

    return (
        <Fragment>
            {UserReducer.updateUserStatus === "pending" ? <Loading isOpen={true} message="Updating user data... Please wait." /> : null}
            <ConfirmCredentials
                handleSubmit={handleSubmit}
                modal={modal}
                data={auth}
                setData={setAuth}
            />
            <UserCardJSX
                handleDelete={handleDelete}
                handleKick={handleKick}
                current={current}
                popover={popover}
                togglePopover={togglePopover}
                orgnizations={orgnizations}
                firstName={firstName}
                lastName={lastName}
                username={username}
                email={email}
                organization_id={organization_id}
                id={id}
                type={UserReducer.userTypes.types[type_id]}
            />
        </Fragment>
    )
}

export default UserCard