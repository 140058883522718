import { STATIC_URL } from "../../../../Common/constants"
import { RiCheckboxMultipleBlankFill } from "react-icons/ri"
import ChartContainer from "../../../Analytics/Charts/ChartContainer"
import BarChart from "../../../Analytics/Charts/BarChart"
import { useEffect, useState } from "react"
import PieChart from "../../../Analytics/Charts/PieChart"

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }
        window.addEventListener("resize", handleResize)
        handleResize()
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return [windowSize, setWindowSize];
}

const AdministratorJSX = ({
    apps_list,
    per_day_graph,
    most_played_apps,
    organization_application_usage
}) => {
    const app_info_container = document.getElementById("app-info-container") === null ? 1200 : document.getElementById("app-info-container")
    const [size, _] = useWindowSize()
    const [widthWeeks, setWidthWeeks] = useState(app_info_container < 800 ? app_info_container : app_info_container < 1200 ? 0.58 * app_info_container : document.getElementById("overall-contianer")?.offsetWidth)
    const [widthApps, setWidthApps] = useState(app_info_container < 800 ? app_info_container : app_info_container < 1200 ? 0.375 * app_info_container : document.getElementById("overall-contianer")?.offsetWidth)

    useEffect(() => {
        setWidthWeeks(app_info_container < 800 ? app_info_container : app_info_container < 1200 ? 0.58 * app_info_container : document.getElementById("overall-contianer")?.offsetWidth)
        setWidthApps(app_info_container < 800 ? app_info_container : app_info_container < 1200 ? 0.375 * app_info_container : document.getElementById("overall-contianer")?.offsetWidth)
    }, [size])

    return (
        <div className="w-[1200px] max-w-[95%] mx-auto my-8">
            <div>
                <h1 className="text-lg text-slate-800 font-bold mb-6">Application Information</h1>
                <span className="flex flex-col md:flex-row items-center justify-between w-full gap-5 md:gap-5" id="app-info-container">
                    <div className="w-full md:w-7/12 flex justify-center items-center">
                        <ChartContainer
                            maxHeight={220}
                            maxWidth={undefined}
                            data={per_day_graph.data}
                            Chart={BarChart}
                            title="Average play time per application per day"
                            properties={{
                                axisLeft: {
                                    legend: 'Play time (min)',
                                    legendOffset: -40,
                                    legendPosition: 'middle'
                                },
                                indexBy: "day",
                                keys: per_day_graph.keys,
                                colors: per_day_graph.colors
                            }}
                        />
                    </div>
                    <div className="w-full md:w-5/12 flex justify-center items-center">
                        <ChartContainer
                            data={most_played_apps}
                            maxHeight={220}
                            maxWidth={undefined}
                            title="Application Playtime (hrs)"
                            Chart={PieChart}
                        />
                    </div>
                </span>
                <div className="mt-5">
                    <ChartContainer
                        maxHeight={organization_application_usage.data.length*30}
                        maxWidth={undefined}
                        data={organization_application_usage.data.sort((a, b) => {
                            let totalA = 0
                            let totalB = 0
                            for (const key of organization_application_usage.keys) {
                                if (a[key] !== undefined) totalA += parseInt(a[key])
                                if (b[key] !== undefined) totalB += parseInt(b[key])
                            }
                            return totalA > totalB ? 1 : -1
                        })}
                        Chart={BarChart}
                        title="Engagement per organization per application"
                        properties={{
                            axisBottom: {
                                legend: 'Engagement (min)',
                                legendOffset: 30,
                                legendPosition: 'middle'
                            },
                            keys: organization_application_usage.keys,
                            indexBy: "Organization",
                            colors: organization_application_usage.colors,
                            layout: "horizontal",
                            groupMode: "stacked"
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default AdministratorJSX