import { IonButton } from "@ionic/react"
import Input from "../../../components/Form/Input"
import Select from "../../../components/Form/Select"

const CreateAddressJSX = ({
    address,
    setAddress,
    handleSubmit,
    isNew,
    countries
}) => (
    <div className="col-span-12  h-full pb-12 md:col-span-10">
        <div className="bg-white mt-6 rounded-lg shadow mx-4 overflow-hidden">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">{isNew === true ? "Create address" : "Edit Address"}</h1>
            <span className="w-full block pb-10 px-4">

                <Select
                    value={address.country_id}
                    multiple={false}
                    options={countries}
                    displayField="name"
                    valueField="id"
                    name="country_id"
                    placeholder="Select country of residence"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />

                <Input
                    name="city"
                    value={address.city}
                    type="text"
                    placeholder="Enter city of residence"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />
                <Input
                    name="zip_code"
                    value={address.zip_code}
                    type="text"
                    placeholder="Enter zip code"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />
                <Input
                    name="address"
                    value={address.address}
                    type="text"
                    placeholder="Enter street name and house/apartment number"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />
                <Input
                    name="contact_phone"
                    value={address.contact_phone}
                    type="text"
                    placeholder="Enter phone number to contact"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />
                <Input
                    name="contact_email"
                    value={address.contact_email}
                    type="email"
                    placeholder="Enter email to contact"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />
                <Input
                    name="contact_name"
                    value={address.contact_name}
                    type="text"
                    placeholder="Enter first and last name of person to contact"
                    handleChange={e => setAddress({ ...address, [e.target.name]: e.target.value })}
                />
                <IonButton className="mt-5" onClick={handleSubmit}>{isNew === true ? "Create" : "Update"} Address</IonButton>
            </span>
        </div>
    </div>
)

export default CreateAddressJSX