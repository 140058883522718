
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Organization } from "../../../Common/Interfaces/Entities"
import { OrganizationActions } from "../../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../../Redux/Organizations/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import BrowseOrganizationsJSX from "./BrowseOrganizationsJSX"
import Loading from "../../../components/Loading"

const BrowseOrganizations: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    
    const organizationsReducer:OrganizationsState = useSelector((state:RootState) => state.organizations)
    const usersReducer:UserState = useSelector((state:RootState) => state.user)
    const [search, setSearch] = useState<string>("")

    useEffect(() => {
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
    }, [dispatch])

    if (
        usersReducer.readUserStatus !== "fulfilled" ||
        organizationsReducer.organization.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading organizations... Please wait" />

    const organizations = organizationsReducer.organization.organizations.filter(o => o !== null && o !== undefined && (
        search.length === 0 || o.name.toLowerCase().startsWith(search.toLowerCase())
    )).sort((a:Organization, b:Organization) => {
        if (a.id === usersReducer.current.organization_id) return -1
        else if (b.id === usersReducer.current.organization_id) return 1
        else if (a.id > b.id) return -1
        else return 1
    })


    return <BrowseOrganizationsJSX
        search={search}
        setSearch={setSearch}
        organizations={organizations}
        current={usersReducer.current}
    />
}

export default BrowseOrganizations