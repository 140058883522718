import { IonButton } from "@ionic/react"
import { TailSpin } from "react-loader-spinner"
import ProductCard from "../../Organizations/PurchaseHistory/ProductCard"
import Translate from "../../../components/Translate"
import { getTranslation } from "../../../Common/Functions"

const BrandingProfilesJSX = ({
    loading,
    purchases,
    count,
    showMore,
    setShowMore,
    search,
    setSearch
}) => (
    <div className="p-8 bg-white rounded-lg shadow mb-4">
        <h3 className="flex justify-between items-center">
            <p className="text-slate-700 font-medium text-lg"><Translate label="Branding Profiles" /></p>
        </h3>
        <span className="flex justify-center items-center mb-5">
            <TailSpin
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
            />
        </span>
        {search.length === 0 && count < 5 ? null : <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder={getTranslation("Search for profile")} className="
            block w-full
            px-3 py-1.5
            mb-5 -mt-3
            text-base font-normal text-gray-700
            bg-white bg-clip-padding
            border border-solid border-gray-300 rounded
            transition ease-in-out
            focus:text-gray-700 focus:bg-white focus:border-cythero-primary focus:outline-none
        " />}
        {count === 0 && search.length === 0 ? <p className="text-slate-600">There are no purchases</p> : count === 0 ? <p className="text-slate-600">No brandings match your query</p> : null}
        {purchases.map(p => <ProductCard
            key={p.id}
            purchase={p}
        />)}
        {count > 5 ? <IonButton className="mt-5" onClick={e => setShowMore(!showMore)}><Translate label={showMore ? "Show Less" : "Show More"} /></IonButton> : null}
    </div>
)

export default BrandingProfilesJSX