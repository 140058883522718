import { IonButton } from "@ionic/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../Common/constants"
import { getTranslation, userOwnsProduct } from "../../../Common/Functions"
import { DLC, DlcTypes, User } from "../../../Common/Interfaces/Entities"
import { PurchaseActions } from "../../../Redux/Purchases/Actions"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import Translate from "../../../components/Translate"


const DlcCard: React.FC<{
    dlc_types: Array<DlcTypes>
    dlc: DLC
    application_id: number
    current: User
}> = ({
    dlc_types,
    dlc,
    application_id,
    current,
}) => {
        const dispatch = useDispatch<AppDispatch>()
        const PurchasesReducer: PurchasesState = useSelector((state: RootState) => state.shop)
        const item_in_cart = PurchasesReducer.cart.carts.find(item => item !== null && item !== undefined && item.dlc_id === dlc.id && item.user_id === current.id)

        const handleCart = e => {
            if (item_in_cart === undefined) {
                const data = new FormData()
                data.append("user_id", current.id.toString())
                data.append("dlc_id", dlc.id.toString())
                dispatch(PurchaseActions.createCart({ "cart": data }))
            } else dispatch(PurchaseActions.deleteCart({ arguments: { id: item_in_cart.id } }))
        }

        let user_owns_dlc = userOwnsProduct(PurchasesReducer.purchases, current, "dlc", dlc.id)

        return (
            <div key={dlc.id} className="w-[95%] md:w-[300px] p-5 shadow bg-white rounded flex flex-col items-between justify-between">
                <span className="w-full flex items-center mb-5">
                    <img alt="dlc cover photo" className="w-16 mr-3" src={STATIC_URL + dlc.cover_path} />
                    <div>
                        <NavLink className="font-semibold" to={"/applications/dlcs/" + application_id}>{dlc.name} </NavLink>
                        <p>
                            {dlc_types[dlc.dlc_type_id].name}
                            {/* ({dlc.dlc_type_id === 5 ? "Recurring" : "One Time Payment"}) */}
                        </p>
                        <p>€{dlc.price} EUR</p>
                    </div>
                </span>
                <p className="">{dlc.description.slice(0, 180) + (dlc.description.length > 180 ? "..." : "")}</p>
                {dlc.dlc_type_id === 5 ? null : user_owns_dlc !== false && dlc.multiple_purchase !== 1 ? <h1 className="text-cythero-primary font-bold text-center mt-3"><Translate label="You own this product" />.</h1> : <IonButton onClick={e => handleCart(e)} className="block mt-4">{item_in_cart === undefined ? getTranslation("ADD TO CART") : getTranslation("REMOVE FROM CART")}</IonButton>}
                {dlc.dlc_type_id === 5 ? user_owns_dlc !== false ? <h1 className="text-cythero-primary font-bold text-center mt-3"><Translate label="You own this product" />.</h1> : <IonButton onClick={e => handleCart(e)} className="block mt-4">{item_in_cart === undefined ? getTranslation("ADD TO CART") : getTranslation("REMOVE FROM CART")}</IonButton> : null}
            </div>
        )
    }

export default DlcCard