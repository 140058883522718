import { NavLink } from "react-router-dom"

const ApplicationRouteLink = ({ app, route }) => (
    <li className="nav-item px-2" key={"app_link_" + app.id}>
        <NavLink className={parseInt(route.split("/")[2]) === app.id ? `underline nav-link text-white whitespace-nowrap` : `nav-link text-white whitespace-nowrap`} to={"/dashboard/" + app.id}>{app.name === "Overall" ? "General" : app.name}</NavLink>
    </li>
)

const ApplicationSelectJsx = ({
    appliations,
    route,
    current_user
}) => (
    <div
        className="relative w-full flex flex-wrap items-center justify-between py-2 bg-gray-900 text-gray-200 shadow-lg navbar navbar-expand-lg navbar-light overflow-x-auto"
    >
        <div className="container-fluid w-full flex flex-wrap items-center justify-between px-6">
            <ul className="navbar-nav flex pl-0 list-style-none">
                {appliations.map(app => app === null || app === undefined ? null : <ApplicationRouteLink key={app.id} app={app} route = { route } />)}
                {appliations.length <= 1 ? null : <ApplicationRouteLink key={0} app={{id: 0, name: "Overall"}} route={route} />}
                {current_user.type_id !== 4 ? null : <ApplicationRouteLink key={-1} app={{id: -1, name: "Administrator"}} route={route} />}
            </ul>
        </div>
    </div>
)

export default ApplicationSelectJsx