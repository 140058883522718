import Dashboards from "./ApplicationsDashboards"

const DashboardContainerJSX = ({
    route,
    children
}) => {
    const selected = route.includes("overall") ? Dashboards[0] : isNaN(parseInt(route.split("/")[route.split("/").length - 1])) ? null : Dashboards.find(item => item.application_id === parseInt(route.split("/")[route.split("/").length - 1]))

    return (
        <div className="w-full">
            {children}
            {selected === null ? null : selected === undefined ? "Application not found" : selected.component}
        </div>
    )
}

export default DashboardContainerJSX