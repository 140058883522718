import React, { Fragment, useEffect, useState } from "react"
import { useIonAlert } from "@ionic/react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import CreateReleaseJSX from "./CreateReleaseJSX"
import Loading from "../../../components/Loading"



const CreateRelease:React.FC = () => {
    const id: number = parseInt(useParams<{ id: string }>().id)
    const dispatch = useDispatch<AppDispatch>()
    const [infoAlert] = useIonAlert()

    const apps: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [submitted, setSubmitted] = useState(false)
    const [release, setRelease] = useState({
        version: "",
        notes: "",
        version_type_id: null,
        application_file: null
    })

    useEffect(() => {
        dispatch(ApplicationActions.readReleaseTypes({ arguments: { hashed: true } }))
    }, [dispatch])

    useEffect(() => {
        if (submitted === false) return
        if (apps.version.createStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: apps.version.createMessage,
        })
        if (apps.version.createStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: apps.version.createMessage,
        })
    }, [apps.version.createStatus, apps.version.createMessage, infoAlert, submitted])

    const handleSubmit = () => {
        if (release.version_type_id === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please select release type (alpha/beta/release...)'
        })
        if (release.version.length < 5) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please follow the conventional version format (Major.Minor.Patch)'
        })
        if (release.notes.length < 120) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please enter more descriptive notes (120 - 500 letters)'
        })
        if (release.notes.length > 500) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Notes too long (please enter less than 500 letters)'
        })
        if (release.application_file === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Please attach a ZIP file to upload with this release.'
        })
        setSubmitted(true)
        const data = new FormData()
        data.append("application_id", id.toString())
        data.append("version_type_id", release.version_type_id.toString())
        data.append("version", release.version)
        data.append("notes", release.notes)
        data.append("application_file", release.application_file)

        dispatch(ApplicationActions.createVersion({ version: data }))
    }

    if (apps.version_types.readStatus !== "fulfilled") 
        return <Loading isOpen={true} message="Loading releases data... Please wait." />
    
    return (
        <Fragment>
            {apps.version.createStatus === "pending" ? <Loading isOpen={true} message="Creatubg release... Please wait." /> : null}
            {release === null ? null : <CreateReleaseJSX
                appliation={apps.application.applications[id]}
                release_types={apps.version_types.types}
                release={release}
                setRelease={setRelease}
                handleSubmit={handleSubmit}
            />}
        </Fragment>
    )
}

export default CreateRelease