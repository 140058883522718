import { IonButton } from "@ionic/react"
import { BsChevronCompactRight } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import ColorPicker from "../../../components/Form/ColorPicker"
import Input from "../../../components/Form/Input"
import Select from "../../../components/Form/Select"




const UpdateApplicationJSX = ({
    application,
    setApplication,
    platforms,
    handleSubmit
}) => (
    <div className="py-6 mx-auto mb-24 w-11/12 max-w-[1300px]">
        <span className="flex items-center w-full mx-auto text-slate-700">
            <BsChevronCompactRight className="mr-2" />
            <NavLink className="hover:underline" to={"/applications/" + application.id}>{application.name}</NavLink>
            <BsChevronCompactRight className="mx-2" />
            <NavLink className="underline" to={"/applications/update/" + application.id}>Update Application</NavLink>
        </span>
        <div className="bg-white mt-6 rounded-lg shadow w-full w-full">
            <h1 className="w-full text-slate-800 font-bold px-6 py-4 text-xl">Update a new application</h1>
            <span className="w-full block pb-10 px-4">
                <Input name="name" value={application.name} handleChange={e => setApplication({ ...application, [e.target.name]: e.target.value })} type="text" placeholder="Enter name of application" />
                <Input name="description" value={application.description} handleChange={e => setApplication({ ...application, [e.target.name]: e.target.value })} type="textarea" placeholder="Enter description" />
                <Select
                    value={application?.platforms || []}
                    multiple={true}
                    options={platforms}
                    displayField="name"
                    valueField="id"
                    name="platforms"
                    placeholder="Select supported platforms"
                    handleChange={e => setApplication({ ...application, [e.target.name]: e.target.value })}
                />
                <ColorPicker
                    name="primary_color"
                    value={application.primary_color}
                    placeholder="Select Primary Color"
                    handleChange={e => setApplication({ ...application, [e.target.name]: e.target.value })}
                />
                <Input accept="image/*" name="cover" value={application.cover_photo} handleChange={e => setApplication({ ...application, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select cover photo" />
                <Input accept="image/*" name="logo" value={application.cover_photo} handleChange={e => setApplication({ ...application, [e.target.name]: e.target.files[0] })} type="file" placeholder="Select logo photo" />
                <Input name="price" value={application.price} handleChange={e => setApplication({ ...application, price: e.target.value })} type="number" placeholder="Enter price of application In EUR" />
                <IonButton className="mt-5" onClick={e => handleSubmit(e)}>Update Application</IonButton>
            </span>
        </div>

    </div>
)

export default UpdateApplicationJSX