import { useIonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateEmail } from "../../../Common/Functions";
import { Organization } from "../../../Common/Interfaces/Entities";
import { OrganizationActions } from "../../../Redux/Organizations/Actions";
import { OrganizationsState } from "../../../Redux/Organizations/Interface";
import { AppDispatch, RootState } from "../../../Redux/Store";
import EditOrganizationFormJSX from "./EditOrganizationFormJSX";

const EditOrganizationForm:React.FC<{organization:Organization}> = ({
    organization
}) => {
    const dispatch = useDispatch<AppDispatch>()

    const organizationReducer:OrganizationsState = useSelector((state:RootState) => state.organizations)

    const [org, setOrg] = useState<Organization>({...organization, profile_picture: null})
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [infoAlert] = useIonAlert() 

    useEffect(() => {
        if (submitted === false) return
        if (organizationReducer.organization.updateStatus === "fulfilled") infoAlert({
            header: 'Success.', buttons: ['OK'],
            message: organizationReducer.organization.updateMessage,
        })
        if (organizationReducer.organization.updateStatus === "rejected") infoAlert({
            header: 'Error.', buttons: ['OK'],
            message: organizationReducer.organization.updateMessage,
        })
    }, [organizationReducer.organization.updateStatus, organizationReducer.organization.updateMessage, submitted, infoAlert])

    const handleSubmit = () => {
        if (org.name.length < 4) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Organization name must be at least 4 characters long.'})
        if (validateEmail(org.email) === null) return infoAlert({
            header: 'Error.', buttons: ['OK'], message: 'Invalid email.'})
        
        setSubmitted(true)
        const form = new FormData()
        form.append("id", org.id.toString())
        form.append("name", org.name)
        form.append("email", org.email)
        form.append("ocolus_id", org.ocolus_id)
        if (org.profile_picture !== null) form.append("profile_picture", org.profile_picture)
        dispatch(OrganizationActions.updateOrganization({ "organization" : form}))
    }

    return <EditOrganizationFormJSX 
        organizationReducer={organizationReducer}
        org={org}
        setOrg={setOrg}
        handleSubmit={handleSubmit}
    />
}

export default EditOrganizationForm