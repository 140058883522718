
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { dateToDateStr } from "../../../../components/Form/DateSelect"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { UserActions } from "../../../../Redux/User/Actions"
import { UserState } from "../../../../Redux/User/Interface"
import SprayverseSession from "../../../Analytics/Reports/SessionCards/SprayverseSession"
import WeldingSession from "../../../Analytics/Reports/SessionCards/WeldingSession"
import { prepareActivityApps, prepareActivityBrowse, prepareActivityLine, prepareActivityUsers } from "../pret"
import OverallJSX from "./OverallJSX"
import Loading from "../../../../components/Loading"



const Overall: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()

    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const analytics: Analytics = useSelector((state: RootState) => state.analytics)

    const [range, setRange] = useState<"OVERALL" | "WEEK" | "MONTH" | "YTD">("OVERALL")
    const [selectedSession, setSelectedSession] = useState<{ session_id: string, application_id: number } | null>(null)
    const [modal, setModal] = useState<boolean>(false)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        dispatch(AnalyticsActions.getLabels({ arguments: { hashed: true } }))
        const data = new FormData()
        if (UserReducer.current.organization_id === null) data.append("user_id", UserReducer.current.id.toString())
        else data.append("org_id", UserReducer.current.organization_id.toString())
        dispatch(AnalyticsActions.dashboardReport({ filters: data }))
    }, [])


    if (UserReducer.readUserStatus !== "fulfilled" || analytics.labels.status !== "fulfilled") return <Loading isOpen={true} message="Loading report pre-requisits... Please wait." />
    if (analytics.dashboardReport.status !== "fulfilled") return <Loading isOpen={true} message="Loading report data... Please wait." />

    const ActivityLine = {
        can_display: false,
        data: []
    }
    const _ActivityLine = prepareActivityLine(analytics.dashboardReport.data.timeline)
    for (const app_id in _ActivityLine) {
        if (_ActivityLine[app_id].length > 3) ActivityLine["can_display"] = true 
        const app = AppsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === parseInt(app_id))
        ActivityLine.data.push({
            id: app.name + " Played",
            color: app.primary_color,
            label: "",
            data: _ActivityLine[app_id]
        })
    }
    const UserActivity = prepareActivityUsers(analytics.dashboardReport.data.user, UserReducer.users)
    const AppsActivity = prepareActivityApps(analytics.dashboardReport.data.application, AppsReducer.application.applications)
    const BrowseActivity = prepareActivityBrowse(analytics.dashboardReport.data.timeline, UserReducer.users, AppsReducer.application.applications, { users: [], parts: [] })
    const handleRangeChange = _range => {
        setRange(_range)

        const data = new FormData()
        data.append("org_id", UserReducer.current.organization_id.toString())
        if (_range !== "OVERALL") {
            const today = new Date;
            let from, to

            if (_range === "WEEK") {
                from = new Date(today.setDate(today.getDate() - today.getDay()))
                to = new Date(today.setDate(today.getDate() - today.getDay() + 6))
            } else if (_range === "MONTH") {
                from = new Date(today.getFullYear(), today.getMonth(), 1)
                to = new Date(today.getFullYear(), today.getMonth() + 1, 0)
            } else if (_range === "YTD") {
                from = new Date(today.getFullYear(), 0, 1)
                to = new Date()
            }

            from = {
                day: from.getDate() < 10 ? "0" + from.getDate() : from.getDate(),
                month: (from.getMonth() + 1) < 10 ? "0" + (from.getMonth() + 1) : (from.getMonth() + 1),
                year: from.getFullYear()
            }
            to = {
                day: to.getDate() < 10 ? "0" + to.getDate() : to.getDate(),
                month: (to.getMonth() + 1) < 10 ? "0" + (to.getMonth() + 1) : (to.getMonth() + 1),
                year: to.getFullYear()
            }

            data.append("from", dateToDateStr(from, "00:00:00"))
            data.append("to", dateToDateStr(to, "23:59:59"))
        }
        dispatch(AnalyticsActions.dashboardReport({ filters: data }))
    }

    return (
        <OverallJSX
            ActivityLine={ActivityLine}
            UserActivity={UserActivity}
            AppsActivity={AppsActivity}
            BrowseActivity={BrowseActivity}
            setSelectedSession={setSelectedSession}
            range={range}
            setRange={handleRangeChange}
            setModal={setModal}
        >
            {selectedSession === null ? null :
                selectedSession.application_id === 1 ? <SprayverseSession modal={modal} setModal={setModal} selectedSession={selectedSession.session_id} /> :
                    selectedSession.application_id === 7 ? <WeldingSession modal={modal} setModal={setModal} selectedSession={selectedSession.session_id} /> : null}
        </OverallJSX>
    )
}

export default Overall