import { useState } from "react"
import { useSelector } from "react-redux"
import { Device, Organization } from "../../../Common/Interfaces/Entities"
import { OrganizationsState } from "../../../Redux/Organizations/Interface"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { RootState } from "../../../Redux/Store"
import DevicesListJSX from "./DevicesListJSX"


const DevicesList:React.FC<{organization:Organization, application_id : number | null}> = ({
    organization,
    application_id
}) => {
    const organizationReducer:OrganizationsState = useSelector((state:RootState) => state.organizations)
    const purchases:PurchasesState = useSelector((state:RootState) => state.shop)
    const [showMore, setShowMore] = useState(false)
    
    const organization_devices:Array<Device> = organizationReducer.device.devices.filter((d:Device) => 
        d !== null && 
        d !== undefined && 
        d.organization_id === organization.id &&
        (application_id === null || application_id === d.application_id)
    )

    const available = purchases.purchases.filter(pur => 
        pur !== null && pur !== undefined && pur.confirmed === 1 &&  
        pur.organization_id === organization.id 
        && pur.product.type === "license" &&
        (application_id === null || pur.product.application_id === application_id)
    )
    
    return <DevicesListJSX 
        application_id={application_id}
        loading={organizationReducer.device.readStatus !== "fulfilled"}
        deleting={organizationReducer.device.deleteStatus === "pending"}
        devices={showMore === true ? organization_devices : [...organization_devices].splice(0, 5)}
        count={organization_devices.length}
        showMore={showMore}
        setShowMore={setShowMore}
        available={available.length}
    />
}

export default DevicesList