import { insertInHash } from "../../Common/Functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { LanguagesState as SliceInterface } from "./Interface"


export const LanguageCreator = {
    createLanguage : createHandler({
        name: "language/languages/create",
        method: "POST",
        route: "/api/languages/languages",
        key: "language",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.languages.createStatus = "pending"
            state.languages.createMessage = "Creating language... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.languages.createStatus = "fulfilled"
            state.languages.createMessage = "Language created successfully..."
            state.languages.languages = insertInHash(state.languages.languages, action.payload.language, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.languages.createStatus = "rejected"
            state.languages.createMessage = action.payload.msg
            return state
        }
    }),
    readLanguage : createHandler({
        name: "language/languages/read",
        method: "GET",
        route: "/api/languages/languages",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.languages.readStatus = "pending"
            state.languages.readMessage = "Loading languages.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.languages.readStatus = "fulfilled"
            state.languages.readMessage = "Languages loaded succesfully."
            state.languages.languages = action.payload.languages

            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.languages.readStatus = "rejected"
            state.languages.readMessage = action.payload.msg
            return state
        }
    }),
    updateLanguage : createHandler({
        name: "language/languages/update",
        method: "PUT",
        route: "/api/languages/languages",
        key: "language",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.languages.updateStatus = "pending"
            state.languages.updateMessage = "Updating language... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.languages.updateStatus = "fulfilled"
            state.languages.updateMessage = "Language updated succesfully."
            state.languages.languages = insertInHash(state.languages.languages, action.payload.language, "id")
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.languages.updateStatus = "rejected"
            state.languages.updateMessage = action.payload.msg
            return state
        }
    }),
    createTranslation : createHandler({
        name: "language/translations/create",
        method: "POST",
        route: "/api/languages/translations",
        key: "translations",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.translations.createStatus = "pending"
            state.translations.createMessage = "Submitting translations... Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.translations.createStatus = "fulfilled"
            state.translations.createMessage = "Translations created successfully... "
            state.translations.translations = action.payload.translations
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.languages.createStatus = "rejected"
            state.languages.createMessage = action.payload.msg
            return state
        }
    }),
    readTranslation : createHandler({
        name: "language/translations/read",
        method: "GET",
        route: "/api/languages/translations",
        skipAuth: false,
        pending: (state:SliceInterface, action) => {
            state.translations.readStatus = "pending"
            state.translations.readMessage = "Loading UI translations.,. Please wait."
        },
        fulfilled: (state:SliceInterface, action) => {
            state.translations.readStatus = "fulfilled"
            state.translations.readMessage = "UI Translations loaded succesfully."
            state.translations.translations = action.payload.translations
            return state
        },
        rejected: (state:SliceInterface, action) => {
            state.translations.readStatus = "rejected"
            state.translations.readMessage = action.payload.msg
            return state
        }
    }),
}

export const [LanguageActions, LanguageReducers] = buildFromCreator(LanguageCreator)