import { useState } from "react"
import { User } from "../../../Common/Interfaces/Entities"
import { OrganizationsState } from "../../../Redux/Organizations/Interface"
import { UserState } from "../../../Redux/User/Interface"
import AddUser from "./AddUser"
import UserAccessBrowserJSX from "./UserAccessBrowserJSX"


const UserAccessBrowser: React.FC<{ UserReducer: UserState, OrganizationReducer: OrganizationsState, organization_id: number, application_id: number }> = ({
    UserReducer,
    OrganizationReducer,
    organization_id,
    application_id
}) => {

    const [modal, setModal] = useState<boolean>(false)
    const [search, setSearch] = useState<string>("")

    const org_users = UserReducer.users.filter((u: User) => u !== null && u !== undefined && u.organization_id === organization_id &&
        (search === "" || (u.username.toLowerCase().startsWith(search.toLowerCase())) ||
            (u.username).toLowerCase().startsWith(search.toLowerCase())))
    const org_users_ids = org_users.map(u => u.id)
    const app_users = OrganizationReducer.access.ApplicationAccess.filter(a => a !== null && a !== undefined && a.application_id === application_id && org_users_ids.includes(a.user_id))
    const app_users_ids = app_users.map(u => u.user_id)


    return <UserAccessBrowserJSX
        app_users={app_users}
        setModal={setModal}
        search={search}
        setSearch={setSearch}
    >
        <AddUser
            application_id={application_id}
            users={org_users.filter(u => app_users_ids.includes(u.id) === false)}
            modal={modal}
            setModal={setModal}
        />
    </UserAccessBrowserJSX>
}

export default UserAccessBrowser