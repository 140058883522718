import { IonAlert } from "@ionic/react";
export interface ConfirmationInterface { open: boolean, function: null | Function, message: string }

const Confirmation:React.FC<{showAlert: ConfirmationInterface, setShowAlert:Function}> = ({
    showAlert, setShowAlert
}) => (
    <IonAlert
        isOpen={showAlert.open}
        onDidDismiss={() => showAlert.function()}
        header="Alert!"
        message={showAlert.message}
        buttons={[
            {
                text: 'No', role: 'cancel',
                handler: () => setShowAlert({ open: false, function: null, message: "" })
            },
            {
                text: 'Yes', role: 'confirm',
                handler: () => showAlert.function()
            },
        ]}
    />
)

export default Confirmation