import { IonButton, IonInput, IonItem, IonLabel, IonList } from "@ionic/react"
import { useState } from "react";
import { HexColorPicker } from "react-colorful";
const ColorPicker = ({
    placeholder,
    name,
    handleChange,
    value
}) => {

    const [showing, setShowing] = useState(false)

    return (
        <IonList className='select-list' >
            <IonItem className='select-list'>
                <IonLabel className="color-picker-label" style={{"color" : value}} position="stacked">{placeholder}</IonLabel>
                {showing === true ? <span className="color-picker-container flex flex-col mt-4">
                    <HexColorPicker
                        color={value}
                        onChange={color => handleChange({
                            target: {
                                name: name,
                                value: color
                            }
                        })}
                    />
                    <IonButton 
                        color={value} 
                        style={{backgroundColor: value}}
                        onClick={e => setShowing(false)}
                    >Close</IonButton>
                </span> : <IonInput onIonChange={e => handleChange(e)} style={{"color" : value}} value={value} color={value} onClick={e => setShowing(true)} />}
            </IonItem>
        </IonList>
    )
}

export default ColorPicker