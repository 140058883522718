import { IonItem } from "@ionic/react"
import SetNewSetting from "./SetNewSetting"
import UpdateSetting from "./UpdateSetting"
import Translate from "../../components/Translate"



const SettingsContainerJSX = ({
    filled,
    unfilled,
    current,
    children
}) => (
    <div className="w-full">
        {children}
        {filled.length === 0 ? null : <span className="block">
            <div className="w-full px-6 py-3 bg-cythero-primary flex items-center mb-12">
                <h1 className="text-white text-3xl"><Translate label="Organization specific settings " /> </h1>
            </div>

            <div className="w-full overflow-x-auto">
                <IonItem className="border-b border-solid border-slate-800 w-[950px] mx-auto rounded-tl rounded-tr">
                    <span className="w-full flex p-2 items-center justify-between">
                        <h1 className="w-3/12 text-slate-700 font-semibold"><Translate label="Application" /></h1>
                        <h1 className="w-5/12 text-slate-700 font-semibold"><Translate label="Parameter" /></h1>
                        <h1 className="text-center w-2/12 text-slate-700 font-semibold"><Translate label="Value" /></h1>
                        <h1 className="text-center w-1/12 text-slate-700 font-semibold"><Translate label="Action" /></h1>
                    </span>
                </IonItem>
                {filled.map((f, idx) => <UpdateSetting
                    key={f.id}
                    setting={f}
                    current={f.settings.find(s => s.organization_id === current.organization_id)}
                    set_rounded={idx === filled.length - 1}
                />)}
            </div>

        </span>}

        {unfilled.length === 0 ? null : <span className="block my-6 mx-5">
            <h3 className="mb-4 text-lg font-bold text-slate-700 w-[950px] mx-auto">Settings you have not set yet: </h3>

            <div className="w-full overflow-x-scroll">
                <IonItem className="border-b border-solid border-slate-800 w-[950px]  mx-auto">
                    <span className="w-full flex p-2 items-center justify-between">
                        <h1 className="w-3/12 text-slate-700 font-semibold"><Translate label="Application" /></h1>
                        <h1 className="w-5/12 text-slate-700 font-semibold"><Translate label="Parameter" /></h1>
                        <h1 className="text-center w-2/12 text-slate-700 font-semibold"><Translate label="Value" /></h1>
                        <h1 className="text-center w-1/12 text-slate-700 font-semibold"><Translate label="Action" /></h1>
                    </span>
                </IonItem>
                {unfilled.map((f, idx) => <SetNewSetting
                    key={f.id}
                    setting={f}
                    organization_id={current.organization_id}
                    set_rounded={idx === unfilled.length - 1}
                />)}
            </div>
        </span>}
    </div>
)

export default SettingsContainerJSX