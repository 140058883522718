import { ApplicationsState } from "../../../../Redux/Applications/Interface"
import { dateToDateStr } from "../../../../components/Form/DateSelect"
import { PurchasesState } from "../../../../Redux/Purchases/Interface"
import { AnalyticsActions } from "../../../../Redux/Analytics/Actions"
import { Analytics } from "../../../../Redux/Analytics/Interface"
import { AppDispatch, RootState } from "../../../../Redux/Store"
import { UserActions } from "../../../../Redux/User/Actions"
import { UserState } from "../../../../Redux/User/Interface"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { prepareActivityLine } from "../pret"
import { useEffect, useState } from "react"
import SprayverseJSX from "./SprayverseJSX"
import UserSessions from "./UserSessions"
import UserOwnsAnalytics from "../../../../components/UserOwnsAnalytics/UserOwnsAnalytics"
import Loading from "../../../../components/Loading"

const Sprayverse: React.FC = props => {
    const dispatch = useDispatch<AppDispatch>()
    const id = parseInt(useParams<{ id: string }>().id)

    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)
    const analytics: Analytics = useSelector((state: RootState) => state.analytics)
    const ShopReducer: PurchasesState = useSelector((state: RootState) => state.shop)

    const [range, setRange] = useState<"OVERALL" | "WEEK" | "MONTH" | "YTD">("OVERALL")
    const [selectedUser, setSelecterUser] = useState(null)

    useEffect(() => {
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
        // if (analytics.sprayverseReport.status === "pending") return
        const data = new FormData()
        data.append("org_id", UserReducer.current.organization_id.toString())
        dispatch(AnalyticsActions.sprayverseReport({ filters: data }))
    }, [])

    if (UserReducer.readUserStatus !== "fulfilled") return <Loading isOpen={true} message="Loading report pre-requisits... Please wait." />
    if (analytics.sprayverseReport.status !== "fulfilled") return <Loading isOpen={true} message="Loading report data... Please wait." />

    const ActivityLine = prepareActivityLine(analytics.sprayverseReport.data.timeline)
    const application = AppsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === id)

    let table_data = []
    try {
        table_data = Object.keys(analytics.sprayverseReport.data.user)
            .sort((a, b) => analytics.sprayverseReport.data.user[a]["time_spent"] > analytics.sprayverseReport.data.user[b]["time_spent"] ? 1 : -1).map(key => ({
                id: UserReducer.users.find(u => u !== null && u !== undefined && u.id === parseInt(key)).id,
                user_name: UserReducer.users.find(u => u !== null && u !== undefined && u.id === parseInt(key)).username,
                user: UserReducer.users.find(u => u !== null && u !== undefined && u.id === parseInt(key)),
                time_spent: Math.floor(analytics.sprayverseReport.data.user[key].time_spent / 60) + " hrs " + Math.round(analytics.sprayverseReport.data.user[key].time_spent % 60) + " mins",
                material_used: analytics.sprayverseReport.data.user[key].material_used.toFixed(2) + " / " + analytics.sprayverseReport.data.user[key].target_material_used,
                coverage: analytics.sprayverseReport.data.user[key].average_coverage.toFixed(2) + " / " + analytics.sprayverseReport.data.user[key].coverage_target,
                grade: analytics.sprayverseReport.data.user[key].average_grade,
                number_sessions: analytics.sprayverseReport.data.user[key].num_sessions
            }))
    } catch (e) {
        console.log(analytics.sprayverseReport.data.user)
        console.log(e)
    }

    const handleRangeChange = _range => {
        setRange(_range)

        const data = new FormData()
        data.append("org_id", UserReducer.current.organization_id.toString())
        if (_range !== "OVERALL") {
            const today = new Date;
            let from, to
            console.log(range)
            if (_range === "WEEK") {
                from = new Date(today.setDate(today.getDate() - today.getDay()))
                to = new Date(today.setDate(today.getDate() - today.getDay() + 6))
            } else if (_range === "MONTH") {
                from = new Date(today.getFullYear(), today.getMonth(), 1)
                to = new Date(today.getFullYear(), today.getMonth() + 1, 0)
            } else if (_range === "YTD") {
                from = new Date(today.getFullYear(), 0, 1)
                to = new Date()
            }

            from = {
                day: from.getDate() < 10 ? "0" + from.getDate() : from.getDate(),
                month: (from.getMonth() + 1) < 10 ? "0" + (from.getMonth() + 1) : (from.getMonth() + 1),
                year: from.getFullYear()
            }
            to = {
                day: to.getDate() < 10 ? "0" + to.getDate() : to.getDate(),
                month: (to.getMonth() + 1) < 10 ? "0" + (to.getMonth() + 1) : (to.getMonth() + 1),
                year: to.getFullYear()
            }

            data.append("from", dateToDateStr(from, "00:00:00"))
            data.append("to", dateToDateStr(to, "23:59:59"))
        }
        dispatch(AnalyticsActions.sprayverseReport({ filters: data }))
    }

    return (
        <UserOwnsAnalytics
            application={application}
            purchases={ShopReducer.purchases}
            user={UserReducer.current}
        >
            <SprayverseJSX
                ActivityLine={ActivityLine}
                SettingsStatistics={analytics.sprayverseReport.data.settings_statistics}
                range={range}
                setRange={handleRangeChange}
                application={application}
                table_data={table_data}
                setSelecterUser={setSelecterUser}
            >
                <UserSessions
                    sessions={analytics.sprayverseReport.data.timeline.filter(tl => tl.user_id === selectedUser)}
                    user={selectedUser === null ? null : UserReducer.users.find(u => u !== null && u !== undefined && u.id === selectedUser)}
                    setSelectrUser={setSelecterUser}
                />
            </SprayverseJSX>
        </UserOwnsAnalytics>
    )
}

export default Sprayverse