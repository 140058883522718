import { BsFillPencilFill, BsTrashFill } from "react-icons/bs"
import { HiUserGroup } from "react-icons/hi"
import { BiUserCircle } from "react-icons/bi"
import { NavLink } from "react-router-dom"
import { Fragment } from "react"
import { IonItem, IonPopover } from "@ionic/react"

import { FaEllipsisV } from "react-icons/fa"
import { MdPersonRemoveAlt1 } from "react-icons/md"

const UserCard = ({
    handleDelete,
    handleKick,
    current,
    popover,
    togglePopover,
    orgnizations,
    firstName,
    lastName,
    username,
    email,
    organization_id,
    type,
    id
}) => (
    <Fragment>
        <div className="mx-auto right-0 mt-2 w-60">
            <div className="bg-white rounded overflow-hidden shadow-lg">
                <div className="text-center px-1 py-2" style={{
                    "backgroundImage" : "url('assets/images/pattern.jpeg')",
                    "backgroundPositionX": (id %100)+"%",
                    "backgroundPositionY": (id %100)+"%",
                }}>
                    <FaEllipsisV onClick={e => togglePopover({ open: true, event: e.nativeEvent })} className="text-white hover:cursor-pointer w-5" />
                    <IonPopover
                        onDidDismiss={e => togglePopover({ open: false, event: undefined })}
                        isOpen={popover.open} event={popover.event}
                        size="auto" side="bottom" alignment="center"
                    >
                        {current.type_id === 4 ? <IonItem className="text-red-500" button={true} detail={false} onClick={handleDelete}>
                            <BsTrashFill className="mr-3" />
                            <p>Delete</p>
                        </IonItem> : null}
                        {current.type_id >= 3 ? <IonItem className="text-blue-500" button={true} detail={false} onClick={handleKick}>
                            <MdPersonRemoveAlt1 className="mr-3" />
                            <p>Leave Organization</p>
                        </IonItem> : null}
                    </IonPopover>
                    <BiUserCircle className="h-24 w-24 text-white rounded-full mx-auto" width="32" height="32" />
                    <span className="flex flex-col items-center justify-center ">
                        <p className="pt-2 text-base font-semibold text-gray-50">{username}</p>
                        <p className="text-sm text-gray-100">({type.name})</p>
                        <p className="text-sm text-gray-100">{email}</p>
                    </span>
                </div>
                <div>
                    <span className="px-4 py-2 flex">
                        <HiUserGroup className="text-cythero-primary" />
                        <div className="pl-3">
                            <p className="text-sm font-medium text-gray-800 leading-none">{organization_id === null ? "Not in an organization" : orgnizations[organization_id]?.name}</p>
                            <p className="text-xs text-gray-500">User Organization</p>
                        </div>
                    </span>
                    <NavLink to={"/users/edit/" + id} className="px-4 py-2 hover:bg-gray-100 flex">
                        <BsFillPencilFill className="text-cythero-primary" />
                        <div className="pl-3">
                            <p className="text-sm font-medium text-gray-800 leading-none">Edit User</p>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    </Fragment>
)

export default UserCard