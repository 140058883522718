import { Fragment } from "react"
import { NavLink } from "react-router-dom"
import Info from "../../../components/Info/Info"


const RegisterJSX = props => {

    return (
        <Fragment>
            <div className="text-center lg:mt-12">
                <h2 className="text-4xl font-bold text-center text-gray-200 lg:text-gray-700">
                    <span className="text-cythero-primary">C</span>ythero<sup className="text-cythero-primary">VR</sup>
                </h2>
                <p className="mt-3 text-gray-200 lg:text-gray-500 ">Sign up to access the future</p>
            </div>

            <div className="mt-8">
                <form>
                    <div>
                        <label htmlFor="firstName" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">First Name</label>
                        <input type="text" name="firstName" onChange={e => props.handleChange(e)} value={props.user.firstName} placeholder="Cosmos" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="lastName" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Last Name</label>
                        <input type="text" name="lastName" onChange={e => props.handleChange(e)} value={props.user.lastName} placeholder="Redshift" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>
                    <div className="mt-2">
                        <label htmlFor="email" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Email Address</label>
                        <input type="email" name="email" onChange={e => props.handleChange(e)} value={props.user.email} placeholder="centaurus@example.com" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="username" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Username</label>
                        <input type="username" name="username" onChange={e => props.handleChange(e)} value={props.user.username} placeholder="Andromeda" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="password" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Password</label>
                        <input type="password" name="password" onChange={e => props.handleChange(e)} value={props.user.password} placeholder="Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>

                    <div className="mt-2">
                        <label htmlFor="confirmPassword" className="block mb-2 text-sm text-gray-200 lg:text-gray-600">Confirm Password</label>
                        <input type="password" name="confirmPassword" onChange={e => props.handleChange(e)} value={props.user.confirmPassword} placeholder="Confirm Password" className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-cythero-primary focus:ring-cythero-primary focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>

                    <div className="mt-4">
                        {props.submitted === false ? <Fragment>
                            <button
                                onClick={e => props.handleSubmit(e, "user")}
                                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cythero-primary rounded-md hover:bg-cythero-light focus:outline-none focus:bg-cythero-light focus:ring focus:ring-cythero-light focus:ring-opacity-50">
                                Register User
                            </button>
                            <h2 className="text-gray-200 lg:text-gray-600 text-center my-2">OR</h2>
                            <button
                                onClick={e => props.handleSubmit(e, "organization")}
                                className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-cythero-primary rounded-md hover:bg-cythero-light focus:outline-none focus:bg-cythero-light focus:ring focus:ring-cythero-light focus:ring-opacity-50">
                                Register Organization
                            </button> </Fragment> : <Info type={props.userSlice.registerStatus} message={props.userSlice.registerMessage} />}
                    </div>

                </form>

                <p className="mt-6 text-sm text-center text-gray-200 lg:text-gray-400 lg:mb-12">Already have an account? <NavLink to="/auth/login" className="text-cythero-light lg:text-cythero-primary focus:outline-none focus:underline hover:underline font-bold">Log In</NavLink>.</p>
            </div>
        </Fragment>
    )
}

export default RegisterJSX