import { Applications } from "../../../Common/Interfaces/Entities"
import ApplicationJsx from "./ApplicationJsx"

interface ApplicationProp {
    application: Applications
}

const Application:React.FC<ApplicationProp> = ({
    application
}) =>  <ApplicationJsx
        id={application.id}
        name={application.name}
        description={application.description}
        cover_path={application.cover_path}
        primary_color={application.primary_color}
    />

export default Application