import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { STATIC_URL } from "../../Common/constants";
import { Cart } from "../../Common/Interfaces/Entities";
import { ApplicationsState } from "../../Redux/Applications/Interface";
import { PurchaseActions } from "../../Redux/Purchases/Actions";
import { AppDispatch } from "../../Redux/Store";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai"
import { IonButton } from "@ionic/react";
import Translate from "../../components/Translate";


const CartItem: React.FC<{ item: Cart, appsReducer: ApplicationsState }> = ({
    item, appsReducer
}) => {
    const dispatch = useDispatch<AppDispatch>()

    const [quantity, setQuantity] = useState(item.quantity)

    useEffect(() => {
        const cleanTimeout = setTimeout(() => {
            const form = new FormData()
            form.append("id", item.id.toString())
            form.append("quantity", quantity.toString())
            dispatch(PurchaseActions.updateCart({ cart: form }))
        }, 650)

        return () => clearTimeout(cleanTimeout)
    }, [quantity])

    const deleteItem = e => {
        dispatch(PurchaseActions.deleteCart({ arguments: { id: item.id } }))
    }

    const product_type = item.dlc_id !== null ? "dlc" : item.application_id !== null ? "application" : "package"
    if (product_type === "dlc") {
        const product = appsReducer.dlc.dlcs.find(dlc => dlc !== null && dlc !== undefined && dlc.id === item.dlc_id)
        if (product === undefined) return <></> 
        return (
            <div className="m-4 flex border-solid border-b-2 border-slate-200 pb-1">
                <img src={STATIC_URL + product.cover_path} className="w-0 md:w-12 md:h-12 md:mr-3 lg:w-16 lg:h-16" />
                <div className="w-full md:w-1/3 flex flex-col justify-center">
                    <h1 className="font-semibold text-slate-800 lg:text-lg text-base">{product.name}</h1>
                    <h1 className="font-semibold text-cythero-primary text-sm">€{(product.price * quantity).toFixed(2)} EUR</h1>
                    <h1>{appsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === product.application_id).name}</h1>
                </div>
                <div className="w-3/4 display flex items-center justify-between">
                    <span className="w-3/4 flex items-center justify-center">
                        {product.dlc_type_id !== 5 &&product.multiple_purchase === 1 && quantity > 1 ? <AiOutlineMinus onClick={e => setQuantity(quantity - 1)} className="w-[20px]" /> : <div className="w-[20px]"></div>}
                        {product.dlc_type_id !== 5 &&product.multiple_purchase === 1 ? <input className="w-[40px] text-center shadow" value={quantity} readOnly={true} /> : <div className="w-[40px]"></div>}
                        {product.dlc_type_id !== 5 &&product.multiple_purchase === 1 ? <AiOutlinePlus className="w-[20px]" onClick={e => setQuantity(quantity + 1)} /> : <div className="w-[20px]"></div>}
                    </span>
                    <span className="w-2/4 md:w-1/4 flex items-center justify-center ml-4">
                        <IonButton color={"danger"} onClick={e => deleteItem(e)}> <Translate label="DELETE" /> </IonButton>
                    </span>
                </div>
            </div>
        )
    } else if (product_type === "package") {
        const product = appsReducer.packages.packages.find(pkg => pkg !== null && pkg !== undefined && pkg.id === item.package_id)
        if (product === undefined) return <></> 
        return (
            <div className="m-4 flex border-solid border-b-2 border-slate-200 pb-1">
                <img src={STATIC_URL + product.cover_path} className="w-0 md:w-12 md:h-12 md:mr-3 lg:w-16 lg:h-16" />
                <div className="w-full md:w-1/3 flex flex-col justify-center">
                    <h1 className="font-semibold text-slate-800 lg:text-lg text-base">{product.name}</h1>
                    <h1 className="font-semibold text-cythero-primary text-sm">€{(product.price * quantity).toFixed(2)} EUR</h1>
                    <h1>{appsReducer.application.applications.find(a => a !== null && a !== undefined && a.id === product.application_id).name}</h1>
                </div>
                <div className="w-3/4 display flex items-center justify-between">
                    <span className="w-3/4 flex items-center justify-center">
                        {quantity > 1 ? <AiOutlineMinus onClick={e => setQuantity(quantity - 1)} className="w-[20px]" /> : <div className="w-[20px]"></div>}
                        <input className="w-[40px] text-center shadow" value={quantity} readOnly={true} />
                        <AiOutlinePlus className="w-[20px]" onClick={e => setQuantity(quantity + 1)} />
                    </span>
                    <span className="w-2/4 md:w-1/4 flex items-center justify-center ml-4">
                        <IonButton color={"danger"} onClick={e => deleteItem(e)}><Translate label="DELETE" /></IonButton>
                    </span>
                </div>
            </div>
        )
    } else {
        const product = appsReducer.application.applications.find(app => app !== null && app !== undefined && app.id === item.application_id)
        return (
            <div className="m-4 flex border-solid border-b-2 border-slate-200 pb-1">
                <img src={STATIC_URL + product.logo_path} className="w-0 md:w-12 md:h-12 md:mr-3 lg:w-16 lg:h-16" />
                <div className="w-full md:w-1/3 flex flex-col justify-center">
                    <h1 className="font-semibold text-slate-800 lg:text-lg text-base">{product.name}</h1>
                    <h1 className="font-semibold text-cythero-primary text-sm">€{(product.price * quantity).toFixed(2)} EUR</h1>
                </div>
                <div className="w-3/4 display flex items-center justify-between">
                    <span className="w-3/4 flex items-center justify-center">
                        <div className="w-[20px]"></div>
                        <div className="w-[40px]"></div>
                        <div className="w-[20px]"></div>
                    </span>
                    <span className="w-2/4 md:w-1/4 flex items-center justify-center ml-4">
                        <IonButton color={"danger"} onClick={e => deleteItem(e)}><Translate label="DELETE" /></IonButton>
                    </span>
                </div>
            </div>
        )
    }
}

export default CartItem