import CreateOrganizationForm from "./RegisterOrganization/CreateOrganizationForm"
import EditOrganizationForm from "./OrganizationForms/EditOrganizationForm"
import OrganizationUsersContainer from "./Users/OrganizationUsersContainer"
import OrganizationAddress from "./OrganizationForms/OrganizationAddress"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import PurchaseHistory from "./PurchaseHistory/PurchaseHistory"
import { PurchaseActions } from "../../Redux/Purchases/Actions"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserState } from "../../Redux/User/Interface"
import { useDispatch, useSelector } from "react-redux"
import { UserActions } from "../../Redux/User/Actions"
import OrganizationCard from "./OrganizationCard"
import { useParams } from "react-router-dom"

import { useEffect } from "react"
import Loading from "../../components/Loading"
 

const OrganizationContainer = () => {
    const selected = useParams<{ option: string }>().option
    const dispatch = useDispatch<AppDispatch>()

    const organizationReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)
    const userReducer: UserState = useSelector((state: RootState) => state.user)


    useEffect(() => {
        dispatch(OrganizationActions.readOrganization({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ arguments: { hashed: true, organization_id : userReducer.current.organization_id } }))
        dispatch(UserActions.readUserTypes({ arguments: { hashed: true } }))
        dispatch(UserActions.readAddresses({ arguments: { hashed: true } }))
        dispatch(UserActions.readCountries({ arguments: { hashed: true } }))
        dispatch(UserActions.readUsers({ arguments: { hashed: true } }))
    }, [dispatch])

    if (
        organizationReducer.organization.readStatus !== "fulfilled" ||
        userReducer.readUserStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading organization data... Please wait." />


    if (userReducer.current.organization_id === null || (userReducer.current.type_id === 4 && selected === "new")) return (
        <div className="mt-4 lg:mt-20 px-4 mb-24 max-w-[800px] mx-auto">
            <CreateOrganizationForm organizationReducer={organizationReducer} />
        </div>
    )


    let currentOrganization = null

    if (selected !== "new") currentOrganization = organizationReducer.organization.organizations[parseInt(selected)]
    else currentOrganization = organizationReducer.organization.organizations.find(org => org !== null && org !== undefined && org.id === userReducer.current.organization_id)

    if (organizationReducer.organization.readStatus !== 'fulfilled' || userReducer.readUserStatus !== 'fulfilled') return <Loading isOpen={true} message="Loading data... Please wait." />
    if (organizationReducer.organization.createStatus === "pending") return <Loading isOpen={true} message="Setting up your organization... Please wait." />
    if (currentOrganization === undefined) return <div>404</div>
    return (
        <div className="mx-3 lg:mx-4 mt-6 mb-24">
            <div className="lg:grid lg:grid-cols-5 gap-4">
                <div className="col-span-2">
                    <OrganizationCard organization={currentOrganization} as_link={false} />
                    <OrganizationUsersContainer organization={currentOrganization} />
                    <PurchaseHistory organization={currentOrganization} application_id={null} />
                </div>
                <div className="col-span-3">
                    <EditOrganizationForm organization={currentOrganization} />
                    {/* {userReducer.address.readStatus === "fulfilled" ? <OrganizationAddress organization={currentOrganization} /> : null} */}
                </div>
            </div>
        </div>
    )
}

export default OrganizationContainer