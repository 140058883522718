import { IonButton } from "@ionic/react"
import { print_pdf } from "../../../../Common/print"
import ChartContainer from "../../../Analytics/Charts/ChartContainer"
import PieChart from "../../../Analytics/Charts/PieChart"
import Translate from "../../../../components/Translate"
import { getTranslation } from "../../../../Common/Functions"
import { DataGrid } from "@mui/x-data-grid"

const WeldVRJSX = ({
    settings_statistics,
    methods_pie,
    parts_pie,
    general_info,
    range,
    setRange,
    application,
    table_data,
    setSelecterUser,
    children
}) => {
    return (
        <div className="w-full">
            {children}
            <div id="overall-contianer" className="w-[1050px] max-w-[95%] mx-auto my-8"> {/* Container */}
                <div className="flex flex-col md:flex-row justify-between items-center mb-8">
                    <h1 className="text-xl font-semibold text-slate-700">WeldVR <Translate label="Dashboard" /></h1>
                    <div className="mt-3 md:mt-0">
                        {["OVERALL", "WEEK", "MONTH", "YTD"].map(item => (
                            <IonButton
                                color={range === item ? "light" : "primary"}
                                onClick={e => {
                                    e.preventDefault()
                                    setRange(item)
                                }}
                            >
                                <Translate label={item} />
                            </IonButton>
                        ))}
                        <IonButton className="ml-5" onClick={e => {
                            e.preventDefault()
                            print_pdf(document.getElementById("printable_sprayverse_dashboard").cloneNode(true), "WeldVR Dashboard Report", application)
                        }}><Translate label="EXPORT" /></IonButton>
                    </div>
                </div>
                <div id="printable_sprayverse_dashboard" className="print:w-[1100px] print:mx-auto">
                    <div className="mb-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 print:grid-cols-4 gap-1">
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            {/* <h1 className="text-slate-600 pt-2 px-2 mb-2 text-sky-500 font-semibold"><Translate label="Electrode Used (metes)" />:</h1> */}
                            <h1 className="text-sm text-slate-600 pt-2 px-1 mb-2 text-violet-500 font-semibold">Coupons Used:</h1>
                            <h1 className="text-xs text-slate-800 font-semibold flex justify-between items-center px-1">
                                <span>{settings_statistics["Coupons"]["Coupons Used"]} welding coupons</span>
                            </h1>
                            <h1 className="text-sm font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-fuchsia-500 to-violet-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{"$"}{settings_statistics["Coupons"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            {/* <h1 className="text-slate-600 pt-2 px-2 mb-2 text-sky-500 font-semibold"><Translate label="Electrode Used (metes)" />:</h1> */}
                            <h1 className="text-sm text-slate-600 pt-2 px-1 mb-2  text-sky-500 font-semibold">MIG Material Used:</h1>
                            <h1 className="text-xs text-slate-800 font-semibold flex justify-between items-center px-1">
                                <span>{settings_statistics["Mig Electrode"]["Electrode Used (meters)"].toFixed(0)} meters of wire</span>
                            </h1>
                            <h1 className="text-sm font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-sky-500 to-cyan-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{"$"}{settings_statistics["Mig Electrode"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            {/* <h1 className="text-slate-600 pt-2 px-2 mb-2 text-sky-500 font-semibold"><Translate label="Electrode Used (metes)" />:</h1> */}
                            <h1 className="text-sm text-slate-600 pt-2 px-1 mb-2  text-teal-500 font-semibold">TIG Material Used:</h1>
                            <h1 className="text-xs text-slate-800 font-semibold flex justify-between items-center px-1">
                                <span>{settings_statistics["Tig Filler Rod"]["Rods Used"]} rods used</span>
                            </h1>
                            <h1 className="text-sm font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-teal-600 to-teal-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{"$"}{settings_statistics["Tig Filler Rod"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            {/* <h1 className="text-slate-600 pt-2 px-2 mb-2 text-sky-500 font-semibold"><Translate label="Electrode Used (metes)" />:</h1> */}
                            <h1 className="text-sm text-slate-600 pt-2 px-1 mb-2  text-indigo-500 font-semibold">STICK Material Used:</h1>
                            <h1 className="text-xs text-slate-800 font-semibold flex justify-between items-center px-1">
                                <span>{settings_statistics["Stick Electrode"]["Electrodes Used"]} electrodes used</span>
                            </h1>
                            <h1 className="text-sm font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-indigo-600 to-indigo-400 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{"$"}{settings_statistics["Stick Electrode"]["Money Saved"].toFixed(2)}</span>
                            </h1>
                        </span>
                        <span className="md:col-span-1 bg-white print:shadow-sm shadow-md rounded-lg">
                            {/* <h1 className="text-slate-600 pt-2 px-2 mb-2 text-sky-500 font-semibold"><Translate label="Electrode Used (metes)" />:</h1> */}
                            <h1 className="text-sm text-slate-600 pt-2 px-1 mb-2 text-emerald-500 font-semibold">Total Savings:</h1>
                            <h1 className="text-xs text-slate-800 font-semibold flex justify-between items-center px-1">
                                <div className="opacity-0">.</div>
                            </h1>
                            <h1 className="text-sm font-semibold flex justify-between items-center p-3 mt-4 bg-gradient-to-r from-emerald-600 to-emerald-500 text-white rounded-bl rounded-br">
                                <span><Translate label="Savings" />: </span>
                                <span>{"$"}{(
                                    settings_statistics["Mig Electrode"]["Money Saved"] + 
                                    settings_statistics["Stick Electrode"]["Money Saved"] + 
                                    settings_statistics["Tig Filler Rod"]["Money Saved"] + 
                                    settings_statistics["Coupons"]["Money Saved"]).toFixed(2)}</span>
                            </h1>
                        </span>
                    </div>
                    {general_info.number_of_sessions === 0 ? <h1 className="text-center text-slate-800 font-medium">Not enough data to display all the available statistics.</h1> :
                        <div className="gap-3 grid grid-cols-3 w-full justify-center items-center" style={{ "pageBreakAfter": "always" }}>
                            <span className="print:col-span-1 col-span-3 md:col-span-1">
                                <ChartContainer
                                    data={methods_pie}
                                    maxHeight={160}
                                    maxWidth={undefined}
                                    title={getTranslation("Training Methods Distribution")}
                                    Chart={PieChart}
                                />
                            </span>
                            <span className="print:col-span-1 col-span-3 md:col-span-1">
                                <ChartContainer
                                    data={parts_pie}
                                    maxHeight={160}
                                    maxWidth={undefined}
                                    title={getTranslation("Training Parts Distribution")}
                                    Chart={PieChart}
                                />
                            </span>
                            <div className="print:col-span-1 bg-white col-span-3 md:col-span-1 p-2 w-full shadow rounded-md w-full relative min-h-[260px]">
                                <h1 className="p-3 pt-3 pb-2 font-bold text-slate-700 text-lg"><Translate label="General Information" /></h1>
                                <p className="pl-3 text-slate-700 text-[15px]"><Translate label="Number of sessions" />: <b>{general_info.number_of_sessions}</b> </p>
                                <p className="pl-3 text-slate-700 text-[15px]"><Translate label="Total Time Spent" />: <b>{general_info.time_spent_welding} min</b> </p>
                                <p className="pl-3 text-slate-700 text-[15px]"><Translate label="Average Work Angle" />: <b>{general_info.avg_work_angle}</b> (Ref. 20-40)</p>
                                <p className="pl-3 text-slate-700 text-[15px]"><Translate label="Average Travel Angle" />: <b>{general_info.avg_travel_angle}</b> (Ref. 15-30)</p>
                                <p className="pl-3 text-slate-700 text-[15px]"><Translate label="Average Speed" />: <b>{general_info.avg_speed}</b> (Ref. 8-15)</p>
                                <p className="pl-3 text-slate-700 text-[15px]"><Translate label="Average Distance" />: <b>{general_info.avg_distance}</b> (Ref. 15-30)</p>
                                {/* <img src="https://weldvr.com/wp-content/uploads/2023/09/3.png" className="opacity-10 w-32 absolute right-8 bottom-8" /> */}
                            </div>
                        </div>}

                    <div
                        className={`print:hidden bg-white my-5 print:w-[1100px] mx-auto rounded-xl overflow-hidden print:shadow-sm shadow-md print:block mui-table-contianer-printable`}
                        style={{
                            maxHeight: ((table_data.length * 52) + 100) + "px"
                        }}
                    >
                        <DataGrid
                            rows={table_data}
                            columns={[
                                { field: 'user_name', headerName: 'User', width: 250 },
                                { field: 'time_spent', headerName: 'Total Time Spent', width: 150 },
                                { field: 'most_used_method', headerName: 'Most Used Method', width: 150 },
                                { field: 'most_welded_part', headerName: 'Most Welded Part', width: 150, },
                                { field: 'number_sessions', headerName: 'Number Of Sessions', width: 150, type: "number" },
                            ]}
                            disableColumnSelector={true}
                            disableRowSelectionOnClick={true}
                            onRowClick={e => setSelecterUser(e.id)}
                            isRowSelectable={() => false}
                        />
                    </div>
                    {table_data.length === 0 ? null : <div
                        className="hidden print:block overflow-x-scroll rounded-xl mb-24 mt-2"
                    >
                        <table
                            className="w-full rounded-lg overflow-hidden min-w-[800px] shadow pb-12"
                            style={{ borderTop: "10px solid #f3f4f6", borderBottom: "10px solid #f3f4f6" }}
                        >
                            <thead>
                                <tr>
                                    <th className="cursor-pointer w-1/5 py-2 px-1 border border-white-400 bg-slate-800 text-white"><Translate label="User" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Total Time Spent" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Most Used Method" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Most Welded Part" /></th>
                                    <th className="cursor-pointer w-1/5 p-2 border border-white-400 bg-slate-800 text-white"><Translate label="Number of sessions" /></th>
                                </tr>
                            </thead>
                            <tbody className="bg-white">
                                {table_data.map(data =>
                                    <tr key={data.user.id} onClick={e => setSelecterUser(data.user.id)} className="cursor-pointer hover:bg-slate-100">
                                        <td className="text-center p-2 shadow">{data.user.username}</td>
                                        <td className="text-center p-2 shadow">{data.time_spent}</td>
                                        <td className="text-center p-2 shadow">{data.most_used_method}</td>
                                        <td className="text-center p-2 shadow">{data.most_welded_part}</td>
                                        <td className="text-center p-2 shadow">{data.number_sessions}</td>
                                    </tr>)}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default WeldVRJSX