
import { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useParams } from "react-router"
import { ApplicationActions } from "../../../Redux/Applications/Actions"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { PurchaseActions } from "../../../Redux/Purchases/Actions"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserState } from "../../../Redux/User/Interface"
import ManageBrandingJSX from "./ManageBrandingJSX"
import Loading from "../../../components/Loading"

const ManageBranding: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const id: number = parseInt(useParams<{ id: string }>().id)
    const AppsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const PurchaseReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const UserReducer: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(ApplicationActions.readApplication({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readDlcImages({ arguments: { hashed: true } }))
        dispatch(PurchaseActions.readPurchases({ arguments: { hashed: true, organization_id: UserReducer.current.organization_id } }))
        dispatch(ApplicationActions.readDlcs({ arguments: { hashed: true } }))
        dispatch(ApplicationActions.readImageType({ arguments: {} }))
    }, [dispatch])

    // useEffect(() => {
    //     if (AppsReducer.dlc_image.createStatus === "fulfilled")
    // }, [AppsReducer.dlc_image.createStatus, AppsReducer.dlc_image.updateStatus])

    if (UserReducer.current.type_id < 3) return <Redirect to={"/dashboard"} />
    if (
        AppsReducer.application.readStatus !== "fulfilled" ||
        AppsReducer.image_types.readStatus !== "fulfilled" ||
        AppsReducer.dlc_image.readStatus !== "fulfilled" ||
        AppsReducer.dlc.readStatus !== "fulfilled" ||
        PurchaseReducer.readStatus !== "fulfilled"
    ) return <Loading isOpen={true} message="Loading branding data... Please wait." />
    const purchase = PurchaseReducer.purchases[id]
    const branding = AppsReducer.dlc.dlcs[purchase?.dlc_id]
    const application = AppsReducer.application.applications[branding?.application_id]
    const images = AppsReducer.dlc_image.dlc_images.filter(img => img !== null && img !== undefined && img.purchase_id === id)
    const image_types = AppsReducer.image_types.types.filter(type => type !== null && type !== undefined && type.dlc_id === branding?.id)
    
    return (
        <Fragment>
            <Loading isOpen={PurchaseReducer.updateStatus === "pending"} message={PurchaseReducer.updateMessage} />
            <ManageBrandingJSX
                purchase={purchase}
                branding={branding}
                application={application}
                images={images}
                image_types={image_types}
                dlc_images={AppsReducer.dlc_image}
            />
        </Fragment>
    )
}

export default ManageBranding