import { Applications, User } from "../../../Common/Interfaces/Entities"

export const prepareActivityLine = (array: Array<{
    session_end: string
    session_id: string
    session_start: string
    application_id: number
    time_spent: number
    user_id: number
}>) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const ret = {
        "1": {},
        "7": {}
    }
    let _array = [...array].sort((a, b) => a.session_start > b.session_start ? 1 : -1)
    for (const item of _array) {
        if (Object.keys(ret).includes(item.application_id.toString()) === false) ret[item.application_id.toString()] = {}
        const date = new Date(item.session_start)
        const key = months[date.getMonth()] + " " + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " " + date.getFullYear()
        for (const app in ret) if (Object.keys(ret[app]).includes(key) === false) ret[app][key] = 0
        ret[item.application_id.toString()][key] += item.time_spent
    }
    const _ret = {}
    for (const key in ret) {
        if (Object.keys(_ret).includes(key) === false) _ret[key] = []
        for (const _key in ret[key]) {
            _ret[key].push({
                x: _key,
                y: ret[key][_key]
            })
        }
    }
    return _ret
}

export const prepareSessionDataLine = (array: Array<{
    time_spent: number
    session_id: string
    user_id: number
    part: string
    session_start: string
    session_end: string
    avg_speed: string
    avg_travel_angle: string
    avg_work_angle: string
}>, key: "avg_speed" | "avg_travel_angle" | "avg_work_angle") => {
    const arr = array.slice(array.length - 15, array.length)
    return arr.map((item, index) => ({
        x: item.part + " " + index,
        y: (parseFloat(item[key])).toFixed(2)
    }))
}

export const prepareActivityUsers = (array: any, users: Array<User>) => {
    const ret = []
    let total_tiem = 0
    for (const item in array) {
        total_tiem += array[item]
        ret.push({
            user: users[parseInt(item)],
            value: array[item]
        })
    }
    return [...ret.sort((a, b) => a.value > b.value ? -1 : 1).slice(0, 5).map(item => ({
        user: item.user,
        value: item.value > 60 ? Math.floor(item.value / 60) + " hr " + Math.round(item.value % 60) + " min" : Math.round(item.value) + " min"
    })),
    {
        user: {
            firstName: "Total Time Painted",
            lastName: ""
        },
        value: total_tiem > 60 ? Math.floor(total_tiem / 60) + " hr " + Math.round(total_tiem % 60) + " min" : Math.round(total_tiem) + " min"
    }
    ]
}

export const prepareActivityApps = (array: any, applications: Array<Applications>) => {
    const ret = []
    for (const item in array) {
        ret.push({
            application: applications[parseInt(item)],
            value: array[item]
        })
    }
    return ret.sort((a, b) => a.value > b.value ? -1 : 1).map(item => ({
        application: item.application,
        value: item.value > 60 ? Math.floor(item.value / 60) + " hr " + Math.round(item.value % 60) + " min" : Math.round(item.value) + " min"
    }))
}
const sum = nums => {
    let total = 0
    for (const num of nums) total += num
    return total
}
export const prepareMostPaintedParts = (array: Array<{ session_end: string, part: string, session_id: string, session_start: string, application_id: number, time_spent: number, user_id: number }>) => {
    const colors = ["#5b21b6", "#047857", "#ef4444", "#0284c7", "#64748b"]
    const part_counts = {
        "total": 0
    }
    for (const item of array) {
        part_counts["total"] += 1
        if (part_counts[item.part] === undefined) part_counts[item.part] = 1
        else part_counts[item.part] += 1
    }
    let arr = []
    for (const key in part_counts) arr.push({ [key]: part_counts[key] })
    arr = arr.sort((a, b) => a[Object.keys(a)[0]] > b[Object.keys(b)[0]] ? -1 : 1)
    if (Object.keys(part_counts).length > 5) arr = arr.slice(1, 5)
    if (part_counts["total"] - sum(arr.map(item => item[Object.keys(item)[0]])) > 0) arr.push({ "Other": part_counts["total"] - sum(arr.map(item => item[Object.keys(item)[0]])) })

    arr = arr.map((item, idx) => ({
        id: Object.keys(item)[0],
        label: Object.keys(item)[0],
        color: colors[idx],
        value: item[Object.keys(item)[0]]
    }))
    if (arr[0].label === "total") arr.shift()
    return arr
}

export const prepareActivityBrowse = (
    array: Array<{ session_end: string, part: string, session_id: string, session_start: string, application_id: number, time_spent: number, user_id: number }>,
    users: Array<User>,
    applications: Array<Applications>,
    filters: { users: Array<number>, parts: Array<string> }) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const sorted = [...array].sort((a, b) => new Date(a.session_start) > new Date(b.session_start) ? -1 : 1)
    const ret = []

    for (const item of sorted) {
        if (filters.users.length !== 0 && filters.users.includes(item.user_id) === false) continue
        if (filters.parts.length !== 0 && filters.parts.includes(item.part) === false) continue
        const date = new Date(item.session_start)
        ret.push({
            item: {
                ...item,
                date: months[date.getMonth()]
                    + " " + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
                    + " " + (date.getHours() < 10 ? "0" + date.getHours() : date.getHours())
                    + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes())
            },
            application: applications.find(a => [undefined, null].includes(a) === false && a.id === item.application_id),
            user: users[item.user_id]
        })
    }
    return ret
}

export const prepareUsersTable = (array: any, users: Array<User>) => {

}