import { NavLink } from "react-router-dom"
import { STATIC_URL } from "../../../Common/constants"
import Translate from "../../../components/Translate"

const ApplicationJsx = ({
    id,
    name,
    description,
    cover_path,
    primary_color
}) => (
    <div className="flex justify-center w-64" key={id}>
        <div className="rounded-lg overflow-hidden shadow bg-white w-full">
            <span className="block w-full" style={{ "background": primary_color }} >
                <img className="rounded-t-lg block w-64 h-64" src={STATIC_URL + cover_path} alt="" />
            </span>

            <div className="p-6 flex flex-col justify-between h-48">
                <h5 className="text-gray-900 text-xl font-medium">{name}</h5>
                <p className="text-gray-700 text-sm"> {description.slice(0, 60)} {description.length > 60 ? "..." : ""} </p>
                <NavLink to={"/applications/" + id} className="w-full text-center inline-block px-6 py-2.5 bg-cythero-primary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:shadow-lg transition duration-150 ease-in-out"><Translate label="View Details" /></NavLink>
            </div>

        </div>
    </div>
)

export default ApplicationJsx