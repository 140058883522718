import { useSelector } from "react-redux"
import { RootState } from "../Redux/Store"

const Translate: React.FC<{ label: string }> = ({ label }) => {
    const lang_id: number = useSelector((state: RootState) => state.user.current.language_id)
    const translation: string = useSelector((state: RootState) => state.language.translations.translations)
    try {
        return <>{translation[label][lang_id].length === 0 ? translation[label][2] : translation[label][lang_id]}</>
    } catch(e) {
        return Object.keys(translation).includes(label) === false ? <>{label}</> : <p className="block w-24 h-6 p-2 animate-pulse bg-slate-100/50"></p>
    }
}

export default Translate