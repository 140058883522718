
import React, { Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../Redux/Store"
import { UserActions } from "../../../Redux/User/Actions"
import { UserState } from "../../../Redux/User/Interface"
import { Address } from "../../../Common/Interfaces/Entities"
import CreateAddress from "./CreateAddress"
import EditAddress from "./EditAddress"
import Loading from "../../../components/Loading"

type userAddressType = Address | undefined

const AddressesContainer: React.FC = ({ }) => {

    const dispatch = useDispatch<AppDispatch>()
    const userReducer: UserState = useSelector((state: RootState) => state.user)

    useEffect(() => {
        dispatch(UserActions.readAddresses({ arguments: { user_id: userReducer.current.id } }))
        dispatch(UserActions.readCountries({ arguments: { hashed: true } }))
    }, [])

    const userAddress: userAddressType = userReducer.address.addresses.find(a => a !== undefined && a !== null && a.user_id === userReducer.current.id)
    const countries = userReducer.countries.countries.filter(c => c !== null && c !== undefined)
    return (
        <Fragment>
            <Loading
                isOpen={userReducer.address.readStatus === "pending" || userReducer.countries.readStatus === "pending"}
                message="Loading address information... Please wait." />
            {userAddress === undefined ?
                <CreateAddress
                    countries={countries}
                    userReducer={userReducer}
                /> :
                <EditAddress
                    userAddress={userAddress}
                    userReducer={userReducer}
                    countries={countries}
                />}
        </Fragment>
    )
}

export default AddressesContainer