import { useState } from "react"
import { useSelector } from "react-redux"
import { Organization, Purchase } from "../../../Common/Interfaces/Entities"
import { ApplicationsState } from "../../../Redux/Applications/Interface"
import { PurchasesState } from "../../../Redux/Purchases/Interface"
import { RootState } from "../../../Redux/Store"
import PurchaseHistoryJSX from "./PurchaseHistoryJSX"


const PurchaseHistory: React.FC<{ organization: Organization, application_id: number | null }> = ({
    organization, application_id
}) => {
    const purchasesReducer: PurchasesState = useSelector((state: RootState) => state.shop)
    const appsReducer: ApplicationsState = useSelector((state: RootState) => state.apps)
    const [showMore, setShowMore] = useState(false)
    const [popover, togglePopover] = useState<{ open: boolean, event: Event | undefined }>({ open: false, event: undefined })
    const [selected, setSelected] = useState<"" | "dlc" | "application" | "license" | "package">("")

    if (application_id === null) {
        const organization_purchases: Array<Purchase> = []
        for (let i = 0; i < purchasesReducer.purchases.length; ++i) {
            if (purchasesReducer.purchases[i] === null || purchasesReducer.purchases[i] === undefined) continue
            const p: Purchase = purchasesReducer.purchases[i]
            if (
                p.confirmed === 1 &&
                p.application_id !== null &&
                p.organization_id === organization.id &&
                p.product.type.includes("branding") === false &&
                (p.product.type !== "dlc" || (p.product.dlc_type_id === 5 &&
                    Math.round(new Date().getTime() - new Date(p.purchase_date).getTime()) / (1000 * 3600 * 24) <= p.product.subscription_duration)
                ) &&
                (selected === "" || p.product.type === selected) && 
                organization_purchases.find(pur => pur.application_id === p.application_id) === undefined
            ) organization_purchases.push(p)
        }
        organization_purchases.reverse()

        return <PurchaseHistoryJSX
            application_id={application_id}
            organization_id={organization.id}
            loading={purchasesReducer.readStatus === "pending"}
            purchases={showMore === true ? organization_purchases : [...organization_purchases].splice(0, 5)}
            count={organization_purchases.length}
            showMore={showMore}
            togglePopover={togglePopover}
            popover={popover}
            selected={selected}
            setSelected={setSelected}
            setShowMore={setShowMore}
        />
    } else {
        const package_purchases = []
        for (const p of purchasesReducer.purchases) {
            if (selected !== "" && selected !== "package") continue
            if (p === null || p === undefined || p.package_id === null || p.confirmed !== 1 || p.organization_id !== organization.id) continue
            const found_current = package_purchases.find(pur => pur !== null && pur !== undefined && pur.order_number === p.order_number)
            if (found_current !== undefined) continue
            if (p.product.application_id !== application_id) continue
            package_purchases.push({
                ...p,
                product: {
                    type: "package",
                    ...appsReducer.packages.packages.find(pkg => pkg !== null && pkg !== undefined && pkg.id === p.package_id)
                }
            })
        }


        const organization_purchases: Array<Purchase> = [...purchasesReducer.purchases.filter((p: Purchase) =>
            p !== null &&
            p !== undefined &&
            p.package_id === null &&
            p.confirmed === 1 &&
            (p.application_id === null || p.application_id === application_id) &&
            (p.product.type === "application" || p.product.application_id === application_id) &&
            p.organization_id === organization.id &&
            p.product.type.includes("branding") === false &&
            (p.product.type !== "dlc" || (p.product.dlc_type_id === 5 &&
                Math.round(new Date().getTime() - new Date(p.purchase_date).getTime()) / (1000 * 3600 * 24) <= p.product.subscription_duration)
            ) &&
            (selected === "" || p.product.type === selected)
        ), ...package_purchases].reverse()
        return <PurchaseHistoryJSX
            application_id={application_id}
            organization_id={organization.id}
            loading={purchasesReducer.readStatus === "pending" || appsReducer.packages.readStatus === "pending"}
            purchases={showMore === true ? organization_purchases : [...organization_purchases].splice(0, 5)}
            count={organization_purchases.length}
            showMore={showMore}
            togglePopover={togglePopover}
            popover={popover}
            selected={selected}
            setSelected={setSelected}
            setShowMore={setShowMore}
        />
    }
}

export default PurchaseHistory