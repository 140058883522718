import { IonInput, IonItem, IonLabel, IonList, IonTextarea } from "@ionic/react"


const Input = ({
    placeholder,
    name,
    handleChange,
    type,
    value
}) => {

    return (
        <IonList className='select-list' >
            <IonItem className='select-list'>
                <IonLabel position="stacked">{placeholder}</IonLabel>
                {type === "textarea" ?
                    <IonTextarea value={value} placeholder={placeholder} name={name} onIonChange={e => handleChange(e)} /> :
                    <IonInput type={type} value={value} placeholder={placeholder} name={name} onIonChange={e => handleChange(e)} />
                }
            </IonItem>
        </IonList>
    )
}

export default Input