import { insertInHash } from "../../Common/Functions"
import { createHandler, buildFromCreator } from "../ReduxTemplates"
import { PurchasesState as SliceInterface } from "./Interface"


export const PurchaseCreator = {
    createPurchase: createHandler({
        name: "purchase/create",
        method: "POST",
        route: "/api/purchases/purchases",
        key: "purchase",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.createStatus = "pending"
            state.createMessage = "Creating purchase... Please wait."
        },
        fulfilled: (state: SliceInterface, action) => {
            state.createStatus = "fulfilled"
            state.createMessage = "Purchase created succesfully."
            state.checkout_id = action.payload.checkout_id
            for (const purchase of action.payload.purchases) state.purchases = insertInHash(state.purchases, purchase, "id")
            for (const id of action.payload.deleted_cart_items) state.cart.carts = state.cart.carts.filter(i => i !== null && i !== undefined && i.id !== id)
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.createStatus = "rejected"
            state.createMessage = action.payload.msg
            return state
        }
    }),
    readPurchases: createHandler({
        name: "purchase/read",
        method: "GET",
        route: "/api/purchases/purchases",
        key: "",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.readStatus = "pending"
            state.readMessage = "Loading purchase(s)... Please wait."
        },
        fulfilled: (state: SliceInterface, action) => {
            state.readStatus = "fulfilled"
            state.readMessage = "Purchase(s) loaded succesfully."
            if (action.payload?.purchase !== undefined) insertInHash(state.purchases, action.payload.purchase, "id")
            else state.purchases = action.payload.purchases

            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.readStatus = "rejected"
            state.readMessage = action.payload.msg
            return state
        }
    }),
    updatePurchase: createHandler({
        name: "purchase/update",
        method: "PUT",
        route: "/api/purchases/purchases",
        key: "purchase",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.updateStatus = "pending"
            state.updateMessage = "Updating purchase... Please wait."
        },
        fulfilled: (state: SliceInterface, action) => {
            state.updateStatus = "fulfilled"
            state.updateMessage = "Purchase updated succesfully."
            for (const pur of action.payload.purchases) insertInHash(state.purchases, pur, "id")
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.updateStatus = "rejected"
            state.updateMessage = action.payload.msg
            return state
        }
    }),
    deletePurchase: createHandler({
        name: "purchase/delete",
        method: "DELETE",
        route: "/api/purchases/purchases",
        key: "purchase",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.deleteStatus = "pending"
            state.deleteMessage = "Deleting purchase... Please wait."
        },
        fulfilled: (state: SliceInterface, action) => {
            state.deleteStatus = "fulfilled"
            state.deleteMessage = "Purchase deleted."
            state.purchases = state.purchases.filter(p => p.id !== action.payload.purchase_id)
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.deleteStatus = "rejected"
            state.deleteMessage = action.payload.msg
            return state
        }
    }),
    createCart: createHandler({
        name: "purchase/createCart",
        method: "POST",
        route: "/api/purchases/shopping_cart",
        key: "cart",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.cart.createStatus = "pending"
            state.cart.createMessage = "Adding item to cart. Please wait..."
            return state
        },
        fulfilled: (state: SliceInterface, action) => {
            state.cart.createStatus = "fulfilled"
            state.cart.createMessage = "Item added to cart successfully."
            state.cart.carts = insertInHash(state.cart.carts, action.payload.cart, "id")
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.cart.createStatus = "rejected"
            state.cart.createMessage = action.payload.msg
            return state
        }
    }),
    readCart: createHandler({
        name: "purchase/readCart",
        method: "GET",
        route: "/api/purchases/shopping_cart",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.cart.readStatus = "pending"
            state.cart.readMessage = "Loading cart item(s). Please wait..."
            return state
        },
        fulfilled: (state: SliceInterface, action) => {
            state.cart.readStatus = "fulfilled"
            state.cart.readMessage = "Item(s) loaded."
            if (action.payload.params.hashed !== true) {
                for (const cart of action.payload.carts) state.cart.carts = insertInHash(state.cart.carts, cart, "id")
            } else state.cart.carts = action.payload.carts
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.cart.readStatus = "rejected"
            state.cart.readMessage = action.payload.msg
            return state
        }
    }),
    updateCart: createHandler({
        name: "purchase/updateCart",
        method: "PUT",
        route: "/api/purchases/shopping_cart",
        key: "cart",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.cart.updateStatus = "pending"
            state.cart.updateMessage = "Updating cart item. Please wait..."
            return state
        },
        fulfilled: (state: SliceInterface, action) => {
            state.cart.updateStatus = "fulfilled"
            state.cart.updateMessage = "Cart item updated successfully."
            state.cart.carts = insertInHash(state.cart.carts, action.payload.cart, "id")
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.cart.updateStatus = "rejected"
            state.cart.updateMessage = action.payload.msg
            return state
        }
    }),
    deleteCart: createHandler({
        name: "purchase/deleteCart",
        method: "DELETE",
        route: "/api/purchases/shopping_cart",
        skipAuth: false,
        pending: (state: SliceInterface, action) => {
            state.cart.deleteStatus = "pending"
            state.cart.deleteMessage = "Removing item from cart. Please wait..."
            return state
        },
        fulfilled: (state: SliceInterface, action) => {
            state.cart.deleteStatus = "fulfilled"
            state.cart.deleteMessage = "Cart item removed successfully."
            const new_carts = [...state.cart.carts]
            new_carts[action.payload.deleted_cart_id] = null
            state.cart.carts = new_carts
            return state
        },
        rejected: (state: SliceInterface, action) => {
            state.cart.deleteStatus = "rejected"
            state.cart.deleteMessage = action.payload.msg
            return state
        }
    })
}

export const [PurchaseActions, PurchaseReducers] = buildFromCreator(PurchaseCreator)