
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { OrganizationActions } from "../../Redux/Organizations/Actions"
import { OrganizationsState } from "../../Redux/Organizations/Interface"
import { AppDispatch, RootState } from "../../Redux/Store"
import { UserActions } from "../../Redux/User/Actions"
import { UserState } from "../../Redux/User/Interface"
import DeviceCard from "./DeviceCard"
import Loading from "../../components/Loading"

const Devices: React.FC = props => {
	const dispatch = useDispatch<AppDispatch>()

	useEffect(() => {
		dispatch(OrganizationActions.readDevice({}))
		dispatch(OrganizationActions.readOrganization({}))
		dispatch(UserActions.readUsers({}))
	}, [dispatch])

	const userReducer: UserState = useSelector((state: RootState) => state.user)
	const organizationReducer: OrganizationsState = useSelector((state: RootState) => state.organizations)

	if (
		userReducer.readUserStatus !== "fulfilled" ||
		organizationReducer.device.readStatus !== "fulfilled" ||
		organizationReducer.organization.readStatus !== "fulfilled"
	) return <Loading isOpen={true} message="Loading device and organizations data... Please wait." />

	let devices = []
	if (userReducer.current.type_id === 4) devices = organizationReducer.device.devices.filter(d => d !== null && d !== undefined)
	else if (userReducer.current.type_id >= 2) devices = organizationReducer.device.devices.filter(d => d !== null && d !== undefined && d.organization_id === userReducer.current.organization_id)
	else devices = organizationReducer.device.devices.filter(d => d !== null && d !== undefined && d.user_id === userReducer.current.id)
		
	return <div className="grid gap-5 grid-cols-1 lg:grid-cols-2 w-11/12 mx-auto my-10 mb-24">
		{devices.map(d => <DeviceCard
			key={d.id}
			user={userReducer.users[d.user_id]}
			device={d}
			organization={d.organization_id === null ? null : organizationReducer.organization.organizations[d.organization_id]}
		/>)}
	</div>
}

export default Devices